<template>
    <div
        class="tabbed-routes"
        :class="{'tabbed-routes--sidebar': sidebar}"
    >
        <div
            v-if="sidebar"
            class="tabbed-routes__sidebar"
        >
            <CategoryMenu>
                <CategoryMenuItem
                    v-for="tab in tabs"
                    :key="tab.key"
                    :active="tab.isActive"
                    @click="selectRoute(tab.route)"
                >
                    {{ tab.title }}
                </CategoryMenuItem>
            </CategoryMenu>
        </div>
        <div
            v-else
            class="tabbed-routes__tabs"
        >
            <div
                v-for="tab in tabs"
                :key="tab.key"
                class="tabbed-routes__tab"
                :class="{
                    'tabbed-routes__tab--active': tab.isActive,
                }"
                @click="selectRoute(tab.route)"
            >
                {{ tab.title }}
            </div>
        </div>
        <div class="tabbed-routes__view">
            <router-view />
        </div>
    </div>
</template>

<script setup>
import { useRouter } from 'vue-router';
import { computed, inject, provide, watch } from 'vue';
import CategoryMenu from '@/components/ui/category-menu/CategoryMenu.vue';
import CategoryMenuItem from '@/components/ui/category-menu/CategoryMenuItem.vue';

const LEVEL_SYMBOL = Symbol.for('tabbed-routes-level');

const props = defineProps({
    sidebar: Boolean,
});

const router = useRouter();
const level = inject(LEVEL_SYMBOL, 1);
provide(LEVEL_SYMBOL, level + 1);

const activeRoute = computed(() => router.currentRoute.value.matched[level]);
const parentRoute = computed(() => router.currentRoute.value.matched[level - 1]);
const siblingRoutes = computed(() => {
    return (parentRoute.value?.children ?? []).filter(route => !route.meta?.permissionChecker || route.meta.permissionChecker());
});

const tabs = computed(() =>
    siblingRoutes.value.map(route => ({
        route,
        key: route.name,
        title: route.meta?.title ?? route.name,
        isActive: route.name === activeRoute.value?.name,
    }))
);

const selectRoute = route => {
    router.replace(route);
};

watch(
    activeRoute,
    () => {
        if (!activeRoute.value) {
            const route = siblingRoutes.value[0];
            if (route) {
                selectRoute(route);
            }
        }
    },
    { immediate: true }
);
</script>

<style>
.tabbed-routes {
    @apply h-full flex flex-col;

    &--sidebar {
        @apply flex-row;
    }

    &__sidebar {
        @apply w-1/3 mr-5 px-4 py-5 bg-graphite-200 overflow-y-auto;
    }

    &__tabs {
        @apply flex mb-4;
    }

    &__tab {
        @apply relative z-10 mr-8 pb-1 font-frank font-medium text-sm leading-5 text-active-400 cursor-pointer;

        &:last-child {
            @apply mr-0;
        }

        &:after {
            content: '';
            @apply absolute bottom-0 left-0 w-full bg-transparent;
            height: 2px;
            border-radius: 1px;
        }

        &:hover {
            @apply text-active-500;
        }

        &--active {
            @apply text-active-500;

            &:after {
                @apply bg-active-500;
            }
        }
    }

    &__view {
        @apply flex-grow overflow-y-auto;
    }
}
</style>
