export const USER_PERMISSIONS = {
    // GL(general ledger): //
    ACCT_VIEW_JOURNAL_ENTRIES_LIST: 'acct_view_journal_entries_list',
    ACCT_ADD_JOURNAL_ENTRY: 'acct_add_journal_entry',
    ACCT_VIEW_JOURNAL_ENTRY_DETAILS: 'acct_view_journal_entry_details',
    ACCT_DELETE_JOURNAL_ENTRY_DETAILS: 'acct_delete_journal_entry_details',
    ACCT_IMPORT_JOURNAL_ENTRIES: 'acct_import_journal_entries',
    ACCT_VIEW_GL_ACCOUNTS_LIST: 'acct_view_GL_accounts_list',
    ACCT_ADD_GL_ACCOUNT: 'acct_add_GL_account',
    ACCT_VIEW_GL_ACCOUNTS_DETAILS: 'acct_view_GL_accounts_details',
    ACCT_VIEW_RECONCILE: 'acct_view_reconcile',
    ACCT_ADD_RECONCILE: 'acct_add_reconcile',
    ACCT_IMPORT_RECONCILE_TRANSACTIONS: 'acct_import_reconcile_transactions',
    ACCT_DELETE_RECONCILIATION: 'acct_delete_reconciliation',
    ACCT_ADD_MANUAL_GL_ADJUSTMENT: 'acct_add_manual_GL_adjustment',
    ACCT_VIEW_BANK_ACCOUNTS_LIST: 'acct_view_bank_accounts_list',
    ACCT_ADD_BANK_ACCOUNT: 'acct_add_bank_account',
    ACCT_EDIT_BANK_ACCOUNT: 'acct_edit_bank_account',
    ACCT_VIEW_GL_REPORTS: 'acct_view_GL_reports',
    ACCT_GENERATE_GL_BALANCE_SHEET_REPORT: 'acct_generate_GL_balance_sheet_report',
    ACCT_GENERATE_GL_BUDGET_COMPARISON_REPORT: 'acct_generate_GL_budget_comparison_report',
    ACCT_GENERATE_GL_REPORT: 'acct_generate_GL_report',
    ACCT_GENERATE_GL_INCOME_STATEMENT_REPORT: 'acct_generate_GL_income_statement_report',
    ACCT_GENERATE_GL_TRIAL_BALANCE_REPORT: 'acct_generate_GL_trial_balance_report',
    ACCT_VIEW_GL_SETTINGS: 'acct_view_GL_settings',
    ACCT_CHANGE_GL_CLOSING_DATE: 'acct_change_GL_closing_date',
    ACCT_EDIT_CHART_OF_ACCOUNTS: 'acct_edit_chart_of_accounts',
    ACCT_DELETE_CHART_OF_ACCOUNTS: 'acct_delete_chart_of_accounts',
    ACCT_EDIT_GL_SETUP: 'acct_edit_GL_setup',
    ACCT_REBUILD_GL_CACHE: 'acct_rebuild_GL_cache',

    // AP(accounts payable)
    ACCT_VIEW_AP_SETTINGS: 'acct_view_ap_settings',
    ACCT_VIEW_INVOICES: 'acct_view_invoices',
    ACCT_VIEW_INVOICE_DETAILS: 'acct_view_invoice_details',
    ACCT_ADD_NEW_INVOICE: 'acct_add_new_invoice',
    ACCT_EDIT_INVOICE_DETAILS: 'acct_edit_invoice_details',
    ACCT_IMPORT_INVOICES: 'acct_import_invoices',
    ACCT_VOID_INVOICE: 'acct_void_invoice',
    ACCT_DELETE_INVOICES: 'acct_delete_invoices',
    ACCT_VIEW_PAYMENTS: 'acct_view_payments',
    ACCT_ADD_NEW_PAYMENT: 'acct_add_new_payment',
    ACCT_VIEW_PAYMENT_DETAILS: 'acct_view_payment_details',
    ACCT_ACCOUNTING_DELETE_PAYMENT: 'acct_accounting_delete_payment',
    ACCT_MANAGE_INVESTORS: 'acct_manage_investors',
    ACCT_VIEW_INVESTORS: 'acct_view_investors',

    // AR(accounts receivable): //
    ACCT_VIEW_DEPOSIT_LIST: 'acct_view_deposit_list',
    ACCT_ADD_DEPOSIT: 'acct_add_deposit',
    ACCT_VIEW_DEPOSIT_DETAILS: 'acct_view_deposit_details',
    ACCT_POST_DEPOSITS: 'acct_post_deposits',
    ACCT_DELETE_DEPOSIT: 'acct_delete_deposit',
    ACCT_VIEW_CHARGE_LIST: 'acct_view_charge_list',
    ACCT_VIEW_TRANSACTION_DETAILS: 'acct_view_transaction_details',
    ACCT_DELETE_TRANSACTION_DETAILS: 'acct_delete_transaction_details',
    ACCT_ADD_CHARGES: 'acct_add_charges',
    ACCT_VIEW_RECURRING_CHARGES_LIST: 'acct_view_recurring_charges_list',
    ACCT_EDIT_RECURRING: 'acct_edit_recurring',
    ACCT_DELETE_RECURRING_DETAILS: 'acct_delete_recurring_details',
    ACCT_VIEW_AR_REPORTS: 'acct_view_AR_reports',
    ACCT_GENERATE_AGED_RECEIVABLES_REPORT: 'acct_generate_aged_receivables_report',
    ACCT_GENERATE_AGED_RECEIVABLES_MONTH_REPORT: 'acct_generate_aged_receivables_month_report',
    ACCT_GENERATE_DEPOSIT_REGISTER_REPORT: 'acct_generate_deposit_register_report',
    ACCT_GENERATE_RESIDENT_LEDGER_DETAILS_REPORT: 'acct_generate_resident_ledger_details_report',
    ACCT_GENERATE_ACCRUAL_INCOME_REPORT: 'acct_generate_accrual_income_report',
    ACCT_VIEW_AR_SETTINGS: 'acct_view_AR_settings',
    ACCT_ADD_CHARGE_CODE: 'acct_add_charge_code',
    ACCT_EDIT_CHARGE_CODE: 'acct_edit_charge_code',
    ACCT_DELETE_CHARGE_CODE: 'acct_delete_charge_code',
    ACCT_VIEW_PAYMENT_REVERSAL_REASONS: 'acct_view_payment_reversal_reasons',
    ACCT_EDIT_LATE_FEE_SCHEMA: 'acct_edit_late_fee_schema',
    ACCT_VIEW_LATE_FEE_SCHEMA: 'acct_view_late_fee_schema',
    ACCT_ADD_LATE_FEE_SCHEMA: 'acct_add_late_fee_schema',
    ACCT_VIEW_NSF_SETTINGS: 'acct_view_NSF_settings',
    ACCT_EDIT_NSF_SETTINGS: 'acct_edit_NSF_settings',
    // ACCT_ACCOUNTING_ADD_PAYMENT: 'acct_accounting_add_payment',
    // ACCT_VIEW_SODA_PROCESS: 'acct_view_SODA_process',
    // ACCT_VIEW_ACCELERATED_RENT_SETTINGS: 'acct_view_accelerated_rent_settings',
    // ACCT_ADD_NEW_CHARGE_CATEGORY: 'acct_add_new_charge_category',
    // ACCT_DELETE_PAYMENT_REVERSAL_REASONS: 'acct_delete_payment_reversal_reasons',
    // ACCT_DELETE_LATE_FEE_SCHEMA: 'acct_delete_late_fee_schema',
    // ACCT_EDIT_PAYMENT_REVERSAL_REASONS: 'acct_edit_payment_reversal_reasons',
    // ACCT_EDIT_ACCELERATED_RENT_SETTINGS: 'acct_edit_accelerated_rent_settings',
    // ACCT_REMOVE_CHARGE_CATEGORY: 'acct_remove_charge_category',
    // ACCT_ADD_PAYMENT_REVERSAL_REASONS: 'acct_add_payment_reversal_reasons',
    // ACCT_PRINT_VOIDED_CHECK: 'acct_print_voided_check',
    // ACCT_REOPEN_RECONCILIATION: 'acct_reopen_reconciliation',
    // ACCT_ADD_RECONCILIATION_RULES: 'acct_add_reconciliation_rules',

    // Vendors
    ACCT_MANAGE_VENDORS: 'acct_manage_vendors',
    ACCT_VIEW_VENDOR: 'acct_view_vendor',
    ACCT_ADD_VENDOR: 'acct_add_vendor',
    ACCT_EDIT_VENDOR: 'acct_edit_vendor',
    ACCT_DELETE_VENDOR: 'acct_delete_vendor',
    ACCT_VIEW_VENDOR_REPORTS: 'acct_view_vendor_reports',
    ACCT_GENERATE_VENDOR_REPORTS: 'acct_generate_vendor_reports',
};

export default USER_PERMISSIONS;
