<template>
    <div class="h-full overflow-auto">
        <file-manager-title>
            <div v-if="manager.libraryFilters.length" class="flex items-center justify-between">
                <div>
                    <span
                        v-for="directory in manager.libraryFilters"
                        :class="{ active: directory ===  currentTab}"
                        class="cursor-pointer mr-4 mt-4"
                        :key="directory"
                        @click="(filteredLibrary = filterByDirectory(directory), currentTab = directory)"
                    >
                        {{ getFilterLabel(directory) }}
                    </span>
                </div>
                <span v-if="manager.maxNbOfFiles !== selectedFiles.length" class="font-inter font-400 text-black text-sm">
                    select {{ manager.maxNbOfFiles - selectedFiles.length }} more image<template v-if="manager.maxNbOfFiles - selectedFiles.length > 1">s</template>
                </span>
            </div>
            <div v-else class="flex items-center justify-between">
                <span class="text-highlight-textSmall">media gallery</span>
                <span v-if="manager.maxNbOfFiles !== selectedFiles.length" class="font-inter font-400 text-black text-sm">
                    select {{ manager.maxNbOfFiles - selectedFiles.length }} more image<template v-if="manager.maxNbOfFiles - selectedFiles.length > 1">s</template>
                </span>
            </div>
        </file-manager-title>

        <div class="fm-preview-grid mb-20 mt-16">
            <div
                class="fm-thumbnail"
                v-for="file in filteredLibrary"
                :key="file.file_uuid"
                :class="getSelectedFileClass(file)"
                @click="toggleSelection(file)"
            >
                <img :src="file.thumbnail_url" class="cursor-pointer" />
            </div>
        </div>

        <div class="absolute bottom-0 left-0 w-full px-8 py-6 flex justify-between bg-gray-200">
            <button
                class="btn-primary btn-transparent"
                @click="close()"
            >
                cancel
            </button>
            <button
                class="btn-primary btn-solid"
                @click="save"
                v-if="selectedFiles.length"
            >
                select
            </button>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import FileManagerTitle from "@/components/ui/filemanager/FileManagerTitle";
import {orderBy} from "lodash-es";

export default {
    components: { FileManagerTitle },
    props: {
        managerId: {
            type: String,
            required: true
        }
    },
    emits: ['close'],
    data: () => {
        return {
            manager: {},
            library: [],
            filteredLibrary: [],
            selectedFiles: [],
            currentTab: 'marketing'
        }
    },
    computed: {
        ...mapGetters({
            getManagerById: 'files/getManagerById'
        }),
        canSelectMoreFiles() {
            return this.manager.maxNbOfFiles > this.selectedFiles.length;
        }
    },
    methods: {
        ...mapActions({
            clearManagerUploadedFiles: 'files/clearManagerUploadedFiles',
            setManagerSelectedFiles: 'files/setManagerSelectedFiles'
        }),
        isSelected(file) {
            return this.selectedFiles.length && this.selectedFiles.includes(file);
        },
        getSelectedFileClass(file) {
            return (this.isSelected(file)) ? 'fm-thumbnail_selected' : '';
        },
        toggleSelection(file) {
            if( this.isSelected(file) ) {
                this.unselectFile(file);
            } else {
                if(this.manager.maxNbOfFiles === 1) {
                    this.selectedFiles=[];
                    this.selectFile(file)
                }
                else {
                    this.selectFile(file);
                }
            }
        },
        selectFile(file) {
            if(this.canSelectMoreFiles)
                this.selectedFiles.push(file);
        },
        unselectFile(file) {
            this.selectedFiles = this.selectedFiles.filter( item => {
                return item.file_uuid !== file.file_uuid;
            });
        },
        save() {
            this.setManagerSelectedFiles( { managerId: this.manager.managerId, files: this.selectedFiles } );
            this.close();
        },
        close() {
            this.clearManagerUploadedFiles(this.manager.managerId);
            this.$emit('close');
        },
        filterByDirectory(directory) {
            return this.manager.library
                .filter(item => item.base_directory === directory)
        },
        getFilterLabel(directory) {
            return {
                'cms' : 'websites',
                'dh' : 'digital human',
                'marketing' : 'main gallery'
            }[directory];
        }
    },
    created() {
        this.manager = this.getManagerById(this.managerId);
    },
    mounted() {
        this.selectedFiles = this.manager.selectedFiles;
        let unselectedFiles = orderBy(
            this.manager.library
                .filter(item => !this.selectedFiles.includes(item)),
            ['created_at'],
            ['desc']
        );

        // sort library by selected files on top
        this.library = [].concat( this.selectedFiles, unselectedFiles );

        // filter the library based on base_directories
        this.filteredLibrary = this.manager.libraryFilters.length ?
            this.filterByDirectory(this.manager.libraryFilters[0]) :
            this.library;
    },
    unmounted(){
        this.close();
    }
}
</script>
<style scoped>
    .active{
        @apply border-b-2 border-blue-500;
    }
</style>
