<template>
    <Form
        :submit="submitPassword"
        class="w-full"
    >
        <div class="mb-4">
            <TextField
                name="confirmation_code"
                label="code"
                :validate="required"
                :disabled="isPending"
                autocomplete="off"
            />
        </div>
        <div class="mb-4">
            <TextField
                type="password"
                name="password"
                label="new password"
                :validate="required"
                :disabled="isPending"
                autocomplete="off"
            />
        </div>
        <MessageBlock
            v-if="error || confirmError"
            type="warning"
            class="flex mb-4"
        >
            <Icon name="warning" />
            <div class="ml-2">
                {{ error || confirmError }}
            </div>
        </MessageBlock>
        <div>
            <button
                :disabled="isPending || isLoading"
                type="submit"
                class="btn-primary btn-solid btn-auth"
            >
                reset my password
            </button>
        </div>
    </Form>
    <div class="mt-4 text-center">
        <button
            class="btn-link auth-btn-link"
            type="button"
            @click="backToSignIn"
        >
            back to sign in
        </button>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useRouter } from 'vue-router';
import { useAuthenticator } from '@aws-amplify/ui-vue';
import { confirmResetPassword } from 'aws-amplify/auth';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import { required } from '@/components/form/validators';
import MessageBlock from '@/components/ui/MessageBlock.vue';
import Icon from '@/components/ui/Icon.vue';

const props = defineProps({
    username: {
        type: String,
        default: null,
    },

    onSubmit: {
        type: Function,
        default: () => {},
    },
});

const isLoading = ref(false);
const confirmError = ref('');

const router = useRouter();

const facade = useAuthenticator();

const isPending = computed(() => facade.isPending);

const error = computed(() => facade.error);

const submitPassword = async ({ confirmation_code, password }) => {
    confirmError.value = '';

    try {
        if (props.username) {
            isLoading.value = true;
            // a user is redirected to the view with reset-password from email
            await confirmResetPassword({
                username: props.username,
                newPassword: password,
                confirmationCode: confirmation_code,
            });

            isLoading.value = false;

            facade.toSignIn();

            props.onSubmit();
        } else {
            // a regular auth flow
            await facade.submitForm({ confirmation_code, password });
        }
    } catch (e) {
        isLoading.value = false;
        confirmError.value = e.message;
        console.error(e);
    }
};

const backToSignIn = () => {
    facade.toSignIn();

    if (props.username) {
        router.replace({ name: 'home' });
    }
};
</script>
