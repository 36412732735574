import {
    LOAD_IMAGES,
    SET_IMAGES,
    SET_EDIT_IMAGE_URL,
    SET_ID_IMAGE_TO_EDIT,
    SET_IMAGE_DESCRIPTION,
    SET_IS_FROM_EDIT_PHOTO,
    SET_PHOTO_TAGS,
    SET_TAGS_VALUE,
    SET_LOADING,
    CLEAN_STATE,
} from './mutations';

const loadImages = ({ commit, state }) => commit(LOAD_IMAGES, state.images);

const setImages = ({ commit }, payload) => commit(SET_IMAGES, payload);

const setEditImageUrl = ({ commit }, payload) => commit(SET_EDIT_IMAGE_URL, payload);

const setIdImageToEdit = ({ commit }, payload) => commit(SET_ID_IMAGE_TO_EDIT, payload);

const setImageDescription = ({ commit }, payload) => commit(SET_IMAGE_DESCRIPTION, payload);

const setIsFromEditPhoto = ({ commit }, payload) => commit(SET_IS_FROM_EDIT_PHOTO, payload);

const setPhotoTags = ({ commit }, payload) => commit(SET_PHOTO_TAGS, payload);

const setTagsValue = ({ commit }, payload) => commit(SET_TAGS_VALUE, payload);

const setLoading = ({ commit }, payload) => commit(SET_LOADING, payload);

const cleanState = ({ commit }, payload) => commit(CLEAN_STATE, payload);

export default {
    loadImages,
    setImages,
    setEditImageUrl,
    setIdImageToEdit,
    setImageDescription,
    setIsFromEditPhoto,
    setPhotoTags,
    setTagsValue,
    setLoading,
    cleanState,
};
