export default {
    data() {
        return {
            prevCategories: [],
        };
    },

    methods: {
        canBeUndone(category) {
            const prevCategory = this.prevCategories.find(({ code }) => code === category.code);

            if (!prevCategory) return false;

            for (const status of category.statuses) {
                const prevStatus = prevCategory.statuses.find(({ code }) => code === status.code);

                if (status.name !== prevStatus.name || status.description !== prevStatus.description) {
                    return true;
                }
            }

            return false;
        },

        undoCategoryReset(category) {
            const prevCategory = this.prevCategories.find(({ code }) => code === category.code);
            const promises = [];

            for (const status of category.statuses) {
                const prevStatus = prevCategory.statuses.find(({ code }) => code === status.code);

                if (status.name !== prevStatus.name || status.description !== prevStatus.description) {
                    promises.push(
                        this.$cbrDataProvider
                            .updateStatus('unitStatuses', {
                                statusCode: status.code,
                                data: {
                                    name: prevStatus.name,
                                    description: prevStatus.description,
                                },
                            })
                            .then(updatedStatus => {
                                category.statuses[category.statuses.findIndex(({ code }) => code === status.code)] = updatedStatus;
                            })
                    );
                }
            }

            this.loading = true;
            Promise.all(promises)
                .then(() => {
                    this.prevCategories = this.prevCategories.filter(({ code }) => code !== category.code);
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
