import { defineStore } from 'pinia';

export const useIntegrationPartnersStore = defineStore('integrationPartners', {
    state: () => ({
        partnerTileColor: [],
        isButtonDisabled: false,
        activeTab: null,
        isWizardFormActive: false,
        isNextButtonClicked: false,
        isNextButtonActive: false,
        partnerWizardFormData: {},
        isBackButtonEnabled: false,
        isPartnerEditActive: false,
        selectedCustomer: {},
        selectedCommunity: {},
        resourceProperty: {},
        parameterLevel: '',
        isParameterTableViewActive: false,
        isPartnerLevelParameterActive: false,
        isCustomerLevelParameterActive: false,
        isCommunityLevelParameterActive: false,
        parameterLevelActiveTab: 1,
        communitiesActiveTab: 2,
        ipsv2Permissions: { permissions: [] },
        isCommunityParameterActive: false,
        activeParameterLabel: '',
    }),

    persist: true,

    getters: {
        hasPermission: state => permissionAlias => {
            return state.ipsv2Permissions?.permissions?.[0]?.permissions.some(permission => permission.id === permissionAlias);
        },

        hasRolePermission: state => {
            return state.ipsv2Permissions?.role;
        },
    },

    actions: {
        setPartnerTileColor(payload) {
            this.partnerTileColor = payload;
        },

        setEditModeValue(payload) {
            this.isButtonDisabled = payload;
        },

        setActiveTab(payload) {
            this.activeTab = payload;
        },

        setIsWizardFormActive(payload) {
            this.isWizardFormActive = payload;
        },

        setIsNextButtonClicked(payload) {
            this.isNextButtonClicked = payload;
        },

        setIsNextButtonActive(payload) {
            this.isNextButtonActive = payload;
        },

        setPartnerWizardFormData(payload) {
            this.partnerWizardFormData = payload;
        },

        setIsBackButtonEnabled(payload) {
            this.isBackButtonEnabled = payload;
        },

        setPartnerEditActive(payload) {
            this.isPartnerEditActive = payload;
        },

        setSelectedCustomer(payload) {
            this.selectedCustomer = payload;
        },

        setSelectedCommunity(payload) {
            this.selectedCommunity = payload;
        },

        setResourceProperty(payload) {
            this.resourceProperty = payload;
        },

        setParameterLevel(payload) {
            this.parameterLevel = payload;
        },

        setIsParameterTableViewActive(payload) {
            this.isParameterTableViewActive = payload;
        },

        setIsPartnerLevelParameterActive(payload) {
            this.isPartnerLevelParameterActive = payload;
        },

        setIsCustomerLevelParameterActive(payload) {
            this.isCustomerLevelParameterActive = payload;
        },

        setIsCommunityLevelParameterActive(payload) {
            this.isCommunityLevelParameterActive = payload;
        },

        setParameterLevelActiveTab(payload) {
            this.parameterLevelActiveTab = payload;
        },

        setCommunitiesActiveTab(payload) {
            this.communitiesActiveTab = payload;
        },

        setIpsV2Permissions(payload) {
            this.ipsv2Permissions = payload;
        },

        setCommunityParameterActive(payload) {
            this.isCommunityParameterActive = payload;
        },

        setActiveParameterLabel(payload) {
            this.activeParameterLabel = payload;
        },
    },
});
