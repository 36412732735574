import parsePhoneNumber from 'libphonenumber-js';

export const sanitizeCognitoUsername = value => {
    if (!value) {
        return '';
    }

    // test US phone number
    const phoneNumber = parsePhoneNumber(value, { defaultCountry: 'US', extract: false });
    if (phoneNumber?.isPossible()) {
        return phoneNumber.number;
    }

    return value.trim();
};

export const getDecodedQuery = encodedQuery => {
    if (!encodedQuery) {
        return {};
    }

    try {
        const decodedQuery = atob(encodedQuery);

        // new URLSearchParams(decodedQuery) loses `+` sign in user email, so reduce is a workaround
        return decodedQuery.split('&').reduce((acc, item) => {
            const [key, value] = item.split('=');
            return {
                ...acc,
                [key]: value,
            };
        }, {});
    } catch (e) {
        console.error(e);
        return {};
    }
};
