export default {
    // GET
    files: '/file',
    communityFiles: '/file/community/{communityId}',
    fileInfo: '/file/{fileId}',
    categories: 'community-category/by-community/{community_uuid}',

    // POST
    file: '/file',
    fileTags: '/tag',

    // DELETE
    // delete requests automatically bind {id} parameter at the end
    // this.$filemanagerDataProvider.delete('deleteFile', {  id: '9b229736-0a9c-4f42-9997-28589e093d84' });
    deleteFile: '/file',
    deleteTag: '/tag',

    // PUT
    // delete requests automatically bind {id} parameter at the end
    // this.$filemanagerDataProvider.update('updateFile', {  id: '9b229736-0a9c-4f42-9997-28589e093d84' });
    updateFile: '/file',

    // PATCH
    patchFile: {
        patch: ['PATCH', '/file/{file_uuid}'],
    },
};
