import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting/routes_names';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';

const routes = [
    {
        name: ROUTES.GL_BANK_ACCOUNTS.name,
        path: ROUTES.GL_BANK_ACCOUNTS.path,
        component: () => import('@/views/accounting/general_ledger/bank_accounts/BankAccountList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctGeneralLedger',
            breadcrumbName: 'bank accounts list',
            title: 'bank accounts list',
            size: 'large',
            flowStartsWith: ROUTES.GL_BANK_ACCOUNTS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_BANK_ACCOUNTS_LIST);
            },
        },
    },

    {
        name: ROUTES.GL_BANK_ACCOUNT_EDIT.name,
        path: ROUTES.GL_BANK_ACCOUNT_EDIT.path,
        component: () => import('@/views/accounting/general_ledger/bank_accounts/BankAccountForm'),
        meta: {
            loadInModal: true,
            actions: false,
            hideFromSearch: true,
            breadcrumbName: 'edit bank account',
            title: 'edit bank account',
            size: 'large',
            flowStartsWith: ROUTES.GL_BANK_ACCOUNT_EDIT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_EDIT_BANK_ACCOUNT);
            },
            editMode: true,
        },
    },

    {
        name: ROUTES.GL_BANK_ACCOUNT_CREATE.name,
        path: ROUTES.GL_BANK_ACCOUNT_CREATE.path,
        component: () => import('@/views/accounting/general_ledger/bank_accounts/BankAccountForm'),
        meta: {
            loadInModal: true,
            actions: false,
            hideFromSearch: true,
            breadcrumbName: 'add bank account',
            title: 'add bank account',
            size: 'large',
            flowStartsWith: ROUTES.GL_BANK_ACCOUNT_EDIT.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_BANK_ACCOUNT);
            },
        },
    },
];

export default routes;
