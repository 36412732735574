import { useStaffStore } from '@/stores/iot/staff';
import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.staff.staff-details',
        items: [
            {
                title: 'authorizations',
                routeName: 'iot.staff.staff-details.authorizations',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_READ_ACCESSES'),
            },
            {
                title: 'keyring',
                routeName: 'iot.staff.staff-details.keyring',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_READ_KEYRING'),
            },
        ],
    },
    {
        routeName: 'iot.staff.staff-details.authorizations',
        items: [
            {
                title: 'add location',
                routeName: 'iot.staff.staff-details.authorizations.add-location',
                params: {
                    id: '{id}',
                },
            },
        ],
        allowed: createPermissionChecker('USER_UPDATE_ACCESSES'),
    },
    {
        routeName: 'iot.staff.staff-details.keyring',
        items: [
            {
                title: 'key management',
                routeName: 'iot.staff.staff-details.keyring.management',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_READ_KEYRING'),
            },
        ],
    },
    {
        routeName: 'iot.vendors.index',
        items: [
            {
                title: 'create vendor',
                routeName: 'iot.vendors.create-vendor',
                allowed: createPermissionChecker('USER_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.vendors.vendor-details',
        items: [
            {
                title: 'authorizations',
                routeName: 'iot.vendors.vendor-details.authorizations',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_READ_ACCESSES'),
            },
            {
                title: 'keyring',
                routeName: 'iot.vendors.vendor-details.keyring',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_READ_KEYRING'),
            },
            {
                title: 'change email',
                routeName: 'iot.vendors.vendor-details.change-email',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_CHANGE_EMAIL'),
            },
            {
                title: 'add to community',
                routeName: 'iot.vendors.vendor-details.add-to-community',
                params: {
                    id: '{id}',
                },
                allowed: () => {
                    const staffStore = useStaffStore();
                    return createPermissionChecker('USER_ADD_TO_COMMUNITY')() && staffStore.isAllowedATC;
                },
            },
            {
                id: 'staff-reset-password',
                title: 'reset password',
                isRegular: true,
                allowed: createPermissionChecker('USER_RESET_PASSWORD'),
            },
            {
                id: 'delete-staff',
                title: 'delete vendor',
                showConfirmation: true,
                confirmationMessage: 'are you sure you want to delete vendor?',
                confirmBtnText: 'yes, delete',
                cancelBtnText: 'no, go back',
                confirmationType: 'warning',
                allowed: () =>
                    createPermissionChecker('USER_DELETE_FROM_COMMUNITY')() || createPermissionChecker('USER_DELETE_FROM_CUSTOMER')(),
            },
        ],
    },
    {
        routeName: 'iot.vendors.vendor-details.authorizations',
        items: [
            {
                title: 'add location',
                routeName: 'iot.vendors.vendor-details.authorizations.add-location',
                params: {
                    id: '{id}',
                },
            },
        ],
        allowed: createPermissionChecker('USER_UPDATE_ACCESSES'),
    },
    {
        routeName: 'iot.vendors.vendor-details.keyring',
        items: [
            {
                title: 'key management',
                routeName: 'iot.vendors.vendor-details.keyring.management',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('USER_READ_KEYRING'),
            },
        ],
    },
];
