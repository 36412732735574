import actions from './actions';
import mutations from './mutations';

const state = {
    amenityType: null,
    amenity: null,
    amenityDetail: null,
    amenities: [],
    amenityDetails: [],
    changedState: [],
    isSetupCompleted: false,
    loading: false,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
