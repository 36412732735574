const getDeploys = state => {
    return state.deploys;
};

const getStagingDeploys = state => {
    return state.deploys.filter(deploy => !deploy.is_production);
};

const getProductionDeploys = state => {
    return state.deploys.filter(deploy => deploy.is_production);
};

export default {
    getDeploys,
    getStagingDeploys,
    getProductionDeploys,
};
