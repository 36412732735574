import { mapActions, mapGetters } from 'vuex';
import { isEqual } from 'lodash-es';
import permissionFetchers from '@/api/permission_fetchers';
import { v4 as uuid } from 'uuid';
import EventBus from '@/utils/EventBus';

const REFRESH_INTERVAL = 5 * 60 * 1000;

export default {
    data() {
        return {
            fetchAppPermissionsIntervalId: undefined,
            taskIds: new Set(),
        };
    },
    computed: {
        ...mapGetters({
            appsPermissions: 'auth/appsPermissions',
            appAccesses: 'auth/appAccesses',
        }),
    },
    methods: {
        ...mapActions({
            setAppPermissions: 'auth/setAppPermissions',
            clearAppPermissions: 'auth/clearAppPermissions',
        }),
        fetchAppPermissions() {
            this.taskIds.clear();

            Object.keys(this.appsPermissions)
                .filter(code => this.appAccesses?.find(app => app === code) === undefined)
                .forEach(code => this.clearAppPermissions(code));

            if (!this.appAccesses) return;

            for (const appCode of this.appAccesses) {
                if (permissionFetchers[appCode]) {
                    const id = uuid();
                    this.taskIds.add(id);
                    permissionFetchers[appCode](this).then(permissions => {
                        if (this.taskIds.has(id)) {
                            this.setAppPermissions({ appCode, permissions });
                        }
                    });
                }
            }
        },
        startAppPermissionsPolling() {
            this.fetchAppPermissions();
            // to be be replaced with WST events listening somewhere in the future
            clearInterval(this.fetchAppPermissionsIntervalId);
            this.fetchAppPermissionsIntervalId = setInterval(this.fetchAppPermissions, REFRESH_INTERVAL);
        },
    },
    watch: {
        appAccesses: {
            handler: function (val, oldVal) {
                if (!isEqual(val, oldVal)) {
                    this.startAppPermissionsPolling();
                }
            },
            deep: true,
        },
        community: {
            handler(community) {
                if (community) {
                    this.startAppPermissionsPolling();
                }
            },
            immediate: true,
        },
    },
    mounted() {
        this.startAppPermissionsPolling();
        EventBus.on('refresh-permissions', this.fetchAppPermissions);
    },
    beforeUnmount() {
        clearInterval(this.fetchAppPermissionsIntervalId);
        EventBus.off('refresh-permissions', this.fetchAppPermissions);
    },
};
