import actions from './actions';
import mutations from './mutations';

const state = {
    isSetupCompleted: false,
    currentTab: 1,
    comesFromFees: false,
    isFromSetup: false,
    loading: false,
};

export default {
    namespaced: true,
    state,
    actions,
    mutations,
};
