import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'superAdmins.index',
        items: [
            {
                title: 'add new super admin',
                routeName: 'superAdmins.create',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_SUPERADMIN),
            },
            {
                title: 'deleted super admins',
                routeName: 'superAdmins.deletedAdmins.index',
                params: {},
            },
        ],
    },
    {
        routeName: 'superAdmins.edit',
        items: [],
    },
];
