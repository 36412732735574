import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    tmpBuffer: undefined,
    list: {
        filterValues: {},
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
