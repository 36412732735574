const loadFloorplans = ({ commit }, payload) => {
    commit('LOAD_FLOORPLANS', payload);
};

const clearFloorplans = ({ commit }) => {
    commit('CLEAR_FLOORPLANS');
};

export default {
    loadFloorplans,
    clearFloorplans,
};
