const all = state => {
    return state.websites;
};

const getWebsiteById = state => id => {
    if (!state.websites.length) return null;

    return state.websites.find(website => website.id === id);
};

const getWebsitesByCommunityId = state => id => {
    if (!state.websites.length) return [];

    return state.websites.filter(website => website.communities_id === id);
};

export default {
    all,
    getWebsiteById,
    getWebsitesByCommunityId,
};
