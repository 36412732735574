import { cloneDeep } from 'lodash-es';
import { ROOT_LOCATION_ID } from '@/utils/constants/location-tree';

/**
 * Update locations tree partly or fully
 * @param {Object} locationTree Location tree to add
 * @param {Object} currentLocations Current location tree (optional)
 * @returns {Object} Updated location tree
 */
export const updateLocationTree = function (locationTree, currentLocations) {
    const resultLocations = currentLocations
        ? cloneDeep(currentLocations)
        : {
            [ROOT_LOCATION_ID]: {
                id: ROOT_LOCATION_ID,
            },
        };

    const flattenLocationTree = locations => {
        locations.forEach(location => {
            const parent = resultLocations[location.parentId];
            const hasChildNodes = location.children && location.children[0]?.id;

            if (parent && !parent.children) {
                parent.children = locations.filter(loc => loc.parentId === location.parentId).map(loc => loc.id);
            }

            const children = hasChildNodes ? location.children.map(location => location.id) : location.children;

            resultLocations[location.id] = {
                ...location,
                children,
            };

            if (hasChildNodes) {
                flattenLocationTree(
                    location.children.map(loc => ({
                        ...loc,
                        parentId: location.id,
                    }))
                );
            }
        });
    };

    const preparedLocationsTree = locationTree.map(location => ({
        ...location,
        parentId: location.parentId || ROOT_LOCATION_ID,
    }));

    flattenLocationTree(preparedLocationsTree);

    return resultLocations;
};

/**
 * @param {Object} tree - structured Cloned tree object
 * @param {String|Number} currentId - rootId of tree
 * @param {String} searchQuery - search query
 */
export const treeSearch = function (tree, currentId, searchQuery) {
    if (!searchQuery) return false;
    let isChildrenContains = false;
    [...tree[currentId].children].forEach(id => {
        if (treeSearch(tree, id, searchQuery)) {
            const index = tree[currentId].children.findIndex(x => x === id);
            if (index !== -1) {
                tree[currentId].children.splice(index, 1);
            }
        } else {
            isChildrenContains = isChildrenContains || true;
        }
    });

    const isSelfContains =
        typeof searchQuery === 'function'
            ? searchQuery(tree[currentId])
            : tree[currentId].name.toLowerCase().includes(searchQuery.toLowerCase());

    if (!isChildrenContains && !isSelfContains) {
        delete tree[currentId];
    }
    return !isChildrenContains && !isSelfContains;
};

/**
 * Filter location tree nodes by provided condition
 * @param {Object} locationTree - location tree object
 * @param {Function} filterCondition - function used to check filter condition
 * @returns {Object} Filtered location tree
 */
export const filterTreeByCondition = (locationTree = {}, filterCondition = () => true) => {
    const locationIdsToHide = [];
    const locationsIds = Object.keys(locationTree);
    const leafNodeIdsWithAccess = locationsIds.filter(locationId => {
        return !locationTree[locationId].children?.length && !filterCondition(locationTree[locationId]);
    });
    const filteredLocationTree = {};
    const checkForHide = locationId => {
        if (!locationId || !locationTree[locationId] || filterCondition(locationTree[locationId]) || locationId === ROOT_LOCATION_ID) {
            return;
        }

        const location = locationTree[locationId];
        const hasConditionChild = location?.children?.findIndex(id => filterCondition(locationTree[id])) >= 0;

        if (!hasConditionChild) {
            locationIdsToHide.push(String(locationId));
            const parentId = location?.parentId;

            if (parentId) {
                checkForHide(parentId);
            }
        }
    };
    const removeHiddenChildren = (location = {}, hiddenLocationsIds = []) => {
        return {
            ...location,
            children: location?.children?.length ? location.children.filter(id => !hiddenLocationsIds.includes(String(id))) : [],
        };
    };

    locationIdsToHide.push(...leafNodeIdsWithAccess);

    leafNodeIdsWithAccess.forEach(locationId => {
        const parentId = locationTree[locationId].parentId;
        checkForHide(parentId);
    });

    locationsIds.forEach(locationId => {
        if (!locationIdsToHide.includes(locationId)) {
            filteredLocationTree[locationId] = removeHiddenChildren(locationTree[locationId], locationIdsToHide);
        }
    });

    return filteredLocationTree;
};

/**
 * sort locations by alphanumeric order
 * @param {Object} locationsTree - location tree object
 */
export const sortLocations = (locationsTree = {}) => {
    Object.values(locationsTree).forEach(location => {
        location.children.sort((a, b) => {
            const location1 = locationsTree[a].name;
            const location2 = locationsTree[b].name;
            return location1.localeCompare(location2, 'en', { numeric: true });
        });
    });
};
