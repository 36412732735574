import { createPermissionChecker, PORTFOLIO_PERMISSIONS, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        path: '/applications',
        name: 'applications.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/ApplicationsList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'applications',
            breadcrumbName: 'applications',
            flowStartsWith: 'applications.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APPS),
        },
    },
    // Bundles
    {
        path: '/applications/bundles',
        name: 'applications.bundles.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/bundles/BundlesList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'bundles',
            title: 'bundles management',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_BUNDLES),
        },
    },
    {
        path: '/applications/bundles/create',
        name: 'applications.bundles.create',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/bundles/BundleCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new bundle',
            hideFromSearch: true,
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_BUNDLE),
        },
    },
    {
        name: 'applications.bundles.details',
        path: '/applications/bundles/:id/details',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/bundles/BundleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view',
            title: 'view bundle',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_BUNDLE_DETAILS),
        },
    },
    {
        path: '/applications/bundles/:id',
        name: 'applications.bundles.edit',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/bundles/BundleEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit bundle',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_BUNDLE),
        },
    },
    // Application
    {
        path: '/applications/create',
        name: 'applications.create',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/ApplicationsCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add new application',
            breadcrumbName: 'add new',
            flowStartsWith: 'applications.create',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_APP),
        },
    },
    {
        name: 'applications.details',
        path: '/applications/:appId/details',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/ApplicationDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view',
            title: 'view application',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_DETAILS),
        },
    },
    {
        path: '/applications/:appId',
        name: 'applications.edit',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/ApplicationsEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'edit application',
            breadcrumbName: 'edit',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_APP),
        },
    },
    {
        path: '/applications/:appId/customers',
        name: 'applications.customers.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/customers/AppCustomersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'customer access',
            breadcrumbName: 'customer access',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMER_APP_ACCESS),
        },
    },
    {
        path: '/applications/:appId/customers/:customerId/:appAccessId/users',
        name: 'applications.customers.users',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/customers/AppCustomerUsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'users',
            breadcrumbName: 'users',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMER_APP_ACCESS),
        },
    },
    {
        path: '/applications/:appId/customers/:customerId/:appAccessId/users/:userId/details',
        name: 'applications.customers.users.details',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/customers/users/UserDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'user',
            breadcrumbName: 'user details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMER_APP_ACCESS),
            readonly: true,
        },
    },
    //  Roles
    {
        path: '/applications/:appId/roles',
        name: 'applications.roles.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/roles/RolesList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'roles',
            breadcrumbName: 'roles',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_ROLES),
        },
    },
    {
        path: '/applications/:appId/roles/:roleId/details',
        name: 'applications.roles.details',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'role details',
            title: 'role details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_ROLES),
        },
    },
    {
        path: '/applications/:appId/deleted-roles',
        name: 'applications.deletedRoles.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/roles/DeletedRolesList.vue'),
        meta: {
            loadInModal: true,
            title: 'deleted roles',
            breadcrumbName: 'deleted roles',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_ROLES),
        },
    },
    {
        path: '/applications/:appId/deleted-roles/:roleId/details',
        name: 'applications.deletedRoles.details',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/roles/RoleDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'deleted role details',
            title: 'deleted role details',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_ROLES),
        },
    },
    //  Permissions
    {
        path: '/applications/:appId/permissions',
        name: 'applications.permissions.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/permissions/PermissionsList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            title: 'permissions',
            breadcrumbName: 'permissions',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_PERMISSIONS),
        },
    },
    {
        path: '/applications/:appId/deleted-permissions',
        name: 'applications.deletedPermissions.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/permissions/DeletedPermissionsList.vue'),
        meta: {
            loadInModal: true,
            title: 'deleted permissions',
            breadcrumbName: 'deleted permissions',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_PERMISSIONS),
        },
    },
    // Deleted applications
    {
        path: '/deleted-applications',
        name: 'applications.deletedApplications.index',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/DeletedApplicationsList'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'deleted applications',
            breadcrumbName: 'deleted applications',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_APPS),
        },
    },
    {
        path: '/deleted-applications/:appId/details',
        name: 'applications.deletedApplications.details',
        component: () => import(/* webpackChunkName: "qxtapp" */ '@/views/auth/applications/ApplicationDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            title: 'view application',
            breadcrumbName: 'view',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_APPS),
        },
    },
];
