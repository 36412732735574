const actions = [
    {
        routeName: 'manage_application.details',
        items: [
            {
                id: 'add-applicant',
                title: 'add new applicant',
                routeName: 'manage_application.add_person',
                params: { role: 'applicant' },
            },
            {
                id: 'add-guarantor',
                title: 'add guarantor',
                routeName: 'manage_application.add_person',
                params: { role: 'guarantor' },
            },
        ],
    },
];

export default actions;
