export default {
    // GET
    websites: '/websites',
    websitePages: '/websites/{websiteId}/pages',
    widgets: '/widgets',
    amenitiesIcons: '/amenity-icons',
    communityAmenitiesTypes: '/community-amenity-types',
    customerAmenities: '/customer-community-amenities',
    customerCommunityAmenities: '/community-amenities/{communityId}',
    customerPagesWidgets: '/customer-widgets',
    customerPagesWidgetsByWebsite: '/customer-widgets-website/{websiteId}',
    globalTemplates: '/global-templates',
    medias: '/customer-images',
    deployments: '/websites/{websiteId}/deployments',
    floorplans: '/websites/{websiteId}/floorplans',
    floorplan: '/websites/{websiteId}/floorplans/{floorplanName}',
    showWebsite: '/websites',
    checkWebsiteDns: '/websites/{websiteId}/check-dns?domain={checkDnsDomain}&target={checkDnsTarget}',
    checkWebsiteProd: '/websites/website/check-prod-domain?domain={domainUrl}',
    pageWidget: '/websites/{websiteId}/pages/{pageName}/widgets',
    analyticsSiteInfo: '/websites/{websiteId}/analytics',
    analyticsLeadInfo: '/websites/{websiteId}/leads',
    customerCommunities: '/customers/{customerId}/communities',
    pointsOfInterest: '/community-points-of-interest/{communityId}',
    pointsOfInterestCategories: '/customer-point-of-interest-categories',
    websiteIdbyCommunity: '/websites/community/{communityId}',
    websiteTemplate: '/websites/{websiteId}/templates',
    websitebyId: '/websites/{websiteId}',
    amenityById: '/customer-community-amenities/{amenityId}',
    getCustomDNS: 'websites/{websiteId}/custom-dns',
    websiteHasHomePage: 'websites/{websiteId}/has-homepage',
    alerts: 'websites/{websiteId}/alerts',
    alertsSources: 'websites/{websiteId}/alerts-sources',
    auditableTypes: 'audits/auditable-types',
    auditsUserRoles: 'audits/user-roles',
    auditsEvents: 'audits/events',
    audits: 'audits',

    // POST
    mediaTags: '/websites/{websiteId}/images/{websiteImageId}/multiple-tags',
    createPage: '/websites/{websiteId}/pages',
    createOrUpdateFloorplan: '/websites/{websiteId}/floorplans',
    createWebsite: '/websites',
    createWebsiteTemplate: '/websites/{websiteId}/templates',
    createWebsiteIntegrationPartner: '/websites/{websiteId}/integrations',
    createCustomerAmenity: '/customer-community-amenities',
    createCustomerCommunityAmenity: '/community-amenities/{communityId}',
    reorderCustomerCommunityAmenities: '/community-amenities/{communityId}/reorder',
    createWebsiteImages: '/websites/{websiteId}/images',
    importFloorplansFile: '/websites/{websiteId}/floorplans-import/preview',
    importFloorplansProcess: '/websites/{websiteId}/floorplans-import/process',
    createPageWidget: '/v2/websites/{websiteId}/page-widgets',
    savePageWidgetValues: '/websites/{websiteId}/widgets/{pageWidgetId}/multiple-values',
    deletePageWidgetValues: '/websites/{websiteId}/widgets/{pageWidgetId}/delete-values',
    updatePointsOfInterest: '/community-points-of-interest/{communityId}/multiple-points',
    createPointOfInterestCategory: '/customer-point-of-interest-categories',
    createDeploy: 'websites/{websiteId}/deploy',
    multipleCustomerAmenities: '/customer-community-amenities/multiple',
    reorderWidgets: 'websites/{websiteId}/pages/{pageName}/reorder-widgets',
    createCustomDNS: 'websites/{websiteId}/custom-dns',

    // PUT
    updatePage: '/websites/{websiteId}/pages',
    updateIntegrationPartners: '/websites/{websiteId}/integrations-multiple',
    updateWebsite: '/websites',
    updateWebsiteTemplate: '/websites/{websiteId}/templates',
    updatePageWidget: '/v2/websites/{websiteId}/page-widgets',
    updateMultipleAmenities: '/community-amenities-multiple/{communityId}',

    // DELETE
    deletePage: '/websites/{websiteId}/pages',
    deleteFloorplan: '/community-floorplans/{communityId}',
    deletePageWidget: '/v2/websites/{websiteId}/page-widgets',
    deletePointOfInterestCategory: '/customer-point-of-interest-categories',

    // POST request behaving like PUT and DELETE due to http manager limitations
    updateAmenity: '/community-amenities/{communityId}/update-amenity',
    deleteCustomerCommunityAmenity: '/community-amenities/{communityId}/delete-amenity',
};
