import store from '@/store';
import { RI } from '@/utils/ppm/permissions';

const createPermissionChecker = permissionId => () => store.getters['ri/hasPermission'](permissionId);

export default [
    {
        routeName: 'ri.index',
        items: [
            {
                title: 'add new rentable item',
                routeName: 'ri.create',
                params: {},
                allowed: createPermissionChecker(RI.MANAGE_RI),
            },
        ],
    },
];
