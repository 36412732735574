export const SET_SETUP_COMPLETED = 'SET_SETUP_COMPLETED';
export const SET_CURRENT_TAB = 'SET_CURRENT_TAB';
export const SET_IS_FROM_SETUP = 'SET_IS_FROM_SETUP';
export const SET_COMES_FROM_FEES = 'SET_COMES_FROM_FEES';
export const SET_LOADING = 'SET_LOADING';

export default {
    [SET_SETUP_COMPLETED]: (state, payload) => (state.isSetupCompleted = payload),
    [SET_CURRENT_TAB]: (state, payload) => (state.currentTab = payload),
    [SET_IS_FROM_SETUP]: (state, payload) => (state.isFromSetup = payload),
    [SET_COMES_FROM_FEES]: (state, payload) => (state.comesFromFees = payload),
    [SET_LOADING]: (state, payload) => (state.loading = payload),
};
