<template>
    <router-link :to="{ name: route, params: params, query: query }">
        <slot />
    </router-link>
</template>

<script>
export default {
    props: {
        route: {
            type: String,
            required: true,
        },

        params: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },

        query: {
            type: Object,
            required: false,
            default: () => {
                return {};
            },
        },
    },
};
</script>
