const routes = [
    {
        name: 'digital_human_ai_settings.index',
        path: '/digital-human-settings_ai',
        component: () => {
            return import('@/views/digital_human_ai/digital_human_settings/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'select community settings',
            title: 'conversation topics and settings',
            size: 'normal',
            flowStartsWith: 'digital_human_settings.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['DHAI'],
            product: 'dh',
        },
    },
];

export default routes;
