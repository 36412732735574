<template>
    <div class="flex flex-col h-full overflow-hidden">
        <div class="flex items-baseline justify-between mb-8">
            <div class="font-frank font-normal text-sm text-black">
                unit statuses
            </div>
            <router-link
                v-if="hasPermission(CBR.UNIT_STATUS_SETTINGS_UPDATE)"
                :to="{ name: 'cbr.unit.statuses.bulk' }"
                class="text-2sm text-active-500 font-frank font-normal underline"
            >
                apply statuses to other communities
            </router-link>
        </div>

        <Tabs
            v-if="defaultStatuses"
            class="flex-grow overflow-hidden"
            @activeIndexChange="index => (activeTabIndex = index)"
        >
            <Tab
                title="current occupancy"
                class="mt-4"
            >
                <CurrentOccupancy
                    :editable="hasPermission(CBR.UNIT_STATUS_SETTINGS_UPDATE)"
                    :default-categories="defaultStatuses['CURRENT_OCCUPANCY']"
                />
            </Tab>

            <Tab
                title="physical condition"
                class="mt-4"
            >
                <PhysicalCondition
                    :editable="hasPermission(CBR.UNIT_STATUS_SETTINGS_UPDATE)"
                    :default-categories="defaultStatuses['PHYSICAL_STATUS']"
                />
            </Tab>

            <Tab
                title="future occupancy"
                class="mt-4"
            >
                <FutureOccupancy
                    :editable="hasPermission(CBR.UNIT_STATUS_SETTINGS_UPDATE)"
                    :default-categories="defaultStatuses['FUTURE_OCCUPANCY']"
                />
            </Tab>

            <Tab
                title="rules"
                class="mt-4"
            >
                <Rules
                    v-if="activeTabIndex === 3"
                    :editable="hasPermission(CBR.UNIT_STATUS_SETTINGS_UPDATE)"
                />
            </Tab>
        </Tabs>

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import Tabs from '@/components/ui/tabs/Tabs';
import Tab from '@/components/ui/tabs/Tab';
import CurrentOccupancy from '@/components/community_settings/unit_statuses/CurrentOccupancy';
import FutureOccupancy from '@/components/community_settings/unit_statuses/FutureOccupancy';
import PhysicalCondition from '@/components/community_settings/unit_statuses/PhysicalCondition';
import Rules from '@/components/community_settings/unit_statuses/Rules';
import { mapGetters } from 'vuex';
import Loader from '@/components/ui/Loader';
import { useAlertStore } from '@/stores/alert';
import { CBR } from '@/utils/ppm/permissions';

export default {
    components: { Loader, Rules, PhysicalCondition, FutureOccupancy, CurrentOccupancy, Tab, Tabs },

    setup() {
        const { notifyError } = useAlertStore();
        return { notifyError };
    },

    data() {
        return {
            loading: false,

            defaultStatuses: null,
            activeTabIndex: 0,
            CBR,
        };
    },

    computed: {
        ...mapGetters({
            hasPermission: 'cbr/hasPermission',
        }),
    },

    created() {
        this.loading = true;
        this.$cbrDataProvider
            .getDefault('unitStatuses')
            .then(response => {
                this.defaultStatuses = response;
            })
            .catch(error => {
                this.notifyError(error);
            })
            .finally(() => {
                this.loading = false;
            });
    },
};
</script>
