import vuexStore from '@/store';
import { defineStore } from 'pinia';
import { useRenewalsStore } from '@/stores/leasing/renewals';
import { useStorage } from '@vueuse/core';

export const useLeasingStore = defineStore('leasing', {
    state: () => ({
        renewalsStore: useRenewalsStore(),
        payerByPartyIdMap: {},
        temporaryCharges: useStorage('temporaryCharges', []),
        temporaryConcessions: useStorage('temporaryConcessions', []),
        deletedTransactions: useStorage('deletedTransactions', []),
        renewalOption: useStorage('renewalOption', {}),
    }),

    getters: {
        hasPermission: () => permissionAlias =>
            permissionAlias && vuexStore.getters['auth/getAppPermissions']('LEASING')?.includes(permissionAlias),
    },

    actions: {
        setPayerByPartyIdMap(payload) {
            this.payerByPartyIdMap = payload;
        },

        setTemporaryCharge(payload) {
            this.temporaryCharges = [...this.temporaryCharges, payload];
        },

        setTemporaryConcessions(payload) {
            this.temporaryConcessions = [...this.temporaryConcessions, payload];
        },

        setDeletedTransactions(payload) {
            this.deletedTransactions = [...this.deletedTransactions, payload];
        },

        resetTemporaryCharges() {
            this.temporaryCharges = [];
        },

        resetTemporaryConcessions() {
            this.temporaryConcessions = [];
        },

        resetDeletedTransactions() {
            this.deletedTransactions = [];
        },

        deleteTemporaryCharge(payload) {
            const parsedTemporaryCharges = this.temporaryCharges.map(el => JSON.parse(el));
            const filteredCharges = parsedTemporaryCharges.filter(charge => charge.transactionId !== payload);
            this.temporaryCharges = filteredCharges.map(item => JSON.stringify(item));
        },

        deleteTemporaryConcession(payload) {
            const parsedTemporaryConcessions = this.temporaryConcessions.map(el => JSON.parse(el));
            const filteredConcessions = parsedTemporaryConcessions.filter(concession => concession.transactionId !== payload);
            this.temporaryConcessions = filteredConcessions.map(item => JSON.stringify(item));
        },

        setRenewalOption(payload) {
            this.renewalOption = payload;
        },

        resetRenewalOption() {
            this.renewalOption = {};
        },
    },
});
