const routes = [
    {
        name: 'dh_ai.availability_pricing.index',
        path: '/digital-human/availability-pricing-units',
        component: () => import('@/views/digital_human_ai/availability_pricing/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'unit availability and pricing',
            title: 'unit availability and pricing',
            size: 'normal',
            flowStartsWith: 'dh.availability_pricing.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['DHAI'],
            product: 'dh',
        },
    },
    {
        name: 'dh_ai.availability_pricing.template',
        path: '/digital-human/availability-pricing-units/template',
        component: () => import('@/views/digital_human_ai/availability_pricing/Template'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'import availability list',
            size: 'normal',
            flowStartsWith: 'dh.availability_pricing.template',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['DHAI'],
            product: 'dh',
        },
    },
    {
        name: 'dh_ai.availability_pricing.create',
        path: '/digital-human/availability-pricing-units/create',
        component: () => import('@/views/digital_human_ai/availability_pricing/Create'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add availability unit',
            size: 'normal',
            flowStartsWith: 'dh.availability_pricing.create',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['DHAI'],
            product: 'dh',
        },
    },
    {
        name: 'dh_ai.availability_pricing.edit',
        path: '/digital-human/availability-pricing-units/:id',
        component: () => import('@/views/digital_human_ai/availability_pricing/Details'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit availability unit',
            size: 'normal',
            flowStartsWith: 'dh.availability_pricing.edit',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['DHAI'],
            product: 'dh',
        },
    },
];

export default routes;
