const actions = [
    {
        routeName: 'conversations_responses.index',
        items: [
            {
                title: 'create custom response',
                routeName: 'conversations_responses.create-response',
            },
            {
                title: 'upload photo',
                routeName: 'conversations_responses.create-card',
            },
            {
                title: 'my conversation report',
                routeName: 'conversations_responses.report',
            },
            {
                title: 'open digital human',
                isDHPreview: true,
                id: 'digital-human-preview',
            },
        ],
    },
];

export default actions;
