export default {
    spaceTypes: {
        getList: ['GET', '/space-type'],
        create: ['POST', '/space-type'],
        update: ['PATCH', '/space-type'],
        delete: ['DELETE', '/space-type/{id}'],
    },

    properties: {
        getList: ['GET', '/property'],
        create: ['POST', '/property'],
        update: ['PATCH', '/property'],
        delete: ['DELETE', '/property/{id}'],
    },

    spaces: {
        getList: ['GET', '/{propertyId}/space'],
        create: ['POST', '/{propertyId}/space'],
        update: ['PATCH', '/{propertyId}/space'],
        delete: ['DELETE', '/{propertyId}/space/{spaceId}'],
    },
};
