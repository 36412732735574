export default [
    {
        routeName: 'locations.properties.index',
        items: [
            {
                title: 'add property',
                routeName: 'locations.properties.create',
                params: {},
            },
            {
                title: 'manage space types',
                routeName: 'locations.spacetypes.index',
                params: {},
            },
        ],
    },
    {
        routeName: 'locations.properties.details',
        items: [
            {
                title: 'edit property',
                routeName: 'locations.properties.edit',
                params: {
                    propertyId: '{propertyId}',
                },
            },
        ],
    },
    {
        routeName: 'locations.spacetypes.index',
        items: [
            {
                title: 'add space type',
                routeName: 'locations.spacetypes.create',
                params: {},
            },
        ],
    },
];
