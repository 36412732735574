<template>
    <div class="flex w-full justify-center overflow-hidden" style="height: 400px">
        <div class="h-full w-4/5">
            <vue-cropper
                ref="cropper"
                :src="originalImage"
                :cropBoxResizable="cropBoxResizable"
                :viewMode="viewMode"
                :zoomOnWheel="zoomOnWheel"
                :aspectRatio="aspectRatio"
                :dragMode="'move'"
                @ready="setCropBoxSize"
            >
            </vue-cropper>
        </div>
        <div class="ml-4">
            <button class="btn-primary text-xs mb-4 block" @click="crop()">
                <icon name="crop" class="h-4 w-4 inline"></icon>
            </button>
            <button class="btn-primary text-xs mb-4 block" @click="zoom(0.2)">
                <icon name="zoomIn" class="h-4 w-4 inline"></icon>
            </button>
            <button class="btn-primary text-xs mb-4 block" @click="zoom(-0.2)">
                <icon name="zoomOut" class="h-4 w-4 inline"></icon>
            </button>
            <button class="btn-primary text-xs mb-4 block" @click="rotate(45)">
                <icon name="rotate" class="h-4 w-4 inline"></icon>
            </button>
            <button class="btn-primary text-xs mb-4 block" @click="reset()">
                <icon name="reset" class="h-4 w-4 inline"></icon>
            </button>
        </div>
    </div>
</template>

<script>
import VueCropper from 'vue-cropperjs';
import 'cropperjs/dist/cropper.min.css';
import Icon from "@/components/ui/Icon";

export default {
    components: { Icon, VueCropper },
    props: {
        image: {
            type: String,
            required: true,
            default: ''
        },
        aspectRatio: {
            type: Number,
            required: false,
            default: 1
        },
        cropBoxResizable: {
            type: Boolean,
            required: false,
            default: true,
        },
        viewMode: {
            type: Number,
            required: false,
            default: 1
        },
        zoomOnWheel: {
            type: Boolean,
            required: false,
            default: false,
        },
    },
    emits: ['image-cropped'],
    data: () => {
        return {
            originalImage: null,
        }
    },
    methods: {
        crop() {
            let base64Image = this.$refs.cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.85);
            this.$refs.cropper.replace(base64Image);
            this.$emit( 'image-cropped', { image: base64Image } );
        },
        reset() {
            this.$refs.cropper.reset();
            this.$refs.cropper.replace(this.originalImage);
            this.$emit( 'image-cropped', { image: this.originalImage } );
        },
        zoom(percent) {
            this.$refs.cropper.relativeZoom(percent);
        },
        rotate(deg) {
            this.$refs.cropper.rotate(deg);
        },
        setCropBoxSize() {
            let canvasData = this.$refs.cropper.getCanvasData();

            this.$refs.cropper.setCropBoxData({
                left: 0,
                top: 0,
                width: canvasData.width,
                height: canvasData.height
            });
        }
    },
    created() {
        this.originalImage = this.image;
    },
    mounted() {
        //this.$refs.cropper.destroy();
        //this.$refs.cropper.crop()
    }
}
</script>


<style scoped>
div.span.cropper-line {
    background-color: transparent;
}
span.cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: .75;
    width: 5px;
}
span.cropper-line.line-e {
    cursor: e-resize;
    right: -3px;
    top: 0;
    width: 5px;
}
span.cropper-line.line-n {
    cursor: ns-resize;
    height: 5px;
    left: 0;
    top: -3px;
}
span.cropper-line.line-w {
    cursor: ew-resize;
    left: -3px;
    top: 0;
    width: 5px;
}

span.cropper-point {
    background-color: #39f;
    height: 5px;
    opacity: .75;
    width: 5px;
}
span.cropper-line.line-s {
    bottom: -3px;
    cursor: ns-resize;
    height: 5px;
    left: 0;
}
span.cropper-point.point-e {
    cursor: ew-resize;
    margin-top: -3px;
    right: -3px;
    top: 50%;
}
span.cropper-point.point-n {
    cursor: ns-resize;
    left: 50%;
    margin-left: -3px;
    top: -3px;
}
span.cropper-point.point-w {
    cursor: ew-resize;
    left: -3px;
    margin-top: -3px;
    top: 50%;
}
span.cropper-point.point-s {
    bottom: -3px;
    cursor: s-resize;
    left: 50%;
    margin-left: -3px;
}
span.cropper-point.point-ne {
    cursor: nesw-resize;
    right: -3px;
    top: -3px;
}
span.cropper-point.point-nw {
    cursor: nwse-resize;
    left: -3px;
    top: -3px;
}
span.cropper-point.point-sw {
    bottom: -3px;
    cursor: nesw-resize;
    left: -3px;
}
span.cropper-point.point-se {
    bottom: -3px;
    cursor: nwse-resize;
    opacity: 1;
    right: -3px;
}
</style>
