import mutations from '@/store/auth/appSync/mutations';
import actions from '@/store/auth/appSync/actions';
import getters from '@/store/auth/appSync/getters';

export default {
    namespaced: true,
    state: {
        appSyncTimeoutIntervalIds: {},
    },
    mutations,
    actions,
    getters,
};
