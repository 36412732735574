import {
    INIT_ATTACHMENTS,
    ADD_ATTACHMENT,
    UPDATE_ATTACHMENT,
    REMOVE_ATTACHMENT,
    CLEAR_ATTACHMENTS,
    SET_ATTACHMENT_PARAMS,
} from './mutations';

const initAttachments = ({ commit }, items) => {
    commit(INIT_ATTACHMENTS, items);
};

const addAttachment = ({ commit }, attach) => {
    commit(ADD_ATTACHMENT, attach);
};

const updateAttachment = ({ commit }, payload) => {
    commit(UPDATE_ATTACHMENT, payload);
};

const removeAttachment = ({ commit }, attachId) => {
    commit(REMOVE_ATTACHMENT, attachId);
};

const clearAttachments = ({ commit }) => {
    commit(CLEAR_ATTACHMENTS);
};

const setAttachmentParams = ({ commit }, params) => {
    commit(SET_ATTACHMENT_PARAMS, params);
};

export default {
    initAttachments,
    addAttachment,
    updateAttachment,
    removeAttachment,
    clearAttachments,
    setAttachmentParams,
};
