import { createAppAccessChecker } from '@/components/auth/permissions';

export default [
    {
        path: '/prmsn-tools/identity-permissions',
        name: 'prmsnTools.identityPermissions.index',
        component: () => import(/* webpackChunkName: "prmsnTools" */ '@/views/auth/prmsn-devtools/IdentityPermissionsDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'identity permissions',
            title: 'identity permissions',
            product: 'admin',
            hideFromSearch: true,
            permissionChecker: createAppAccessChecker('PRMSN'),
        },
    },
];
