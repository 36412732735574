const LOAD_WEBSITE_INFO = (state, payload) => {
    state[payload] = payload.data;
};

const ADD_WEBSITE_INFO = (state, payload) => {
    state.websiteInfo.push(payload);
};

const CLEAR_WEBSITE_INFO = state => {
    state.websiteInfo = [];
};

export default {
    LOAD_WEBSITE_INFO,
    ADD_WEBSITE_INFO,
    CLEAR_WEBSITE_INFO,
};
