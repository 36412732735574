import getEnvVariable from '@/utils/getEnvVariable';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import MockAdapter from '@/api/adapters/MockAdapter';
import DataProvider from '@/api/DataProvider';
import httpClient from '@/api/httpClient';
import store from '@/store';
import mocks from './mocks';
import resources from './resources';

const baseURL = getEnvVariable('VUE_APP_AMNT_API_URL');

const beforeRequestInterceptor = ({ requestConfig }) => {
    const resultingConfig = {
        ...requestConfig,
        headers: {
            ...requestConfig.headers,
            'X-Quext-Customer-Id': store.getters['auth/customerId'],
        },
    };

    const communityId = store.getters['auth/community']?.id;

    if (communityId) {
        resultingConfig.headers['X-Quext-Community-Id'] = communityId;
    }

    return resultingConfig;
};

class AmenitiesMockAdapter extends MockAdapter {
    constructor(data) {
        super(data);
    }

    getList(data, resource, params) {
        const items = super.getList(data, resource, params);

        switch (resource) {
            case '':
                return { items };

            default:
                return items;
        }
    }
}

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);
const mockAdapter = new AmenitiesMockAdapter(mocks);

export default new DataProvider({
    handle: (action, resource, params) => {
        switch (resource) {
            case '':
                return mockAdapter.handle(action, resource, params);
            default:
                return httpAdapter.handle(action, resource, params);
        }
    },
});
