export default {
    rentableItems: '/ri',
    items: {
        getList: ['GET', '/ri/{riId}/items'],
        create: ['POST', '/ri/{riId}/items'],
        update: ['PATCH', '/ri/{riId}/items/{id}'],
        updateAvailability: ['PATCH', 'ri/{riId}/items/availability'],
        delete: ['DELETE', '/ri/{riId}/items/{id}'],
        nameCheck: ['POST', '/ri/{riId}/items/name-check'],
        deleteInBulk: ['POST', '/ri/{riId}/items/delete'],
        filterReservations: ['POST', '/ri/{riId}/items/filter-reservations'],
    },

    generalHistory: {
        getList: ['GET', '/ri/history'],
    },

    itemsPaged: {
        getList: ['GET', '/ri/{riId}/items/paged'],
    },

    individualHistory: {
        getList: ['GET', '/ri/{riId}/history'],
    },

    riNameCheck: '/ri/name-check',
    riReservationsToCancel: '/ri/{riId}/count-reservations-to-cancel',
    chargeCodes: '/charge-codes',
    categories: '/categories',
    timeUnits: '/settings/ts/units',

    buildings: '/buildings',
    units: '/buildings/{buildingId}/units',
    profiles: '/buildings/{buildingId}/units/{unitId}/profiles',

    weekdays: '/community/weekdays',
    communitySchedule: '/community/working-hours',
    communityTimezone: '/community/tz',

    reservations: {
        getList: ['GET', '/ri/{riId}/reservations'],
        create: ['POST', '/ri/{riId}/reservations'],
        getOne: ['GET', '/ri/{riId}/reservations/{id}'],
        cancel: ['POST', '/ri/{riId}/reservations/cancel'],
        availabilityStats: ['POST', '/ri/{riId}/reservations/calc-availability-stats'],
        availableDays: ['GET', '/ri/{riId}/availability/days'],
        availableTime: ['GET', '/ri/{riId}/availability/time'],
        update: ['PATCH', '/ri/{riId}/reservations/{id}/deposit-status'],
    },

    validateReservation: 'ri/{riId}/reservations/validate-on-create',

    permissions: '/my/permissions',
};
