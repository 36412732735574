const getAllLibraryFiles = state => {
    return state.library;
};

// accepts an array of file uuids
const getLibraryFilesByIds = state => {
    return ids =>
        state.library.filter(file => {
            return ids.includes(file.file_uuid);
        });
};

// accepts an file uuid
const getLibraryFileById = state => {
    return id =>
        state.library.find(file => {
            return id === file.file_uuid;
        });
};

// accepts base_directory string or an Array of base_directory
const getLibraryFilesByBaseDirectory = state => {
    return base_directory =>
        state.library.filter(file => {
            return Array.isArray(base_directory) ? base_directory.includes(file.base_directory) : file.base_directory === base_directory;
        });
};

// accepts base_directory string or an Array of base_directory
const getLibraryImageFilesByBaseDirectory = state => {
    return base_directory =>
        state.library.filter(file => {
            if (Array.isArray(base_directory))
                return base_directory.includes(file.base_directory) && state.imageMimeTypes.includes(file.file_type);
            else return file.base_directory === base_directory && state.imageMimeTypes.includes(file.file_type);
        });
};

const getLibraryFilesByType = state => {
    return type =>
        state.library.filter(file => {
            if (Array.isArray(type)) {
                return type.includes(file.file_type);
            } else {
                return file.file_type === type;
            }
        });
};

const getImageMimeTypes = state => {
    return state.imageMimeTypes;
};

const getThumbnailUrlByFileId = state => id => {
    const file = state.library.find(file => {
        return id === file.file_uuid;
    });

    return file && file.thumbnail_url ? file.thumbnail_url : null;
};

const getFileUrlByFileId = state => id => {
    const file = state.library.find(file => {
        return id === file.file_uuid;
    });

    return file && file.file_url ? file.file_url : null;
};

const getFileIdByThumbnailUrl = state => url => {
    const file = state.library.find(file => {
        return url === file.thumbnail_url;
    });

    return file && file.file_uuid ? file.file_uuid : null;
};

const isImage = state => {
    return file => state.imageMimeTypes.includes(file.type);
};

const getManagerById = state => {
    return managerId =>
        state.managers.find(manager => {
            return manager.managerId === managerId;
        });
};

const geUiMediaGalleryOption = state => {
    return key => {
        return state.uiMediaGalleryOptions[key];
    };
};

export default {
    // user global library
    getAllLibraryFiles,
    getLibraryFilesByIds,
    getLibraryFileById,
    getLibraryFilesByBaseDirectory,
    getLibraryImageFilesByBaseDirectory,
    getLibraryFilesByType,
    getImageMimeTypes,
    getThumbnailUrlByFileId,
    getFileUrlByFileId,
    getFileIdByThumbnailUrl,
    isImage,

    // manager
    getManagerById,

    // Ui media gallery option
    geUiMediaGalleryOption,
};
