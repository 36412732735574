const actions = [
    {
        routeName: 'dh.availability_pricing.index',
        items: [
            {
                title: 'add available unit',
                routeName: 'dh.availability_pricing.create',
            },
            {
                title: 'import availability list',
                routeName: 'dh.availability_pricing.template',
            },
        ],
    },
];

export default actions;
