<template>
    <div
        v-if="hasError"
        class="form-error"
    >
        {{ message }}
    </div>
    <div
        v-else-if="hint"
        class="form-hint"
    >
        {{ hint }}
    </div>
    <div
        v-else-if="preserveSpace"
        class="h-8"
    />
</template>

<script>
import { useFieldError } from '@/composables/useFieldError';

export default {
    props: {
        name: {
            type: String,
            required: true,
        },

        validate: {
            type: [Function, Array],
            default: () => {},
        },

        hint: {
            type: String,
            default: '',
        },

        preserveSpace: {
            type: Boolean,
            required: false,
            default: false,
        },
    },

    setup(props) {
        const { hasError, message } = useFieldError(props);

        return {
            hasError,
            message,
        };
    },
};
</script>
