const actions = [
    {
        routeName: 'guest-cards.index',
        items: [
            {
                title: 'Add new',
                routeName: 'guest-cards.create',
            },
            {
                title: 'settings',
                routeName: 'guest-cards.settings',
            },
        ],
    },
];

export default actions;
