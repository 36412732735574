export const LOAD_CATEGORIES = 'LOAD_CATEGORIES';
export const LOAD_INTENTS = 'LOAD_INTENTS';
export const LOAD_RESPONSES = 'LOAD_RESPONSES';
export const LOAD_SUB_INTENTS = 'LOAD_SUB_INTENTS';
export const SET_CATEGORY = 'SET_CATEGORY';
export const SET_INTENT = 'SET_INTENT';
export const SET_SUB_INTENT = 'SET_SUB_INTENT';
export const SET_CATEGORY_REPORT = 'SET_CATEGORY_REPORT';
export const SET_LOADING = 'SET_LOADING';
export const CLEAN_STATE = 'CLEAN_STATE';

export default {
    [LOAD_CATEGORIES]: (state, payload) => (state.categories = payload),
    [LOAD_INTENTS]: (state, payload) => (state.intents = payload),
    [LOAD_SUB_INTENTS]: (state, payload) => (state.subIntents = payload),
    [LOAD_RESPONSES]: (state, payload) => (state.responses = payload),
    [SET_CATEGORY]: (state, payload) => (state.category = payload),
    [SET_CATEGORY_REPORT]: (state, payload) => (state.categoryReport = payload),
    [SET_INTENT]: (state, payload) => (state.intent = payload),
    [SET_SUB_INTENT]: (state, payload) => (state.subIntent = payload),
    [SET_LOADING]: (state, payload) => (state.loading = payload),
    [CLEAN_STATE]: (state, payload) => {
        switch (payload) {
            case 'category':
                state.intents = [];
                state.subIntents = [];
                state.responses = [];
                state.category = null;
                state.intent = null;
                state.subIntent = null;
                break;
            case 'intent':
                state.subIntents = [];
                state.responses = [];
                state.intent = null;
                state.subIntent = null;
                break;
            case 'subIntent':
                state.responses = [];
                state.subIntent = null;
                break;
            default:
                state.categories = [];
        }
    },
};
