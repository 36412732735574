const loadWebsites = ({ commit }, payload) => {
    commit('LOAD_WEBSITES', payload);
};

const addWebsite = ({ commit }, payload) => {
    commit('ADD_WEBSITE', payload);
};

const updateWebsite = ({ commit }, payload) => {
    commit('UPDATE_WEBSITE', payload);
};

const updateWebsiteTemplate = ({ commit }, payload) => {
    commit('UPDATE_WEBSITE_TEMPLATE', payload);
};

export default {
    loadWebsites,
    addWebsite,
    updateWebsite,
    updateWebsiteTemplate,
};
