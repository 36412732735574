const loadPagesWidgets = ({ commit }, payload) => {
    commit('LOAD_PAGES_WIDGETS', payload);
};

const addPageWidget = ({ commit }, payload) => {
    commit('ADD_PAGE_WIDGET', payload);
};

const updatePageWidgetValues = ({ commit }, payload) => {
    commit('UPDATE_PAGE_WIDGET_VALUES', payload);
};

const updatePageWidgetOrder = ({ commit }, payload) => {
    commit('UPDATE_PAGE_WIDGET_ORDER', payload);
};

const deletePageWidget = ({ commit }, payload) => {
    commit('DELETE_PAGE_WIDGET', payload);
};

export default {
    loadPagesWidgets,
    addPageWidget,
    updatePageWidgetValues,
    updatePageWidgetOrder,
    deletePageWidget,
};
