import {
    LOAD_CATEGORIES,
    LOAD_INTENTS,
    LOAD_SUB_INTENTS,
    LOAD_RESPONSES,
    LOAD_PHOTOS,
    SET_CATEGORY,
    SET_INTENT,
    SET_SUB_INTENT,
    SET_LOADING,
    CLEAN_STATE,
} from './mutations';

const loadCategories = ({ commit }, payload) => commit(LOAD_CATEGORIES, payload);

const loadIntents = ({ commit }, payload) => commit(LOAD_INTENTS, payload);

const loadSubIntents = ({ commit }, payload) => commit(LOAD_SUB_INTENTS, payload);

const loadResponses = ({ commit }, payload) => commit(LOAD_RESPONSES, payload);

const loadPhotos = ({ commit }, payload) => commit(LOAD_PHOTOS, payload);

const setCategory = ({ commit }, payload) => commit(SET_CATEGORY, payload);

const setIntent = ({ commit }, payload) => commit(SET_INTENT, payload);

const setSubIntent = ({ commit }, payload) => commit(SET_SUB_INTENT, payload);

const setLoading = ({ commit }, payload) => commit(SET_LOADING, payload);

const cleanState = ({ commit }, payload) => commit(CLEAN_STATE, payload);

export default {
    loadCategories,
    loadIntents,
    loadSubIntents,
    loadResponses,
    loadPhotos,
    setCategory,
    setIntent,
    setSubIntent,
    setLoading,
    cleanState,
};
