<template>
    <transition name="slide-in">
        <div
            v-if="show"
            class="absolute bottom-0 left-0 top-0 right-0 z-20"
        >
            <div
                class="modal-confirmation p-10 absolute bottom-0 left-0 text-center w-full z-50"
                :class="classList"
            >
                <h5 class="modal-confirmation__message">
                    {{ message }}
                </h5>
                <component
                    :is="customComponent"
                    v-if="customComponent"
                    v-bind="customComponentConfig"
                    v-on="customComponentEvents"
                    @disableConfirmation="disableConfirm"
                />
                <p class="modal-confirmation__note">
                    {{ note }}
                </p>

                <div class="mt-6">
                    <button
                        class="btn-transparent mx-2"
                        :class="buttonClass"
                        @click.stop="cancel"
                    >
                        {{ cancelBtnText }}
                    </button>
                    <button
                        v-if="showConfirmBtn"
                        class="btn-solid mx-2"
                        :class="buttonClass"
                        :disabled="isConfirmDisabled"
                        @click.stop="confirm"
                    >
                        {{ confirmBtnText }}
                    </button>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import EventBus from '@/utils/EventBus';
import { slugify } from '@/utils/String';

export default {
    data: () => {
        return {
            show: false,
            action: null,
            message: null,
            type: null,
            confirmBtnText: null,
            cancelBtnText: null,
            customComponent: null,
            customComponentConfig: null,
            customComponentEvents: null,
            isConfirmDisabled: false,
            showConfirmBtn: true,
        };
    },

    computed: {
        classList() {
            const warning = ['warning'];
            const success = ['success'];

            return this.type === 'warning' ? warning.join(' ') : success.join(' ');
        },

        confirmationId() {
            if (!this.action.id) {
                console.error('No unique action id for confirmation slide-up');
            }
            return this.action.id ?? slugify(this.action.title);
        },

        buttonClass() {
            return this.type === 'warning' ? 'btn-danger' : 'btn-primary';
        },
    },

    mounted() {
        EventBus.on('show-modal-confirmation', this.showModalConfirmation);
        EventBus.on('hide-modal-confirmation', this.hideModalConfirmation);
    },

    unmounted() {
        EventBus.off('show-modal-confirmation', this.showModalConfirmation);
        EventBus.off('hide-modal-confirmation', this.hideModalConfirmation);
    },

    methods: {
        cancel() {
            EventBus.emit(`cancel-${this.confirmationId}`, this.action);
            this.show = false;
            if (!this.action.id) {
                console.error('No unique action id for confirmation slide-up');
            }
            const id = this.action.id ?? slugify(this.action.title);

            EventBus.emit(`cancel-confirmation-${id}`);
        },

        confirm() {
            EventBus.emit(`confirm-${this.confirmationId}`, this.action);
            this.show = false;
        },

        showModalConfirmation(action) {
            this.action = action;
            this.show = true;
            this.message = this.action.confirmationMessage ?? 'Are you sure you want to perform this action?';
            this.note = this.action.confirmationNote ?? null;
            this.confirmBtnText = this.action.confirmBtnText ?? 'confirm';
            this.cancelBtnText = this.action.cancelBtnText ?? 'cancel';
            this.type = this.action.confirmationType ?? 'success';
            this.customComponent = this.action.confirmationCustomComponent || null;
            this.customComponentConfig = this.action.confirmationCustomComponentConfig || null;
            this.customComponentEvents = this.action.confirmationCustomComponentEvents || null;
            this.isConfirmDisabled = this.action.isInitialConfirmDisabled || false;
            this.showConfirmBtn = this.action.showConfirmBtn ?? true;
        },

        hideModalConfirmation() {
            if (this.show) {
                this.cancel();
            }
        },

        disableConfirm(isDisabled) {
            this.isConfirmDisabled = isDisabled;
        },
    },
};
</script>

<style scoped>
.modal-confirmation.success {
    @apply bg-active-100 text-active-800;
}
.modal-confirmation.warning {
    @apply bg-red-100 text-red-900;
}

.warning .btn-primary {
    @apply text-white font-inter font-semibold py-3 px-6 border;
    background-color: #c75053;
    border-color: #c75053;
    letter-spacing: 0.5px;
}

.slide-in-enter-active {
    transition: all 0.3s ease;
}
.slide-in-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-in-enter-from,
.slide-in-leave-to {
    transform: translateY(100%);
    opacity: 0;
}

.modal-confirmation__message {
    @apply font-medium font-frank text-2sm mx-auto w-2/3 break-words;
}

.modal-confirmation__note {
    @apply font-inter font-normal text-2sm mt-2 whitespace-pre-wrap;
}
</style>
