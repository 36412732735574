import {
    CLEAR_APP_PERMISSIONS,
    SET_APP_PERMISSIONS,
    SET_PROFILE,
    SIGN_IN,
    SIGN_OUT,
    SET_PROFILES,
    SET_COMMUNITY,
    SET_COMMUNITIES,
    SET_CUSTOMER_APPS,
    SET_CUSTOMER_COMMUNITY_APP_CODES,
} from './mutations';

const signIn = ({ commit }, payload) => commit(SIGN_IN, payload);

const signOut = ({ commit }) => commit(SIGN_OUT);

const setProfile = ({ commit }, payload) => commit(SET_PROFILE, payload);

const setProfiles = ({ commit }, payload) => commit(SET_PROFILES, payload);

const setAppPermissions = ({ commit }, payload) => commit(SET_APP_PERMISSIONS, payload);

const clearAppPermissions = ({ commit }, payload) => commit(CLEAR_APP_PERMISSIONS, payload);

const setCommunity = ({ commit }, payload) => commit(SET_COMMUNITY, payload);

const setCommunities = ({ commit }, payload) => commit(SET_COMMUNITIES, payload);

const setCustomerApps = ({ commit }, payload) => commit(SET_CUSTOMER_APPS, payload);

const setCustomerCommunityAppCodes = ({ commit }, payload) => commit(SET_CUSTOMER_COMMUNITY_APP_CODES, payload);

export default {
    signIn,
    signOut,
    setProfile,
    setAppPermissions,
    clearAppPermissions,
    setProfiles,
    setCommunity,
    setCommunities,
    setCustomerApps,
    setCustomerCommunityAppCodes,
};
