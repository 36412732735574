export const LEASING_PERMISSIONS = {
    LEASE_INTEGRATION_SYNC: 'LEASE_INTEGRATION_SYNC',
    LEASE_READ_COMMUNITY: 'LEASE_READ_COMMUNITY',
    LEASE_CREATE_OCCUPANCY: 'LEASE_CREATE_OCCUPANCY',
    LEASE_MANAGE_OCCUPANCY: 'LEASE_MANAGE_OCCUPANCY',

    //applications and lease
    LEASE_APPLICATION_AND_LEASE_MANAGEMENT: 'LEASE_APPLICATION_AND_LEASE_MANAGEMENT',

    // process move-ins
    LEASE_MOVE_IN: 'LEASE_MOVE_IN',

    // renewal offers
    LEASE_OFFER_RENEWAL: 'LEASE_OFFER_RENEWAL',

    // transfer resident
    LEASE_TRANSFER_RESIDENTS: 'LEASE_TRANSFER_RESIDENTS',

    // resident details
    LEASE_RESIDENT_DETAILS_READ_ONLY: 'LEASE_RESIDENT_DETAILS_READ_ONLY',
    LEASE_RESIDENT_DETAILS_ACCESS: 'LEASE_RESIDENT_DETAILS_ACCESS',

    // month-to-month
    LEASE_MONTH_TO_MONTH_MANAGEMENT: 'LEASE_MONTH_TO_MONTH_MANAGEMENT',

    // move out residents
    LEASE_MOVE_OUT: 'LEASE_MOVE_OUT',

    // notice to vacate
    LEASE_NTV: 'LEASE_NTV',
};

export default LEASING_PERMISSIONS;
