<template>
    <div class="labels-list">
        <List
            :columns="labelsListColumns"
            :items="labels"
            track-by="id"
            class="mb-8"
        >
            <template
                v-if="showAddForm"
                #add
            >
                <div class="border border-active-100 bg-active-50">
                    <FinalForm :submit="handleNewLabelSubmit">
                        <template #default="props">
                            <form
                                class="flex"
                                @submit="props.handleSubmit"
                            >
                                <div class="px-2 py-4 w-5/12">
                                    <TextField
                                        name="name"
                                        :validate="[required, maxLength(50)]"
                                    />
                                </div>
                                <div class="px-2 py-4 w-5/12">
                                    <TextField
                                        name="description"
                                        :validate="[required, maxLength(200)]"
                                    />
                                </div>
                                <div class="px-2 py-4 w-2/12 flex items-center flex-wrap">
                                    <button
                                        class="btn-primary btn-solid mr-2"
                                        type="submit"
                                    >
                                        save
                                    </button>
                                    <icon-button
                                        icon="trash"
                                        class="text-red-800"
                                        @click="$emit('cancelAddNew')"
                                    />
                                </div>
                            </form>
                        </template>
                    </FinalForm>
                </div>
            </template>

            <template #column:name="{ item }">
                <EditableField
                    :with-edit-control="editable"
                    :initial-values="item"
                    :on-submit="handleLabelSubmit"
                >
                    <template #field="{ editMode }">
                        <TextField
                            name="name"
                            size="small"
                            :edit-mode="editMode"
                            :validate="[required, maxLength(50)]"
                        />
                    </template>
                </EditableField>
            </template>
            <template #column:description="{ item }">
                <EditableField
                    :with-edit-control="editable"
                    :initial-values="item"
                    :on-submit="handleLabelSubmit"
                >
                    <template #field="{ editMode }">
                        <TextField
                            name="description"
                            size="small"
                            :edit-mode="editMode"
                            :validate="[required, maxLength(200)]"
                        />
                    </template>
                </EditableField>
            </template>
            <template #column:delete="{ item }">
                <template v-if="editable">
                    <div class="delete-icon">
                        <trash-icon
                            v-if="item.deletable"
                            class="w-4 h-4 text-red-800"
                            @click="handleLabelDelete(item)"
                        />

                        <tooltip
                            v-else
                            icon="trash"
                            position-x="left"
                            class="w-5 h-5 text-graphite-700"
                        >
                            this status is mandatory
                        </tooltip>
                    </div>
                </template>
                <template v-else>
&nbsp;
                </template>
            </template>
        </List>

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import EditableField from '@/components/ui/inlineediting/EditableField';
import TextField from '@/components/form/TextField';
import List from '@/components/list/List';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import { useAlertStore } from '@/stores/alert';
import Loader from '@/components/ui/Loader';
import TrashIcon from '@/components/ui/icons/TrashIcon';
import Tooltip from '@/components/ui/Tooltip';
import { FinalForm } from 'vue-final-form';
import IconButton from '@/components/ui/IconButton';

const labelsListColumns = [
    {
        name: 'name',
        title: 'status name',
        class: 'w-5/12',
    },
    {
        name: 'description',
        title: 'description',
        class: 'w-6/12',
    },
    {
        name: 'delete',
        title: '',
        class: 'w-1/12',
    },
];

export default {
    components: { IconButton, Tooltip, TrashIcon, Loader, List, TextField, EditableField, FinalForm },

    mixins: [ValidatorMixin],

    props: {
        labelsListColumns: {
            type: Array,
            default: () => labelsListColumns,
        },

        labels: {
            type: Array,
            required: true,
        },

        showAddForm: {
            type: Boolean,
            default: false,
        },

        editable: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['update:labels', 'cancelAddNew', 'submitNewLabel'],

    setup() {
        const { notifyError } = useAlertStore();
        return { notifyError };
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        handleLabelSubmit(label) {
            this.loading = true;

            this.$cbrDataProvider
                .update('unitStatusLabels', {
                    id: label.id,
                    data: {
                        name: label.name,
                        description: label.description,
                    },
                })
                .then(updatedLabel => {
                    const toEmit = [...this.labels];
                    toEmit[this.labels.findIndex(({ id }) => id === updatedLabel.id)] = updatedLabel;
                    this.$emit('update:labels', toEmit);
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        handleLabelDelete(label) {
            this.loading = true;

            this.$cbrDataProvider
                .delete('unitStatusLabels', { id: label.id })
                .then(() => {
                    this.$emit(
                        'update:labels',
                        this.labels.filter(({ id }) => id !== label.id)
                    );
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        handleNewLabelSubmit(values) {
            this.$emit('submitNewLabel', values);
        },
    },
};
</script>

<style scoped>
.delete-icon {
    @apply hidden;
}

.labels-list:deep(.body) {
    > .row {
        @apply items-start;

        &:hover {
            .delete-icon {
                @apply block;
            }
        }
    }
}
</style>
