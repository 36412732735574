import { createPermissionChecker, prepareIotRoutes } from '../helper';

const routes = [
    {
        name: 'iot.leak-sensors.create',
        path: '/iot/leak-sensors/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/leak_sensors/Create.vue'),
        meta: {
            breadcrumbName: 'add sensor',
            title: 'new sensor',
            flowStartsWith: 'iot.leak-sensors.index',
            permissionChecker: createPermissionChecker('LEAK_SENSOR_CREATE'),
        },
    },
    {
        name: 'iot.leak-sensors.select-router',
        path: '/iot/leak-sensors/create/select-router',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/leak_sensors/ChangeRouter.vue'),
        meta: {
            breadcrumbName: 'change router',
            title: 'new sensor',
            flowStartsWith: 'iot.leak-sensors.create',
            permissionChecker: createPermissionChecker('LEAK_SENSOR_CREATE'),
        },
    },
    {
        name: 'iot.leak-sensors.details',
        path: '/iot/leak-sensors/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/leak_sensors/Details.vue'),
        meta: {
            breadcrumbName: 'sensor details',
            title: 'sensor details',
            actions: true,
            flowStartsWith: 'iot.leak-sensors.index',
            permissionChecker: createPermissionChecker('LEAK_SENSOR_READ'),
        },
    },
    {
        name: 'iot.leak-sensors.connection-log',
        path: '/iot/leak-sensors/:id/connection-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/leak_sensors/ConnectionLog.vue'),
        meta: {
            breadcrumbName: 'connection log',
            title: 'connection log',
            actions: true,
            flowStartsWith: 'iot.leak-sensors.index',
            permissionChecker: createPermissionChecker('LEAK_SENSOR_READ_CONNECTION_LOG_LIST'),
        },
    },
    {
        name: 'iot.leak-sensors.notification-log',
        path: '/iot/leak-sensors/notification-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/leak_sensors/NotificationLog.vue'),
        meta: {
            breadcrumbName: 'notification log',
            title: 'notification log',
            flowStartsWith: 'iot.leak-sensors.index',
            permissionChecker: createPermissionChecker('ZWAVE_NOTIFICATION_LOG_READ_LIST'),
        },
    },
];

export default prepareIotRoutes(routes);
