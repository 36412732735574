import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import httpClient from '@/api/httpClient';
import resources from './resources';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '@/store';

const baseURL = getEnvVariable('VUE_APP_ENCS_API_URL');
const beforeRequestInterceptor = ({ requestConfig }) => {
    return {
        ...requestConfig,
        headers: {
            ...requestConfig.headers,
            'X-Quext-Customer-Id': store.getters['auth/customerId'],
            'X-Quext-Community-Id': store.getters['auth/community']?.id,
        },
    };
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider({
    handle(action, resource, params) {
        return httpAdapter.handle(action, resource, params);
    },
});
