export const AUTH_PERMISSION_CATEGORY = {
    PORTFOLIO: 'PORTFOLIO',
    QUEXT: 'QUEXT',
};

export const QUEXT_PERMISSIONS = {
    QUEXT_SETUP: 'view_qxt_stp',
    ADD_PRESET: 'add_prst',
    EDIT_PRESET: 'edit_prst',
    DELETE_PRESET: 'delete_prst',
    VIEW_PRESETS: 'view_prst_lst',
    VIEW_PRESET_PERMISSIONS: 'view_prst_prms',
    EDIT_USER_PERMISSIONS: 'edit_usr_prms',
    ADD_SUPERADMIN: 'add_spr_adm',
    DELETE_SUPERADMIN: 'delete_spr_adm',
    EDIT_SUPERADMIN: 'edit_spr_adm',
    VIEW_SUPERADMIN: 'view_spr_adm',
    BLOCK_SUPERADMIN: 'blck_accs_spr_adm',
    RESTORE_SUPERADMIN: 'rstr_accs_spr_adm',
    REINVITE_SUPERADMIN: 'resend_invt_spr_adm',
    RESET_PASSWORD_SUPERADMIN: 'reset_psswd_spr_adm',
    RESET_MFA_SUPERADMIN: 'reset_mfa_spr_adm',
    VIEW_QUEXT_USERS: 'view_qxt_usr_lst',
    VIEW_QUEXT_USER_DETAILS: 'view_qxt_usr_dtls',
    VIEW_DELETED_QUEXT_USERS: 'view_dltd_qxt_usrs',
    ADD_QUEXT_USER: 'add_qxt_usr',
    EDIT_QUEXT_USER: 'edit_qxt_usr',
    DELETE_QUEXT_USER: 'delete_qxt_usr',
    VIEW_QU_PERMISSIONS: 'view_qxt_usr_prms',
    EDIT_QU_PERMISSIONS: 'edit_qxt_usr_prms',
    BLOCK_QUEXT_USER: 'blck_accs_qxt_usr',
    RESTORE_QUEXT_USER: 'rstr_accs_qxt_usr',
    REINVITE_QUEXT_USER: 'resend_invt_qxt_usr',
    RESET_PASSWORD_QUEXT_USER: 'reset_psswd_qxt_usr',
    RESET_MFA_QUEXT_USER: 'reset_mfa_qxt_usr',
    VIEW_APPS: 'view_app_lst',
    VIEW_APP_DETAILS: 'view_app_dtls',
    VIEW_DELETED_APPS: 'view_dltd_apps',
    ADD_APP: 'add_app',
    EDIT_APP: 'edit_app',
    DELETE_APP: 'delete_app',
    SYNC_APP: 'sync_app',
    TEST_CONNECTION: 'tst_app_conn',
    VIEW_APP_ROLES: 'view_app_rls',
    VIEW_APP_PERMISSIONS: 'view_app_prms',
    VIEW_CUSTOMER_APP_ACCESS: 'view_cstmr_accs_to_apps',
    CUSTOMER_ACCESS: 'rvk_rstr_cstmr_accs',
    VIEW_BUNDLE_DETAILS: 'view_bndl_dtls',
    ADD_BUNDLE: 'add_bndl',
    EDIT_BUNDLE: 'edit_bndl',
    DELETE_BUNDLE: 'delete_bndl',
    VIEW_METAROLES: 'view_metarole_template_lst',
    ADD_METAROLE: 'add_metarole_template',
    EDIT_METAROLE: 'edit_metarole_template',
    DELETE_METAROLE: 'delete_metarole_template',
    VIEW_METAROLE_USAGE: 'view_metarole_template_usages',
    VIEW_CUSTOMERS: 'view_cstmr_lst',
    VIEW_DELETED_CUSTOMERS: 'view_dltd_cstmrs',
    ADD_CUSTOMER: 'add_cstmr',
    EDIT_CUSTOMER: 'edit_cstmr',
    DELETE_CUSTOMER: 'delete_cstmr',
    MANAGE_CUSTOMER_LICENSING: 'manage_cstmr_lcns',
    IMPORT_COMMUNITIES: 'import_cstmr_cmmnt',
    ADD_COMMUNITY: 'add_cstmr_cmmnt',
    EDIT_COMMUNITY: 'edit_cstmr_cmmnt',
    DELETE_COMMUNITY: 'delete_cstmr_cmmnt',
    VIEW_COMMUNITY_LICENSING: 'view_cstmr_cmmnt_lcns',
    MANAGE_COMMUNITY_LICENSING: 'manage_cstmr_cmmnt_lcns',
    VIEW_COMMUNITIES_SELECTOR: 'view_cstmr_cmmnt_lst_slct',
    VIEW_COMMUNITY_INTEGRATION_PARTNERS: 'view_cstmr_cmmnt_ips',
    MANAGE_COMMUNITY_INTEGRATION_PARTNERS: 'manage_cstmr_cmmnt_ips',
    VIEW_INTEGRATION_PARTNERS: 'view_lst_ips',
    ADD_INTEGRATION_PARTNER: 'add_ips',
    MANAGE_PARTNER: 'manage_dtls_ips',
    MANAGE_PURPOSES: 'manage_prps',
};

export const PORTFOLIO_PERMISSIONS = {
    VIEW_BUNDLES: 'view_bndl_lst',
    VIEW_CUSTOMER_DETAILS: 'view_cstmr_dtls',
    VIEW_CUSTOMER_LICENSING: 'view_cstmr_lcns',
    VIEW_CUSTOMER_APP_ACCESS: 'view_cstmr_app_accs',
    DEACTIVATE_CUSTOMER: 'deactivate_cstmr',
    VIEW_CUSTOMER_ROLES: 'view_cstmr_rls_lst',
    SYNC_CUSTOMER_ROLES: 'sync_cstmr_rls',
    VIEW_CUSTOMER_PERMISSIONS: 'view_cstmr_prms_lst',
    SYNC_CUSTOMER_PERMISSIONS: 'synchronize_cstmr_prms',
    VIEW_COMMUNITIES: 'view_cstmr_cmmnt_lst',
    VIEW_COMMUNITY_DETAILS: 'view_cstmr_cmmnt_dtls',
    VIEW_DELETED_COMMUNITIES: 'view_dltd_cstmr_cmmnt_lst',
    VIEW_CUSTOMER_USERS: 'view_prtf_usr_lst',
    VIEW_CUSTOMER_USER_DETAILS: 'view_prtf_usr_dtls',
    VIEW_DELETED_CUSTOMER_USERS: 'view_prtf_usr_lst',
    ADD_CUSTOMER_USER: 'add_prtf_usr',
    EDIT_CUSTOMER_USER: 'edit_prtf_usr',
    DELETE_CUSTOMER_USER: 'delete_prtf_usr',
    REINVITE_CUSTOMER_USER: 'resend_invt_prtfl_usr',
    RESET_PASSWORD_CUSTOMER_USER: 'reset_psswd_prtfl_usr',
    RESET_MFA_CUSTOMER_USER: 'reset_mfa_prtfl_usr',
    BLOCK_CUSTOMER_USER: 'blck_accs_prtfl_usr',
    RESTORE_CUSTOMER_USER: 'rstr_accs_prtfl_usr',
    VIEW_CUSTOMER_USER_PERMISSIONS: 'view_cstmr_usr_prms',
    EDIT_CUSTOMER_USER_PERMISSIONS: 'edit_cstmr_usr_prms',
    VIEW_PORTFOLIO_ADMINS: 'view_prtf_adm_lst',
    VIEW_PORTFOLIO_ADMIN_DETAILS: 'view_prtf_adm_dtls',
    ADD_PORTFOLIO_ADMIN: 'add_prtf_adm',
    EDIT_PORTFOLIO_ADMIN: 'edit_prtf_adm',
    DELETE_PORTFOLIO_ADMIN: 'delete_prtf_adm',
    BLOCK_PORTFOLIO_ADMIN: 'blck_accs_prtfl_adm',
    RESTORE_PORTFOLIO_ADMIN: 'rstr_accs_prtfl_adm',
    REINVITE_PORTFOLIO_ADMIN: 'resend_invt_prtfl_adm',
    RESET_PASSWORD_PORTFOLIO_ADMIN: 'reset_psswd_prtfl_adm',
    RESET_MFA_PORTFOLIO_ADMIN: 'reset_mfa_prtfl_adm',
};
