export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_RENTAL_INSURANCE_POLICY_ID = 'SET_RENTAL_INSURANCE_POLICY_ID';
export const SET_CHARGES = 'SET_CHARGES';
export const SET_CURRENT_CHARGE = 'SET_CURRENT_CHARGE';
export const CLEAN_STATE = 'CLEAN_STATE';

export default {
    [SET_START_DATE]: (state, payload) => (state.startDate = payload),
    [SET_END_DATE]: (state, payload) => (state.endDate = payload),
    [SET_RENTAL_INSURANCE_POLICY_ID]: (state, payload) => (state.rentalInsurancePolicyId = payload),
    [SET_CHARGES]: (state, payload) => state.charges.push(payload),
    [SET_CURRENT_CHARGE]: (state, payload) => (state.currentCharge = payload),
    [CLEAN_STATE]: (state, payload) => {
        switch (payload) {
            case 'startDate':
                state.startDate = null;
                break;
            case 'endDate':
                state.endDate = null;
                break;
            case 'charges':
                state.charges = [];
                break;
            case 'currentCharge':
                state.currentCharge = {};
                break;
            default:
        }
    },
};
