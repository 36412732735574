export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const CLEAN_STATE = 'CLEAN_STATE';

export default {
    [SET_START_DATE]: (state, payload) => (state.startDate = payload),
    [SET_END_DATE]: (state, payload) => (state.endDate = payload),
    [CLEAN_STATE]: (state, payload) => {
        switch (payload) {
            case 'startDate':
                state.startDate = null;
                break;
            case 'endDate':
                state.endDate = null;
                break;
            default:
        }
    },
};
