import { SET_LIST_FILTER_VALUES, SET_TMP_BUFFER } from './mutations';

const setListFilterValues = ({ commit }, payload) => commit(SET_LIST_FILTER_VALUES, payload);

const setTmpBuffer = ({ commit }, payload) => commit(SET_TMP_BUFFER, payload);

const resetTmpBuffer = ({ commit }) => commit(SET_TMP_BUFFER, undefined);

export default {
    setListFilterValues,
    setTmpBuffer,
    resetTmpBuffer,
};
