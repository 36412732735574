const getPointsOfInterest = state => {
    return state.pointsOfInterest;
};

const getPointsOfInterestCategories = state => {
    return state.pointsOfInterestCategories;
};

export default {
    getPointsOfInterest,
    getPointsOfInterestCategories,
};
