<template>
    <div class="main-wrapper">
        <div
            v-for="item in items"
            :key="item.code"
            class="flex flex-col mb-6"
        >
            <div class="text-sm font-semibold mb-3">
                {{ item.name }}
            </div>
            <div class="ml-4">
                <DefaultStatusesList
                    :statuses="item.children"
                    :labels-mode="labelsMode"
                />
            </div>
        </div>
    </div>
</template>

<script>
import DefaultStatusesList from '@/components/community_settings/unit_statuses/DefaultStatusesList';
export default {
    components: { DefaultStatusesList },

    props: {
        categories: {
            type: Array,
            required: true,
        },

        labelsMode: {
            type: Boolean,
            default: false,
        },
    },

    computed: {
        items() {
            if (!this.labelsMode) return this.categories.map(category => ({ ...category, children: category.statuses }));

            return this.categories.reduce((acc, category) => {
                return [
                    ...acc,
                    ...category.statuses.map(status => ({
                        ...status,
                        name: `${category.name}.${status.name}`,
                        children: status.labels,
                    })),
                ];
            }, []);
        },
    },
};
</script>

<style scoped>
.main-wrapper {
    max-height: 370px;
    overflow: auto;
}
</style>
