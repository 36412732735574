import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        name: 'metaroles.index',
        path: '/meta-roles',
        component: () => import(/* webpackChunkName: "metaroles" */ '@/views/auth/metaroles/MetarolesList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'meta roles',
            title: 'meta roles',
            flowStartsWith: 'metaroles.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES),
        },
    },
    {
        name: 'metaroles.create',
        path: '/meta-roles/create',
        component: () => import(/* webpackChunkName: "metaroles" */ '@/views/auth/metaroles/MetarolesCreate'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add new',
            title: 'add new meta role',
            flowStartsWith: 'metaroles.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_METAROLE),
        },
    },
    {
        name: 'metaroles.details',
        path: '/meta-roles/:id/details',
        component: () => import(/* webpackChunkName: "metaroles" */ '@/views/auth/metaroles/MetaroleDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view',
            title: 'view meta role',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES),
        },
    },
    {
        name: 'metaroles.usage.index',
        path: '/meta-roles/:id/usage',
        component: () => import(/* webpackChunkName: "metaroles" */ '@/views/auth/metaroles/MetaroleUsageList'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'view usage',
            title: 'meta roles usage',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLE_USAGE),
        },
    },
    {
        name: 'metaroles.edit',
        path: '/meta-roles/:id',
        component: () => import(/* webpackChunkName: "metaroles" */ '@/views/auth/metaroles/MetarolesEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit meta role',
            flowStartsWith: 'metaroles.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_METAROLE),
        },
    },
    {
        name: 'deletedMetaroles.index',
        path: '/deleted-meta-roles',
        component: () => import(/* webpackChunkName: "metaroles" */ '@/views/auth/metaroles/DeletedMetarolesList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'deleted meta roles',
            title: 'deleted meta roles',
            flowStartsWith: 'metaroles.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES),
        },
    },
    {
        name: 'deletedMetaroles.details',
        path: '/deleted-meta-roles/:id/details',
        component: () => import(/* webpackChunkName: "metaroles" */ '@/views/auth/metaroles/MetaroleDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'view',
            title: 'view meta role',
            flowStartsWith: 'deletedMetaroles.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_METAROLES),
        },
    },
];
