import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    dashboard: {
        preferences: null,
        graphColors: null,
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
