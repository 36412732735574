import { defineStore } from 'pinia';
import { computed } from 'vue';
import store from '@/store';

function hasValue(permissionValue, targetValue) {
    return targetValue ? permissionValue === targetValue : true;
}

function formatPermissions(permissions) {
    return permissions.map(({ domainObjectName, domainObjectAction, value }) => ({
        id: `${domainObjectName}_${domainObjectAction}`,
        value,
    }));
}

export const useIotPermissionsStore = defineStore('iotPermissions', () => {
    const hasPermissions = computed(() => (permissionIds, value) => {
        const activeCommunity = store.getters['auth/community'];

        if (!permissionIds || !permissions.value.length || !activeCommunity) {
            return false;
        }

        const permissionIdsArray = Array.isArray(permissionIds) ? permissionIds : [permissionIds];
        const hasPerm = permissionIdsArray.some(
            permissionId =>
                !!permissions.value.find(({ id, value: permissionValue }) => id === permissionId && hasValue(permissionValue, value))
        );

        return hasPerm;
    });

    const permissions = computed(() => {
        const storePermissions = store.getters['auth/getAppPermissions']('IOT') || [];

        return formatPermissions(storePermissions);
    });

    return { permissions, hasPermissions };
});
