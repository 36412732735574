import { fetchAuthSession, fetchDevices, forgetDevice } from 'aws-amplify/auth';
import getEnvVariable from '@/utils/getEnvVariable';

export const useAuthDevices = () => {
    const mfaTrustTime = +getEnvVariable('VUE_APP_MFA_TRUST_TIME');

    const forgetUserDevice = () => {
        try {
            return forgetDevice();
        } catch (e) {
            console.error(e);
        }
    };

    const processUserDevice = async () => {
        if (!mfaTrustTime) {
            return;
        }
        const { tokens } = await fetchAuthSession({ forceRefresh: false });
        const deviceKey = tokens.accessToken.payload.device_key;

        if (!deviceKey) {
            return;
        }

        const devices = await fetchDevices();

        const userDevice = devices.find(({ id }) => id === deviceKey);

        if (!userDevice) {
            return;
        }

        const { createDate, lastAuthenticatedDate } = userDevice;

        const shouldForgetDevice = lastAuthenticatedDate - createDate > mfaTrustTime;
        if (shouldForgetDevice) {
            await forgetUserDevice();
        }
    };

    return {
        processUserDevice,
    };
};
