import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    application: null,
    currentApplicant: null,
    future_guarantor: null,
    future_applicant: null,
    application_to_lease: null,
    applications: [],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
