import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting/routes_names';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';

const actions = [
    {
        routeName: ROUTES.GL_BANK_ACCOUNTS.name,
        items: [
            {
                title: 'add bank account',
                routeName: ROUTES.GL_BANK_ACCOUNT_CREATE.name,
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_BANK_ACCOUNT);
                },
                actionIcon: 'BankBuilding',
                style: 'fill: white; margin-top: 0.1rem;',
            },
        ],
    },
];

export default actions;
