<template>
    <AuthCard :loading="isPending">
        <template #heading>
            set up multi-factor authentication for your account
        </template>
        <template #default>
            <div v-show="currentMfaType === 'totp'">
                <div class="pb-6 mb-6 border-b">
                    <span>1. install an authenticator app on your mobile device</span>
                    <AuthAppsTooltip />
                </div>
                <div class="pb-6 mb-6 border-b">
                    <div class="mb-2">
                        <span>2. use this app to scan the QR code or enter a setup key manually</span>
                        <button
                            class="btn-action ml-1"
                            type="button"
                            @click="toggleSecretKey"
                        >
                            ({{ secretTogglerText }}
                            <icon
                                class="w-4 h-4"
                                :class="{ 'transform rotate-180': secretKeyVisible }"
                                name="chevronDown"
                            />)
                        </button>
                    </div>
                    <div
                        v-show="secretKeyVisible"
                        class="mb-4"
                    >
                        <input
                            class="form-input"
                            :value="secretKeyValue"
                        >
                    </div>
                    <TotpQrCode :qr-value="qrValue" />
                </div>
                <Form
                    :submit="verifyMFACode"
                    class="w-full"
                >
                    <div class="mb-2">
                        3. please enter the six digit code from your authenticator app
                    </div>
                    <div class="mb-4">
                        <TextField
                            name="mfacode"
                            label="authenticator code"
                            type="text"
                            :validate="[required, minLength(6)]"
                            autocomplete="off"
                        />
                    </div>
                    <MessageBlock
                        v-if="error || verifyCodeError"
                        type="warning"
                        class="flex mb-4"
                    >
                        <Icon name="warning" />
                        <div class="ml-2">
                            {{ error || verifyCodeError }}
                        </div>
                    </MessageBlock>
                    <div>
                        <button
                            :disabled="isPending"
                            type="submit"
                            class="btn-primary btn-solid btn-auth"
                        >
                            submit
                        </button>
                    </div>
                </Form>
            </div>
        </template>
    </AuthCard>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useAuthenticator } from '@aws-amplify/ui-vue';
import { setUpTOTP, verifyTOTPSetup, updateMFAPreference, fetchUserAttributes } from 'aws-amplify/auth';
import getEnvVariable from '@/utils/getEnvVariable';
import { SIGNED_IN } from '@/utils/authEvents';
import EventBus from '@/utils/EventBus';
import AuthCard from '@/components/auth/AuthCard';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import Icon from '@/components/ui/Icon';
import MessageBlock from '@/components/ui/MessageBlock.vue';
import AuthAppsTooltip from '@/components/auth/custom_auth_flow/AuthAppsTooltip.vue';
import { required, minLength } from '@/components/form/validators';
import TotpQrCode from '@/components/auth/custom_auth_flow/TotpQrCode.vue';

const props = defineProps({
    onSubmit: {
        type: Function,
        required: true,
    },
});

const ENV_NAME = getEnvVariable('VUE_APP_ENV_NAME');

const ISSUER = `Quext${ENV_NAME ? `-${ENV_NAME}` : ''}`;

const currentMfaType = ref('totp');

const secretKeyVisible = ref(false);

const secretKey = ref('');

const qrValue = ref('');

const verifyCodeError = ref('');

const isPending = ref(false);

const facade = useAuthenticator();

const error = computed(() => facade.error);

const secretKeyValue = computed(() => secretKey.value || 'Failed to generate a secret key');

const secretTogglerText = computed(() => `${secretKeyVisible.value ? 'hide' : 'show'} secret code`);

const toggleSecretKey = () => {
    secretKeyVisible.value = !secretKeyVisible.value;
};

const generateSecretKey = async () => {
    try {
        const output = await setUpTOTP();
        const { email } = await fetchUserAttributes();

        const setupUri = output.getSetupUri(ISSUER, email);

        secretKey.value = output.sharedSecret;
        qrValue.value = setupUri.href;
    } catch (e) {
        console.error(e);
    }
};

const verifyMFACode = async ({ mfacode }) => {
    verifyCodeError.value = '';

    try {
        isPending.value = true;

        await verifyTOTPSetup({ code: mfacode });

        await updateMFAPreference({ totp: 'PREFERRED' });

        isPending.value = false;

        props.onSubmit();

        EventBus.emit(SIGNED_IN);
    } catch (e) {
        // Token is not verified
        verifyCodeError.value = e.message;

        isPending.value = false;
    }
};

onMounted(() => {
    generateSecretKey();
});
</script>
