<template>
    <div class="w-full h-full">

        <div v-if="isUploading" class="flex h-full items-center justify-center">
            <p>{{ uploadingHint }}</p>
            <loader :loading="isUploading" :backdrop="false" />
        </div>

        <div v-else class="flex h-full items-center justify-center">
            <!-- option 1: choose from library -->
            <div
                class="mx-8 border border-highlight-300 bg-highlight-200 text-highlight-textLarge w-1/4 py-12 text-center cursor-pointer"
                @click="$emit('show-manager-library')"
                v-if="manager.library.length && !manager.libraryDisabled"
            >
                <icon name="photograph" class="w-10 h-10 inline"></icon>
                <p class="font-500 font-frank mt-1 text-sm underline">select from media gallery</p>
            </div>

            <span v-if="manager.library.length && !manager.libraryDisabled">or</span>

            <!-- option 2: upload from device -->
            <div
                class="mx-8 border border-highlight-300 bg-highlight-200 text-highlight-textLarge w-1/4 py-12 text-center cursor-pointer"
                @click="openFileDialog"
            >
                <icon name="camera" class="w-10 h-10 inline"></icon>
                <p class="font-500 font-frank mt-1 text-sm underline">upload from device</p>
                <input
                    type="file"
                    ref="upload-input"
                    class="hidden"
                    :accept="manager.uploader.accepts"
                    :multiple="isMultipleUpload"
                    @change="validateUploadedFiles"
                />
            </div>
        </div>

        <div class="fm-content-footer">
            <button
                class="btn-primary btn-transparent"
                @click="close"
                v-if="!isUploading"
            >
                cancel
            </button>
        </div>

    </div>
</template>

<script>
import Icon from "@/components/ui/Icon";
import {v4} from "uuid";
import Loader from '@/components/ui/Loader';
import { mapActions, mapGetters } from "vuex";
import NotifyMixin from "@/mixins/NotifyMixin";

export default {
    mixins: [ NotifyMixin ],
    components: { Icon, Loader },
    props: {
        managerId: {
            type: String,
            required: true
        }
    },
    emits: [
        'show-manager-library',
        'show-upload-preview',
        'filesUploaded',
        'close',
    ],
    computed: {
        ...mapGetters({
            getManagerById: 'files/getManagerById',
            isImage: 'files/isImage',
        }),
        remainingNbOfFileUploads() {
            return this.manager.maxNbOfFiles - this.manager.selectedFiles.length;
        },
        isMultipleUpload() {
            return this.remainingNbOfFileUploads > 1;
        },
        canUploadMoreFiles() {
            return this.manager.maxNbOfFiles > this.manager.selectedFiles.length;
        },
        uploadingHint() {
            let str = (this.manager.uploader.copyToDirectory.length) ? 'marketing and copy directories.' : 'marketing directory.'
            return 'Uploading files to ' + str;
        }
    },
    data: () => {
        return {
            manager: {},
            canUpload: true,
            isUploading: false,
            uploadedFiles: []
        }
    },
    methods: {
        ...mapActions({
            addFilesToLibrary: 'files/addFilesToLibrary',
            addFilesToManagerUploadedFiles: 'files/addFilesToManagerUploadedFiles',
            clearManagerUploadedFiles: 'files/clearManagerUploadedFiles'
        }),
        openFileDialog() {
            if(this.canUploadMoreFiles)
                this.$refs['upload-input'].click();
        },
        /**
         * - validate number of files selected
         * - validate max size of files selected
         */
        validateUploadedFiles() {
            let files = this.$refs['upload-input'].files;
            this.canUpload = true;

            if ( files.length > this.remainingNbOfFileUploads ) {
                window.alert("You can only upload " + this.remainingNbOfFileUploads + " files");
                this.canUpload = false;
            }

            files.forEach( file => {
                if( (file.size / 1000000) > this.manager.uploader.maxUploadSize ) {
                    window.alert('The file ' + file.name + ' exceeds the max allowed uploaded size (' + this.manager.uploader.maxUploadSize + 'MB)');
                    this.canUpload = false;
                }
            })

            if(this.canUpload) {
                this.getUploadedFiles();
            }
        },
        getUploadedFiles() {
            let files = this.$refs['upload-input'].files;
            let promises = [];

            files.forEach((file) => {
                let filePromise = new Promise( (resolve, reject) => {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);

                    reader.onload = (event) => {
                        let uploadedFile = {
                            id: v4(),
                            base64: event.target.result,
                            name: file.name,
                            type: file.type,
                            size: file.size
                        };
                        resolve(uploadedFile);
                        this.addFilesToManagerUploadedFiles( { files: [ uploadedFile ], managerId: this.managerId } );
                    }

                    reader.onerror = reject;
                });
                promises.push(filePromise);
            });

            Promise.all(promises).then( files => {
                this.upload(files);
            });
        },
        /**
         * upload original files to copy directories
         * and marketing directory behind the scenes
         */
        upload(files) {
            this.isUploading = true;

            this.$filemanagerDataProvider.create('file', {
                data: {
                    file: files,
                    community_id: this.manager.communityId,
                    copy_to_directory: this.manager.uploader.copyToDirectory.join(',')
                }
            })
            .then( (response) => {
                let files = response.data;
                // create file api and get file api are not consistent
                // the first returns file_classification and the second returns file_type
                files.forEach( (file) => {
                    if(Object.hasOwn(file, 'file_classification')) {
                        file.file_type = file.file_classification;
                    }
                });

                this.addFilesToLibrary(files);
                this.manager.uploader.mainDirectory ? this.$emit('show-upload-preview') : this.close();
            })
            .catch(() => {
                this.notifyError('There was an error uploading the file(s).')
                this.close();
            })
            .finally( () => {
                this.isUploading = false;
            });
        },
        close() {
            this.clearManagerUploadedFiles(this.manager.managerId);
            this.$emit('filesUploaded');
            this.$emit('close');
        }
    },
    created() {
        this.manager = this.getManagerById(this.managerId);
    }
}
</script>

