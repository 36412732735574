const LOAD_FLOORPLANS = (state, payload) => {
    state.floorplans = payload;
};

const CLEAR_FLOORPLANS = state => {
    state.floorplans = [];
};

export default {
    LOAD_FLOORPLANS,
    CLEAR_FLOORPLANS,
};
