<template>
    <div>
        <button
            v-if="editable"
            class="btn-secondary btn-xs mb-8"
            :disabled="!hasNonDefaultValues"
            @click="resetStatuses()"
        >
            <Tooltip v-if="hasNonDefaultValues">
                <template #label>
                    <Close2Icon class="w-2 h-2 mr-2" />
                    reset to default statuses all categories
                </template>

                <template #default>
                    <DefaultCategoriesList :categories="defaultCategories" />
                </template>
            </Tooltip>

            <template v-else>
                <Close2Icon class="w-2 h-2 mr-2" />
                reset to default statuses all categories
            </template>
        </button>

        <div
            v-for="category in categories"
            :key="category.code"
        >
            <div class="flex items-center mb-3">
                <div class="font-frank font-bold text-black text-sm lowercase mr-1">
                    {{ category.name }}
                </div>
                <tooltip
                    icon="info"
                    class="text-graphite-800"
                >
                    {{ category.description }}
                </tooltip>
            </div>

            <div class="flex justify-start mb-4">
                <button
                    v-if="editable"
                    class="btn-secondary btn-xs"
                    :disabled="isCategoryDefault(category)"
                    @click="resetStatuses(category.code)"
                >
                    <Tooltip v-if="!isCategoryDefault(category)">
                        <template #label>
                            <Close2Icon class="w-2 h-2 mr-2" />
                            reset to default statuses
                        </template>

                        <template #default>
                            <DefaultStatusesList :statuses="getDefaultCategory(category.code).statuses" />
                        </template>
                    </Tooltip>

                    <template v-else>
                        <Close2Icon class="w-2 h-2 mr-2" />
                        reset to default statuses
                    </template>
                </button>

                <button
                    v-if="canBeUndone(category)"
                    class="btn-secondary btn-xs ml-4"
                    @click="undoCategoryReset(category)"
                >
                    undo
                </button>
            </div>

            <StatusList
                v-model:statuses="category.statuses"
                :editable="editable"
            />
        </div>

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import Close2Icon from '@/components/ui/icons/Close2Icon';
import StatusList from '@/components/community_settings/unit_statuses/StatusList';
import Tooltip from '@/components/ui/Tooltip';
import Loader from '@/components/ui/Loader';
import { useAlertStore } from '@/stores/alert';
import StatusCompareMixin from '@/components/community_settings/unit_statuses/StatusCompareMixin';
import DefaultCategoriesList from '@/components/community_settings/unit_statuses/DefaultCategoriesList';
import DefaultStatusesList from '@/components/community_settings/unit_statuses/DefaultStatusesList';
import StatusResetUndoMixin from '@/components/community_settings/unit_statuses/StatusResetUndoMixin';

export default {
    components: { DefaultStatusesList, DefaultCategoriesList, Loader, Tooltip, StatusList, Close2Icon },

    mixins: [StatusCompareMixin, StatusResetUndoMixin],

    props: {
        editable: {
            type: Boolean,
            default: true,
        },

        defaultCategories: {
            type: Array,
            required: true,
        },
    },

    setup() {
        const { notifySuccess, notifyError } = useAlertStore();
        return { notifySuccess, notifyError };
    },

    data() {
        return {
            loading: false,
            categories: [],
        };
    },

    mounted() {
        this.loading = true;

        this.$cbrDataProvider
            .getCategories('unitStatuses', { part: 'CURRENT_OCCUPANCY' })
            .then(response => {
                this.categories = [
                    response.find(({ code }) => code === 'VACANT'),
                    response.find(({ code }) => code === 'INDEFINITE'),
                    response.find(({ code }) => code === 'ENDING'),
                ];
            })
            .catch(error => {
                this.notifyError(error);
            })
            .finally(() => {
                this.loading = false;
            });
    },

    methods: {
        resetStatuses(categoryCode = undefined) {
            this.loading = true;

            this.$cbrDataProvider
                .reset('unitStatuses', {
                    data: {
                        part: 'CURRENT_OCCUPANCY',
                        categoryCode,
                    },
                })
                .then(response => {
                    if (categoryCode !== undefined) {
                        this.prevCategories = [this.categories.find(({ code }) => code === categoryCode)];
                    } else {
                        this.prevCategories = [...this.categories];
                    }

                    this.categories = response;

                    const categoryName = this.categories.find(({ code }) => code === categoryCode)?.name;
                    if (categoryName) {
                        this.notifySuccess(`${categoryName} statuses  reset to default`);
                    } else {
                        this.notifySuccess(`statuses in all categories reset to default`);
                    }
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>
