import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'metaroles.index',
        items: [
            {
                title: 'add new meta role',
                routeName: 'metaroles.create',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_METAROLE),
            },
            {
                title: 'deleted meta roles',
                routeName: 'deletedMetaroles.index',
                params: {},
            },
        ],
    },
    {
        routeName: 'metaroles.details',
        items: [],
    },
];
