const actions = [
    {
        routeName: 'custom_responses.index',
        items: [
            {
                title: 'custom responses report',
                routeName: 'custom_responses.report',
            },
        ],
    },
];

export default actions;
