import Axios from 'axios';
import { omitBy, isNil } from 'lodash-es';
import store from '@/store';
import queryString from 'query-string';

const noAuthHttpClient = baseURL => async request => {
    try {
        return Axios.request({
            ...request,
            baseURL,
            paramsSerializer: params => queryString.stringify(params),
            headers: omitBy(
                {
                    ...request.headers,
                },
                isNil
            ),
        }).catch(error => {
            const response = error?.response;
            // Proxy response from RAA contains a string with a detailed error message in 'data' prop.
            const proxyResponse = typeof response?.data === 'string';
            const message = (proxyResponse && response?.data) || response?.data?.message || error.message;

            return Promise.reject({
                message,
                ...response,
            });
        });
    } catch (e) {
        store.dispatch('alerts/notifyError', e.message);
    }
};

export default noAuthHttpClient;
