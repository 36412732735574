import store from '@/store';

const permissionChecker = () => store.getters['dh/hasPermission'] || store.getters['cms/hasPermission'];

const routes = [
    {
        name: 'calendar_settings.index',
        path: '/calendar-settings/settings',
        component: () => {
            return import('@/views/calendar/settings/Index');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'calendar settings',
            title: 'Calendar settings',
            flowStartsWith: 'calendar_events.index',
            allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            product: 'marketing',
            permissionChecker,
        },
    },
];

export default routes;
