const all = state => {
    return state.properties;
};

const getPropertyById = state => {
    return id => state.properties.find(property => property.communityId === id);
};

export default {
    all,
    getPropertyById,
};
