import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.pacs-queue-log-details.index',
        path: '/iot/pacs-queue-log/:transactionId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/pacs/Details.vue'),
        meta: {
            breadcrumbName: 'transaction details',
            title: 'transaction details',
            flowStartsWith: 'iot.pacs-queue-log.index',
            permissionChecker: createPermissionChecker('LOCK_READ_PACS_QUEUE_LOG_LIST'),
        },
    },
]);
