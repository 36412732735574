import { createPermissionChecker, prepareIotRoutes } from '../helper';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default prepareIotRoutes([
    {
        name: 'iot.automation',
        path: '/iot/automation',
        component: TabbedRouterModal,
        meta: {
            breadcrumbName: 'automation',
            title: 'automation',
            nonStacked: true,
            permissionChecker: createPermissionChecker(['SMART_POLICY_READ_LIST', 'AUTO_OPEN_READ_LIST']),
        },
        children: [
            {
                name: 'iot.automation.index',
                path: '/iot/automation/scenarios',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/Index.vue'),
                meta: {
                    actions: true,
                    title: 'scenarios',
                    flowStartsWith: 'iot.automation.index',
                    permissionChecker: createPermissionChecker('SMART_POLICY_READ_LIST'),
                },
            },
            {
                name: 'iot.auto_open.index',
                path: '/iot/automation/auto-open',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/auto_open/Index.vue'),
                meta: {
                    actions: true,
                    title: 'auto open',
                    flowStartsWith: 'iot.auto_open.index',
                    permissionChecker: createPermissionChecker('AUTO_OPEN_READ_LIST'),
                },
            },
            {
                name: 'iot.schedules.index',
                path: '/iot/automation/schedules',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/schedules/Index.vue'),
                meta: {
                    actions: true,
                    title: 'schedules',
                    flowStartsWith: 'iot.schedules.index',
                    permissionChecker: createPermissionChecker('LOCK_CREDENTIALS_SCHEDULE_READ_LIST'),
                },
            },
        ],
    },
]);
