import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    images: [],
    editImageUrl: '',
    idImageToEdit: '',
    imageDescription: '',
    isFromEditPhoto: false,
    photoTags: [],
    tagsValue: [],
    loading: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
