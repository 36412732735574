<template>
    <transition name="slide-down">
        <div class="fm-wrapper" v-if="isOpen" id="modal-file-manager">
            <div class="fm-overlay"></div>
            <div class="fm-content">
                <div class="fm-content-wrapper">

                    <file-manager-options
                        :manager-id="manager.managerId"
                        @show-manager-library="step = 'library'"
                        @show-upload-preview="step = 'upload-preview'"
                        @close="close"
                        @filesUploaded="handleFilesUploaded"
                        v-if="step === 'options'"
                    >
                    </file-manager-options>

                    <file-manager-library
                        :manager-id="manager.managerId"
                        @close="close"
                        v-if="step === 'library' && manager.library.length && !manager.libraryDisabled"
                    >
                    </file-manager-library>

                    <file-manager-upload-preview
                        :manager-id="manager.managerId"
                        :files="manager.uploader.uploadedFiles"
                        :noCropper="noCropper"
                        @uploaded-to-s3="handleUploadHook"
                        @close="close"
                        v-if="step === 'upload-preview'"
                    >
                    </file-manager-upload-preview>

                    <file-manager-edit-image
                        :manager-id="manager.managerId"
                        :file-uuid="file_uuid"
                        @close="close"
                        v-if="step === 'edit-image' && file_uuid"
                    ></file-manager-edit-image>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import {mapGetters} from "vuex";
import EventBus from "@/utils/EventBus";
import FileManagerOptions from "@/components/ui/filemanager/FileManagerOptions";
import FileManagerLibrary from "@/components/ui/filemanager/FileManagerLibrary";
import FileManagerEditImage from "@/components/ui/filemanager/FileManagerEditImage";
import FileManagerUploadPreview from "@/components/ui/filemanager/FileManagerUploadPreview";

export default {
    components: { FileManagerOptions, FileManagerUploadPreview, FileManagerLibrary, FileManagerEditImage },
    data: () => {
        return {
            isOpen: false,
            manager: {},
            step: 'options', // options | library | upload-preview | edit-image
            file_uuid: null, // case of edit image
            noCropper: false
        }
    },
    computed: {
        ...mapGetters({
            getManagerById: 'files/getManagerById'
        }),
    },
    methods: {
        close() {
            this.isOpen = false;
            this.step = 'options';
        },
        handleFilesUploaded() {
            EventBus.emit('data-updated');
        },
        handleUploadHook(file) {
            EventBus.emit('uploaded-to-s3', {
                file: file,
                managerId: this.manager.managerId
            })
        }
    },
    mounted() {
        EventBus.on('open-modal-file-manager', (payload) => {
            this.manager = this.getManagerById(payload.managerId);
            this.noCropper = payload.noCropper;

            if(payload?.step === 'edit-image' && payload.file_uuid) {
                this.step = payload.step;
                this.file_uuid = payload.file_uuid;
            }

            this.isOpen = true;
        });

        EventBus.on('close-modal-file-manager', () => {
            this.isOpen = false;
        });
    }
}
</script>

<style scoped>
.slide-down-enter-active {
    transition: all .3s ease;
}
.slide-down-leave-active {
    transition: all .2s cubic-bezier(1.0, 0.5, 0.8, 1.0);
}
.slide-down-enter-from, .slide-down-leave-to{
    transform: translateY(-100%);
    opacity: 0;
}
</style>