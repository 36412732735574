const loadAmenitiesIcons = ({ commit }, payload) => {
    commit('LOAD_AMENITIES_ICONS', payload);
};

const loadCommunityAmenitiesTypes = ({ commit }, payload) => {
    commit('LOAD_COMMUNITY_AMENITIES_TYPES', payload);
};

const loadCustomerAmenities = ({ commit }, payload) => {
    commit('LOAD_CUSTOMER_AMENITIES', payload);
};

const loadCustomerCommunityAmenities = ({ commit }, payload) => {
    commit('LOAD_CUSTOMER_COMMUNITY_AMENITIES', payload);
};

const addCustomerAmenity = ({ commit }, payload) => {
    commit('ADD_CUSTOMER_AMENITY', payload);
};

const addCustomerCommunityAmenity = ({ commit }, payload) => {
    commit('ADD_CUSTOMER_COMMUNITY_AMENITY', payload);
};

const removeCustomerCommunityAmenity = ({ commit }, payload) => {
    commit('REMOVE_CUSTOMER_COMMUNITY_AMENITY', payload);
};

const clearCustomerCommunityAmenities = ({ commit }) => {
    commit('CLEAR_CUSTOMER_COMMUNITY_AMENITY');
};

const loadCustomerCommunityAmenitiesByType = ({ commit }, payload) => {
    commit('LOAD_CUSTOMER_COMMUNITY_AMENITIES_BY_TYPE', payload);
};

export default {
    loadAmenitiesIcons,
    loadCommunityAmenitiesTypes,
    loadCustomerAmenities,
    loadCustomerCommunityAmenities,
    addCustomerAmenity,
    addCustomerCommunityAmenity,
    removeCustomerCommunityAmenity,
    clearCustomerCommunityAmenities,
    loadCustomerCommunityAmenitiesByType,
};
