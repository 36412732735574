import DATA_CONSUMING_SYSTEM_ROUTES from '@/utils/constants/data_consuming_system/data_consuming_system_routes_constants';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners.js';
import DCS_PERMISSIONS from '@/utils/constants/data_consuming_system/permissions';

const createPermissionChecker = permissionId => () => {
    const hasPermissions = useIntegrationPartnersStore().hasPermission;
    return hasPermissions(permissionId);
};

const ROUTES = [
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.path,
        component: () => {
            return import('@/views/integration_dashboard/dashboard/Index.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.title,
            size: 'large',
            passFlowTo: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_TASK.name,
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.name,
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_TASK.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_TASK.path,
        component: () => {
            return import('@/views/integration_dashboard/task/Task.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_TASK.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_TASK.title,
            size: 'large',
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB.name,
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB.path,
        component: () => {
            return import('@/views/integration_dashboard/job/Job.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB.title,
            size: 'large',
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.name,
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB_BY_STATUS.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB_BY_STATUS.path,
        component: () => {
            return import('@/views/integration_dashboard/job/Job.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB_BY_STATUS.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB_BY_STATUS.title,
            size: 'large',
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.name,
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.ADD_SCHEDULE.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.ADD_SCHEDULE.path,
        component: () => {
            return import('@/views/integration_dashboard/ManageSchedule.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.ADD_SCHEDULE.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.ADD_SCHEDULE.title,
            size: 'large',
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.name,
            product: 'integration_dashboard',
            permissionChecker: () =>
                createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES)() && createPermissionChecker(DCS_PERMISSIONS.UPDATE_SCHEDULES)(),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.EDIT_SCHEDULE.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.EDIT_SCHEDULE.path,
        component: () => {
            return import('@/views/integration_dashboard/ManageSchedule.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.EDIT_SCHEDULE.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.EDIT_SCHEDULE.title,
            size: 'large',
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.DASHBOARD.name,
            product: 'integration_dashboard',
            permissionChecker: () =>
                createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES)() && createPermissionChecker(DCS_PERMISSIONS.UPDATE_SCHEDULES)(),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_EVENT.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_EVENT.path,
        component: () => {
            return import('@/views/integration_dashboard/event/CdcEvent.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_EVENT.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_EVENT.title,
            size: 'large',
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB.name,
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_MESSAGE.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_MESSAGE.path,
        component: () => {
            return import('@/views/integration_dashboard/event/UnifiedMessage.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_MESSAGE.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_MESSAGE.title,
            size: 'large',
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_SCHEDULES.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_SCHEDULES.path,
        component: () => {
            return import('@/views/integration_dashboard/schedules/Schedules.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_SCHEDULES.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_SCHEDULES.title,
            size: 'large',
            flowStartsWith: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_JOB.name,
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.VIEW_SCHEDULES),
        },
    },
    {
        name: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_AUDIT.name,
        path: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_AUDIT.path,
        component: () => {
            return import('@/views/integration_dashboard/audit/Audit.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_AUDIT.breadcrumbName,
            title: DATA_CONSUMING_SYSTEM_ROUTES.VIEW_AUDIT.title,
            size: 'large',
            product: 'integration_dashboard',
            permissionChecker: () => createPermissionChecker(DCS_PERMISSIONS.AUDIT_LOG),
        },
    },
];

export default ROUTES;
