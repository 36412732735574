import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.firekey.management',
        path: '/iot/firekey/management',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/firekey/Management.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'key management',
            title: 'master key',
            flowStartsWith: 'iot.firekey.index',
            permissionChecker: createPermissionChecker('LOCK_ISSUE_KEY_CREDENTIALS'),
        },
    },
]);
