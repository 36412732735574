import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';
import { useLeasingStore } from '@/stores/leasing';

const routes = [
    {
        name: 'manage_application.index',
        path: '/applications-list',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Index.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'applications and lease',
            title: 'applications and lease',
            flowStartsWith: 'manage_application.index',
            product: 'application_service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'manage_application.details',
        path: '/applicantion/details/:occupancyId',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Application.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'application details',
            title: 'application details',
            size: 'large',
            flowStartsWith: 'manage_application.index',
            product: 'application_service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'screening_report',
        path: '/applicantion/screening-report',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/IdVerificationReport.vue');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'screening report',
            title: 'screening report',
            size: 'large',
            flowStartsWith: 'manage_application.index',
            product: 'application_service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'screening.report',
        path: '/applicantion/screening-report',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/ScreeningReport.vue');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'screening report',
            title: 'screening report',
            size: 'large',
            flowStartsWith: 'manage_application.index',
            product: 'application_service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'manage_application.add_person',
        path: '/applicantion/add-person/:role',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Guarantor/AddPerson.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'add person',
            title: 'add person',
            size: 'normal',
            flowStartsWith: 'manage_application.details',
            product: 'application_service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'manage_application.create_profile',
        path: '/applicantion/guarantor/create-profile',
        component: () => {
            return import('@/views/resident_lifecycle/application_service/Guarantor/CreateProfile.vue');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'create profile',
            title: 'create profile',
            size: 'normal',
            flowStartsWith: 'manage_application.details',
            product: 'application_service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    // Leases
    {
        name: 'lease_editor.lease',
        path: '/lease/create-lease',
        component: () => {
            return import('@/views/mvp_lease_service/CreateLease.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create lease',
            title: 'create lease',
            size: 'normal',
            flowStartsWith: 'manage_application.index',
            product: 'lease-service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'lease_editor.default_lease',
        path: '/lease/create-default-lease',
        component: () => {
            return import('@/views/mvp_lease_service/CreateDefaultLease.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create default lease',
            title: 'create default lease',
            size: 'normal',
            flowStartsWith: 'manage_application.index',
            product: 'lease-service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'lease_editor.manage_custom_forms',
        path: '/lease/manage-custom-forms',
        component: () => {
            return import('@/views/mvp_lease_service/ManageCustomForms.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'manage custom forms',
            title: 'manage custom forms',
            size: 'normal',
            flowStartsWith: 'manage_application.index',
            product: 'lease-service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'lease_editor.print_menu',
        path: '/lease/forms-selection',
        component: () => {
            return import('@/views/mvp_lease_service/PrintMenu.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'forms selector',
            title: 'forms selector',
            size: 'normal',
            flowStartsWith: 'manage_application.index',
            product: 'lease-service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
    {
        name: 'lease_editor.esignature_tracker',
        path: '/lease/e-signature/tracker',
        component: () => {
            return import('@/views/mvp_lease_service/ESignatureTracker.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'lease e-signature tracker',
            title: 'lease e-signature tracker',
            size: 'normal',
            flowStartsWith: 'manage_application.index',
            product: 'lease-service',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_APPLICATION_AND_LEASE_MANAGEMENT);
            },
        },
    },
];

export default routes;
