import {
    ADD_COMMUNITY_LOCATIONS,
    ADD_SINGLE_LOCATION,
    CLEAR_COMMUNITY_LOCATIONS,
    DELETE_COMMUNITY_LOCATION,
    UPDATE_SINGLE_LOCATION,
} from './mutations';

const addCommunityLocations = ({ commit }, payload) => commit(ADD_COMMUNITY_LOCATIONS, payload);

const addSingleLocation = ({ commit }, payload) => commit(ADD_SINGLE_LOCATION, payload);

const updateSingleLocation = ({ commit }, payload) => commit(UPDATE_SINGLE_LOCATION, payload);

const deleteCommunityLocation = ({ commit }, payload) => commit(DELETE_COMMUNITY_LOCATION, payload);

const clearCommunityLocations = ({ commit }) => commit(CLEAR_COMMUNITY_LOCATIONS);

export default {
    addCommunityLocations,
    addSingleLocation,
    updateSingleLocation,
    deleteCommunityLocation,
    clearCommunityLocations,
};
