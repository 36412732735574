import { defineStore } from 'pinia';
import { computed } from 'vue';
import store from '@/store';
import { EMAILBOT_PERMISSIONS } from '@/utils/constants/emailbot/permissions';

export const useEmailbotStore = defineStore('emailbot', () => {
    const permissions = computed(() => {
        const community = store.getters['auth/community'];
        const permissions = store.getters['auth/getAppPermissions']('EMAILBOT');
        return permissions?.find(({ communityId }) => community?.id === communityId)?.permissions ?? [];
    });

    const hasPermission = computed(() => permissionId => permissions.value.some(({ id }) => id === permissionId));

    return {
        permissions,
        EMAILBOT_PERMISSIONS,
        hasPermission,
    };
});
