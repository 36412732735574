import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.leak-sensors.index',
        items: [
            {
                title: 'add sensor',
                routeName: 'iot.leak-sensors.create',
                allowed: createPermissionChecker('LEAK_SENSOR_CREATE'),
            },
            {
                title: 'notification log',
                routeName: 'iot.leak-sensors.notification-log',
                allowed: createPermissionChecker('ZWAVE_NOTIFICATION_LOG_READ_LIST'),
            },
        ],
    },
    {
        routeName: 'iot.leak-sensors.details',
        items: [
            {
                title: 'connection log',
                routeName: 'iot.leak-sensors.connection-log',
                allowed: createPermissionChecker('LEAK_SENSOR_READ_CONNECTION_LOG_LIST'),
            },
        ],
    },
];
