<template>
    <MessageBlock
        v-if="idleMessage"
        class="flex items-center justify-center mb-4"
    >
        <Icon
            name="info"
            class="w-6 h-6"
        />
        <div class="ml-2">
            {{ idleMessage }}
        </div>
    </MessageBlock>
    <Form
        ref="form"
        :submit="submitSignIn"
        class="w-full"
    >
        <div class="mb-4">
            <TextField
                name="username"
                label="email or phone number"
                :validate="[required, cognitoUsername]"
                autocomplete="username"
            />
        </div>
        <div class="mb-4">
            <TextField
                name="password"
                label="password"
                type="password"
                :validate="required"
                autocomplete="current-password"
            />
        </div>
        <MessageBlock
            v-if="error"
            type="warning"
            class="flex mb-4"
        >
            <Icon name="warning" />
            <div class="ml-2">
                {{ error }}
            </div>
        </MessageBlock>
        <div>
            <button
                :disabled="isPending"
                type="submit"
                class="btn-primary btn-solid btn-auth"
            >
                sign in
            </button>
        </div>
    </Form>
</template>

<script setup>
import { computed } from 'vue';
import { useAuthenticator } from '@aws-amplify/ui-vue';
import Icon from '@/components/ui/Icon';
import MessageBlock from '@/components/ui/MessageBlock.vue';
import TextField from '@/components/form/TextField';
import Form from '@/components/form/Form';
import { required, cognitoUsername } from '@/components/form/validators';
import { sanitizeCognitoUsername } from '@/components/auth/helpers';
import store from '@/store';

const facade = useAuthenticator();

const isPending = computed(() => facade.isPending);

const error = computed(() => facade.error);

const idleMessage = computed(() => store.getters['flashes/getFlash']);

const submitSignIn = async ({ username, password }) => {
    try {
        // aws-amplify triggers signIn({ username, password }) and transits to nextStep if the operation is successfully performed
        await facade.submitForm({ username: sanitizeCognitoUsername(username), password });
    } catch (e) {
        console.error(e);
    }
};
</script>
