const addAction = async ({ commit }, payload) => {
    commit('ADD_ACTION', payload);
};

const removeAction = ({ commit }, payload) => {
    commit('REMOVE_ACTION', payload);
};

const resetActions = async ({ commit }) => {
    commit('RESET_ACTIONS');
};

export default {
    addAction,
    resetActions,
    removeAction,
};
