import store from '@/store';
import { AMNT } from '@/utils/ppm/permissions';

const createPermissionChecker = permissionId => () => store.getters['amnt/hasPermission'](permissionId);

export default [
    /*
     * Individual amenities: Enterprise Level
     */
    // {
    //     path: '/amenities/individual',
    //     name: 'amenities.enterprise.individual.index',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/individual/AmenitiesList'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'enterprise amenities',
    //         title: 'enterprise amenities',
    //         flowStartsWith: 'amenities.enterprise.individual.index',
    //         permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_VIEW),
    //     },
    // },
    {
        path: '/amenities/individual/create',
        name: 'amenities.enterprise.individual.create',
        component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/individual/AmenityDetailsEL'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new global amenity',
            flowStartsWith: 'amenities.enterprise.individual.index',
            permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_MODIFY),
        },
    },
    {
        path: '/amenities/individual/:amenityId/:tab?',
        name: 'amenities.enterprise.individual.edit', // TODO: Change route names and some components, community/CL are not 'enterprise'.
        component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/individual/AmenityIndividual'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'amenity details',
            title: 'amenity details',
            flowStartsWith: 'amenities.community.individual.index',
            product: 'amnt',
            passFlowTo: 'amenities.enterprise.individual.edit',
            permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_VIEW),
        },
    },
    // {
    //     path: '/amenities/individual/:amenityId/assigned-communities',
    //     name: 'amenities.enterprise.individual.assignedCommunities',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/AssignedCommunities'),
    //     props: {
    //         type: 'AMENITY',
    //         level: 'ENTERPRISE',
    //     },
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'assigned communities list',
    //         title: 'assigned communities list',
    //         flowStartsWith: 'amenities.enterprise.individual.index',
    //         permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_VIEW),
    //     },
    // },
    // {
    //     path: '/amenities/individual/:amenityId/group-membership',
    //     name: 'amenities.enterprise.individual.groupMembership',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/individual/GroupMembership'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'group membership',
    //         title: 'group membership',
    //         flowStartsWith: 'amenities.enterprise.individual.index',
    //         permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_GROUP_VIEW),
    //     },
    // },

    /*
     * Individual amenities: Community Level
     */
    {
        path: '/amenities/community/individual/:tab?',
        name: 'amenities.community.individual.index',
        component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/individual/AmenitiesGeneral'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'property amenities',
            title: 'property amenities',
            flowStartsWith: 'amenities.community.individual.index',
            product: 'amnt',
            permissionChecker: createPermissionChecker(AMNT.CL_AMENITY_VIEW),
        },
    },
    // {
    //     path: '/amenities/community/individual/:amenityId',
    //     name: 'amenities.community.individual.edit',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/individual/AmenityDetailsCL'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'edit amenity',
    //         title: 'edit amenity',
    //         flowStartsWith: 'amenities.community.individual.index',
    //         passFlowTo: 'amenities.community.individual.edit',
    //         permissionChecker: createPermissionChecker(AMNT.CL_AMENITY_VIEW),
    //     },
    // },
    // {
    //     path: '/amenities/community/individual/:amenityId/assigned-communities',
    //     name: 'amenities.community.individual.assignedCommunities',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/AssignedCommunities'),
    //     props: {
    //         type: 'AMENITY',
    //         level: 'COMMUNITY',
    //     },
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'assigned unit list',
    //         title: 'assigned unit list',
    //         flowStartsWith: 'amenities.community.individual.index',
    //         permissionChecker: createPermissionChecker(AMNT.CL_AMENITY_VIEW),
    //     },
    // },
    // {
    //     path: '/amenities/community/individual/:amenityId/group-membership',
    //     name: 'amenities.community.individual.groupMembership',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/individual/GroupMembership'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'group membership',
    //         title: 'group membership',
    //         flowStartsWith: 'amenities.community.individual.index',
    //         permissionChecker: createPermissionChecker(AMNT.CL_AMENITY_GROUP_VIEW),
    //     },
    // },

    /*
     * Amenity groups: Enterprise Level
     */
    // {
    //     path: '/amenities/groups',
    //     name: 'amenities.enterprise.groups.index',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/groups/GroupsList'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'global amenity groups',
    //         title: 'global amenity groups',
    //         flowStartsWith: 'amenities.enterprise.groups.index',
    //         permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_GROUP_VIEW),
    //     },
    // },
    // {
    //     path: '/amenities/groups/create',
    //     name: 'amenities.enterprise.groups.create',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/groups/GroupDetailsEL'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'add new',
    //         title: 'add a new global amenitу group',
    //         flowStartsWith: 'amenities.enterprise.groups.index',
    //         permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_GROUP_MODIFY),
    //     },
    // },
    // {
    //     path: '/amenities/groups/:groupId',
    //     name: 'amenities.enterprise.groups.edit',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/groups/GroupDetailsEL'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'edit amenity group',
    //         title: 'edit amenity group',
    //         flowStartsWith: 'amenities.enterprise.groups.index',
    //         passFlowTo: 'amenities.enterprise.groups.edit',
    //         permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_GROUP_VIEW),
    //     },
    // },
    // {
    //     path: '/amenities/groups/:groupId/assigned-communities',
    //     name: 'amenities.enterprise.groups.assignedCommunities',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/AssignedCommunities'),
    //     props: {
    //         type: 'GROUP',
    //         level: 'ENTERPRISE',
    //     },
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'assigned communities list',
    //         title: 'assigned communities list',
    //         flowStartsWith: 'amenities.enterprise.groups.index',
    //         permissionChecker: createPermissionChecker(AMNT.EL_AMENITY_GROUP_VIEW),
    //     },
    // },

    /*
     * Amenity groups: Community Level
     */
    // {
    //     path: '/amenities/community/groups',
    //     name: 'amenities.community.groups.index',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/groups/GroupsList'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'community amenity groups',
    //         title: 'community amenity groups',
    //         flowStartsWith: 'amenities.community.groups.index',
    //         permissionChecker: createPermissionChecker(AMNT.CL_AMENITY_GROUP_VIEW),
    //     },
    // },
    // {
    //     path: '/amenities/communities-assignment/:id?',
    //     name: 'amenities.communities-assignment',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/CommunitiesAssignment'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'communities assignment',
    //         title: 'communities assignment',
    //         permissionChecker: createPermissionChecker(AMNT.EL_COMMUNITY_ASSIGNMENT),
    //     },
    // },
    // {
    //     path: '/amenities/community/groups/:groupId',
    //     name: 'amenities.community.groups.edit',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/groups/GroupDetailsCL'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'edit amenity group',
    //         title: 'edit amenity group',
    //         flowStartsWith: 'amenities.community.groups.index',
    //         passFlowTo: 'amenities.community.groups.edit',
    //         permissionChecker: createPermissionChecker(AMNT.CL_AMENITY_GROUP_VIEW),
    //     },
    // },
    {
        path: '/amenities/community/units-assignment',
        name: 'amenities.community.unitsAssignment',
        component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/UnitsAssignment'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'units assignment',
            title: 'units assignment',
            flowStartsWith: 'amenities.community.individual.index',
            permissionChecker: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
        },
    },
    // {
    //     path: '/amenities/community/individual/:amenityId/units-assignment',
    //     name: 'amenities.community.individual.unitsAssignment',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/UnitsAssignment'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'units assignment',
    //         title: 'units assignment',
    //         flowStartsWith: 'amenities.community.individual.index',
    //         permissionChecker: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
    //     },
    // },
    // {
    //     path: '/amenities/community/groups/:groupId/units-assignment',
    //     name: 'amenities.community.groups.unitsAssignment',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/UnitsAssignment'),
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'units assignment',
    //         title: 'units assignment',
    //         flowStartsWith: 'amenities.community.groups.index',
    //         permissionChecker: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
    //     },
    // },
    // {
    //     path: '/amenities/community/groups/:groupId/assigned-communities',
    //     name: 'amenities.community.groups.assignedCommunities',
    //     component: () => import(/* webpackChunkName: "amenities" */ '@/views/amenities/assignment/AssignedCommunities'),
    //     props: {
    //         type: 'GROUP',
    //         level: 'COMMUNITY',
    //     },
    //     meta: {
    //         loadInModal: true,
    //         actions: true,
    //         breadcrumbName: 'assigned unit list',
    //         title: 'assigned unit list',
    //         flowStartsWith: 'amenities.community.groups.index',
    //         permissionChecker: createPermissionChecker(AMNT.CL_AMENITY_GROUP_VIEW),
    //     },
    // },
];
