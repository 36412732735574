import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    persistedValues: {
        reservationsList: null,
        assetsList: null,
        locationsTree: {
            checkedLocations: [],
            formValues: {},
        },
    },
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
