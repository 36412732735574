import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.light-switches.details',
        path: '/iot/light-switches/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/light_switches/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'switch details',
            title: 'switch details',
            flowStartsWith: 'iot.light-switches.index',
            permissionChecker: createPermissionChecker('LIGHT_SWITCH_READ_LIST'),
        },
    },
    {
        name: 'iot.light-switches.configuration',
        path: '/iot/light-switches/:id/configuration',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/light_switches/Configuration.vue'),
        meta: {
            breadcrumbName: 'configuration',
            title: 'configuration',
            flowStartsWith: 'iot.light-switches.details',
            permissionChecker: createPermissionChecker('LIGHT_SWITCH_READ'),
        },
    },
    {
        name: 'iot.light-switches.connection-log',
        path: '/iot/light-switches/:id/connection-log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/light_switches/ConnectionLog.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'connection log',
            title: 'connection log',
            flowStartsWith: 'iot.light-switches.details',
            permissionChecker: createPermissionChecker('LIGHT_SWITCH_READ_CONNECTION_LOG_LIST'),
        },
    },
    {
        name: 'iot.light-switches.create',
        path: '/iot/light-switches/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/light_switches/AddSwitch.vue'),
        meta: {
            breadcrumbName: 'add switch',
            title: 'new switch',
            flowStartsWith: 'iot.light-switches.index',
            permissionChecker: createPermissionChecker('LIGHT_SWITCH_INSTALL'),
        },
    },
    {
        name: 'iot.light-switches.select-router',
        path: '/iot/light-switches/select-router',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/light_switches/SelectRouter.vue'),
        meta: {
            breadcrumbName: 'change router',
            title: 'new switch',
            flowStartsWith: 'iot.light-switches.index',
            permissionChecker: createPermissionChecker('LIGHT_SWITCH_INSTALL'),
            nonStacked: true,
        },
    },
]);
