<template>
    <transition name="slide-in">
        <div
            v-if="show"
            class="bg-active-100 text-active-800 p-10 absolute bottom-0 left-0 text-center w-full z-50"
        >
            <p class="font-600 font-frank text-lg mx-auto w-2/3">
                {{ message }}
            </p>
            <div class="mt-4 text-center">
                <icon
                    name="gridLoader"
                    class="h-12 w-12 inline"
                    :class="showIndicator ? 'opacity-1' : 'opacity-0'"
                />
            </div>
        </div>
    </transition>
</template>

<script>
import EventBus from '@/utils/EventBus';
import { slugify } from '@/utils/String';
import Icon from '@/components/ui/Icon';

export default {
    components: { Icon },
    data: () => {
        return {
            show: false,
            showIndicator: false,
            action: null,
            message: null,
            downloadStarted: 'your download has started',
            downloadComplete: 'your download is complete',
        };
    },

    mounted() {
        EventBus.on('show-modal-download', action => {
            this.action = action;
            this.message = this.action.downloadStarted ? this.action.downloadStarted : this.downloadStarted;
            this.showIndicator = true;
            this.show = true;
            EventBus.emit(slugify(this.action.title));
        });

        EventBus.on('hide-download-indicator', () => {
            this.message = this.action.downloadComplete ? this.action.downloadComplete : this.downloadComplete;
            this.showIndicator = false;
            window.setTimeout(() => {
                this.show = false;
            }, 1000);
        });
    },
};
</script>

<style scoped>
.slide-in-enter-active {
    transition: all 0.3s ease;
}
.slide-in-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-in-enter-from,
.slide-in-leave-to {
    transform: translateY(100%);
    opacity: 0;
}
</style>
