import { computed, ref } from 'vue';
import store from '@/store';
import getEnvVariable from '@/utils/getEnvVariable';

export const usePendo = () => {
    const pendo = ref(window.pendo);

    const visitorProfile = computed(() => store.getters['auth/profile']);
    const visitorUsername = computed(() => store.getters['auth/cognitoUserId']);
    const visitorEmail = computed(() => store.getters['auth/email']);
    const visitorRole = computed(() => store.getters['auth/userRole']);

    const initializeVisitor = () => {
        if (!pendo.value) {
            console.error('Pendo is not found');
            return;
        }

        const disableGuides = getEnvVariable('VUE_APP_PENDO_DISABLE_GUIDES');
        const { firstName = '', lastName = '' } = visitorProfile.value || {};

        pendo.value.initialize({
            visitor: {
                id: visitorUsername.value,
                name: `${firstName} ${lastName}`,
                email: visitorEmail.value,
                role: visitorRole.value,
            },
            disableGuides: disableGuides === 'true',
        });
    };

    return {
        initializeVisitor,
    };
};
