import { SET_PREFERENCES, SET_GRAPH_COLORS } from './constants';
export default {
    [SET_PREFERENCES]: (state, payload) => {
        state.dashboard.preferences = payload;
    },

    [SET_GRAPH_COLORS]: (state, payload) => {
        state.dashboard.graphColors = payload;
    },
};
