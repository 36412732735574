import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '@/store';

const baseURL = getEnvVariable('VUE_APP_API_DIGITAL_HUMAN_URL');

// const actions = ['get', 'getOne', 'getList', 'create'];
// const digitalHumanResources = [
//     'conversationReport',
//     'conversationReportPdf',
//     'customResponseReportByTopic',
//     'customResponseReportByTopicPdf',
//     'customResponseCustomer',
//     'customResponseCustomerPdf',
//     'globalResponseReport',
//     'globalResponseReportPdf',
//     'transcriptReport',
//     'mismatchReport',
//     'previewGlobalResponsesReport',
//     'previewCustomResponsesTopicReport',
//     'previewCustomResponsesCustomerReport',
//     'previewConversationGlobalReport',
//     'previewConversationCustomReport',
//     'translateReport',
//     'uploadAvailability',
//     'allUnits',
//     'unit',
//     'editUnit',
//     'validateAvailability',
//     'allCommunityCustomIntentResponses',
//     'allGuest',
//     'intent',
//     'getIntent',
//     'deleteIntent',
//     'getHoursOfOperations',
//     'previewTrainingPhrasesReport',
//     'trainingPhrasesReportPdf',
// ];

const beforeRequestInterceptor = ({ requestConfig }) => ({
    ...requestConfig,
    responseType: requestConfig.params.responseType,
    headers: {
        ...requestConfig.params.headers,
        'X-Quext-Customer-Id': store.getters['auth/customerId'],
        'X-Quext-Community-Id': store.getters['auth/community']?.id,
    },
});

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider(httpAdapter);
