import EventBus from '@/utils/EventBus';

export default {
    data() {
        return {
            cleanupConfirmationHandlers: null,
        };
    },

    methods: {
        requestConfirmation(action) {
            return new Promise(resolve => {
                const id = 'confirmation-mixin-action';

                const onConfirm = () => {
                    resolve(true);
                    this.cleanupConfirmationHandlers?.();
                };

                const onCancel = () => {
                    resolve(false);
                    this.cleanupConfirmationHandlers?.();
                };

                EventBus.on(`confirm-${id}`, onConfirm);
                EventBus.on(`cancel-${id}`, onCancel);

                this.cleanupConfirmationHandlers = () => {
                    EventBus.off(`confirm-${id}`, onConfirm);
                    EventBus.off(`cancel-${id}`, onCancel);
                    this.cleanupConfirmationHandlers = null;
                };

                EventBus.emit('show-modal-confirmation', {
                    ...action,
                    id,
                });
            });
        },
    },

    beforeUnmount() {
        EventBus.emit('hide-modal-confirmation');
        this.cleanupConfirmationHandlers?.();
    },
};
