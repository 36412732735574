import { isQuextAdmin } from '@/utils/actionAllowedRules';

export default [
    {
        routeName: 'calendar_events.index',
        items: [
            {
                title: 'add new event',
                routeName: 'calendar_events.create',
                params: {},
                allowed: isQuextAdmin,
            },
            {
                title: 'settings',
                routeName: 'calendar_settings.index',
                params: {},
                allowed: isQuextAdmin,
            },
        ],
    },
    // {
    //     routeName: "quextUsers.edit",
    //     items: [],
    // },
];
