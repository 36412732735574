import { SET_START_DATE, SET_END_DATE, CLEAN_STATE } from './mutations';

const setStartDate = ({ commit }, payload) => commit(SET_START_DATE, payload);

const setEndDate = ({ commit }, payload) => commit(SET_END_DATE, payload);

const cleanState = ({ commit }, payload) => commit(CLEAN_STATE, payload);

export default {
    setStartDate,
    setEndDate,
    cleanState,
};
