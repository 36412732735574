import { createPermissionChecker, prepareIotRoutes } from '../helper';

const routes = [
    {
        name: 'iot.thermostats.add',
        path: '/iot/thermostats/add',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/AddThermostat.vue'),
        meta: {
            breadcrumbName: 'add new thermostat',
            title: 'add new thermostat',
            actions: true,
            flowStartsWith: 'iot.thermostats.add',
            permissionChecker: createPermissionChecker('THERMOSTAT_CREATE'),
        },
    },
    {
        name: 'iot.thermostats.update-firmware',
        path: '/iot/thermostats/update-firmware',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/UpdateFirmware.vue'),
        meta: {
            breadcrumbName: 'update firmware',
            title: 'update firmware',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_RESET'),
        },
    },
    {
        name: 'iot.thermostats.details',
        path: '/iot/thermostats/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/Details.vue'),
        meta: {
            breadcrumbName: 'thermostat details',
            title: 'thermostat details',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_READ'),
        },
    },
    {
        name: 'iot.thermostats.advanced-settings',
        path: '/iot/thermostats/:id/advanced-settings',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/AdvancedSettings.vue'),
        meta: {
            breadcrumbName: 'advanced settings',
            title: 'advanced settings',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_READ'),
        },
    },
    {
        name: 'iot.thermostats.replace',
        path: '/iot/thermostats/:id/replacement',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/Replacement.vue'),
        meta: {
            breadcrumbName: 'replace thermostat',
            title: 'replace thermostat',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_REPLACE'),
        },
    },
    {
        name: 'iot.thermostats.select-location',
        path: '/iot/thermostats/:id/select-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/SelectLocation.vue'),
        meta: {
            breadcrumbName: 'select location',
            title: 'select location',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_UPDATE'),
        },
    },
    {
        name: 'iot.thermostats.log',
        path: '/iot/thermostats/:id/log',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/Log.vue'),
        meta: {
            breadcrumbName: 'logs',
            title: 'logs',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_READ_LOG'),
        },
    },
    {
        name: 'iot.thermostats.manage-schedule',
        path: '/iot/thermostats/:id/schedule',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/Schedule.vue'),
        meta: {
            breadcrumbName: 'manage schedule',
            title: 'manage schedule',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_READ'),
        },
    },
    {
        name: 'iot.thermostats.diagnostic',
        path: '/iot/thermostats/:id/diagnostic',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/Diagnostic.vue'),
        meta: {
            breadcrumbName: 'diagnostic',
            title: 'diagnostic',
            actions: true,
            flowStartsWith: 'iot.thermostats.index',
            permissionChecker: createPermissionChecker('THERMOSTAT_READ_DIAGNOSTIC'),
        },
    },
    {
        name: 'iot.thermostats.columns',
        path: '/iot/thermostats/columns',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/ThermostatListColumns.vue'),
        meta: {
            breadcrumbName: 'configure columns',
            title: 'configure columns',
            actions: true,
            flowStartsWith: 'iot.thermostats.columns',
            permissionChecker: createPermissionChecker('THERMOSTAT_READ_LIST'),
        },
    },
];

export default prepareIotRoutes(routes);
