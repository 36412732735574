import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.light-switches.index',
        items: [
            {
                title: 'add switch',
                routeName: 'iot.light-switches.create',
                allowed: createPermissionChecker('LIGHT_SWITCH_INSTALL'),
            },
        ],
    },
    {
        routeName: 'iot.light-switches.details',
        items: [
            {
                title: 'configuration',
                routeName: 'iot.light-switches.configuration',
                allowed: createPermissionChecker('LIGHT_SWITCH_READ'),
            },
            {
                title: 'connection log',
                routeName: 'iot.light-switches.connection-log',
                params: {
                    lockId: '{id}',
                },
                allowed: createPermissionChecker('LIGHT_SWITCH_READ_CONNECTION_LOG_LIST'),
            },
        ],
    },
];
