const actions = [
    {
        routeName: 'partner-field.edit',
        items: [
            {
                id: 'delete-field-all',
                title: 'delete field',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this field from all documents?',
                confirmBtnText: 'yes, delete',
                confirmationType: 'warning',
            },
            {
                id: 'delete-field-document',
                title: 'delete field form document',
                isRegular: true,
            },
            {
                id: 'delete-field-translation',
                title: 'delete field translation',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this field translation?',
                confirmBtnText: 'yes, delete',
                confirmationType: 'warning',
            },
        ],
    },
    {
        routeName: 'add-quext-field.manage',
        items: [
            {
                id: 'manage-quext-concepts',
                title: 'manage quext concepts',
                routeName: 'manage-quext-concept.manage',
            },
        ],
    },
];

export default actions;
