import UnitStatuses from '@/components/community_settings/UnitStatuses';
import store from '@/store';
import { CBR } from '@/utils/ppm/permissions';

export default {
    key: 'unit_statuses',
    title: 'unit statuses',
    component: UnitStatuses,
    permissionChecker: () => store.getters['cbr/hasPermission'](CBR.UNIT_STATUS_SETTINGS_VIEW),
};
