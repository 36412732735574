import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';
import { useLeasingStore } from '@/stores/leasing';

const routes = [
    {
        name: 'leases.index',
        path: '/leases',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'process move-ins',
            title: 'process move-ins',
            size: 'normal',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            requiresCustomer: true,
            requiresCommunity: true,
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.lease-and-resident',
        path: '/lease-and-resident/:id',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/LeaseAndResident');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'lease and resident',
            title: 'lease and resident',
            size: 'normal',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.costs',
        path: '/costs/:id/:startDate/:endDate?/:source?/',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/Costs');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'costs',
            title: 'costs',
            size: 'normal',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.overview',
        path: '/overview/:id',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/Overview');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'lease overview',
            title: 'lease overview',
            size: 'normal',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.addOneTime',
        path: '/add-one-time',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddOneTime');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add one-time',
            title: 'add one-time',
            size: 'small',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.addRecurring',
        path: '/add-recurring/:id/:transactionId/:startDate/:endDate/:source',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddRecurring');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add recurring',
            title: 'add recurring',
            size: 'small',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.editCharge',
        path: '/add-recurring/:id/:transactionId/:startDate/:endDate/:source',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddRecurring');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit charge',
            title: 'edit charge',
            size: 'small',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            editMode: true,
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.addConcession',
        path: '/add-concession/:id/:transactionId/:startDate/:endDate/:source',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddConcession');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add concession',
            title: 'add concession',
            size: 'small',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
    {
        name: 'leases.editConcession',
        path: '/add-concession/:id/:transactionId/:startDate/:endDate/:source',
        component: () => {
            return import('@/views/resident_lifecycle/move_in/AddConcession');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit concession',
            title: 'edit concession',
            size: 'small',
            flowStartsWith: 'leases.index',
            product: 'move-in',
            editMode: true,
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_MOVE_IN);
            },
        },
    },
];

export default routes;
