import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import { resources, proxyResources } from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';

const baseURL = getEnvVariable('VUE_APP_PERMISSIONS_API_URL');
const proxeURL = getEnvVariable('VUE_APP_PROXY_PRMSN_API_URL');
const proxeTargetAppURL = getEnvVariable('VUE_APP_PROXY_TARGET_APP_URL');

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources);
const proxyHttpAdapter = new HttpAdapter(httpClient(proxeURL), proxyResources.permissions);
const proxyTargetAppHttpAdapter = new HttpAdapter(httpClient(proxeTargetAppURL), proxyResources.targetApp);

export default new DataProvider({
    handle: (action, resource, params) => {
        switch (resource) {
            case 'identityPermissions':
                return proxyHttpAdapter.handle(action, resource, params);
            case 'quextUser':
            case 'customerUser':
                return proxyTargetAppHttpAdapter.handle(action, resource, params);
            default:
                return httpAdapter.handle(action, resource, params);
        }
    },
});
