import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.automation.index',
        items: [
            {
                title: 'automation log',
                routeName: 'iot.automation.logs',
                allowed: createPermissionChecker('SMART_POLICY_READ_EVENT_LOG_LIST'),
            },
        ],
    },
    {
        routeName: 'iot.automation.energy-saving',
        items: [
            {
                title: 'automation rules',
                routeName: 'iot.automation.energy-saving.rules',
                allowed: createPermissionChecker('SMART_POLICY_READ_STATE_LIST'),
            },
        ],
    },
    {
        routeName: 'iot.automation.staff-access',
        items: [
            {
                title: 'automation rules',
                routeName: 'iot.automation.staff-access.rules',
                allowed: createPermissionChecker('USER_GROUP_READ_LIST'),
            },
        ],
    },
    {
        routeName: 'iot.automation.move-ins',
        items: [
            {
                title: 'automation rules',
                routeName: 'iot.automation.move-ins.rules',
                allowed: createPermissionChecker('SMART_POLICY_READ_STATE_LIST'),
            },
        ],
    },
    {
        routeName: 'iot.schedules.index',
        items: [
            {
                title: 'add new',
                routeName: 'iot.schedules.create',
                allowed: createPermissionChecker('LOCK_CREDENTIALS_SCHEDULE_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.schedules.edit',
        items: [
            {
                id: 'iot-schedule-delete',
                title: 'delete',
                showConfirmation: true,
                confirmationMessage: 'are you sure you want to delete this schedule?',
                confirmBtnText: 'yes, delete',
                cancelBtnText: 'no, go back',
                confirmationType: 'warning',
                allowed: createPermissionChecker('LOCK_CREDENTIALS_SCHEDULE_DELETE'),
            },
        ],
    },
    {
        routeName: 'iot.automation.leak-sensors',
        items: [
            {
                title: 'automation rules',
                routeName: 'iot.automation.leak-sensors.rules',
                allowed: createPermissionChecker('LEAK_SENSOR_AUTOMATION_READ'),
            },
        ],
    },
];
