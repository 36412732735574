import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';
import { useLeasingStore } from '@/stores/leasing';

const routes = [
    {
        name: 'transfer_resident.index',
        path: '/transfer-resident',
        component: () => {
            return import('@/views/resident_lifecycle/transfer_resident/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'transfer resident',
            title: 'transfer resident',
            size: 'normal',
            flowStartsWith: 'transfer_resident.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_TRANSFER_RESIDENTS);
            },
        },
    },
    {
        name: 'transfer_resident.create-lease',
        path: '/create-lease',
        component: () => {
            return import('@/views/resident_lifecycle/transfer_resident/CreateLease');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create lease',
            title: 'create lease',
            size: 'normal',
            flowStartsWith: 'transfer_resident.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_TRANSFER_RESIDENTS);
            },
        },
    },
    {
        name: 'transfer_resident.edit-resident-details',
        path: '/edit-resident-details',
        component: () => {
            return import('@/views/resident_lifecycle/transfer_resident/EditResidentDetails');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit resident details',
            title: 'edit resident details',
            size: 'normal',
            flowStartsWith: 'transfer_resident.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_TRANSFER_RESIDENTS);
            },
        },
    },
];

export default routes;
