<script setup>
import { onBeforeUnmount, onMounted } from 'vue';
import EventBus from '@/utils/EventBus';
import getEnvVariable from '@/utils/getEnvVariable';
import { generatePayload } from '@/components/permission_service/utils';
import { usePermissionsStore } from '@/stores/permissions';
import { REFRESH_PERMISSIONS_EVENT, LOAD_PERMISSIONS_EVENT, PERMISSION_UPDATE_CHANNEL } from '@/components/permission_service/constants';
import PermissionsDataProvider from '@/api/permissions';
import config from '@/permissions.config';

const serviceEnabled = Boolean(Number(getEnvVariable('VUE_APP_PERMISSIONS_SERVICE'))) && config && Object.keys(config).length > 0;

const permissionStore = usePermissionsStore();

function loadPermissions(data = {}) {
    PermissionsDataProvider.adapter
        .get('permissions', { identityId: permissionStore.identityId, data })
        .then(response => {
            console.log(`
                Permissions for user identity: ${permissionStore.identityId}.
                Customer context: ${permissionStore.context?.customerId}.
                Community context: ${permissionStore.context?.communityId}.
                Result:
                ${JSON.stringify(response, null, ' ')}
            `);

            permissionStore.setData(response.apps);
        })
        .catch(error => {
            console.log('error', error);
        });
}

function handleNotification({ channel, body: message }) {
    if (!serviceEnabled) {
        return;
    }

    if (channel === PERMISSION_UPDATE_CHANNEL && message?.cognitoUserId === permissionStore.identityId) {
        const data = permissionStore.config;
        loadPermissions(data);
    }
}

function refreshPermissions() {
    const context = permissionStore.context;
    const appCodes = permissionStore.supportedAppCodes;
    const data = generatePayload(config, { ...context, appCodes });

    permissionStore.setConfig(data);

    loadPermissions(data);
}

EventBus.on(REFRESH_PERMISSIONS_EVENT, () => {
    if (!serviceEnabled) {
        return;
    }
    refreshPermissions();
});

EventBus.on(LOAD_PERMISSIONS_EVENT, filters => {
    if (!serviceEnabled) {
        return;
    }
    loadPermissions(filters);
});

EventBus.on('wst-notification', handleNotification);

onMounted(() => {
    if (serviceEnabled) {
        refreshPermissions();
    }
});

onBeforeUnmount(() => {
    EventBus.off('wst-notification', handleNotification);
    EventBus.off(REFRESH_PERMISSIONS_EVENT);
    EventBus.off(LOAD_PERMISSIONS_EVENT);
});
</script>
