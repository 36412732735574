export const PERMISSION_UPDATE_CHANNEL = 'PRMSN/permissions_updated';

export const REFRESH_PERMISSIONS_EVENT = 'permission-service:refresh';

export const LOAD_PERMISSIONS_EVENT = 'permission-service:load';

export const PERMISSION_DOMAIN = {
    CUSTOMER: {
        key: 'CUSTOMER',
        configKey: 'customer',
        label: 'Customer',
    },
    QUEXT: {
        key: 'QUEXT',
        configKey: 'quext',
        label: 'Quext',
    },
};

export const PERMISSION_CONTEXT = {
    NO_CONTEXT: {
        key: 'NO_CONTEXT',
        configKey: 'no-context',
        label: 'no context',
    },
    CUSTOMER: {
        key: 'CUSTOMER',
        configKey: 'customer',
        label: 'Customer',
    },
    COMMUNITY: {
        key: 'COMMUNITY',
        configKey: 'community',
        label: 'Community',
    },
};

export const PROFILE_TYPES = {
    SUPER_ADMIN: 'SUPER_ADMIN',
    QUEXT: 'QUEXT',
    PORTFOLIO_ADMIN: 'PORTFOLIO_ADMIN',
    PORTFOLIO: 'PORTFOLIO',
};
