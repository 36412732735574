import EventBus from '@/utils/EventBus';
import { mapActions, mapGetters } from 'vuex';
import { useFullPageStore } from '@/stores/navigation';
import { mapStores } from 'pinia';

export default {
    computed: {
        ...mapGetters({
            backModal: 'modals/getLastModal',
            getModalByRouteName: 'modals/getModalByRouteName',
            getLastModal: 'modals/getLastModal',
            activeModalTitle: 'modals/getActiveModalTitle',
            activeBreadcrumbName: 'modals/getActiveBreadcrumbName',
        }),

        ...mapStores(useFullPageStore),
    },

    methods: {
        ...mapActions({
            pushModal: 'modals/pushModal',
            popAllModals: 'modals/popAllModals',
            removeModalByRouteName: 'modals/removeModalByRouteName',
            refreshModals: 'modals/refreshModals',
            setActiveModalTitle: 'modals/setActiveModalTitle',
            setActiveModalBreadcrumbName: 'modals/setActiveModalBreadcrumbName',
        }),

        /*
         *  helper method to set title and breadcrumb name for the active modal.
         */
        setActiveModalName(name) {
            if (!name) {
                return;
            }

            this.setActiveModalTitle(name.toLowerCase());
            this.setActiveModalBreadcrumbName(name.toLowerCase());
        },

        /*
         *  helper method to navigate to a given route name.
         */
        redirect: function (routeName, params) {
            if (!routeName) {
                return;
            }
            this.$router?.push({ name: routeName, params }).then(() => {});
        },

        /*
         *  helper method to replace a given route(aka "from") with a new one(aka "to")
         */
        async replaceModal({ from, to }) {
            if (this.getLastModal?.routeName === from.name) {
                return;
            }
            this.removeModalByRouteName(from.name);
            this.pushModal({
                template: this.$el.outerHTML,
                routeName: to.name,
                params: to.params,
                breadcrumbName: to.meta.breadcrumbName,
                title: to.meta.title,
            });
            await this.$router?.replace({ name: to.name, params: to.params });
        },

        /*
         *  helper method to close the active modal.
         */
        close() {
            const route = this.backModal
                ? { name: this.backModal.routeName, params: this.backModal.params }
                : { name: this.fullPageStore.lastFullPageRouteName };

            this.$router.push(route).then(() => {});
        },

        hideDownloadIndicator() {
            EventBus.emit('hide-download-indicator');
        },

        handleNavigateForward({ from, to }) {
            // Fix multiple usage of ModalNavigation mixin inside components on the same view
            if (this.getLastModal?.routeName === from.name) {
                return;
            }

            // cleanup images to avoid retrieving revoked blobs
            if (this.$el.querySelectorAll) {
                this.$el.querySelectorAll('img[src^="blob:"]').forEach(el => {
                    el.src = '';
                });
            }

            if (from.matched[0].name === to.matched[0].name) {
                return null;
            }

            this.pushModal({
                template: this.$el.outerHTML,
                routeName: from.name,
                params: from.params,
                breadcrumbName: this.activeBreadcrumbName || from.meta.breadcrumbName,
                title: this.activeModalTitle,
            });
        },

        handleNavigateBack({ to }) {
            this.refreshModals(to.name);
        },
    },

    mounted() {
        EventBus.on('navigateForward', this.handleNavigateForward);
        EventBus.on('navigateBack', this.handleNavigateBack);
    },

    beforeUnmount() {
        EventBus.off('navigateForward', this.handleNavigateForward);
        EventBus.off('navigateBack', this.handleNavigateBack);

        this.setActiveModalBreadcrumbName(undefined);
    },
};
