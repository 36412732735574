const routes = [
    {
        name: 'lease.index',
        path: '/lease',
        component: () => {
            return import('@/views/lease/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'new lease',
            title: 'new lease',
            size: 'large',
            flowStartsWith: 'lease.index',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
    {
        name: 'lease.lease-terms',
        path: '/lease-terms',
        component: () => {
            return import('@/views/lease/LeaseTerms');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'lease terms',
            flowStartsWith: 'lease.index',
            title: 'lease terms',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.Animal-addendum',
        path: '/animal-addendum',
        component: () => {
            return import('@/views/lease/AnimalAddendum');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'animal addendum',
            title: 'animal addendum',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.Lead-Hazard',
        path: '/lead-hazard',
        component: () => {
            return import('@/views/lease/LeadHazard');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'lead hazard',
            title: 'lead hazard',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.student-lease',
        path: '/student-lease',
        component: () => {
            return import('@/views/lease/StudentLease');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'student lease',
            flowStartsWith: 'lease.index',
            title: 'student lease',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.other-charges',
        path: '/other-charges',
        component: () => {
            return import('@/views/lease/OtherCharges');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'other charges',
            flowStartsWith: 'lease.index',
            title: 'other charges',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.special-provisions',
        path: '/special-provisions',
        component: () => {
            return import('@/views/lease/SpecialProvisions');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'special provisions',
            flowStartsWith: 'lease.index',
            title: 'special provisions',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.attachments',
        path: '/attachments',
        component: () => {
            return import('@/views/lease/Attachments');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'attachments',
            flowStartsWith: 'lease.index',
            title: 'attachments',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.allocation-addenda',
        path: '/allocation-addenda',
        component: () => {
            return import('@/views/lease/AllocationAddenda');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'allocation addenda',
            flowStartsWith: 'lease.index',
            title: 'allocation addenda',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.misc-addenda',
        path: '/misc-addenda',
        component: () => {
            return import('@/views/lease/MiscellaneousAddenda');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'misc. addenda',
            flowStartsWith: 'lease.index',
            title: 'misc. addenda',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.remote-card-code',
        path: '/remote-card-code',
        component: () => {
            return import('@/views/lease/RemoteCardCode');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'remote/card/code',
            flowStartsWith: 'lease.index',
            title: 'remote/card/code',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.renters-insurance',
        path: '/renters-insurance',
        component: () => {
            return import('@/views/lease/RentersInsurance');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'renters insurance',
            flowStartsWith: 'lease.index',
            title: 'renters insurance',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.lease-guaranty',
        path: '/lease-guaranty',
        component: () => {
            return import('@/views/lease/LeaseGuaranty');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'lease guaranty',
            flowStartsWith: 'lease.index',
            title: 'lease guaranty',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.summary-information',
        path: '/summary-information',
        component: () => {
            return import('@/views/lease/SummaryInformation');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'summary information',
            flowStartsWith: 'lease.index',
            title: 'summary information',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.covid19-forms',
        path: '/covid19-forms',
        component: () => {
            return import('@/views/lease/Covid19Forms');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'covid-19 form',
            flowStartsWith: 'lease.index',
            title: 'covid-19 form',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.rent-consessions',
        path: '/rent-consessions',
        component: () => {
            return import('@/views/lease/RentConsessions');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'rent consessions',
            flowStartsWith: 'lease.index',
            title: 'rent consessions',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'lease.intrusion-alarm',
        path: '/intrusion-alarm',
        component: () => {
            return import('@/views/lease/IntrusionAlarm');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'intrusion alarm',
            flowStartsWith: 'lease.index',
            title: 'intrusion alarm',
            size: 'large',
            product: 'lease',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
];

export default routes;
