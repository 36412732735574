<template>
    <transition name="slide-down">
        <div
            v-if="isOpen"
            id="modal-colors-selector"
            class="fm-wrapper"
        >
            <div class="fm-overlay" />
            <div class="fm-content">
                <div class="fm-content-wrapper">
                    <div class="h-full">
                        <div class="w-full px-8 py-4 -ml-8 font-medium font-600 font-frank text-base text-active-600">
                            {{ title }}
                        </div>
                        <div
                            class="mt-8 px-8"
                            :class="{ 'grid grid-cols-2 gap-7': selectorsAmount > 1 }"
                        >
                            <div
                                v-for="(item, index) in selectorsAmount"
                                :key="index"
                                class="relative"
                            >
                                <div
                                    v-if="index % 2 !== 0"
                                    class="separator"
                                />
                                <div class="text-center mb-2">
                                    {{ selectorsLabels[index] }}
                                </div>
                                <ColorPicker
                                    :class="'m-auto'"
                                    :color="selectorsDefaultColors[index]"
                                    :visible-formats="['hex']"
                                    default-format="hex"
                                    alpha-channel="hide"
                                    @color-change="onColorChange($event, index)"
                                />
                            </div>
                        </div>
                        <div class="absolute bottom-0 left-0 w-full px-8 py-6 flex justify-between bg-gray-200">
                            <button
                                class="btn-primary btn-transparent"
                                @click="close"
                            >
                                cancel
                            </button>
                            <button
                                class="btn-primary btn-solid"
                                @click="submit"
                            >
                                save
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </transition>
</template>

<script>
import EventBus from '@/utils/EventBus';
import { ColorPicker } from 'vue-accessible-color-picker';

export default {
    components: { ColorPicker },
    data: () => {
        return {
            isOpen: false,
            title: 'color selector',
            selectorsAmount: 1,
            selectorsLabels: ['primary color'],
            selectorsDefaultColors: ['#ededed'],
            selectedColors: [],
        };
    },

    mounted() {
        EventBus.on('open-modal-colors-selector', params => {
            this.isOpen = true;
            this.loadSelector(params);
        });

        EventBus.on('close-modal-colors-selector', () => {
            this.isOpen = false;
        });
    },

    methods: {
        onColorChange(event, index) {
            this.selectedColors[index] = event.cssColor;
        },

        submit() {
            EventBus.emit('modal-colors-selector-submitted', { selectedColors: this.selectedColors });
            this.close();
        },

        close() {
            this.isOpen = false;
        },

        loadSelector(params) {
            if (params === undefined) {
                return;
            }
            if (params.title) {
                this.title = params.title;
            }
            if (params.selectorsAmount > 1) {
                this.selectorsAmount = params.selectorsAmount;
            }

            if (params.selectorsLabels) {
                this.selectorsLabels = params.selectorsLabels;
            }

            if (params.selectorsDefaultColors) {
                this.selectorsDefaultColors = params.selectorsDefaultColors;
            }
        },
    },
};
</script>

<style scoped>
.slide-down-enter-active {
    transition: all 0.3s ease;
}
.slide-down-leave-active {
    transition: all 0.2s cubic-bezier(1, 0.5, 0.8, 1);
}
.slide-down-enter-from,
.slide-down-leave-to {
    transform: translateY(-100%);
    opacity: 0;
}
.separator {
    border-left: 1px solid #ccc;
    height: 90%;
    position: absolute;
    bottom: 0;
}
</style>
