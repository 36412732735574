import getEnvVariable from '@/utils/getEnvVariable';
const searchServiceEnabled = Boolean(Number(getEnvVariable('VUE_APP_SEARCH_SERVICE')));
const routes = [
    {
        name: 'home',
        path: '/',
        component: searchServiceEnabled ? () => import('@/views/search/Index.vue') : () => import('@/views/homepage/home/Index.vue'),
    },
];

export default routes;
