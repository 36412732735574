import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    icons: [],
    communityAmenitiesTypes: [],
    customerAmenities: [],
    customerCommunityAmenities: {
        Community: [],
        Apartment: [],
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
