import { PERMISSION_DOMAIN, PERMISSION_CONTEXT } from '@/components/permission_service/constants';

export const generatePayload = (config, { customerId, communityId, appCodes }) => {
    if (!appCodes || !config) {
        return { apps: [] };
    }

    const payload = appCodes
        .map(appCode => {
            const appConfig = config[appCode];

            if (!appConfig) {
                return;
            }

            const filters = Object.values(PERMISSION_DOMAIN).reduce((acc, domain) => {
                const domainConfig = appConfig.domains[domain.configKey];

                if (!domainConfig) {
                    return acc;
                }

                const data = Object.values(PERMISSION_CONTEXT).reduce((result, context) => {
                    const contextConfig = domainConfig.context[context.configKey];

                    if (!contextConfig) {
                        return result;
                    }

                    const contextIds = [];

                    if (context.key === PERMISSION_CONTEXT.CUSTOMER.key && customerId) {
                        contextIds.push(customerId);
                    } else if (context.key === PERMISSION_CONTEXT.COMMUNITY.key && communityId) {
                        contextIds.push(communityId);
                    }

                    const contextPayload = {
                        domain: domain.key,
                        context: context.key,
                        contextIds,
                        permissionKeys: contextConfig,
                    };

                    result.push(contextPayload);

                    return result;
                }, []);

                return [...acc, ...data];
            }, []);

            return {
                appCode,
                filters,
            };
        })
        .filter(Boolean);

    return { apps: payload };
};
