import { useEmailbotStore } from '@/stores/emailbot/emailbot';
import EMAILBOT_PERMISSIONS from '@/components/emailbot/common/emailbot_permissions_constants';

const createPermissionChecker = permissionId => () => {
    const hasPermission = useEmailbotStore().hasPermission;
    return hasPermission(permissionId);
};

export default [
    {
        path: '/emailbot/:emailConfigId/details',
        name: 'emailbot.details',
        component: () => import('@/views/emailbot/EmailbotDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'email bot details',
            title: 'view email bot',
            hideFromSearch: true,
            size: 'large',
            permissionChecker: createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_EMAILBOT),
            requiresCustomer: true,
        },
    },
    {
        path: '/emailbot/:emailConfigId',
        name: 'emailbot.edit',
        component: () => import('@/views/emailbot/EmailbotEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit email bot',
            title: 'edit email bot',
            hideFromSearch: true,
            size: 'large',
            permissionChecker: createPermissionChecker(EMAILBOT_PERMISSIONS.EDIT_EMAILBOT),
            requiresCustomer: true,
        },
    },
    {
        path: '/emailbot/create',
        name: 'emailbot.create',
        component: () => import('@/views/emailbot/EmailbotCreate.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create email bot',
            title: 'create email bot',
            hideFromSearch: true,
            requiresCustomer: true,
            size: 'large',
            permissionChecker: createPermissionChecker(EMAILBOT_PERMISSIONS.CREATE_EMAILBOT),
        },
    },
];
