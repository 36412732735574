const hasPermission = (state, getters, rootState, rootGetters) => permissionId => {
    return (
        rootGetters['auth/getAppPermissions']('CBR')?.find(({ communityId, permissions }) => {
            return communityId === rootGetters['auth/community']?.id && permissions.find(({ id }) => id === permissionId);
        }) !== undefined
    );
};

const persistedValues = state => state.persistedValues;

export default {
    hasPermission,
    persistedValues,
};
