<template>
    <Tooltip
        icon="info"
        text="Google Authenticator, Duo Mobile or Authy app"
        class="ml-2"
    >
        <template #default>
            <div>
                <p class="font-600 mb-2">
                    authenticator apps:
                </p>
                <div
                    v-for="(group, index) in authenticatorApps"
                    :key="index"
                >
                    <span class="my-2 block">{{ group.title }}</span>
                    <ul :style="{ 'list-style': 'square inside' }">
                        <li
                            v-for="(app, liIndex) in group.apps"
                            :key="liIndex"
                        >
                            <a
                                :href="app.link"
                                target="_blank"
                            >
                                {{ app.title }}
                                <icon
                                    name="externalLink"
                                    class="w-2 h-2 inline-block"
                                />
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </template>
    </Tooltip>
</template>

<script setup>
import Tooltip from '@/components/ui/Tooltip';
import Icon from '@/components/ui/Icon';

const authenticatorApps = [
    {
        title: 'iOS',
        apps: [
            {
                title: 'Authy',
                link: 'https://apps.apple.com/us/app/authy/id494168017',
            },
            {
                title: 'Duo Mobile',
                link: 'https://apps.apple.com/us/app/duo-mobile/id422663827',
            },
            {
                title: 'Google Authenticator',
                link: 'https://apps.apple.com/us/app/google-authenticator/id388497605',
            },
            {
                title: 'LastPass Authenticator',
                link: 'https://apps.apple.com/us/app/lastpass-authenticator/id1079110004',
            },
            {
                title: 'Microsoft Authenticator',
                link: 'https://apps.apple.com/us/app/microsoft-authenticator/id983156458',
            },
        ],
    },
    {
        title: 'Android',
        apps: [
            {
                title: 'Authy',
                link: 'https://play.google.com/store/apps/details?id=com.authy.authy',
            },
            {
                title: 'Duo Mobile',
                link: 'https://play.google.com/store/apps/details?id=com.duosecurity.duomobile',
            },
            {
                title: 'Google Authenticator',
                link: 'https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2',
            },
            {
                title: 'LastPass Authenticator',
                link: 'https://play.google.com/store/apps/details?id=com.lastpass.authenticator',
            },
            {
                title: 'Microsoft Authenticator',
                link: 'https://play.google.com/store/apps/details?id=com.azure.authenticator',
            },
        ],
    },
];
</script>
