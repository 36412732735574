const all = state => {
    return state.pages;
};

const count = state => {
    return state.pages.length;
};

const getPageBySlugAndWebsiteId = state => {
    if (!state.pages.length) return null;

    return (slug, websiteId) => state.pages.find(page => page.url_alias === slug && page.websites_id === websiteId);
};

const getPagesByWebsite = state => {
    return websiteId => state.pages.filter(page => page.websites_id === websiteId);
};

const getMainPageByWebsite = state => {
    return () => state.pages.filter(page => page.is_home_page === true);
};

export default {
    all,
    count,
    getPageBySlugAndWebsiteId,
    getPagesByWebsite,
    getMainPageByWebsite,
};
