<template>
    <div class="-mb-4">
        <div
            v-for="status in statuses"
            :key="labelsMode ? status.id : status.code"
            class="mb-4"
        >
            <div class="text-xs font-semibold mb-2">
                {{ status.name }}
            </div>
            <div class="text-2sm font-normal">
                {{ status.description }}
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        statuses: {
            type: Array,
            required: true,
        },

        labelsMode: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
