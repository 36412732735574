const actions = [
    {
        routeName: 'medias.show',
        items: [
            {
                id: 'delete-media',
                title: 'Delete',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this file?',
                confirmBtnText: 'yes, delete',
                confirmationType: 'warning',
            },
            // {
            //     title: "edit",
            //     routeName: "medias.edit",
            //     params: {
            //         id: '{id}'
            //     },
            // },
        ],
    },
];

export default actions;
