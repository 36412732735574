import mutations from './mutations';
import actions from './actions';
import getters from './getters';

const state = {
    user: {
        authenticated: false,
        activeProfile: undefined,
        appsPermissions: {},
        profiles: undefined,
        activeCommunity: undefined,
        activeCustomerApps: [],
        activeCustomerCommunityAppCodes: [],
    },
    communities: [],
};

export default {
    namespaced: true,
    state,
    mutations,
    actions,
    getters,
};
