const listFilterValues = state => state.list.filterValues;

const tmpBuffer = state => state.tmpBuffer;

const hasPermission = (state, getters, rootState, rootGetters) => permissionId => {
    return (
        rootGetters['auth/getAppPermissions']('SREQ')?.find(({ communityId, permissions }) => {
            return communityId === rootGetters['auth/community']?.id && permissions.find(({ id }) => id === permissionId);
        }) !== undefined
    );
};

export default {
    listFilterValues,
    tmpBuffer,
    hasPermission,
};
