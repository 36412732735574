import { SET_PREFERENCES, SET_GRAPH_COLORS } from './constants';

const setPreference = async ({ commit }, payload) => {
    commit(SET_PREFERENCES, payload);
};

const setGraphColor = async ({ commit }, payload) => {
    commit(SET_GRAPH_COLORS, payload);
};

export default {
    setPreference,
    setGraphColor,
};
