const LOAD_AMENITIES_ICONS = (state, payload) => {
    state.icons = payload;
};

const LOAD_COMMUNITY_AMENITIES_TYPES = (state, payload) => {
    state.communityAmenitiesTypes = payload;
};

const LOAD_CUSTOMER_AMENITIES = (state, payload) => {
    state.customerAmenities = payload;
};

const LOAD_CUSTOMER_COMMUNITY_AMENITIES = (state, payload) => {
    state.customerCommunityAmenities = payload;
};

const ADD_CUSTOMER_AMENITY = (state, payload) => {
    state.customerAmenities.push(payload);
};

const ADD_CUSTOMER_COMMUNITY_AMENITY = (state, payload) => {
    state.customerCommunityAmenities[payload.type].push(payload);
};

const REMOVE_CUSTOMER_COMMUNITY_AMENITY = (state, payload) => {
    state.customerCommunityAmenities[payload.type] = state.customerCommunityAmenities[payload.type].filter(item => {
        return item.type === payload.type && item.amenity !== payload.amenity;
    });
};

const CLEAR_CUSTOMER_COMMUNITY_AMENITY = state => {
    state.customerCommunityAmenities = {
        Community: [],
        Apartment: [],
    };
};

const LOAD_CUSTOMER_COMMUNITY_AMENITIES_BY_TYPE = (state, payload) => {
    state.customerCommunityAmenities[payload.type] = payload.data;
};

export default {
    LOAD_AMENITIES_ICONS,
    LOAD_COMMUNITY_AMENITIES_TYPES,
    LOAD_CUSTOMER_AMENITIES,
    LOAD_CUSTOMER_COMMUNITY_AMENITIES,
    ADD_CUSTOMER_AMENITY,
    ADD_CUSTOMER_COMMUNITY_AMENITY,
    REMOVE_CUSTOMER_COMMUNITY_AMENITY,
    CLEAR_CUSTOMER_COMMUNITY_AMENITY,
    LOAD_CUSTOMER_COMMUNITY_AMENITIES_BY_TYPE,
};
