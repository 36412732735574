<template>
    <div
        class="editable-field relative"
        :class="field_uid"
    >
        <FinalForm
            ref="fieldForm"
            :submit="handleFormSubmit"
            :initial-values="initialValues"
            :class="formClass"
        >
            <template #default="props">
                <form @submit="props.handleSubmit">
                    <div
                        class="inline-flex items-center"
                        :class="editMode && editClass"
                    >
                        <div
                            ref="field"
                            class="relative w-full"
                            @keydown="handleKeydown"
                        >
                            <!-- @slot Field control that has editMode -->
                            <slot
                                name="field"
                                :edit-mode="editMode"
                                :form-props="props"
                                :click-handler="edit"
                            />
                        </div>
                        <div :class="{ 'self-start': props.invalid }">
                            <SpinLoader
                                v-if="updating"
                                class="ml-1"
                            />
                            <div
                                v-else-if="editMode"
                                class="editable-field-actions flex items-center"
                            >
                                <slot
                                    name="cancel"
                                    :cancel-handler="cancel"
                                >
                                    <IconButton
                                        icon="close"
                                        type="button"
                                        class="flex-shrink-0 ml-2 text-graphite-800"
                                        @click.stop="cancel"
                                    />
                                </slot>
                                <slot
                                    name="save"
                                    :form-props="props"
                                >
                                    <IconButton
                                        icon="checkmark"
                                        class="flex-shrink-0 ml-1 text-active-500"
                                        @click.stop="props.handleSubmit"
                                    />
                                </slot>
                            </div>
                            <div v-else-if="withEditControl">
                                <IconButton
                                    icon="pencil2"
                                    class="flex-shrink-0 ml-2 row-hover--visible"
                                    @click.stop="edit"
                                />
                            </div>
                        </div>
                    </div>
                </form>
            </template>
        </FinalForm>
    </div>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import IconButton from '@/components/ui/IconButton';
import SpinLoader from '@/components/ui/SpinLoader';
import { isEqual } from 'lodash-es';

export default {
    name: 'EditableField',
    components: {
        FinalForm,
        IconButton,
        SpinLoader,
    },

    props: {
        /**
         * Action to perform on saving changes
         */
        onSubmit: {
            type: Function,
            required: true,
        },

        /**
         * Initial values to fill the form when editMode is on
         */
        initialValues: {
            type: Object,
            default: () => ({}),
        },

        /**
         * Classes to apply to the control container inside the form
         */
        editClass: {
            type: String,
            default: undefined,
        },

        /**
         * Classes to apply to the form in editMode
         */
        formClass: {
            type: String,
            default: undefined,
        },

        /**
         * If the control should close editMode and return in its text state after saving changes
         */
        closeOnSubmit: {
            type: Boolean,
            default() {
                return true;
            },
        },

        /**
         * Cancel changes when click outside the control. By default submit changes on click outside
         */
        cancelOnOutside: {
            type: Boolean,
            default: false,
        },

        /**
         * Do nothing when click outside the control. By default submit changes on click outside
         */
        noClickOutside: {
            type: Boolean,
            default: false,
        },

        /**
         * Show pencil icon to start editing
         */
        withEditControl: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['edit-mode-change'],
    data: function () {
        return {
            editMode: false,
            updating: false,
        };
    },

    computed: {
        field_uid() {
            return 'editable-field-' + this._uid;
        },

        innerState() {
            return this.$refs.fieldForm.formState;
        },
    },

    watch: {
        editMode(val) {
            this.$emit('edit-mode-change', val);

            this.$nextTick(() => {
                if (!this.editMode) {
                    return;
                }

                const el = this.$refs.field.querySelector('input');

                if (!el) {
                    return;
                }

                el.focus();
            });
        },

        initialValues(val) {
            this.$refs.fieldForm?.finalForm.initialize(val);
        },
    },

    created() {
        document.addEventListener('click', this.handleOutsideClickEvent);
    },

    beforeUnmount() {
        document.removeEventListener('click', this.handleOutsideClickEvent);
    },

    methods: {
        edit() {
            this.editMode = true;
        },

        cancel() {
            this.editMode = false;
            this.$refs.fieldForm.finalForm.reset();
        },

        handleKeydown(e) {
            if (e.key === 'Escape') {
                this.cancel();
            }
        },

        async handleFormSubmit(values) {
            if (!isEqual(values, this.initialValues)) {
                this.updating = true;
                const result = await this.onSubmit(values);
                this.updating = false;
                if (result) {
                    return result;
                }
            }
            if (this.closeOnSubmit) {
                this.editMode = false;
            }
        },

        closeEditMode() {
            this.editMode = false;
        },

        handleOutsideClickEvent(e) {
            if (this.noClickOutside) {
                return;
            }
            if (this.editMode && !this.$el.contains(e.target) && !e.target.closest('.' + this.field_uid)) {
                if (this.cancelOnOutside) {
                    this.cancel();
                } else {
                    this.$refs.fieldForm.finalForm.submit();
                }
            }
        },
    },
};
</script>

<style scoped>
.editable-field:hover:deep(.editable-field-button) {
    @apply opacity-100;
}
.editable-field-actions {
    height: 2.5rem;
    align-self: baseline;
}
</style>
