import { defineStore } from 'pinia';

export const useRenewalsStore = defineStore('renewals', {
    state: () => ({
        renewalHeader: '',
        renewalParagraphs: [],
    }),

    actions: {
        setRenewalPreview(paragraphs, header) {
            this.renewalParagraphs = paragraphs;
            this.renewalHeader = header;
        },
    },
});
