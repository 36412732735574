import { SET_SETUP_COMPLETED, SET_CURRENT_TAB, SET_IS_FROM_SETUP, SET_COMES_FROM_FEES, SET_LOADING } from './mutations';

const setSetupCompleted = ({ commit }, payload) => commit(SET_SETUP_COMPLETED, payload);
const setCurrentTab = ({ commit }, payload) => commit(SET_CURRENT_TAB, payload);
const setIsFromSetup = ({ commit }, payload) => commit(SET_IS_FROM_SETUP, payload);
const setComesFromFees = ({ commit }, payload) => commit(SET_COMES_FROM_FEES, payload);
const setLoading = ({ commit }, payload) => commit(SET_LOADING, payload);

export default {
    setSetupCompleted,
    setIsFromSetup,
    setCurrentTab,
    setComesFromFees,
    setLoading,
};
