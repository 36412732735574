const routes = [
    {
        name: 'custom_responses.index',
        path: '/custom-responses',
        component: () => {
            return import('@/views/digital_human/manage_custom_responses/Index');
        },
        meta: {
            actions: true,
            loadInModal: true,
            breadcrumbName: 'monitor custom responses',
            title: 'monitor custom responses',
            size: 'large',
            flowStartsWith: 'custom_responses.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'custom_responses.report',
        path: '/custom-responses-report',
        component: () => {
            return import('@/views/digital_human/manage_custom_responses/Report.vue');
        },
        meta: {
            loadInModal: true,
            title: 'custom responses report',
            breadcrumbName: 'custom responses report',
            allowedRoles: ['SUPER_ADMIN'],
            requiredAppAccess: ['MIA'],
            size: 'large',
        },
    },
];

export default routes;
