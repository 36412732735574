<template>
    <ul class="category-menu">
        <slot />
    </ul>
</template>

<style scoped>
.category-menu :deep(.category-menu__item:not(:last-child)) {
    margin-bottom: 0.65rem;
}
</style>