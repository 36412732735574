const hasPermission = (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/isQuextAdmins'] && +process.env.VUE_APP_PARTNER_DOCUMENTS;
};

const getPartnerField = state => state.partner_field;
const getPartner = state => state.partner;

export default {
    hasPermission,
    getPartnerField,
    getPartner,
};
