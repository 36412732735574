const ADD_FLASH = (state, payload) => {
    state.flash = payload;
};

const RESET_FLASH = state => {
    state.flash = null;
};

export default {
    ADD_FLASH,
    RESET_FLASH,
};
