<template>
    <button
        type="button"
        class="category-menu-item-btn"
        :class="{'category-menu-item-btn--active': active}"
        :disabled="disabled"
    >
        <span
            class="category-menu-item-btn__content "
            data-cy="filter__group__header"
        >
            <span class="category-menu-item-btn__label">
                <!-- @slot Label container -->
                <slot />
            </span>
            <span
                v-if="typeof number == 'number'"
                class="category-menu-item-btn__counter"
            >
                {{ number }}
            </span>
        </span>
        <!-- @slot Additional content -->
        <slot name="additional-content" />
    </button>       
</template>

<script>
export default {
    props: {
        /**
         * Value of counter
         */
        number: {
            type: Number,
            default: null,
        },

        /**
         * Button active state
         */
        active: {
            type: Boolean,
            default: false,
        },
        /**
         * Button disabled state
         */
        disabled: {
            type: Boolean,
            default: false,
        },
    },
}
</script>

<style scoped>
.category-menu-item-btn {
    @apply block border border-solid w-full py-2 px-3 border-gray-300;
 
    &__content {
        @apply flex content-center justify-between;
    }

    &__label {
        @apply flex-grow inline-flex items-center font-frank text-2sm text-gray-850;
    }

    &__counter {
        @apply inline-block h-6 min-w-6 align-top text-xs font-inter rounded-full px-2 bg-graphite-400 text-graphite-900;
        padding-top: 3px;
        padding-bottom: 3px;
    }


    &--active {
        @apply border-active-500;
    }

    &--active & {
        &__label {
            @apply text-active-500;
        }

        &__counter {
            @apply text-white bg-active-500;
        }
    }


    &:hover {
        @apply border-active-600 bg-active-600;
    }

    &:hover & {
        &__label {
            @apply text-white;
        }

        &__counter {
            @apply text-white bg-active-700;
        }
    }


    &:disabled {
        @apply border-graphite-300 bg-graphite-300;
    }

    &:disabled & {
        &__label {
            @apply text-graphite-700;
        }

        &__counter {
            @apply hidden;
        }
    }
}
</style>