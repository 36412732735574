import { useEmailbotStore } from '@/stores/emailbot/emailbot';
import EMAILBOT_PERMISSIONS from '@/components/emailbot/common/emailbot_permissions_constants';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

const createPermissionChecker = permissionId => () => {
    const hasPermission = useEmailbotStore().hasPermission;
    return hasPermission(permissionId);
};

export default [
    {
        name: 'emailbot.index',
        path: '/emailbot',
        component: TabbedRouterModal,
        meta: {
            breadcrumbName: 'Community Email BOTs',
            title: 'emailbot',
            loadInModal: true,
            nonStacked: true,
        },
        children: [
            {
                name: 'emailbot.bots.index',
                path: '/emailbot/bots',
                component: () => import(/* webpackChunkName: "emailbot" */ '@/views/emailbot/bots/Index.vue'),
                meta: {
                    loadInModal: true,
                    actions: true,
                    title: 'email bots',
                    flowStartsWith: 'emailbot.bots.index',
                    product: 'emailbot',
                    requiresCustomer: true,
                    size: 'large',
                    permissionChecker: () =>
                        createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_EMAILBOT)() ||
                        createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE)() ||
                        createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_CATEGORY)(),
                },
            },
            {
                name: 'emailbot.templates.index',
                path: '/emailbot/templates',
                component: () => import(/* webpackChunkName: "emailbot" */ '@/views/emailbot/templates/Index.vue'),
                meta: {
                    loadInModal: true,
                    actions: true,
                    title: 'templates',
                    flowStartsWith: 'emailbot.templates.index',
                    product: 'emailbot',
                    requiresCustomer: true,
                    size: 'large',
                    permissionChecker: () =>
                        createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_EMAILBOT)() ||
                        createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_COMMUNITY_TEMPLATE)() ||
                        createPermissionChecker(EMAILBOT_PERMISSIONS.VIEW_CATEGORY)(),
                },
            },
        ],
    },
];
