import { APP_SYNC_STARTED, APP_SYNC_STOPPED } from '@/store/auth/appSync/mutations';

const appSyncStarted = ({ commit }, payload) => commit(APP_SYNC_STARTED, payload);

const appSyncStopped = ({ commit }, payload) => commit(APP_SYNC_STOPPED, payload);

export default {
    appSyncStarted,
    appSyncStopped,
};
