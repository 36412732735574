<template>
    <label
        v-if="$slots['default']"
        :for="name"
    >
        <slot />
        <Tooltip
            v-if="tooltip"
            icon="info"
            class="w-4 h-4 ml-1 text-graphite-800"
            :css="{
                tooltipPopup: 'w-auto',
            }"
            :text="tooltip"
            :position-y="tooltipVerticalPosition"
        />
    </label>
</template>

<script>
import Tooltip from '@/components/ui/Tooltip';

export default {
    name: 'FieldLabel',

    components: {
        Tooltip,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        tooltip: {
            type: String,
            default: '',
        },

        tooltipVerticalPosition: {
            type: String,
            default: undefined,
        },
    },
};
</script>
