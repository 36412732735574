import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.automation.logs',
        path: '/iot/automation/scenarios/logs',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/AutomationLogs.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation log',
            title: 'automation log',
            flowStartsWith: 'iot.automation.logs',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ_EVENT_LOG_LIST'),
        },
    },
    {
        name: 'iot.automation.energy-saving',
        path: '/iot/automation/scenarios/energy-saving',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/energy_saving/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation details',
            title: 'energy saving mode',
            flowStartsWith: 'iot.automation.energy-saving',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ'),
        },
    },
    {
        name: 'iot.automation.energy-saving.rules',
        path: '/iot/automation/scenarios/energy-saving/rules',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/energy_saving/AutomationRules.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation rules',
            title: 'energy saving mode',
            flowStartsWith: 'iot.automation.energy-saving.rules',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ_STATE_LIST'),
        },
    },
    {
        name: 'iot.automation.energy-saving.rules-schedule',
        path: '/iot/automation/scenarios/energy-saving/rules/schedule',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/energy_saving/Schedule.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'manage schedule',
            title: 'energy saving mode',
            flowStartsWith: 'iot.automation.energy-saving.rules',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ_STATE_LIST'),
        },
    },
    {
        name: 'iot.automation.staff-access',
        path: '/iot/automation/scenarios/staff-access',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/staff_access/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation details',
            title: 'staff access to vacant units',
            flowStartsWith: 'iot.automation.staff-access',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ'),
        },
    },
    {
        name: 'iot.automation.staff-access.rules',
        path: '/iot/automation/scenarios/staff-access/rules',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/staff_access/AutomationRules.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation rules',
            title: 'staff access to vacant units',
            flowStartsWith: 'iot.automation.staff-access.rules',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ'),
        },
    },
    {
        name: 'iot.automation.move-ins',
        path: '/iot/automation/scenarios/move-ins',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/move_ins/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation details',
            title: 'recommended move-in settings',
            flowStartsWith: 'iot.automation.move-ins',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ'),
        },
    },
    {
        name: 'iot.automation.move-ins.rules',
        path: '/iot/automation/scenarios/move-ins/rules',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/move_ins/AutomationRules.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation rules',
            title: 'recommended move-in settings',
            flowStartsWith: 'iot.automation.move-ins.rules',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ_STATE_LIST'),
        },
    },
    {
        name: 'iot.automation.move-ins.rules-schedule',
        path: '/iot/automation/scenarios/move-ins/rules/schedule',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/move_ins/Schedule.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'manage schedule',
            title: 'recommended move-in settings',
            flowStartsWith: 'iot.automation.move-ins.rules',
            permissionChecker: createPermissionChecker('SMART_POLICY_READ_STATE_LIST'),
        },
    },
    {
        name: 'iot.automation.leak-sensors',
        path: '/iot/automation/scenarios/leak-sensors',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/leak_sensors/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'notifications',
            title: 'notifications',
            flowStartsWith: 'iot.automation.leak-sensors',
            permissionChecker: createPermissionChecker('LEAK_SENSOR_AUTOMATION_READ'),
        },
    },
    {
        name: 'iot.automation.leak-sensors.rules',
        path: '/iot/automation/scenarios/leak-sensors/rules',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/leak_sensors/Edit.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'automation rules',
            title: 'automation rules',
            flowStartsWith: 'iot.automation.leak-sensors',
            permissionChecker: createPermissionChecker('LEAK_SENSOR_AUTOMATION_READ'),
        },
    },
    {
        name: 'iot.automation.leak-sensors.rules.add',
        path: '/iot/automation/scenarios/leak-sensors/rules/add',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/scenarios/leak_sensors/Create.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'add user',
            title: 'new user',
            flowStartsWith: 'iot.automation.leak-sensors',
            permissionChecker: createPermissionChecker('LEAK_SENSOR_AUTOMATION_UPDATE'),
        },
    },
]);
