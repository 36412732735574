export default vm => {
    return vm.profile.customerId && vm.community?.id
        ? vm.$accountsDataProvider
            .get('permissions', {
                headers: {
                    customerId: vm.profile.customerId,
                    communityId: vm.community.id,
                },
            })
            .then(({ permissions }) => {
                if (permissions[0]?.permissions?.length > 0) {
                    return permissions[0].permissions.map(permission => permission.name);
                }
                return [];
            })
            .catch(() => [])
        : Promise.resolve([]);
};
