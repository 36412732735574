export const SUNDAY = 'SUNDAY';
export const MONDAY = 'MONDAY';
export const TUESDAY = 'TUESDAY';
export const WEDNESDAY = 'WEDNESDAY';
export const THURSDAY = 'THURSDAY';
export const FRIDAY = 'FRIDAY';
export const SATURDAY = 'SATURDAY';

export const WEEKDAYS = [SUNDAY, MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY, SATURDAY];
export const WORKDAYS = [MONDAY, TUESDAY, WEDNESDAY, THURSDAY, FRIDAY];
export const WEEKENDS = [SATURDAY, SUNDAY];
