import { createPermissionChecker } from '@/router/iot/helper';

export default [
    {
        routeName: 'iot.leases.index',
        items: [
            {
                title: 'add new lease',
                routeName: 'iot.create-lease.index',
                allowed: createPermissionChecker('LEASE_CREATE'),
            },
        ],
    },
    {
        routeName: 'iot.lease-details.index',
        items: [
            {
                title: 'lease authorizations',
                routeName: 'iot.lease.authorizations',
                params: {
                    id: '{leaseId}',
                },
                allowed: createPermissionChecker('LEASE_READ_ACCESSES'),
            },
            {
                title: 'transfer residents',
                routeName: 'iot.lease-transfer.index',
                params: {
                    leaseId: '{leaseId}',
                },
                allowed: () => createPermissionChecker('LEASE_TRANSFER')() && createPermissionChecker('DELAY_LINE_READ', false)(),
            },
            {
                title: 'renew lease',
                routeName: 'iot.lease-renew.index',
                params: {
                    leaseId: '{leaseId}',
                },
                allowed: () => createPermissionChecker('LEASE_RENEW') && createPermissionChecker('DELAY_LINE_READ', false)(),
            },
            {
                id: 'iot-archive-lease',
                showConfirmation: true,
                title: 'archive lease',
                confirmationNote: 'You won’t be able to undo it',
                confirmationMessage: 'are you sure you want to archive the lease?',
                confirmBtnText: 'yes, archive',
                cancelBtnText: 'no, go back',
                confirmationType: 'warning',
                allowed: createPermissionChecker('LEASE_ARCHIVE'),
            },
        ],
    },
    {
        routeName: 'iot.lease.authorizations',
        items: [
            {
                title: 'add location',
                routeName: 'iot.lease.authorizations.add-location',
            },
        ],
        allowed: createPermissionChecker('LEASE_UPDATE_ACCESSES'),
    },
];
