import { createPermissionChecker, PORTFOLIO_PERMISSIONS, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'applications.index',
        items: [
            {
                title: 'add new application',
                routeName: 'applications.create',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_APP),
            },
            {
                title: 'deleted applications',
                routeName: 'applications.deletedApplications.index',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_APPS),
            },
            {
                title: 'manage bundles',
                routeName: 'applications.bundles.index',
                params: {},
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_BUNDLES),
            },
        ],
    },
    {
        routeName: 'applications.create',
        items: [],
    },
    {
        routeName: 'applications.details',
        items: [
            {
                title: 'manage roles',
                routeName: 'applications.roles.index',
                params: {
                    appId: '{appId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_ROLES),
            },
            {
                title: 'manage permissions',
                routeName: 'applications.permissions.index',
                params: {
                    appId: '{appId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_PERMISSIONS),
            },
            {
                title: 'customer access',
                routeName: 'applications.customers.index',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_CUSTOMER_APP_ACCESS),
            },
        ],
    },
    {
        routeName: 'applications.roles.index',
        items: [
            {
                title: 'deleted roles',
                routeName: 'applications.deletedRoles.index',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_APP_ROLES),
            },
        ],
    },
    {
        routeName: 'applications.permissions.index',
        items: [],
    },
    {
        routeName: 'applications.customers.users.details',
        items: [],
    },
    {
        routeName: 'applications.bundles.index',
        items: [
            {
                title: 'add new bundle',
                routeName: 'applications.bundles.create',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_BUNDLE),
            },
        ],
    },
    {
        routeName: 'applications.bundles.details',
        items: [],
    },
];
