export default {
    autoopen: {
        getList: ['GET', '/integrations/autoopen'],
        create: ['POST', '/integrations/autoopen'],
        getOne: ['GET', '/integrations/autoopen/{id}'],
        update: ['PUT', '/integrations/autoopen/{id}'],
        delete: ['DELETE', '/integrations/autoopen/{id}'],
        getLocationRules: ['GET', '/integrations/autoopen/location/{id}'],
        enableRule: ['PUT', '/integrations/autoopen/location/{id}/switch'],
    },
};
