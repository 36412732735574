<template>
    <div class="flex flex-col h-full">
        <div>
            <div
                v-if="!assignedUnitTypesQty"
                class="container-card bg-active-50"
            >
                <div class="container-card__text text-active-700">
                    Please assign a pricing matrix to all your unit types.
                </div>
                <div class="container-button bg-active-500">
                    <router-link
                        :to="{ name: 'community.settings.pricingMatrix.details' }"
                        class="container-button__text"
                    >
                        assign pricing matrix
                    </router-link>
                </div>
            </div>
        </div>
        <div>
            <div
                v-if="assignedUnitTypesQty && allUnitTypesQty !== assignedUnitTypesQty"
                class="container-card bg-red-100"
            >
                <div class="container-card__text text-red-900">
                    Before you leave, please be sure to assign a pricing matrix to all your unit types ({{
                        `${assignedUnitTypesQty}/${allUnitTypesQty}`
                    }}).
                </div>
                <div class="container-button bg-red-500">
                    <router-link
                        :to="{ name: 'community.settings.pricingMatrix.details' }"
                        class="container-button__text"
                    >
                        assign pricing matrix
                    </router-link>
                </div>
            </div>
            <div v-if="assignedUnitTypesQty">
                <div
                    v-for="(item, id) in pricingMatrices"
                    :key="id"
                >
                    <Collapsible
                        :css="{
                            contentClass: 'p-0',
                            headingClass: 'bg-white',
                            headingExpandedClass: 'pb-0',
                            collapsibleClass: 'border-0',
                            arrowClass: 'w-4 min-w-4 h-4 text-black mb-4',
                        }"
                        initial-expanded
                    >
                        <template #heading>
                            <div class="flex justify-between w-full">
                                <div class="flex">
                                    <p class="font-bold mb-5">
                                        {{ formatUnitTypeNames(item.unitTypes) }}
                                    </p>
                                    <router-link :to="{ name: 'community.settings.pricingMatrix.details', params: { id: item.id } }">
                                        <IconButton
                                            class="text-highlight-base ml-2"
                                            icon="pencil2"
                                        />
                                    </router-link>
                                </div>
                            </div>
                        </template>
                        <template #content>
                            <List
                                :columns="columns"
                                :items="item.rowsForDisplay"
                                class="mb-5"
                            />
                        </template>
                    </Collapsible>
                </div>
            </div>
        </div>

        <loader :loading="isLoading" />
    </div>
</template>

<script>
import Loader from '@/components/ui/Loader';
import IconButton from '@/components/ui/IconButton';
import List from '@/components/list/List.vue';
import Collapsible from '@/components/ui/Collapsible';
import { useAlertStore } from '@/stores/alert';
import { formatCurrency } from '@/utils/String';

const COLUMNS = [
    {
        name: 'month',
        title: 'month',
        class: 'w-6/12',
    },
    {
        name: 'premium',
        title: 'premium',
        class: 'flex justify-end w-6/12',
    },
];

export default {
    components: { Loader, List, IconButton, Collapsible },

    setup() {
        const { notifyError } = useAlertStore();

        return { notifyError };
    },

    data() {
        return {
            isLoading: false,
            pricingMatrices: [],
            unitTypesQty: 0,
            allUnitTypesQty: 0,
            unitTypesUnassignedQty: 0,
            columns: COLUMNS,
        };
    },

    computed: {
        assignedUnitTypesQty() {
            return this.unitTypesQty - this.unitTypesUnassignedQty;
        },
    },

    created() {
        this.isloading = true;
        Promise.all([this.loadUnitTypesWithPricingMatrices(), this.loadListPricingMatrices()])
            .catch(error => {
                this.notifyError(error);
            })
            .finally(() => {
                this.isLoading = false;
            });
    },

    methods: {
        formatCurrency,

        loadListPricingMatrices() {
            this.$cbrDataProvider
                .getList('pricingMatrix')
                .then(response => {
                    this.pricingMatrices = response;

                    if (response.length > 0) {
                        this.pricingMatrices.forEach(item => {
                            const mergedRows = [];
                            let currentRow = null;

                            item.matrix.rows.forEach(row => {
                                const formattedPremium = `${
                                    row.premiumType === 'percent' ? row.premiumValue + '%' : formatCurrency(row.premiumValue)
                                }`;

                                if (currentRow && currentRow.premium === formattedPremium) {
                                    currentRow.month += `, ${row.month}`;
                                } else {
                                    currentRow = {
                                        month: row.month.toString(),
                                        premium: formattedPremium,
                                    };
                                    mergedRows.push(currentRow);
                                }
                            });

                            item.rowsForDisplay = mergedRows;
                        });
                    }
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        loadUnitTypesWithPricingMatrices() {
            Promise.all([
                this.$cbrDataProvider.getList('unitTypes', {
                    limit: 1000,
                }),
                this.$cbrDataProvider.getList('unitTypesWithPricingMatrix'),
            ])
                .then(([listUnitTypesResponse, response]) => {
                    this.allUnitTypesQty = listUnitTypesResponse.items.length;
                    this.unitTypesQty = response.length;
                    this.unitTypesUnassignedQty = response.filter(item => !item.pricingMatrixId).length;
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.isLoading = false;
                });
        },

        formatUnitTypeNames(unitTypes) {
            const MAX_DISPLAY = 3;
            const names = unitTypes.map(unit => unit.name);

            if (names.length <= MAX_DISPLAY) {
                return names.join(', ');
            } else {
                const displayedNames = names.slice(0, MAX_DISPLAY).join(', ');
                return `${displayedNames}...`;
            }
        },
    },
};
</script>

<style scoped>
.container-card {
    display: flex;
    align-items: center;
    flex-direction: column;
    height: 10rem;
    width: 100%;
    margin-bottom: 1.5rem;
    text-align: center;
}

.container-button {
    justify-content: center;
    align-items: center;
    display: flex;
    height: 2.5rem;
    width: 40%;
}

.container-card__text {
    @apply font-frank font-medium text-sm mt-8 mb-5 px-5;
}

.container-button__text {
    @apply text-2sm font-frank font-medium text-white;
}
</style>
