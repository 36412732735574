const loadPointsOfInterest = ({ commit }, payload) => {
    commit('LOAD_POINTS_OF_INTEREST', payload);
};

const loadPointsOfInterestCategories = ({ commit }, payload) => {
    commit('LOAD_POINTS_OF_INTEREST_CATEGORIES', payload);
};

export default {
    loadPointsOfInterest,
    loadPointsOfInterestCategories,
};
