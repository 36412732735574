const loadPages = ({ commit }, payload) => {
    commit('LOAD_PAGES', payload);
};

const createPage = ({ commit }, payload) => {
    commit('CREATE_PAGE', payload);
};

const updatePage = ({ commit }, payload) => {
    commit('UPDATE_PAGE', payload);
};

export default {
    loadPages,
    createPage,
    updatePage,
};
