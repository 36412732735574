import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting/routes_names';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';

const routes = [
    {
        name: ROUTES.AR_CHARGES.name,
        path: ROUTES.AR_CHARGES.path,
        component: () => import('@/views/accounting/accounts_receivable/charges/ChargeList'),
        meta: {
            loadInModal: true,
            actions: true,
            product: 'acctReceivable',
            breadcrumbName: 'charge list',
            title: 'charge list',
            size: 'large',
            flowStartsWith: ROUTES.AR_CHARGES.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST);
            },
        },
    },
    {
        name: ROUTES.AR_RESIDENT_CHARGES.name,
        path: ROUTES.AR_RESIDENT_CHARGES.path,
        component: () => import('@/views/accounting/accounts_receivable/charges/ResidentCharges'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'resident charges',
            title: 'resident charges',
            size: 'large',
            flowStartsWith: ROUTES.AR_RESIDENT_CHARGES.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST);
            },
        },
    },
    {
        name: ROUTES.AR_PLANNED_FUTURE_CHARGES.name,
        path: ROUTES.AR_PLANNED_FUTURE_CHARGES.path,
        component: () => import('@/views/accounting/accounts_receivable/charges/PlannedFutureCharges'),
        meta: {
            loadInModal: true,
            actions: false,
            hideFromSearch: true,
            breadcrumbName: 'planned future charges',
            title: 'planned future charges',
            size: 'large',
            flowStartsWith: ROUTES.AR_PLANNED_FUTURE_CHARGES.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_RECURRING_CHARGES_LIST);
            },
        },
    },
    {
        name: ROUTES.AR_CHARGES_ADD_NEW.name,
        path: ROUTES.AR_CHARGES_ADD_NEW.path,
        component: () => import('@/views/accounting/accounts_receivable/charges/ChargeForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'add new charge',
            title: 'add new charge',
            size: 'small',
            flowStartsWith: ROUTES.AR_CHARGES_ADD_NEW.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
            },
        },
    },
    {
        name: ROUTES.AR_CHARGES_VIEW_DETAILS.name,
        path: ROUTES.AR_CHARGES_VIEW_DETAILS.path,
        component: () => import('@/views/accounting/accounts_receivable/charges/ChargeForm'),
        meta: {
            loadInModal: true,
            actions: true,
            hideFromSearch: true,
            breadcrumbName: 'view charge details',
            title: 'view charge details',
            size: 'small',
            flowStartsWith: ROUTES.AR_CHARGES_VIEW_DETAILS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_TRANSACTION_DETAILS);
            },
            editMode: true,
        },
    },
    {
        name: ROUTES.AR_RESIDENT_ADD_NEW_CONCESSION.name,
        path: ROUTES.AR_RESIDENT_ADD_NEW_CONCESSION.path,
        component: () => import('@/views/accounting/accounts_receivable/credits/ConcessionForm'),
        meta: {
            loadInModal: true,
            breadcrumbName: 'add concession',
            title: 'add concession',
            size: 'large',
            hideFromSearch: true,
            flowStartsWith: ROUTES.AR_RESIDENT_ADD_NEW_CONCESSION.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
            },
        },
    },
    {
        name: ROUTES.AR_RESIDENT_VIEW_DETAILS.name,
        path: ROUTES.AR_RESIDENT_VIEW_DETAILS.path,
        component: () => import('@/views/accounting/accounts_receivable/credits/ConcessionForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view details',
            title: 'view details',
            size: 'large',
            hideFromSearch: true,
            flowStartsWith: ROUTES.AR_RESIDENT_VIEW_DETAILS.name,
            permissionChecker: () => {
                const acctStore = useAccountingStore();
                return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_TRANSACTION_DETAILS);
            },
            editMode: true,
        },
    },
];

export default routes;
