import {
    PERSIST_ASSETS_LIST_VALUES,
    PERSIST_LOCATIONS_TREE_VALUES,
    PERSIST_RESERVATIONS_LIST_VALUES,
    UPDATE_CHECKED_LOCATIONS,
    CLEAR_CHECKED_LOCATIONS,
} from './mutations';

const persistAssetsListValues = ({ commit }, payload) => commit(PERSIST_ASSETS_LIST_VALUES, payload);

const persistLocationsTreeValues = ({ commit }, payload) => commit(PERSIST_LOCATIONS_TREE_VALUES, payload);

const persistReservationsListValues = ({ commit }, payload) => commit(PERSIST_RESERVATIONS_LIST_VALUES, payload);

const setCheckedLocations = ({ commit }, payload) => commit(UPDATE_CHECKED_LOCATIONS, payload);

const clearCheckedLocations = ({ commit }, payload) => commit(CLEAR_CHECKED_LOCATIONS, payload);

export default {
    persistAssetsListValues,
    persistLocationsTreeValues,
    persistReservationsListValues,
    setCheckedLocations,
    clearCheckedLocations,
};
