import { createPermissionChecker, prepareIotRoutes } from '../../helper';

const lockRoutes = [
    {
        name: 'iot.locks.brivo',
        path: '/iot/locks/brivo/:lockId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock details',
            title: 'lock details',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('BRIVO_DEVICES_READ_DETAILS'),
        },
    },
    {
        name: 'iot.locks.brivo.change-location',
        path: '/iot/locks/brivo/:lockId/change-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/ChangeLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'change location',
            title: 'change location',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('BRIVO_DEVICES_CHANGE_LOCATION'),
        },
    },
    {
        name: 'iot.locks.brivo.lock-history',
        path: '/iot/locks/brivo/:lockId/lock-history',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/LockHistory.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock history',
            title: 'lock history',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('BRIVO_DEVICES_READ_DETAILS'),
        },
    },
];

const managementRoutes = [
    {
        name: 'iot.locks.lock-management.brivo',
        path: '/iot/locks/management/brivo',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/management/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'brivo',
            title: 'lock installation',
            flowStartsWith: 'iot.locks.lock-management.brivo',
            permissionChecker: createPermissionChecker('BRIVO_AUTHENTICATION_READ'),
        },
    },
    {
        name: 'iot.locks.lock-management.brivo.select-location',
        path: '/iot/locks/management/brivo/:lockId/select-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/management/SelectLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'select location',
            title: 'select location',
            flowStartsWith: 'iot.locks.lock-management.brivo',
            permissionChecker: createPermissionChecker('BRIVO_DEVICES_CHANGE_LOCATION'),
        },
    },
    {
        name: 'iot.locks.lock-management.brivo.import-sites',
        path: '/iot/locks/management/brivo/import-sites',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/management/ImportSites.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'import sites',
            title: 'import sites',
            flowStartsWith: 'iot.locks.lock-management.brivo.import-sites',
            permissionChecker: createPermissionChecker('BRIVO_SITES_IMPORT'),
        },
    },
    {
        name: 'iot.locks.lock-management.brivo.disconnect',
        path: '/iot/locks/management/brivo/disconnect',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/brivo/management/Disconnect.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'disconnect account',
            title: 'disconnect account',
            flowStartsWith: 'iot.locks.lock-management.brivo.disconnect',
            permissionChecker: createPermissionChecker('BRIVO_SITES_DELETE'),
        },
    },
];

export default prepareIotRoutes([...lockRoutes, ...managementRoutes]);
