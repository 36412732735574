const PUSH_UNSAVED_CHANGES = (state, payload) => {
    state.unsaved_changes[payload.route] = payload.data;
};

const RESET = state => {
    state.unsaved_changes = {};
};

export default {
    PUSH_UNSAVED_CHANGES,
    RESET,
};
