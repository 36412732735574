import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';

const baseURL = getEnvVariable('VUE_APP_API_CALENDAR_URL');

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources);

export default new DataProvider(httpAdapter);
