import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting/routes_names';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';

const actions = [
    {
        routeName: ROUTES.GL_BANK_RECONCILIATIONS.name,
        items: [
            {
                title: 'start reconciliation',
                routeName: ROUTES.GL_BANK_RECONCILIATION_CREATE.name,
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_RECONCILE);
                },
                actionIcon: 'Plus',
                style: 'fill: white; margin-top: 0.1rem;',
            },
        ],
    },

    {
        routeName: ROUTES.GL_BANK_RECONCILIATION_EDIT.name,
        items: [
            {
                title: 'add new entries',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_JOURNAL_ENTRY);
                },
                routeName: ROUTES.GL_JOURNAL_ENTRIES_ADD_NEW_ENTRIES.name,
                actionIcon: 'Plus',
                style: 'fill: white; margin-top: 0.1rem;',
            },
            {
                title: 'add adjustment',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_MANUAL_GL_ADJUSTMENT);
                },
                routeName: ROUTES.GL_BANK_RECONCILIATION_ADD_ADJUSTMENT.name,
            },
        ],
    },
];

export default actions;
