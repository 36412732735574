import store from '@/store';
import { COMMON_AREA, MAINTENANCE_AREA } from '@/views/cbr/constants';
import { CBR } from '@/utils/ppm/permissions';

const createPermissionChecker = permissionId => () => store.getters['cbr/hasPermission'](permissionId);

export default [
    {
        routeName: 'cbr.living.communityStructure',
        items: [
            {
                title: 'new building',
                routeName: 'cbr.living.communityStructure.detail',
                allowed: createPermissionChecker(CBR.LIVING_SPACES_CREATE),
            },
            {
                title: 'new unit',
                routeName: 'cbr.living.units.create',
                allowed: createPermissionChecker(CBR.LIVING_SPACES_CREATE),
            },
            {
                title: 'new common area',
                routeName: 'cbr.living.commonspace.create',
                params: { businessPurpose: COMMON_AREA },
                allowed: createPermissionChecker(CBR.LIVING_SPACES_CREATE),
            },
            {
                title: 'new maintenance area',
                routeName: 'cbr.living.commonspace.create',
                params: { businessPurpose: MAINTENANCE_AREA },
                allowed: createPermissionChecker(CBR.LIVING_SPACES_CREATE),
            },
        ],
    },
    {
        routeName: 'cbr.living.units',
        items: [
            {
                title: 'add new unit',
                routeName: 'cbr.living.units.create',
                params: {
                    buildingId: '{buildingId}',
                },
                allowed: createPermissionChecker(CBR.LIVING_SPACES_CREATE),
            },
        ],
    },
];
