import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.residents.create',
        path: '/iot/residents/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/residents/Create.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'create resident',
            title: 'create resident',
            flowStartsWith: 'iot.residents.create',
            permissionChecker: createPermissionChecker('RESIDENT_CREATE'),
        },
    },
    {
        name: 'iot.residents.details',
        path: '/iot/residents/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/residents/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'resident details',
            title: 'resident details',
            flowStartsWith: 'iot.residents.index',
            permissionChecker: createPermissionChecker('RESIDENT_READ'),
        },
    },
    {
        name: 'iot.residents.details.authorizations',
        path: '/iot/residents/:id/authorizations',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/residents/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'authorizations',
            title: 'resident authorizations',
            flowStartsWith: 'iot.residents.index',
            permissionChecker: createPermissionChecker('RESIDENT_READ'),
        },
    },
    {
        name: 'iot.residents.details.authorizations.add-location',
        path: '/iot/residents/:id/authorizations/add-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/authorizations/residents/AddLocation.vue'),
        meta: {
            breadcrumbName: 'add location',
            title: 'add location',
            flowStartsWith: 'iot.residents.index',
            permissionChecker: createPermissionChecker('RESIDENT_READ'),
        },
    },
    {
        name: 'iot.residents.details.keyring',
        path: '/iot/residents/:id/keyring',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/keyring/ResidentKeyring.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'keyring',
            title: 'residents keyring',
            flowStartsWith: 'iot.residents.index',
            permissionChecker: createPermissionChecker('USER_READ_KEYRING'),
        },
    },
    {
        name: 'iot.residents.details.keyring.management',
        path: '/iot/residents/:id/keyring/management',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/keyring/Management.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'key management',
            title: 'key management',
            flowStartsWith: 'iot.residents.index',
            permissionChecker: createPermissionChecker('USER_READ_KEYRING'),
        },
    },
    {
        name: 'iot.residents.change_email',
        path: '/iot/residents/:id/change-email',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/residents/ChangeEmail.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'change email',
            title: 'change email',
            flowStartsWith: 'iot.residents.index',
            permissionChecker: createPermissionChecker('USER_CHANGE_EMAIL'),
        },
    },
]);
