<template>
    <Form
        :initial-values="initialValues"
        :submit="submitSignIn"
        class="w-full"
    >
        <div class="mb-4">
            <TextField
                name="confirmation_code"
                label="enter 6-digit code from your authenticator app"
                type="text"
                placeholder="enter code"
                :validate="required"
                autocomplete="off"
            />
        </div>
        <div class="mb-4 text-2sm">
            <CheckboxInput
                name="isDeviceRemembered"
                label="trust this device"
                class="mb-4"
            />
        </div>
        <MessageBlock
            v-if="error || confirmError"
            type="warning"
            class="flex mb-4"
        >
            <Icon name="warning" />
            <div class="ml-2">
                {{ error || confirmError }}
            </div>
        </MessageBlock>
        <div>
            <button
                :disabled="isPending"
                type="submit"
                class="btn-primary btn-solid btn-auth"
            >
                confirm
            </button>
        </div>
    </Form>
    <div class="mt-4 text-center">
        <button
            class="btn-link auth-btn-link"
            type="button"
            @click="backToSignIn"
        >
            back to sign in
        </button>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import { useAuthenticator } from '@aws-amplify/ui-vue';
import { confirmSignIn, rememberDevice } from 'aws-amplify/auth';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import CheckboxInput from '@/components/auth/form/CheckboxInput';
import MessageBlock from '@/components/ui/MessageBlock.vue';
import Icon from '@/components/ui/Icon.vue';
import { required } from '@/components/form/validators';

const initialValues = ref({
    isDeviceRemembered: true,
});

const confirmError = ref('');

const facade = useAuthenticator();

const isPending = ref(false);

const error = computed(() => facade.error);

const submitSignIn = async ({ confirmation_code, isDeviceRemembered }) => {
    confirmError.value = '';

    try {
        isPending.value = true;

        await confirmSignIn({ challengeResponse: confirmation_code });

        if (isDeviceRemembered) {
            await rememberDevice();
        }

        isPending.value = false;

        facade.send({ type: 'SIGN_IN_WITH_REDIRECT' });
    } catch (e) {
        isPending.value = false;
        confirmError.value = e.message;
        console.error(e);
    }
};

const backToSignIn = () => facade.toSignIn();
</script>
