import store from '@/store';

const USER_PERMISSIONS = {
    RI: {
        MANAGE_RESERVATIONS: 'RESERVATIONS_EDIT',
        // VIEW_RESIDENT_PROFILE: 'RP_VIEW',
        VIEW_RI: 'RI_VIEW',
        // MARK_ITEM_AS_RETURNED: 'MARK_ASSET_AS_RETURNED',
        MANAGE_RI: 'RI_EDIT',
        VIEW_RESERVATIONS: 'RESERVATIONS_VIEW',
        // VIEW_SETTINGS: 'SETTINGS_VIEW',
        // MANAGE_SETTINGS: 'SETTINGS_EDIT',
    },
    AMNT: {
        CL_VIEW_UNIT_ASSIGNMENTS: 'UL_V',
        CL_UNIT_ASSIGNMENT: 'CL_UA',
        // EL_PRICING_NOTES_MANAGEMENT: 'EL_PNM',
        CL_AMENITY_GROUP_MODIFY: 'CL_AGM',
        // EL_PRICING_NOTES_VIEW: 'EL_PNV',
        CL_MANAGE_UNIT_ASSIGNMENTS: 'UL_M',
        // EL_HISTORY_LOG_VIEW: 'EL_HL',
        CL_AMENITY_VIEW: 'CL_AV',
        EL_AMENITY_GROUP_VIEW: 'EL_AGV',
        CL_AMENITY_MODIFY: 'CL_AM',
        CL_AMENITY_GROUP_VIEW: 'CL_AGV',
        EL_AMENITY_GROUP_MODIFY: 'EL_AGM',
        // CL_PRICING_NOTES_VIEW: 'CL_PNV',
        // CL_HISTORY_LOG_VIEW: 'CL_HL',
        EL_AMENITY_VIEW: 'EL_AV',
        // CL_PRICING_NOTES_MANAGEMENT: 'CL_PNM',
        EL_COMMUNITY_ASSIGNMENT: 'EL_CA',
        EL_AMENITY_MODIFY: 'EL_AM',
    },
    CBR: {
        UNIT_TYPES_DELETE: 'UTD',
        LOCATIONS_UPDATE: 'LU',
        // LOCATIONS_VIEW: 'LV',
        LIVING_SPACES_VIEW: 'LSV',
        LIVING_SPACES_CREATE: 'LSC',
        UNIT_TYPES_VIEW: 'UTV',
        UNIT_STATUS_SETTINGS_UPDATE: 'USSU',
        FLOOR_PLANS_VIEW: 'FPV',
        // UNIT_CHAT_NOTES_EDIT: 'UCNE',
        FLOOR_PLANS_CREATE: 'FPC',
        UNIT_TYPES_UPDATE: 'UTU',
        UNIT_DETAILS_UPDATE_STATUS: 'UDUS',
        // LOCATIONS_DELETE: 'LD',
        FLOOR_PLANS_UPDATE: 'FPU',
        // UNIT_CHAT_NOTES_DELETE: 'UCND',
        UNIT_DETAILS_UPDATE_UNIT_TYPE_ID: 'UDUUT',
        UNIT_DETAILS_UPDATE_PARKING: 'UDUP',
        // LOCATIONS_LIVING_VIEW: 'LLV',
        // UNIT_CHAT_NOTES_CREATE: 'UCNC',
        // LIVING_SPACES_UPDATE: 'LSU',
        FLOOR_PLANS_DELETE: 'FPD',
        // COMMON_SPACES_VIEW: 'CSV',
        UNIT_TYPE_ASSIGNMENT: 'UTA',
        // LOCATIONS_CREATE: 'LC',
        // MAINTENANCE_SPACES_VIEW: 'MSV',
        UNIT_TYPES_CREATE: 'UTC',
        // UNIT_CHAT_NOTES_VIEW: 'UCNV',
        UNIT_STATUS_SETTINGS_VIEW: 'USSV',
    },
    SREQ: {
        // SERVICE_REQUEST_JOB_DECISION: 'SRJD',
        TIME_INOUT_LOG_MANAGEMENT_ON_BEHALF: 'TLMoB',
        SERVICE_REQUEST_VIEW: 'SRV',
        COMMUNITY_SETTINGS_VIEW: 'CSV',
        // SERVICE_REQUEST_CHAT_SIMPLE_MESSAGE_VIEW: 'SRCHSMV',
        SERVICE_REQUEST_CREATE: 'SRC',
        // 'SERVICE_REQUEST_CHAT_PRIVATE_COST-ALLOCATION_NOTE_CREATE': 'SRCHPrCANC',
        // SERVICE_REQUEST_CHAT_STAT_VIEW: 'SRCHSV',
        SERVICE_REQUEST_PRINT: 'SRPr',
        // SERVICE_REQUEST_CHAT_PRIVATE_NOTE_CREATE: 'SRCHPrNC',
        SERVICE_REQUEST_STATUS_UPDATE: 'SRSU',
        COMMUNITY_SETTINGS_MANAGEMENT: 'CSM',
        // 'SERVICE_REQUEST_CHAT_PUBLIC_COST-ALLOCATION_NOTE_VIEW': 'SRCHPCANV',
        // SERVICE_REQUEST_CREATE_ON_BEHALF_OF_RESIDENT: 'SRCoBR',
        SERVICE_REQUEST_CANCEL: 'SRCancel',
        SERVICE_REQUEST_ASSIGNEE_UPDATE: 'SRAU',
        // SERVICE_REQUEST_CHAT_PUBLIC_NOTE_CREATE: 'SRCHPNC',
        TIME_INOUT_LOG_VIEW: 'TLV',
        SERVICE_REQUEST_ATTACHMENTS_UPDATE: 'SRAttU',
        SERVICE_REQUEST_UPDATE: 'SRU',
        // 'SERVICE_REQUEST_CHAT_PUBLIC_COST-ALLOCATION_NOTE_CREATE': 'SRCHPCANC',
        // SERVICE_REQUEST_CHAT_PUBLIC_NOTE_VIEW: 'SRCHPNV',
        // LOCATIONS_LIST: 'LL',
        // SERVICE_REQUEST_CHAT_SIMPLE_MESSAGE_CREATE: 'SRCHSMC',
        TIME_INOUT_LOG_MANAGEMENT: 'TLM',
        // SERVICE_REQUEST_CHAT_PRIVATE_NOTE_VIEW: 'SRCHPrNV',
        // 'SERVICE_REQUEST_CHAT_PRIVATE_COST-ALLOCATION_NOTE_VIEW': 'SRCHPrCANV',
        // SERVICE_REQUEST_PERFORMANCE: 'SRP',
    },
    MSGS: {
        MESSAGES_MANAGEMENT: 'MM',
        MESSAGES_VIEW: 'MV',
    },
};

/**
 * Helpers to get the IDs and names, use only when permissions were updated from the BE to map them easily.
 */
// const USER_PERMISSIONS_RAW = {
//     /* RI */
//     RI: [
//         {
//             id: 'RESERVATIONS_EDIT',
//             name: 'Manage reservations',
//         },
//         {
//             id: 'RP_VIEW',
//             name: 'View resident profile',
//         },
//         {
//             id: 'RI_VIEW',
//             name: 'View RI',
//         },
//         {
//             id: 'MARK_ASSET_AS_RETURNED',
//             name: 'Mark item as returned',
//         },
//         {
//             id: 'RI_EDIT',
//             name: 'Manage RI',
//         },
//         {
//             id: 'RESERVATIONS_VIEW',
//             name: 'View reservations',
//         },
//         {
//             id: 'SETTINGS_VIEW',
//             name: 'View settings',
//         },
//         {
//             id: 'SETTINGS_EDIT',
//             name: 'Manage settings',
//         },
//     ],

//     /* AMNT */
//     AMNT: [
//         {
//             id: 'UL_V',
//             name: 'CL View unit assignments',
//         },
//         {
//             id: 'CL_UA',
//             name: 'CL Unit Assignment',
//         },
//         {
//             id: 'EL_PNM',
//             name: 'EL Pricing Notes Management',
//         },
//         {
//             id: 'CL_AGM',
//             name: 'CL Amenity Group Modify',
//         },
//         {
//             id: 'EL_PNV',
//             name: 'EL Pricing Notes View',
//         },
//         {
//             id: 'UL_M',
//             name: 'CL Manage unit assignments',
//         },
//         {
//             id: 'EL_HL',
//             name: 'EL History Log view',
//         },
//         {
//             id: 'CL_AV',
//             name: 'CL Amenity View',
//         },
//         {
//             id: 'EL_AGV',
//             name: 'EL Amenity Group View',
//         },
//         {
//             id: 'CL_AM',
//             name: 'CL Amenity Modify',
//         },
//         {
//             id: 'CL_AGV',
//             name: 'CL Amenity Group View',
//         },
//         {
//             id: 'EL_AGM',
//             name: 'EL Amenity Group Modify',
//         },
//         {
//             id: 'CL_PNV',
//             name: 'CL Pricing Notes View',
//         },
//         {
//             id: 'CL_HL',
//             name: 'CL History Log view',
//         },
//         {
//             id: 'EL_AV',
//             name: 'EL Amenity View',
//         },
//         {
//             id: 'CL_PNM',
//             name: 'CL Pricing Notes Management',
//         },
//         {
//             id: 'EL_CA',
//             name: 'EL Community Assignment',
//         },
//         {
//             id: 'EL_AM',
//             name: 'EL Amenity Modify',
//         },
//     ],

//     /* CBR */
//     CBR: [
//         {
//             id: 'UTD',
//             name: 'Unit Types Delete',
//         },
//         {
//             id: 'LU',
//             name: 'Locations Update',
//         },
//         {
//             id: 'LV',
//             name: 'Locations View',
//         },
//         {
//             id: 'LSV',
//             name: 'Living Spaces View',
//         },
//         {
//             id: 'LSC',
//             name: 'Living Spaces Create',
//         },
//         {
//             id: 'UTV',
//             name: 'Unit Types View',
//         },
//         {
//             id: 'USSU',
//             name: 'Unit Status Settings Update',
//         },
//         {
//             id: 'FPV',
//             name: 'Floor Plans View',
//         },
//         {
//             id: 'UCNE',
//             name: 'Unit Chat Notes Edit',
//         },
//         {
//             id: 'FPC',
//             name: 'Floor Plans Create',
//         },
//         {
//             id: 'UTU',
//             name: 'Unit Types Update',
//         },
//         {
//             id: 'UDUS',
//             name: 'Unit Details Update Status',
//         },
//         {
//             id: 'LD',
//             name: 'Locations Delete',
//         },
//         {
//             id: 'FPU',
//             name: 'Floor Plans Update',
//         },
//         {
//             id: 'UCND',
//             name: 'Unit Chat Notes Delete',
//         },
//         {
//             id: 'UDUUT',
//             name: 'Unit Details Update Unit Type Id',
//         },
//         {
//             id: 'UDUP',
//             name: 'Unit Details Update Parking',
//         },
//         {
//             id: 'LLV',
//             name: 'Locations Living View',
//         },
//         {
//             id: 'UCNC',
//             name: 'Unit Chat Notes Create',
//         },
//         {
//             id: 'LSU',
//             name: 'Living Spaces Update',
//         },
//         {
//             id: 'FPD',
//             name: 'Floor Plans Delete',
//         },
//         {
//             id: 'CSV',
//             name: 'Common Spaces View',
//         },
//         {
//             id: 'UTA',
//             name: 'Unit Type Assignment',
//         },
//         {
//             id: 'LC',
//             name: 'Locations Create',
//         },
//         {
//             id: 'MSV',
//             name: 'Maintenance Spaces View',
//         },
//         {
//             id: 'UTC',
//             name: 'Unit Types Create',
//         },
//         {
//             id: 'UCNV',
//             name: 'Unit Chat Notes View',
//         },
//         {
//             id: 'USSV',
//             name: 'Unit Status Settings View',
//         },
//     ],

//     /* SREQ */
//     SREQ: [
//         {
//             id: 'SRJD',
//             name: 'Service Request Job Decision',
//         },
//         {
//             id: 'TLMoB',
//             name: 'Time In-Out Log Management On Behalf',
//         },
//         {
//             id: 'SRV',
//             name: 'Service Request View',
//         },
//         {
//             id: 'CSV',
//             name: 'Community Settings View',
//         },
//         {
//             id: 'SRCHSMV',
//             name: 'Service Request Chat Simple Message View',
//         },
//         {
//             id: 'SRC',
//             name: 'Service Request Create',
//         },
//         {
//             id: 'SRCHPrCANC',
//             name: 'Service Request Chat Private Cost-Allocation Note Create',
//         },
//         {
//             id: 'SRCHSV',
//             name: 'Service Request Chat Stat View',
//         },
//         {
//             id: 'SRPr',
//             name: 'Service Request Print',
//         },
//         {
//             id: 'SRCHPrNC',
//             name: 'Service Request Chat Private Note Create',
//         },
//         {
//             id: 'SRSU',
//             name: 'Service Request Status Update',
//         },
//         {
//             id: 'CSM',
//             name: 'Community Settings Management',
//         },
//         {
//             id: 'SRCHPCANV',
//             name: 'Service Request Chat Public Cost-Allocation Note View',
//         },
//         {
//             id: 'SRCoBR',
//             name: 'Service Request Create On behalf of Resident',
//         },
//         {
//             id: 'SRCancel',
//             name: 'Service Request Cancel',
//         },
//         {
//             id: 'SRAU',
//             name: 'Service Request Assignee Update',
//         },
//         {
//             id: 'SRCHPNC',
//             name: 'Service Request Chat Public Note Create',
//         },
//         {
//             id: 'TLV',
//             name: 'Time In-Out Log View',
//         },
//         {
//             id: 'SRAttU',
//             name: 'Service Request Attachments Update',
//         },
//         {
//             id: 'SRU',
//             name: 'Service Request Update',
//         },
//         {
//             id: 'SRCHPCANC',
//             name: 'Service Request Chat Public Cost-Allocation Note Create',
//         },
//         {
//             id: 'SRCHPNV',
//             name: 'Service Request Chat Public Note View',
//         },
//         {
//             id: 'LL',
//             name: 'Locations List',
//         },
//         {
//             id: 'SRCHSMC',
//             name: 'Service Request Chat Simple Message Create',
//         },
//         {
//             id: 'TLM',
//             name: 'Time In-Out Log Management',
//         },
//         {
//             id: 'SRCHPrNV',
//             name: 'Service Request Chat Private Note View',
//         },
//         {
//             id: 'SRCHPrCANV',
//             name: 'Service Request Chat Private Cost-Allocation Note View',
//         },
//         {
//             id: 'SRP',
//             name: 'Service Request Performance',
//         },
//     ],

//     /* MSGS */
//     MSGS: [
//         {
//             id: 'MM',
//             name: 'Messages Management',
//         },
//         {
//             id: 'MV',
//             name: 'Messages View',
//         },
//     ],
// };

// const mappedPermissions = Object.assign(
//     {},
//     ...Object.entries(USER_PERMISSIONS_RAW).map(([key, value]) => ({
//         [key]: Object.assign(
//             {},
//             ...value.map(({ id, name }) => {
//                 const _name = name
//                     .split(' ')
//                     .map(word => word.toUpperCase())
//                     .join('_');

//                 return { [_name]: id };
//             })
//         ),
//     }))
// );

export const { RI, AMNT, CBR, SREQ, MSGS } = USER_PERMISSIONS;

export const hasPpmAccess = () => {
    return (
        store.getters['sreq/hasPermission'](SREQ.SERVICE_REQUEST_VIEW) ||
        store.getters['msgs/hasPermission'](MSGS.MESSAGES_VIEW) ||
        store.getters['cbr/hasPermission'](CBR.LIVING_SPACES_VIEW) ||
        store.getters['ri/hasPermission'](RI.VIEW_RI) ||
        store.getters['amnt/hasPermission'](AMNT.CL_AMENITY_VIEW)
    );
};

export default USER_PERMISSIONS;
