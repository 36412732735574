const routes = [
    {
        name: 'dashboard',
        path: '/dashboard',
        component: () => {
            return import('@/views/report/Index');
        },
        meta: { stickyTopBar: true },
    },
];

export default routes;
