import { SET_TMP_BUFFER } from './mutations';
import { SET_LIST_FILTER_VALUES } from './mutations';

const saveToTmpBuffer = ({ commit }, payload) => commit(SET_TMP_BUFFER, payload);
const resetTmpBuffer = ({ commit }) => commit(SET_TMP_BUFFER, undefined);
const setListFilterValues = ({ commit }, payload) => commit(SET_LIST_FILTER_VALUES, payload);

export default {
    saveToTmpBuffer,
    resetTmpBuffer,
    setListFilterValues,
};
