const actions = [
    {
        routeName: 'widgets.edit',
        items: [
            {
                id: 'cms-delete-widget',
                title: 'Delete Module',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this module?',
                confirmBtnText: 'yes, delete',
                confirmationType: 'warning',
            },
        ],
    },
];

export default actions;
