const hasPermission = (state, getters, rootState, rootGetters) => {
    return rootGetters['auth/isQuextAdmins'] && +process.env.VUE_APP_LOCATION_EDITOR;
};

const spaceTypes = state => state.spaceTypes;
const realProperties = state => state.properties;

const initialized = state => state.initialized;

export default {
    hasPermission,
    initialized,
    spaceTypes,
    realProperties,
};
