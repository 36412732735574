export default [
    {
        path: '/searchEngineDashboard',
        name: 'searchEngineDashboard.index',
        component: () => import(/* webpackChunkName: "SearchEngineDashboard" */ '@/views/search/SearchEngineDashboard'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'dashboard',
            title: 'search engine dashboard',
            flowStartsWith: 'searchEngineDashboard.index',
            product: 'Search Engine',
        },
    },
    {
        path: '/newsearch',
        name: 'search.index',
        component: () => import('@/views/search/Index.vue'),
        meta: {
            title: 'search engine temporary hidden page',
            product: 'Search Engine',
        },
    },
];
