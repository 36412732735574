import { hasAnyCommunitySettingsAccess } from '@/community_settings';

export default [
    {
        path: '/community-settings/:tab?',
        name: 'community.settings.index',
        component: () => import(/* webpackChunkName: "communitySettings" */ '@/views/community_settings/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'community settings',
            title: 'community settings',
            flowStartsWith: 'community.settings.index',
            product: 'community.settings',
            permissionChecker: hasAnyCommunitySettingsAccess,
        },
    },
    // Add / edit pricing matrix
    {
        path: '/community-settings/pricing-matrix/:id?',
        name: 'community.settings.pricingMatrix.details',
        component: () =>
            import(/* webpackChunkName: "communitySettings" */ '@/views/community_settings/pricing_matrix/PricingMatrixDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'pricing matrix',
            title: 'pricing matrix',
            flowStartsWith: 'community.settings.index',
            product: 'community.settings',
            permissionChecker: () => true, // TODO: PPM: Check permission
        },
    },
    {
        path: '/community-settings/notifications/audience/:id',
        name: 'community.settings.notifications.audience',
        component: () => import(/* webpackChunkName: "communitySettings" */ '@/views/community_settings/notifications/audience/Index'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'edit audience',
            title: 'edit audience',
            flowStartsWith: 'community.settings.index',
            product: 'community.settings',
            permissionChecker: () => true, // TODO: PPM: Check permission
        },
    },
];
