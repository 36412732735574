import { defineStore } from 'pinia';

export const useAccountsReceivableStore = defineStore('accountsReceivable', {
    state: () => ({
        chargeResidentData: {},
        residentDepositData: {},
        payersByAccountIdMap: {},
        payerByPartyIdMap: {},
        chargeCodeNames: [],
        occupancyData: [],
        accountIdForDeposit: '',
        remainingSecDepAmount: '',
        chargeAmount: '',
        paidChargeAmount: '',
        securityDeposit: false,
    }),

    getters: {
        accountId: state => state.chargeResidentData.occupancy_id,
        unitName: state => state.chargeResidentData.c_unit_name,
        getPartyData: state => state.chargeResidentData.party,
        getChargeCodeNames: state => {
            if (state.chargeCodeNames.length > 0) {
                return state.chargeCodeNames.map(({ value }) => value);
            }
            return state.chargeCodeNames;
        },
        isSecurityDeposit: state => state.securityDeposit,
    },

    actions: {
        setResidentData(payload) {
            this.chargeResidentData = payload;
        },
        setChargeCodeNames(payload) {
            this.chargeCodeNames = payload;
        },
        setResidentDepositData(payload) {
            this.residentDepositData = payload;
        },
        setPayersByAccountIdMap(payload) {
            this.payersByAccountIdMap = payload;
        },
        setPayerByPartyIdMap(payload) {
            this.payerByPartyIdMap = payload;
        },
        setAccountIdForDeposit(payload) {
            this.accountIdForDeposit = payload;
        },
        setOccupancyData(payload) {
            this.occupancyData = payload;
        },
        setRemainingSecDepAmountAmount(payload) {
            this.remainingSecDepAmount = payload;
        },
        setChargeAmount(payload) {
            this.chargeAmount = payload;
        },
        setPaidChargeAmount(payload) {
            this.paidChargeAmount = payload;
        },
        setSecurityDeposit(payload) {
            this.securityDeposit = payload;
        },
    },
});
