export const SET_DOCUMENT_INSTANCE = 'SET_DOCUMENT_INSTANCE';
export const SET_LEASES = 'SET_LEASES';
export const SET_LEASE = 'SET_LEASE';
export const SET_E_SIGNATURE = 'SET_E_SIGNATURE';
export const SET_BLUE_MOON_CREDENTIALS = 'SET_BLUE_MOON_CREDENTIALS';
export const SET_CLEAR_LEASE_STATE = 'SET_CLEAR_LEASE_STATE';
export const SET_IS_KEY_UPDATED = 'SET_IS_KEY_UPDATED';
export const SET_LEASE_CREATION_EXECUTED = 'SET_LEASE_CREATION_EXECUTED';
export const SET_LEASE_SIGNATURE_EXECUTED = 'SET_LEASE_SIGNATURE_EXECUTED';
export const SET_LEASE_DOWNLOAD_EXECUTED = 'SET_LEASE_DOWNLOAD_EXECUTED';
export const SET_CREDITS_TO_EDIT = 'SET_CREDITS_TO_EDIT';

export default {
    [SET_DOCUMENT_INSTANCE]: (state, payload) => {
        state.document_instance = payload;
    },
    [SET_LEASES]: (state, payload) => {
        state.leases = payload;
    },
    [SET_LEASE]: (state, payload) => {
        state.lease = payload;
    },
    [SET_E_SIGNATURE]: (state, payload) => {
        state.e_signature = payload;
    },
    [SET_BLUE_MOON_CREDENTIALS]: (state, payload) => {
        state.blue_moon_credentials = payload;
    },
    [SET_CLEAR_LEASE_STATE]: state => {
        state.lease = {};
        state.document_instance = {};
        state.e_signature = {};
        state.isLeaseCreationExecuted = false;
        state.isLeaseSignatureExecuted = false;
        state.isLeaseDownloadExecuted = false;
    },
    [SET_IS_KEY_UPDATED]: (state, payload) => {
        state.isKeyUpdated = payload;
    },
    [SET_LEASE_CREATION_EXECUTED]: (state, payload) => {
        state.isLeaseCreationExecuted = payload;
    },
    [SET_LEASE_SIGNATURE_EXECUTED]: (state, payload) => {
        state.isLeaseSignatureExecuted = payload;
    },
    [SET_LEASE_DOWNLOAD_EXECUTED]: (state, payload) => {
        state.isLeaseDownloadExecuted = payload;
    },
    [SET_CREDITS_TO_EDIT]: (state, payload) => {
        state.credits = payload;
    },
};
