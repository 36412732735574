<template>
  <div
    class="collapsible"
    :class="[
      finalCss.collapsibleClass,
      expanded ? finalCss.expandedClass : '',
      {
        'collapsible--expanded': expanded,
        'hover:bg-active-100': isCollapsible
      },
    ]"
  >
    <div
      class="collapsible__heading"
      :class="[
        finalCss.headingClass,
        expanded ? finalCss.headingExpandedClass : '',
        { 'cursor-pointer': isCollapsible }
      ]"
      @click="handleClick"
    >
      <!-- @slot Heading block -->
      <slot name="heading" />
      <Icon
        v-if="isCollapsible"
        :name="iconName"
        class="collapsible__arrow"
        :class="finalCss.arrowClass"
        @click.stop="handleClick"
      />
    </div>
    <transition name="slide-down">
        <div v-if="isCollapsible && expanded">
            <div
                class="collapsible__content"
                :class="finalCss.contentClass"
            >
                <!-- @slot Content block -->
                <slot name="content" />
            </div>
        </div>
    </transition>
  </div>
</template>

<script>
import Icon from "@/components/ui/Icon";

const css = {
  collapsibleClass: 'w-full border border-transparent',
  expandedClass: 'bg-active-50',
  headingClass: 'p-4',
  headingExpandedClass: '',
  contentClass: 'px-4 pb-4',
  arrowClass: 'w-4 min-w-4 h-4 text-gray-300',
};

export default {
  name: 'Collapsible',

  components: {
      Icon,
  },

  props: {
    /**
     * Initial value for the `expanded` data prop
     */
    initialExpanded: {
      type: Boolean,
      default: false,
    },
    /**
     * Custom CSS styles
     */
    css: {
      type: Object,
      default() {
        return css;
      },
    },
  
    isCollapsible: {
      type: Boolean,
      default: true,
    },
    /**
     * Custom icon for the collapsed state
     */
     collapsedIcon: {
      type: String,
      default: 'chevronDown',
    },
    /**
     * Custom icon for the expanded state
     */
    expandedIcon: {
      type: String,
      default: 'chevronUp',
    },
  },

  emits: ['click'],

  methods: {
    handleClick() {
      if (!this.isCollapsible) {
        return;
      }
  
      this.expanded = !this.expanded;
      /**
       * Emitted when the heading panel was clicked
       */
      this.$emit('click');
    },
  },

  data(){
    return {
      expanded: false,
    };
  },

  watch: {
    initialExpanded: {
      handler: function() {
        this.expanded = this.initialExpanded;
      },
      immediate: true,
    },
  
    isCollapsible(value) {
      if (!value) {
        this.expanded = false;
      }
    },
  },

  computed: {
    finalCss() {
      return { ...css, ...this.css };
    },

    iconName() {
      return this.expanded ? this.expandedIcon : this.collapsedIcon;
    }
  }
}
</script>

<style scoped>
  .collapsible--expanded {
    @apply border-active-200;
  }

  .collapsible__heading {
    @apply flex items-center;
  }

.slide-down {
    &-enter-active {
        transition-duration: 0.3s;
        transition-timing-function: ease-in;
    }
    &-leave-active {
        transition-duration: 0.3s;
        transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
    }
    &-enter-to,
    &-leave-from {
        overflow-y: hidden;
        max-height: 100vh;
    }
    &-enter-from,
    &-leave-to {
        overflow: hidden;
        max-height: 0;
    }
}
</style>
