const routes = [
    {
        name: 'translate_responses.index',
        path: '/translate-responses',
        component: () => {
            return import('@/views/digital_human/translate_responses/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'translation tools',
            title: 'translation tools',
            size: 'normal',
            flowStartsWith: 'translate_responses.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
];

export default routes;
