import LEASING_PERMISSIONS from '@/utils/constants/leasing_permissions_constants';
import { useLeasingStore } from '@/stores/leasing';

const routes = [
    {
        name: 'renewals.index',
        path: '/renewals',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/Index');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'renewal offers',
            title: 'renewal offers',
            size: 'normal',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.editRenewalOffer',
        path: '/edit-renewal-offer/:occupancy_id/:renewal_status/:renewal_id?',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditRenewalOffer');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit renewal offer',
            title: 'edit renewal offer',
            size: 'normal',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.renewalDetails',
        path: '/renewal-details/:occupancy_id/:renewal_id',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/RenewalDetails');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'renew lease details',
            title: 'renew lease details',
            size: 'normal',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.editOfferLetter',
        path: '/edit-offer-letter/:occupancy_id/:renewal_id/:renewal_status',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit offer letter',
            title: 'edit offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.editCustomOfferLetter',
        path: '/edit-custom-offer-letter/:occupancy_id/:renewal_id/:renewal_status',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/EditCustomOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit custom offer letter',
            title: 'edit custom offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.previewOfferLetter',
        path: '/preview-offer-letter/:occupancy_id/:occupancy_phase_index',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/PreviewOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'preview offer letter',
            title: 'Preview offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.previewTemplateOfferLetter',
        path: '/preview-offer-letter',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/PreviewOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'preview offer letter',
            title: 'Preview offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.simplePreviewTemplateOfferLetter',
        path: '/simple-preview-offer-letter',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/SimplePreviewOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'preview offer letter',
            title: 'Preview offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.simplePreviewTemplateOfferLetter',
        path: '/simple-preview-offer-letter',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/SimplePreviewOfferLetter');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'preview offer letter',
            title: 'Preview offer letter',
            size: 'small',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
    {
        name: 'renewals.chat',
        path: '/renewal-chat/:occupancy_id',
        component: () => {
            return import('@/views/resident_lifecycle/renewals/Chat');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'renewal offer chat',
            title: 'renewal offer chat',
            size: 'small',
            flowStartsWith: 'renewals.index',
            product: 'move-in',
            permissionChecker: () => {
                const leasingStore = useLeasingStore();
                return leasingStore.hasPermission(LEASING_PERMISSIONS.LEASE_OFFER_RENEWAL);
            },
        },
    },
];

export default routes;
