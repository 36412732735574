import store from '@/store';
import { CBR } from '@/utils/ppm/permissions';

const createPermissionChecker = permissionId => () => store.getters['cbr/hasPermission'](permissionId);

export default [
    {
        routeName: 'cbr.floorplans',
        items: [
            {
                title: 'add new floor plan',
                routeName: 'cbr.floorplans.create',
                params: {},
                allowed: createPermissionChecker(CBR.FLOOR_PLANS_CREATE),
            },
        ],
    },
    {
        routeName: 'cbr.floorplans.details',
        items: [],
    },
];
