<template>
    <FinalForm
        ref="form"
        class="community-notifications"
        :initial-values="initialValues"
        :submit="handleSubmit"
    >
        <template #default="props">
            <form @submit="props.handleSubmit">
                <section
                    v-for="(value, id, index) in initialValues.notificationsState"
                    :key="id"
                >
                    <h1 class="font-frank text-sm font-bold mb-4">
                        {{ value.name }}
                    </h1>
                    <List
                        :columns="COLUMNS"
                        :items="value.notifications"
                    >
                        <template #column:emailEnabled="{ index: _index }">
                            <ToggleField
                                :name="`notificationsState[${id}].notifications[${_index}].emailEnabled`"
                                size="small"
                            />
                        </template>
                        <template #column:mobileEnabled="{ index: _index }">
                            <ToggleField
                                :name="`notificationsState[${id}].notifications[${_index}].mobileEnabled`"
                                size="small"
                                disabled
                            />
                        </template>
                        <template #column:inAppEnabled="{ index: _index }">
                            <ToggleField
                                :name="`notificationsState[${id}].notifications[${_index}].inAppEnabled`"
                                size="small"
                                disabled
                            />
                        </template>
                    </List>
                    <div class="mt-4 px-4 w-full flex justify-end">
                        <button
                            type="button"
                            class="flex items-center btn-primary btn-transparent"
                            @click="() => handleEditAudienceClick(id, value.name)"
                        >
                            <Icon
                                name="edit"
                                class="w-4 h-4 text-active-500 mr-2"
                            />
                            <div>edit audience</div>
                        </button>
                    </div>
                    <hr
                        v-if="index < Object.keys(initialValues.notificationsState).length - 1"
                        class="my-8"
                    >
                </section>
                <ModalFooter
                    :footer="{
                        primaryButton: 'save',
                        tertiaryButton: 'cancel',
                    }"
                    :tertiary="close"
                />
            </form>
        </template>
    </FinalForm>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import { mapState, mapActions } from 'pinia';
import ModalNavigation from '@/mixins/ModalNavigation';
import ConfirmationMixin from '@/mixins/ConfirmationMixin';
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import ModalFooter from '@/components/ui/modals/ModalFooter.vue';
import Icon from '@/components/ui/Icon';
import List from '@/components/list/List.vue';
import ToggleField from '@/components/form/ToggleField';
import { useAlertStore } from '@/stores/alert';
import { useCommunitySettingsStore } from '@/stores/community_settings';

const COLUMNS = [
    {
        name: 'label',
        title: 'event',
        class: 'community-notifications__column-description',
    },
    {
        name: 'emailEnabled',
        title: 'email',
        class: 'community-notifications__column-toggle',
    },
    {
        name: 'mobileEnabled',
        title: 'mobile',
        class: 'community-notifications__column-toggle',
    },
    {
        name: 'inAppEnabled',
        title: 'in-app',
        class: 'community-notifications__column-toggle',
    },
];

export default {
    components: {
        FinalForm,
        List,
        ModalFooter,
        ToggleField,
        Icon,
    },

    mixins: [InitializeFormMixin, ModalNavigation, ConfirmationMixin],

    setup() {
        const { notifySuccess, notifyError } = useAlertStore();

        return { notifySuccess, notifyError };
    },

    data() {
        return {
            initialValues: {
                notificationsState: {},
            },

            COLUMNS,
        };
    },

    computed: {
        ...mapState(useCommunitySettingsStore, ['notificationsState']),
    },

    async mounted() {
        this.fetchNotifications().then(() => {
            // Load saved state when coming back from audience.
            // Route check is done in parent and clears state when coming from other screen.
            if (Object.keys(this.notificationsState).length) {
                this.$refs.form?.finalForm?.change('notificationsState', this.notificationsState);
            }
        });
    },

    methods: {
        ...mapActions(useCommunitySettingsStore, ['setNotificationsState']),

        async beforeRouteLeave() {
            // Called from parent using ref.
            const isDirty = this.$refs.form.formState.dirty;

            if (!isDirty) {
                return true;
            }

            return this.requestConfirmation({
                confirmationType: 'warning',
                confirmationMessage: 'Notification Settings changes you made may not be saved',
                confirmBtnText: 'leave anyway',
                cancelBtnText: 'go back',
            });
        },

        async fetchNotifications() {
            try {
                const data = await this.$encsDataProvider.getList('notificationsConfig');

                this.initialValues = {
                    notificationsState: Object.assign(
                        {},
                        ...data.map(({ category, configs }) => ({
                            [category.id]: {
                                name: category.name,
                                notifications: configs,
                            },
                        }))
                    ),
                };
            } catch (error) {
                this.notifyError(error);
            }
        },

        handleEditAudienceClick(categoryId, audienceName) {
            const state = this.$refs.form.formState.values.notificationsState;

            this.setNotificationsState(state);

            this.$router.push({
                name: 'community.settings.notifications.audience',
                params: {
                    id: categoryId,
                },
                query: {
                    audienceName,
                },
            });
        },

        async handleSubmit(values) {
            if (!this.$refs.form.formState.dirty) {
                this.close();

                return;
            }

            const confirmationMessage = 'Notification Settings changes will be saved.';

            const isSaveConfirmed = await this.requestConfirmation({
                confirmationType: 'success',
                confirmationMessage,
                confirmBtnText: 'yes, save',
                cancelBtnText: 'no, go back',
            });

            if (!isSaveConfirmed) {
                return;
            }

            try {
                const data = Object.values(values.notificationsState).flatMap(({ notifications }) => notifications);

                await this.$encsDataProvider.update('notificationsConfig', {
                    data,
                });
            } catch (error) {
                this.notifyError(error);
            }
        },
    },
};
</script>

<style scoped>
.community-notifications {
    width: fit-content;

    & :deep(&__column-description) {
        @apply flex-grow flex-shrink-0;

        min-width: 200px;
    }

    & :deep(&__column-toggle) {
        @apply flex-shrink-0 text-center;

        min-width: 120px;
        width: 120px;
    }

    & :deep(.row) {
        width: fit-content;
    }
}
</style>
