<template>
    <div>
        <ContextSelect
            ref="profileSelector"
            v-model="profileContext"
            :options="options"
            track-by="profileId"
            label="customerName"
            placeholder="select profile"
            no-clear
        />
    </div>
</template>

<script>
import { sortBy } from 'lodash-es';
import { mapActions, mapGetters } from 'vuex';
import { useAlertStore } from '@/stores/alert';
import ContextSelect from '@/components/auth/context_select/ContextSelect';
import EventBus from '@/utils/EventBus';
import { REFRESH_PERMISSIONS_EVENT } from '@/components/permission_service/constants';

export default {
    name: 'ProfileContextSelect',
    components: {
        ContextSelect,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const { notifyError } = useAlertStore();
        return { notifyError };
    },

    data() {
        return {
            options: [],
            profileContext: null,
        };
    },

    computed: {
        ...mapGetters({
            profile: 'auth/profile',
        }),
    },

    watch: {
        profileContext(value) {
            this.setProfile(value);
            this.$refs.profileSelector.search = null;

            EventBus.emit(REFRESH_PERMISSIONS_EVENT);
        },
    },

    mounted() {
        this.fetchProfiles();
    },

    methods: {
        ...mapActions({
            setProfile: 'auth/setProfile',
        }),

        fetchProfiles() {
            return this.$authDataProvider
                .get('profile', { nonDeleted: true })
                .then(response => {
                    const customerUsers =
                        response.customerUsers?.map(({ firstName, lastName, customerName, ...item }) => ({
                            ...item,
                            customerName,
                            firstName,
                            lastName,
                            fullName: `${firstName} ${lastName} (${customerName ? customerName : 'super admin'})`,
                        })) || [];
                    this.options = sortBy(customerUsers, ['customerName']);
                    this.profileContext = customerUsers.find(({ profileId }) => this.profile?.profileId === profileId);
                })
                .catch(e => {
                    console.error(e);
                    this.notifyError('Failed to fetch data for profile context selector');
                });
        },
    },
};
</script>
