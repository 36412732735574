export default {
    photosLibrary: 'file/community/{communityId}',
    communitySettings: 'setting/community/{communityId}',
    allVariablesSettings: 'setting/community/{communityId}/variable',
    allFeesSettings: 'setting/community/{communityId}/fee',
    communitySetting: 'setting/community',
    photo: 'file',
    photoD: 'file/{url}',
    communityConversationById: 'conversation/community/{communityId}',
    uploadAvailability: 'unit/availability',
    validateAvailability: '/unit/availability/validate',
    allUnits: 'unit',
    unit: 'unit',
    editUnit: 'unit/{id}',
    unitAvailabilityTemplate: 'unit/availability/template',
    syncUnits: 'unit/availability/sync',
    communityByCustomer: 'communityByCustomer/{idCommunity}/{idCustomer}',
    getHoursOfOperations: 'setting/community/hours-of-operation',
    createCustomerCategory: 'customer-category',
    updateTopicCheckbox: {
        update: ['PUT', '/community-topic-positive-response-indicator/{community_topic_content_id}'],
    },
    category: {
        fetch: ['GET', '/community/{community_id}/category-type/{category_type}/categories-and-topics'],
        delete: ['DELETE', '/customer-category/{customer_category_id}'],
        update: ['PUT', '/customer-category/{customer_category_id}'],
    },
    topic: {
        create: ['POST', '/customer-topic-and-community-topic-content'],
        delete: ['DELETE', '/customer-topic-and-all-community-content/{customer_topic_id}'],
        update: ['PUT', '/community-topic-content/{community_topic_content_id}'],
    },
};
