import { createPermissionChecker, prepareIotRoutes } from './helper';
export default prepareIotRoutes([
    {
        name: 'iot.integration_queue.index',
        path: '/iot/integration-queue',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/integration_queue/Index'),
        meta: {
            actions: true,
            breadcrumbName: 'integration queue',
            title: 'integration queue',
            flowStartsWith: 'iot.integration_queue.index',
            nonStacked: true,
            permissionChecker: createPermissionChecker('EXT_COMMUNITY_EVENT_READ'),
        },
    },
    {
        name: 'iot.integration_queue.details',
        path: '/iot/integration-queue/event-details/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/integration_queue/Details'),
        meta: {
            actions: true,
            breadcrumbName: 'event details',
            title: 'event details',
            flowStartsWith: 'iot.integration_queue.index',
            permissionChecker: createPermissionChecker('EXT_COMMUNITY_EVENT_READ'),
        },
    },
    {
        name: 'iot.integration_queue.auto_apply_settings',
        path: '/iot/integration-queue/auto-apply-settings',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/integration_queue/AutoApplySettings.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'auto-apply settings',
            title: 'auto-apply settings',
            flowStartsWith: 'iot.integration_queue.index',
            permissionChecker: createPermissionChecker('EXT_COMMUNITY_EVENT_READ_CONFIG'),
        },
    },
]);
