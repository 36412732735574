export default vm => {
    return vm.profile.customerId && vm.community?.id
        ? vm.$leasingDataProvider
            .get('permissions', {
                headers: {
                    customerId: vm.profile.customerId,
                    communityId: vm.community.id,
                },
            })
            .then(({ data }) => (data.permissions[0]?.permissions || []).map(permission => permission.name))
            .catch(() => [])
        : Promise.resolve([]);
};
