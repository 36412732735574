import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.schedules.create',
        path: '/iot/automation/schedules/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/schedules/Create.vue'),
        meta: {
            breadcrumbName: 'add new',
            title: 'new schedule',
            flowStartsWith: 'iot.schedules.index',
            permissionChecker: createPermissionChecker('LOCK_CREDENTIALS_SCHEDULE_CREATE'),
        },
    },
    {
        name: 'iot.schedules.edit',
        path: '/iot/automation/schedules/edit/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/automation/schedules/Edit.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'edit',
            flowStartsWith: 'iot.schedules.index',
            permissionChecker: createPermissionChecker('LOCK_CREDENTIALS_SCHEDULE_UPDATE'),
        },
    },
]);
