export const PERSIST_ASSETS_LIST_VALUES = 'PERSIST_ASSETS_LIST_VALUES';
export const PERSIST_LOCATIONS_TREE_VALUES = 'PERSIST_LOCATIONS_TREE_VALUES';
export const PERSIST_RESERVATIONS_LIST_VALUES = 'PERSIST_RESERVATIONS_LIST_VALUES';
export const UPDATE_CHECKED_LOCATIONS = 'UPDATE_CHECKED_LOCATIONS';
export const CLEAR_CHECKED_LOCATIONS = 'CLEAR_CHECKED_LOCATIONS';

export default {
    [PERSIST_ASSETS_LIST_VALUES]: (state, payload) => {
        state.persistedValues.assetsList = payload;
    },
    [PERSIST_LOCATIONS_TREE_VALUES]: (state, payload) => {
        state.persistedValues.locationsTree.formValues = payload;
    },
    [PERSIST_RESERVATIONS_LIST_VALUES]: (state, payload) => {
        state.persistedValues.reservationsList = payload;
    },
    [UPDATE_CHECKED_LOCATIONS]: (state, payload) => {
        state.persistedValues.locationsTree.checkedLocations = payload;
    },
    [CLEAR_CHECKED_LOCATIONS]: state => {
        state.persistedValues.locationsTree.checkedLocations = [];
    },
};
