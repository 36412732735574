export const COMMON_AREA = 'commonAreas';
export const MAINTENANCE_AREA = 'maintenanceAreas';
export const COMMON_AREA_NAME = 'COMMON_AREA';
export const MAINTENANCE_AREA_NAME = 'STAFF_ONLY';

const BUILDING_TYPE = 'BUILDING';
const MAINTENANCE_AREA_TYPE = 'MAINTENANCE_AREA';
const COMMON_AREA_TYPE = 'COMMON_AREA';
const APARTMENT_TYPE = 'APARTMENT';

export const buildingsTypes = {
    [BUILDING_TYPE]: BUILDING_TYPE,
    [MAINTENANCE_AREA_TYPE]: MAINTENANCE_AREA_TYPE,
    [COMMON_AREA_TYPE]: COMMON_AREA_TYPE,
    [APARTMENT_TYPE]: APARTMENT_TYPE,
};
