import { defineStore } from 'pinia';
import { useStorage } from '@vueuse/core';

export const useFullPageStore = defineStore('fullPage', () => {
    const lastFullPageRouteName = useStorage('fullPage', 'home');

    function setLastFullPageRouteName(routeName) {
        lastFullPageRouteName.value = routeName;
    }

    return { lastFullPageRouteName, setLastFullPageRouteName };
});
