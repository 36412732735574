import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import noAuthHttpClient from '@/api/noAuthHttpClient';
import getEnvVariable from '@/utils/getEnvVariable';

const baseURL = getEnvVariable('VUE_APP_EMAILBOT_API_URL');

const beforeRequestInterceptor = ({ requestConfig }) => ({
    ...requestConfig,
    headers: {
        ...requestConfig.headers,
    },
});

const httpAdapter = new HttpAdapter(noAuthHttpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider({
    handle: (action, resource, params) => {
        return httpAdapter.handle(action, resource, params);
    },
});
