const routes = [
    {
        name: 'setup-wizard.index',
        path: '/setup-wizard',
        component: () => {
            return import('@/views/digital_human/setup_wizard/Index');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'setup wizard',
            title: 'setup a community',
            flowStartsWith: 'setup-wizard.index',
            product: 'dh',
            requiresCustomer: true,
            requiresCommunity: true,
            hideFromSearch: true,
        },
    },
    {
        name: 'setup-wizard.setup',
        path: '/setup-wizard/setup',
        component: () => {
            return import('@/views/digital_human/setup_wizard/Setup');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'setup',
            title: 'setup',
            flowStartsWith: 'setup-wizard.setup',
            product: 'dh',
            requiresCustomer: true,
            requiresCommunity: true,
            hideFromSearch: true,
        },
    },
];

export default routes;
