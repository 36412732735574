export const INIT_ATTACHMENTS = 'INIT_ATTACHMENTS';
export const ADD_ATTACHMENT = 'ADD_ATTACHMENT';
export const UPDATE_ATTACHMENT = 'UPDATE_ATTACHMENT';
export const REMOVE_ATTACHMENT = 'REMOVE_ATTACHMENT';
export const CLEAR_ATTACHMENTS = 'CLEAR_ATTACHMENTS';
export const SET_ATTACHMENT_PARAMS = 'SET_ATTACHMENT_PARAMS';

export default {
    [INIT_ATTACHMENTS]: (state, items) => {
        state.attachments = items;
    },

    [ADD_ATTACHMENT]: (state, attach) => {
        state.attachments.push(attach);
    },

    [UPDATE_ATTACHMENT]: (state, payload) => {
        const index = state.attachments.findIndex(attach => attach.id === payload.id);

        if (index >= 0) {
            state.attachments[index] = { ...state.attachments[index], ...payload.values };
        } else {
            state.attachments.push(payload.values);
        }
    },

    [REMOVE_ATTACHMENT]: (state, attachId) => {
        const index = state.attachments.findIndex(a => a.id === attachId);
        const attach = state.attachments[index];

        if (index < 0) {
            return;
        }

        if (attach.previewUrl && attach.previewUrl.startsWith('blob:')) {
            URL.revokeObjectURL(attach.previewUrl);
        }

        state.attachments.splice(index, 1);
    },

    [CLEAR_ATTACHMENTS]: state => {
        state.attachments.forEach(attach => {
            if (attach.previewUrl && attach.previewUrl.startsWith('blob:')) {
                URL.revokeObjectURL(attach.previewUrl);
            }
        });

        state.attachments = [];
        state.params = {
            maxItems: 0,
            ownerRoute: null,
            ownerService: null,
            urlFetcher: null,
        };
    },

    [SET_ATTACHMENT_PARAMS]: (state, params) => {
        state.params = params;
    },
};
