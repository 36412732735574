import { round } from '@/utils/Numbers';

/**
 * Apply mask to the given amount
 * @param {string | number} value
 * @param {Object} props
 * @returns {string} Masked amount
 */
export const mask = function (value = 0, props = {}) {
    const { precision = 2, prefix = '', suffix = '', decimal = '.', delimeter = ',' } = props;
    const negative = String(value).indexOf('-') === -1 ? '' : '-';
    const absValue = Math.abs(value);
    const floatValue = absValue.toFixed(precision);
    const [integerPart, decimalPart] = splitIntegerAndDecimal(floatValue);
    const delimetedIntegerPart = getDelimetedValue(integerPart, delimeter);

    return `${negative}${prefix}${joinIntegerAndDecimal(delimetedIntegerPart, decimalPart, decimal)}${suffix}`;
};

/**
 * Unmask the given value
 * @param {string} maskedValue
 * @param {number} precision
 * @returns {number} Unmasked value
 */
export const unmask = function (maskedValue, precision = getPrecision(maskedValue)) {
    if (typeof maskedValue === 'number') {
        return maskedValue;
    }

    const negative = getNegative(maskedValue);
    const digits = getOnlyDigits(maskedValue) || 0;
    const currency = convertToCurrency(digits, precision);

    return negative * currency;
};

export const splitIntegerAndDecimal = function (floatValue) {
    return String(floatValue).split('.');
};

/**
 * Return precision for the provided float value
 * @param {string | number} floatValue
 * @returns {number} Precision value
 */
export const getPrecision = function (floatValue) {
    return (splitIntegerAndDecimal(floatValue)?.[1] || []).length;
};

/**
 * Convert digits string to the value according to the given precision
 * @param {string} digits
 * @param {number} precision
 * @returns {number} Precised amount
 */
export const convertToCurrency = function (digits, precision) {
    const divider = Math.pow(10, precision);
    const result = parseFloat(digits) / divider;

    return round(result, precision);
};

export const getOnlyDigits = function (maskedValue) {
    return maskedValue.replace(/\D+/g, '');
};

export const joinIntegerAndDecimal = function (integer, decimal, delimeter) {
    return decimal ? integer + delimeter + decimal : integer;
};

/**
 * Format integer with delimeted thousands
 * @param {string} integer
 * @param {string} delimeter
 * @returns {string} Delimeted integer
 */
export const getDelimetedValue = function (integer, delimeter) {
    return integer.replace(/(\d)(?=(?:(\d{3}))+\b)/gm, `$1${delimeter}`);
};

export const getNegative = function (maskedValue) {
    return maskedValue.indexOf('-') === -1 ? 1 : -1;
};

/**
 * Format string containg unmasked/partially masked prices to contain masked copies
 * @param {string} query
 * @returns {string} Query string with masked prices
 */
export const maskQuery = function (query) {
    const maskedPrices = query
        .split(' ')
        .filter(queryUnit => queryUnit && getOnlyDigits(queryUnit))
        .reduce((acc, numberQuery) => {
            const queryPrecision = getPrecision(numberQuery);
            const rawNumberQuery = unmask(numberQuery, queryPrecision);
            const maskedNumberQuery = mask(rawNumberQuery, { prefix: '$' });
            const precisionToRemove = getPrecision(maskedNumberQuery) - queryPrecision;
            const slicedMaskedQuery = precisionToRemove ? maskedNumberQuery.slice(0, -precisionToRemove) : maskedNumberQuery;

            return `${acc} ${slicedMaskedQuery}`;
        }, '');

    return `${query}${maskedPrices}`;
};

export const parseAmountFormat = value => (value ? parseFloat(value.replace('$', '')?.replaceAll(',', '')) : null);
