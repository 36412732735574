<template>
    <div class="auth-wrapper">
        <div class="auth-card">
            <icon
                name="logo"
                class="mb-6 h-12"
            />
            <b class="auth-heading">
                <!-- @slot Heading of the auth card -->
                <slot name="heading" />
            </b>
            <!-- @slot Content inside the auth card -->
            <slot name="default" />
        </div>
        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';
import Loader from '@/components/ui/Loader';

export default {
    name: 'AuthCard',
    components: {
        Icon,
        Loader,
    },

    props: {
        /**
         * Show loading spinner with blur effect
         */
        loading: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
