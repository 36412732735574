const LOAD_PAGES = (state, payload) => {
    state.pages = payload;
};

const LOAD_CUSTOMER_PAGES = (state, payload) => {
    state.customerPages = payload;
};

const CREATE_PAGE = (state, payload) => {
    state.pages.push(payload);
};

const UPDATE_PAGE = (state, payload) => {
    const page = state.pages.find(page => page.website_pages_name === payload.originalPageName);
    Object.assign(page, payload);
};

export default {
    LOAD_PAGES,
    LOAD_CUSTOMER_PAGES,
    CREATE_PAGE,
    UPDATE_PAGE,
};
