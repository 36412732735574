const routes = [
    {
        name: 'bookmarks',
        path: '/bookmarks',
        component: () => {
            return import('@/views/homepage/bookmarks/Index');
        },
    },
];

export default routes;
