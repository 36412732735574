<template>
    <div
        class="dropdown form-input"
        :class="{
            'dropdown--opened': opened,
            'dropdown--multiple': multiple,
            'dropdown--empty': !selectedRealOptions.length,
            'dropdown--disabled': disabled,
            'dropdown--no-controls': noControls,
            'dropdown--centered': centered,
            'dropdown--no-decoration': noDecoration,
            [`dropdown--size-${size}`]: size,
        }"
        ref="dropdown"
        @click="toggleDropdown"
    >

        <!-- @slot Tags block -->
        <slot
            name="tags"
            v-bind="{
                multiple,
                searchable,
                searchQuery,
                controlText,
                opened,
                disabled,
                selectedOptions: selectedRealOptions,
                getOptionKey,
                getOptionLabel,
                selectOption,
                openDropdown
            }"
        >
            <div
                class="dropdown__tags"
                ref="tags"
            >
                <template v-if="multiple">
                    <!-- @slot Multiple options -->
                    <slot
                        name="multiple-choice"
                        v-bind="{
                            disabled,
                            selectedOptions: selectedRealOptions,
                            openDropdown,
                            selectOption,
                            handleDelete,
                        }"
                    >
                        <CollapsibleTags
                          v-if="selectedOptionsTags.length"
                          :items="selectedOptionsTags"
                          :max-rows="1"
                          @tag-delete="handleDelete"
                          :disabled="disabled"
                        />
                    </slot>
                </template>

                <template v-if="!multiple || opened || selectedRealOptions.length === 0">
                    <div
                        v-if="noDecoration && !searchable"
                        class="dropdown__input dropdown__input--text"
                        :class="{'dropdown__input--value': selectedRealOptions.length && !opened}"
                    >
                        {{ controlText }}
                    </div>
                    <input
                        v-else
                        type="text"
                        class="dropdown__input"
                        :class="{'dropdown__input--value': selectedRealOptions.length && !opened}"
                        ref="searchInput"
                        v-model="searchQuery"
                        :placeholder="controlText"
                        :disabled="isDisabled"
                    />
                </template>
                <div v-if="isDisabled" class="dropdown__overlay"></div>
            </div>
        </slot>
        <div class="dropdown__buttons">
            <button
                v-if="!disabled && clearable"
                type="button"
                class="dropdown__button"
                :class="{
                  'dropdown__button--hidden': nonClearableValue
                }"
                @click.stop="clearSelection"
            >
                <icon name="closeCircle2" class="w-4 h-4"/>
            </button>
            <button
                type="button"
                class="dropdown__button dropdown__button--opener"
                :disabled="disabled"
            >
                <icon name="chevronDown" class="w-4 h-4"/>
            </button>
        </div>
        <div
            v-if="opened"
            class="dropdown__menu"
            :class="{
               'dropdown__menu--above': menuAbove,
               'dropdown__menu--below': !menuAbove,
               'dropdown__menu--single': !multiple,
               'dropdown__menu--multiple': multiple,
            }"
            :style="menuStyle"
            ref="menu"
            @scroll="handleMenuScroll"
        >
            <div
                v-for="(option, index) in filteredOptions"
                :key="getOptionKey(option)"
                :ref="setItemRef"
                @click.stop="selectOption(option)"
                @mouseover="handleItemMouseOver(index)"
            >

                <!-- @slot Dropdown item block -->
                <slot name="item" v-bind="getOptionParameters(option, index)">
                    <div
                        class="dropdown__item"
                        :class="{
                            'dropdown__item--active': !isOptionSeparator(option) && currentItem === index,
                            'dropdown__item--selected': isOptionSelected(option),
                            'dropdown__item--separator': isOptionSeparator(option),
                            'dropdown__item--separator-empty': isOptionSeparator(option) && !getOptionLabel(option),
                            'dropdown__item--disabled': isOptionDisabled(option),
                        }"
                    >
                        <!-- @slot Dropdown option block -->
                        <slot name="option" v-bind="getOptionParameters(option, index)">
                            <div v-if="!isOptionSeparator(option)" class="dropdown__checkbox">
                                <Icon v-if="isOptionSelected(option)" name="checkmark" class="dropdown__checkboxMarker"/>
                            </div>
                            <div>
                                <!-- @slot Dropdown option content block -->
                                <slot name="option-content" v-bind="getOptionParameters(option, index)">
                                    <div class="dropdown__itemLabel">{{ getOptionLabel(option) }}</div>
                                    <div
                                        v-if="getOptionDescription(option) != null"
                                        class="dropdown__itemDescription"
                                    >{{ getOptionDescription(option) }}</div>
                                </slot>
                            </div>
                        </slot>
                        <div
                            class="dropdown__itemIcon"
                            @click.stop="optionIconClicked(option)"
                        >
                            <!-- @slot Option's trailing icon -->
                            <slot name="option-trailing-icon" :option="option"/>
                        </div>
                    </div>
                </slot>
            </div>
            <div v-if="loading || loadingDropdownItems" class="dropdown__item dropdown__item--centered" :class="{'py-20': !filteredOptions.length}">
                <BeatLoader class="h-4"/>
            </div>
            <div
                v-if="!loading && !loadingDropdownItems && !filteredOptions.length"
                class="dropdown__item dropdown__item--empty"
                @click="() => $emit('emptyResultClick')"
            >
               <!-- @slot Empty result block -->
                <slot name="empty-result">
                    No results found matching your search
                </slot>
            </div>
        </div>
    </div>
</template>

<script>
import {debounce} from "lodash-es";
import Icon from "@/components/ui/Icon";
import BeatLoader from '@/components/ui/BeatLoader';
import CollapsibleTags from '@/components/ui/CollapsibleTags';

export const PaginationMode = {
    BASIC: 'basic',
    TOKEN: 'token',
}

export const Size = {
    NORMAL: 'normal', 
    SMALL: 'small',
    XSMALL: 'xsmall',
}

export default {
    components: {BeatLoader, Icon, CollapsibleTags},

    props: {
        /**
         * Presets the selected options value
         * @type {Object|Array|String|Number}
         */
        value: {
            type: null,
            required: true
        },

        /**
         * Array of available options
         */
        options: {
            type: Array,
            required: false
        },

        /**
         * Data provider to fetch options
         */
        dataProvider: {
            type: Object,
            required: false,
        },

        /**
         * Name of a resource used by the data provider
         */
        resource: {
            type: String,
            required: false,
        },

        /**
         * Additional parameters that could be sent with options requests
         */
        requestParams: {
            type: Object,
            required: false,
        },

        /**
         * Data mapping function applied to each response
         */
        responseMapper: {
            type: Function,
            default: response => response.items
              ? response.items
              : response,
            required: false,
        },

        /**
         * Data mapping function applied to each item from a response
         */
        itemMapper: {
            type: Function,
            default: option => option,
            required: false,
        },

        /**
         * Should options be loaded partially while scrolling the options list
         */
        lazyLoad: {
            type: Boolean,
            default: false,
            required: false,
        },

        /**
         * Number of options that could partially loaded in the lazy mode
         */
        pageSize: {
            type: Number,
            default: 20,
            required: false,
        },

        /**
         * Name of parameter to pass for API search
         */
        apiSearchParamName: {
            type: String,
            default: 'search',
        },

        /**
         * Equivalent to the `multiple` attribute on a `<select>` input
         */
        multiple: {
            type: Boolean,
            default: false
        },

        /**
         * Should the value be option object or just key
         */
        objectMode: {
            type: Boolean,
            required: false,
            default: false,
        },

        /**
         * Key to find options
         */
        trackBy: {
            type: String,
            default: 'key',
        },

        /**
         * Label property name to look for in option object
         */
        label: {
            type: String,
            default: 'value',
        },

        /**
         * Description property name to look for in option object
         */
        description: {
            type: String,
            default: 'description',
        },

        /**
         * Equivalent to the `placeholder` attribute on a `<select>` input
         */
        placeholder: {
            type: String,
            default: 'Search items'
        },

        /**
         * Enable/disable search in options
         */
        searchable: {
            type: Boolean,
            required: false,
            default: true
        },

        /**
         * Custom options filtering function.
         * Receives 2 parameters - search query and unfiltered options array. Must return array of filtered options
         */
        localFilteringFunction: {
            type: Function,
            required: false,
        },

        /**
         * Switches off the Clear button
         */
        noClear: {
            type: Boolean,
            required: false,
            default: false
        },

        /**
         * If the control should be disabled
         */
        disabled: {
            type: Boolean,
            required: false,
            default: false
        },

        /**
         * Do not render controls
         */
        noControls: {
            type: Boolean,
            required: false,
            default: false
        },

        /**
         * Center content
         */
        centered: {
            type: Boolean,
            required: false,
            default: false
        },

        /**
         * Do not draw decoration around a component
         */
        noDecoration: {
            type: Boolean,
            required: false,
            default: false,
        },

        /**
         * If the menu's width should be adjusted to fit its content
         */
        menuAutoWidth: {
            type: Boolean,
            default: false,
        },

        /**
         * Maximal height of menu
         */
        maxHeight: {
            type: Number,
            required: false,
            default: 8*36,
        },

        /**
         * Hide the available clear button when the dropdown has a selected value but is not opened
         */
        nonClearableValue: {
            type: Boolean,
            required: false,
            default: false,
        },

        /**
         * Select first option by default
         */
        preselectFirstOption: {
            type: Boolean,
            default: false,
        },

        /**
         * Display loading when waiting for options to load
         */
        loadingDropdownItems: {
            type: Boolean,
            default: false,
        },

        /**
         * Size for the field
         */
        size: {
            type: String,
            default: Size.NORMAL,
            validator: function (value) {
                return [Size.NORMAL, Size.SMALL, Size.XSMALL].includes(value);
            },
        },

        /**
         * Pagination mode for data provider: basic or token based
         */
        paginationMode: {
            type: String,
            default: PaginationMode.BASIC,
            validator: function (value) {
                return Object.values(PaginationMode).includes(value);
            },
        },

        /**
         * Add select all checkbox
        */
        selectAll: {
            type: Boolean,
            default: false,
        }
    },

    emits: [
        /**
         *  Emitted when the control changes its value
         */
        'input',
        /**
         *  Emitted when the control is cleared using the X sign
         */
        'cleared',
        /**
         *  Emitted when the dropdown opens
         */
        'open',
        /**
         *  Emitted when the dropdown closes
         */
        'close',
        /**
         *  Emitted on options update
         */
        'options-loaded',
        /**
         *  Emitted on dropdown option icon click
         */
        'icon-clicked',
        /**,
         *  Emitted on options scrolled to bottom
         */
        'scrolled-to-bottom',
        /**
         * When clicking item shown when no results found after searching
         */
        'emptyResultClick',
    ],

    data() {
        return {
            opened: false,
            searchQuery: null,
            internalValue: null,
            loadedOptions: [],
            loading: false,
            hasNext: true,
            currentItem: 0,
            menuStyle: {},
            menuAbove: false,
            containingBlock: null,
            intersectionObserver: null,
            itemRefs: [],
            storedOptions: [],
            selectAllOption: {
                [this.label]: 'Select all',
                [this.trackBy]: 'selectAll',
                $isSelectAllOption: true
            }
        }
    },

    computed: {
        filteredOptions() {
            const separator = {
                $isSeparator: true
            }

            let options = [];

            if (typeof this.localFilteringFunction === "function") {
                options = this.localFilteringFunction(this.searchQuery, this.loadedOptions);

                if (this.selectAll && this.multiple) {
                    options.unshift(this.selectAllOption, separator);
                }

                return options;
            }

            if (!this.searchQuery || this.lazyLoad) {
                options = this.loadedOptions;
            } else {
                options = this.loadedOptions.filter(option => {
                        return this.getOptionLabel(option)
                            .toLowerCase()
                            .includes(this.searchQuery.toLowerCase());
                    });
            }

            if (this.selectAll && this.multiple) {
                options.unshift(this.selectAllOption, separator);
            }

            return options;
        },

        selectedOptions() {
            return (
                this.internalValue == null
                    ? []
                    : this.multiple
                        ? this.internalValue
                        : [this.internalValue]
            )
                .map(value => this.getOptionByValue(value))
                .filter(o => o);
        },

        selectedRealOptions() {
            return this.selectedOptions.filter(({ $isSelectAllOption, $isSeparator }) => !$isSelectAllOption && !$isSeparator);
        },

        selectedOptionsTags() {
          return this.selectedRealOptions.map(
            option => ({
              key: this.getOptionKey(option),
              text: this.getOptionLabel(option),
              clearable: option?.$isDisabled ? false : true
            }));
        },

        controlText() {
            if (this.selectedOptions.length) {
                if (this.multiple) {
                    return '';
                }

                return this.selectedOptions
                    .map(option => this.getOptionLabel(option))
                    .join(', ');
            } else {
                return this.placeholder;
            }
        },

        selectedMap() {
            return this.selectedOptions.reduce((acc, option) => {
                acc[this.getOptionKey(option)] = option;
                return acc;
            }, {});
        },

        clearable() {
            return this.selectedOptions.length && !this.noClear;
        },

        isDisabled() {
            return this.disabled || !this.searchable;
        },
    },

    methods: {
        openDropdown() {
            if (this.disabled) {
                return;
            }

            this.opened = true;
            if (this.searchable) {
                this.$nextTick(() => {
                    this.$refs.searchInput?.focus();
                });
            }
        },

        closeDropdown() {
            this.opened = false;
            this.searchQuery = null;
            this.currentItem = 0;
        },

        selectOption(option) {
            if (this.isOptionDisabled(option)) {
                return;
            }

            if (this.multiple) {
                if (this.isOptionSelected(option)) {
                    this.changeValue(option.$isSelectAllOption ? [] : this.selectedOptions.filter(opt => this.getOptionKey(opt) !== this.getOptionKey(option)));
                } else {
                    this.changeValue(option.$isSelectAllOption ? this.filteredOptions : [...this.selectedOptions, option]);
                }
            } else {
                this.changeValue([option]);
                this.closeDropdown();
            }
        },

        changeValue(options) {
            const values = options.reduce((res, option) => {
                if (option && !option.$isSeparator && !option.$isSelectAllOption) {
                    res.push(this.getValueByOption(option));
                }

                return res;
            }, []);

            this.internalValue = this.multiple ? values : values[0];

            if (this.lazyLoad) {
              this.storedOptions = options;
            }

            this.$emit('input', this.internalValue);
        },

        handleItemMouseOver(index) {
            if (!this.isOptionDisabled(this.filteredOptions[index])) {
                this.currentItem = index;
            }
        },

        handleClickOutside(e) {
            if (this.$el.contains(e.target)) {
                return;
            }

            this.closeDropdown();
        },

        toggleDropdown() {
            if (this.disabled) {
                return;
            }

            if (this.opened) {
                this.closeDropdown();
            } else {
                this.openDropdown();
            }
        },

        clearSelection() {
            if (this.disabled) {
                return;
            }

            setTimeout(() => {
                this.changeValue([]);
                this.$emit('cleared');
            });
        },

        handleKeyUp(event) {
            if (this.filteredOptions.filter(option => !this.isOptionDisabled(option)).length === 0) {
                return;
            }

            // arrow up
            if (event.keyCode === 38) {
                this.changeCurrentItem(this.currentItem, -1);

            // arrow down
            } else if (event.keyCode === 40) {
                this.changeCurrentItem(this.currentItem, 1);

            // enter
            } else if (event.keyCode === 13) {
                this.selectOption(this.filteredOptions[this.currentItem]);
            }
        },

        changeCurrentItem(currentItem, direction) {
            currentItem += direction;

            if (currentItem < 0) {
                currentItem = this.filteredOptions.length - 1;
            } else if (currentItem > this.filteredOptions.length - 1) {
                currentItem = 0;
            }

            if (this.isOptionDisabled(this.filteredOptions[currentItem])) {
                this.changeCurrentItem(currentItem, direction);
            } else {
                this.currentItem = currentItem;
            }
        },

        calculatePosition() {
            if (!this.$refs.dropdown) {
                return;
            }

            const cbRect = this.containingBlock
                ? this.containingBlock.getBoundingClientRect()
                : {
                    left: 0,
                    top: 0,
                    right: window.innerWidth,
                    bottom: window.innerHeight,
                };

            const controlRect = this.$refs.dropdown.getBoundingClientRect();

            if (controlRect.width === 0 && controlRect.height === 0) {
                this.opened = false;
                return;
            }

            const style = {};

            style.left = `${controlRect.left - cbRect.left}px`;
            style[this.menuAutoWidth ? 'minWidth' : 'width'] = `${controlRect.width}px`;

            const heightUnder = Math.min(window.innerHeight - controlRect.bottom, this.maxHeight);
            const heightAbove = Math.min(controlRect.top, this.maxHeight);

            this.menuAbove = heightUnder < heightAbove;

            if (this.menuAbove) {
                style.bottom = `${cbRect.bottom - controlRect.top}px`;
                style.maxHeight = `${heightAbove}px`;
            } else {
                style.top = `${controlRect.bottom - cbRect.top}px`;
                style.maxHeight = `${heightUnder}px`;
            }

            this.menuStyle = style;
        },

        findContainingBlock() {
            let el = this.$el;

          // eslint-disable-next-line no-cond-assign
            while (el = el.parentElement) {
                const style = getComputedStyle(el);

                if (style.position === 'fixed' || style.transform !== 'none') {
                    break;
                }
            }

            this.containingBlock = el;
        },

        getOptionByValue(value) {
            if (this.objectMode) {
                return value;
            }

            return this.getOptionByKey(value);
        },

        getOptionByKey(key){
            const availableOptions = Array.from(this.loadedOptions);

            if (this.lazyLoad) {
                const existKeys = new Set(availableOptions.map(opt => this.getOptionKey(opt)));
                availableOptions.push(...this.storedOptions.filter(opt => !existKeys.has(this.getOptionKey(opt))));
            }

            return availableOptions.find(option => this.getOptionKey(option) === key);
        },

        getValueByOption(option) {
            return this.objectMode
                ? option
                : this.getOptionKey(option);
        },

        getOptionParameters(option, index) {
            return {
                option,
                active: this.currentItem === index,
                selected: this.isOptionSelected(option),
                separator: this.isOptionSeparator(option),
                disabled: this.isOptionDisabled(option),
            };
        },

        isOptionSelected(option) {
            return !!this.selectedMap[this.getOptionKey(option)];
        },

        isOptionDisabled(option) {
            return option.$isDisabled || this.isOptionSeparator(option);
        },

        isOptionSeparator(option) {
            return option.$isSeparator;
        },

        getOptionKey(option) {
            return option?.[this.trackBy];
        },

        getOptionLabel(option) {
            return option?.[this.label] ?? '';
        },

        getOptionDescription(option) {
            return option?.[this.description];
        },

        loadNextPage() {
            if (!this.lazyLoad || !this.hasNext || this.loading) {
                return;
            }

            this.loading = true;

            this.dataProvider.getList(this.resource, this.getRequestParameters())
                .then(response => {
                    const options = this.getOptionsFromResponse(response);
                    this.hasNext = options.length >= this.pageSize;
                    const existKeys = new Set(this.loadedOptions.map(option => this.getOptionKey(option)));
                    this.loadedOptions = [
                        ...this.loadedOptions,
                        ...options.filter(option => !existKeys.has(this.getOptionKey(option))),
                    ];
                })
                .finally(() => {
                    this.loading = false;
                });
        },

        getRequestParameters() {
            const params = {};
           
            switch (this.paginationMode) {
                case PaginationMode.BASIC:
                    params['page'] = Math.ceil(this.loadedOptions.length / this.pageSize);
                    params['size'] = this.pageSize;
                    params['sort'] = 'id,ASC';
                    break;
                case PaginationMode.TOKEN:
                    if (this.loadedOptions.length) {
                        const lastToken = this.loadedOptions.at(-1)?.token;
                        params['token'] = lastToken;
                        params['dir'] = 'FORWARD';
                    }
                    break;
            }

            if (this.searchQuery) {
                params[this.apiSearchParamName] = this.searchQuery;
            }

            return {...params, ...this.requestParams};
        },

        setItemRef(el) {
            if (el) {
                this.itemRefs.push(el);
            }
        },

        handleMenuScroll(event) {
            if (event.target.scrollHeight - event.target.scrollTop - event.target.clientHeight <= 1) {
                this.$emit('scrolled-to-bottom');
                this.loadNextPage();
            }
        },

        optionIconClicked(option) {
            this.$emit('icon-clicked', option?.id);
        },

        addEventHandlers() {
            document.addEventListener("click", this.handleClickOutside);
            window.addEventListener("scroll", this.calculatePosition, true);
            window.addEventListener("resize", this.calculatePosition);
            document.addEventListener("keyup", this.handleKeyUp);

            this.intersectionObserver = new IntersectionObserver(([entry]) => {
                if (!entry.isIntersecting) {
                    this.closeDropdown();
                }
            }, {
                root: this.containingBlock,
                threshold: 0.5,
            });
            this.intersectionObserver.observe(this.$refs.dropdown);
        },

        removeEventHandlers() {
            if (this.intersectionObserver) {
                this.intersectionObserver.disconnect();
                this.intersectionObserver = null;
            }

            document.removeEventListener("click", this.handleClickOutside);
            window.removeEventListener("scroll", this.calculatePosition, true);
            window.removeEventListener("resize", this.calculatePosition);
            document.removeEventListener("keyup", this.handleKeyUp);
        },

        _loadOptions() {
            if (this.dataProvider) {
                if (!this.resource) {
                    throw new Error('Dropdown: `resource` not specified');
                }

                this.hasNext = true;
                this.loadedOptions = [];
                this.loading = true;

                this.dataProvider.getList(this.resource, this.getRequestParameters())
                    .then(response => {
                        this.loadedOptions = this.getOptionsFromResponse(response);
                        this.hasNext = this.loadedOptions.length >= this.pageSize;

                        if (this.preselectFirstOption && this.loadedOptions.length > 0) {
                            this.selectOption(this.loadedOptions[0]);
                        }

                        this.$emit('options-loaded', response.totalSize);
                    })
                    .finally(() => {
                        this.loading = false;
                    });
            } else {
                if (!this.options) {
                    throw new Error('Dropdown: either `options` or `dataProvider` must be specified');
                }

                this.loadedOptions = this.options.map(this.itemMapper);
                this.$emit('options-loaded', this.options.totalSize);

                if (this.preselectFirstOption && this.loadedOptions.length > 0) {
                    this.selectOption(this.loadedOptions[0]);
                }
            }
        },

        handleDelete(tag) {
            this.selectOption(this.getOptionByKey(tag.key));
        },

        getOptionsFromResponse(response) {
            if (this.paginationMode === PaginationMode.TOKEN) {
                return this.responseMapper(response).map(({token, value}) => ({token, ...value})).map(this.itemMapper);
            }

            return this.responseMapper(response).map(this.itemMapper);
        }
    },

    watch: {
        value() {
            const pureFilteredOptions = this.filteredOptions.filter(({ $isSeparator, $isSelectAllOption }) => !$isSeparator && !$isSelectAllOption);

            if (
                this.multiple &&
                this.selectAll &&
                pureFilteredOptions.length === this.value.length
            ) {
                this.internalValue = [this.getValueByOption(this.selectAllOption), ...this.value];
            } else {   
                this.internalValue = this.value;
            }
        },

        currentItem(value) {
            const {menu} = this.$refs;

            const menuRect = menu.getBoundingClientRect();
            const itemRect = this.itemRefs[value].getBoundingClientRect();

            if (itemRect.top < menuRect.top) {
                menu.scrollTop += itemRect.top - menuRect.top;
            } else if (itemRect.bottom > menuRect.bottom) {
                menu.scrollTop += itemRect.bottom - menuRect.bottom;
            }
        },

        selectedOptions() {
            this.$nextTick(() => {
                this.calculatePosition();
            });
        },

        opened(value) {
            this.$nextTick(() => {
                if (value) {
                    this.findContainingBlock();
                    this.calculatePosition();
                    this.addEventHandlers();

                    this.$emit('open');
                } else {
                    this.removeEventHandlers();

                    this.$emit('close');
                }
            });
        },
    },

    created() {
        this.internalValue = this.value;
    },

    mounted() {
        this.$watch(
            vm => [vm.options, vm.dataProvider, vm.resource, vm.requestParams],
            this._loadOptions,
            {
                immediate: true,
            }
        );

        this.$watch(
            vm => vm.searchQuery,
            debounce(this._loadOptions, 500),
        );
    },

    beforeUpdate() {
        this.itemRefs = [];
    },

    beforeUnmount() {
        this.removeEventHandlers();
    },
}
</script>

<style scoped>
.dropdown {
    @apply flex pr-1 justify-between bg-white transition font-inter text-2sm;
    padding-left: 0.625rem;
    min-height: 40px;
    padding-top: 3px;
    padding-bottom: 3px;
    transition: border-color 200ms ease;

    &--size-small {
        min-height: 2.25rem;
        height: 2.25rem;
        @apply px-2;

        .dropdown__input {
            height: 1.5625rem;
        }
    }

    &--size-xsmall {
        min-height: 1.625rem;
        height: 1.625rem;
        @apply px-2;

        .dropdown__input {
            height: 0.9375rem;
        }
    }

    &:hover:not(&--disabled):not(&--opened) {
        @apply border-gray-500;
    }

    &--disabled {
        @apply border-gray-200 bg-gray-100 cursor-default;
    }

    &--opened {
        @apply border-active-500;

        .dropdown__button--hidden {
            visibility: visible;
        }
    }

    &--multiple:not(&--opened) {
      & :deep(.collapsible-tags) {
        @apply flex-grow;
      }
    }

    &--multiple:not(&--empty) {
        @apply pl-1;
    }

    &--no-controls {
        .dropdown__buttons,
        .dropdown__checkbox {
            @apply hidden;
        }
    }

    &--centered {
        padding-left: 0.25rem;

        .dropdown__input {
            @apply text-center;
        }

        .dropdown__item {
            @apply justify-center;
        }
    }

    &--no-decoration {
        @apply inline-flex justify-start bg-transparent border-none border-b p-0 min-w-0;
        height: 1.75rem;
        min-height: 1.75rem;

        &.dropdown--centered {
            @apply justify-center;
        }

        .dropdown__tags {
            @apply flex-grow-0;
        }

        .dropdown__menu {
            &--above {
                @apply border-b;
            }

            &--below {
                @apply border-t;
            }
        }
    }

    &__tags {
        @apply flex flex-grow flex-wrap items-center overflow-hidden relative;
    }

    &__input {
        @apply min-w-0 outline-none font-inter bg-transparent text-black text-2sm cursor-pointer truncate;
        margin: 2px;
        height: 28px;
        flex: 1 1 40px;

        .dropdown--disabled & {
            @apply cursor-default;
        }

        &::placeholder {
            @apply text-graphite-700 opacity-100;
        }

        &--text {
            @apply m-0 font-medium;
            height: 16px;
            line-height: 16px;
        }

        &--value,
        &--value::placeholder {
            @apply text-black;

            .dropdown--disabled & {
                @apply text-graphite-700;
            }
        }

        &[disabled] {
             pointer-events:none;
        }
    }

    &__buttons {
        @apply flex items-end;
    }

    &__button {
        @apply flex justify-center items-center w-6 h-full transition text-gray-400;

        &:focus {
            @apply outline-none;
        }

        &:hover:not(:disabled) {
            @apply text-gray-500;
        }

        &--opener {
            @apply rotate-0;

            .dropdown:hover &:not(:disabled) {
                @apply text-gray-500;
            }

            .dropdown--opened & {
                @apply transform rotate-180;
            }
        }

        &--hidden {
            visibility: hidden;
        }
    }

    &__menu {
        @apply fixed z-50 border bg-white font-inter text-2sm h-auto overflow-auto overscroll-contain;

        &--above {
            @apply border-b-0 rounded-t-xs;
        }

        &--below {
            @apply border-t-0 rounded-b-xs;
        }
    }

    &__item {
        @apply flex items-center p-2 cursor-pointer;
        min-height: 2.25rem;

        &--separator {
            @apply border-t border-gray-200 text-xs pt-3 pb-1;
        }

        &--separator&--disabled  {
            @apply text-black font-semibold pl-4;
        }

        &--separator-empty {
            @apply p-0;
            min-height: auto;
        }

        &--disabled {
            @apply cursor-default text-graphite-700;

            .dropdown__itemDescription {
                color: inherit;
            }
        }

        &--selected {
            @apply text-active-500;

            .dropdown__menu--single &:not(.dropdown__item--active) {
                @apply bg-active-50;
            }

            .dropdown__itemDescription {
                color: inherit;
            }
        }

        &--active {
            @apply bg-active-100;
        }

        &--empty {
            @apply justify-center text-xs bg-active-50 font-medium text-active-400;
        }

        &--centered {
            @apply justify-center;
        }
    }

    &__checkbox {
        @apply flex flex-shrink-0 justify-center items-center w-3 h-3 rounded-xs mr-2 self-start mt-1;

        .dropdown__menu--multiple & {
            @apply border border-active-500;
        }

        .dropdown__menu--multiple .dropdown__item--selected & {
            @apply bg-active-500 text-white;
        }

        .dropdown__menu--multiple .dropdown__item--disabled & {
            @apply border border-gray-200;
        }

        .dropdown__menu--multiple .dropdown__item--selected.dropdown__item--disabled & {
            @apply bg-gray-200 text-white;
        }
    }

    &__checkboxMarker {
        @apply w-3 h-3;

        .dropdown__menu--multiple & {
            @apply w-2 h-2;
        }
    }

    &__itemDescription {
        @apply mt-1 text-3xs text-graphite-900;
    }

    &__overlay {
        @apply absolute left-0 top-0 w-full h-full cursor-pointer;
    }

    &__itemIcon {
         margin-left: auto;
     }
}

.dropdown :deep(.tag) {
    @apply my-0;
}
</style>
