import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';

const baseURL = getEnvVariable('VUE_APP_ACCT_API_URL');
const beforeRequestInterceptor = ({ requestConfig }) => ({
    ...requestConfig,
    headers: {
        ...requestConfig.headers,
    },
});

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider(httpAdapter);
