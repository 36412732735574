const getImages = state => state.images;

const getCurrentIndex = state => state.currentIndex;

const getLibrary = state => state.library;

const getLoading = state => state.loading;

export default {
    getImages,
    getCurrentIndex,
    getLibrary,
    getLoading,
};
