const routes = [
    // create page
    {
        name: 'pages.create',
        path: '/websites/:websiteId/pages/create',
        component: () => {
            return import('@/views/cms/pages/Create');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create page',
            title: 'add page',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    // show page
    {
        name: 'pages.show',
        path: '/websites/:websiteId/pages/:pageSlug',
        component: () => {
            return import('@/views/cms/pages/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view page',
            title: 'view page',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    // edit page
    {
        name: 'pages.edit',
        path: '/websites/:websiteId/pages/:pageSlug/edit',
        component: () => {
            return import('@/views/cms/pages/Edit');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit page',
            title: 'edit page',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    // list of modules available for page
    {
        name: 'modules.index',
        path: '/websites/:websiteId/pages/:pageSlug/modules',
        component: () => {
            return import('@/views/cms/modules/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'modules',
            title: 'modules',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    // list of widgets available for module
    {
        name: 'modules.show',
        path: '/websites/:websiteId/pages/:pageSlug/modules/:moduleName',
        component: () => {
            return import('@/views/cms/modules/Show');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view module',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    // create a widget for a page
    {
        name: 'widgets.create',
        path: '/websites/:websiteId/pages/:pageSlug/widgets/:widgetId',
        component: () => {
            return import('@/views/cms/widgets/Create');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'create module',
            title: 'create module',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
    // edit widget of a page
    {
        name: 'widgets.edit',
        path: '/websites/:websiteId/pages/:pageSlug/page-widget/:pageWidgetId/edit',
        component: () => {
            return import('@/views/cms/widgets/Edit');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit module',
            title: 'edit module',
            flowStartsWith: 'websites.index',
            passFlowTo: 'websites.show',
            product: 'cms',
            requiresCustomer: true,
            requiresCommunity: true,
            requiredAppAccess: ['CMS'],
        },
    },
];

export default routes;
