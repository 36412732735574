import { createPermissionChecker, PORTFOLIO_PERMISSIONS, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'company.index',
        items: [
            {
                title: 'manage communities',
                routeName: 'company.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
            {
                title: 'manage users',
                routeName: 'company.users.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            },
            {
                title: 'manage admins',
                routeName: 'company.admins.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            },
            {
                title: 'manage roles',
                routeName: 'company.roles.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            },
            {
                title: 'manage permissions',
                routeName: 'company.permissions.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_PERMISSIONS),
            },
        ],
    },
    {
        routeName: 'deactivatedCompany.index',
        items: [
            {
                title: 'view communities',
                routeName: 'company.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
            {
                title: 'view users',
                routeName: 'company.users.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            },
            {
                title: 'view admins',
                routeName: 'company.admins.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            },
        ],
    },
    // Communities
    {
        routeName: 'company.communities.index',
        items: [
            {
                title: 'add new community',
                routeName: 'company.communities.create',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_COMMUNITY),
            },
            {
                title: 'archived communities',
                routeName: 'company.deletedCommunities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_COMMUNITIES),
            },
        ],
    },
    {
        routeName: 'company.community.create',
        items: [],
    },
    // Users
    {
        routeName: 'company.users.index',
        items: [
            {
                title: 'add new user',
                routeName: 'company.users.create',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_CUSTOMER_USER),
            },
            {
                title: 'deleted users',
                routeName: 'company.deletedUsers.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_CUSTOMER_USERS),
            },
        ],
    },
    {
        routeName: 'company.users.create',
        items: [],
    },
    {
        routeName: 'company.users.edit',
        items: [],
    },
    {
        routeName: 'company.users.details',
        items: [
            {
                title: 'edit user',
                routeName: 'company.users.edit',
                params: {
                    customerId: '{customerId}',
                    userId: '{userId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.EDIT_CUSTOMER_USER),
            },
        ],
    },
    // Admins
    {
        routeName: 'company.admins.index',
        items: [
            {
                title: 'add portfolio admin',
                routeName: 'company.admins.create',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_PORTFOLIO_ADMIN),
            },
            {
                title: 'deleted portfolio admins',
                routeName: 'company.deletedAdmins.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            },
        ],
    },
    {
        routeName: 'company.admins.details',
        items: [],
    },
    // Roles
    {
        routeName: 'company.roles.index',
        items: [
            {
                title: 'deleted roles',
                routeName: 'company.deletedRoles.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            },
        ],
    },
    // Permissions
    {
        routeName: 'company.permissions.index',
        items: [],
    },
];
