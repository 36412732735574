const getFloorplans = state => {
    return state.floorplans;
};

const getFloorplanByName = state => {
    return floorplanName => state.floorplans.find(floorplan => floorplan.name === floorplanName);
};

export default {
    getFloorplans,
    getFloorplanByName,
};
