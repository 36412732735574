export default {
    messages: {
        getList: ['GET', '/chats/{id}/messages'],
        create: ['POST', '/chats/{id}/messages'],
        lastRead: ['PATCH', '/chats/{id}/last-read-message'],
        update: ['PUT', '/chats/{chatId}/messages/{messageId}'],
        delete: ['DELETE', '/chats/{chatId}/messages/{messageId}'],
    },
    chats: {
        getOne: ['GET', '/chats/{id}'],
    },
    tags: '/tags',
};
