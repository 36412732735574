<template>
    <div
        id="modal-header"
        class="modal-nav"
    >
        <div class="modal-nav__breadcrumbs">
            <breadcrumbs />
        </div>

        <div class="modal-nav__title">
            <span
                class="title"
                :title="title"
            >
                {{ title }}
            </span>
            <span class="h-4 w-4 ml-6">
                <icon
                    name="close"
                    class="h-4 w-4 text-gray-400 inline hover:text-white cursor-pointer"
                    @click="close"
                />
            </span>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EventBus from '@/utils/EventBus';
import Icon from '@/components/ui/Icon';
import Breadcrumbs from '@/components/ui/modals/Breadcrumbs';

export default {
    components: { Icon, Breadcrumbs },

    emits: ['close'],

    computed: {
        ...mapGetters({
            title: 'modals/getActiveModalTitle',
        }),
    },

    methods: {
        close() {
            if (document.getElementById('modal-file-manager')) {
                EventBus.emit('close-modal-file-manager');
            } else if (document.getElementById('modal-colors-selector')) {
                EventBus.emit('close-modal-colors-selector');
            } else {
                this.$emit('close');
            }
        },
    },
};
</script>

<style scoped>
.modal-nav {
    @apply flex items-center justify-between pb-4;
}

.modal-nav__breadcrumbs {
    @apply mr-2;
}

.modal-nav__breadcrumbs,
.modal-nav__title {
    max-width: 50%;
}

.modal-nav__title {
    @apply flex ml-2;
}

.title {
    @apply text-white font-frank font-bold text-lg truncate;
}
</style>
