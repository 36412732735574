const LOAD_PROPERTIES = (state, payload) => {
    state.properties = payload;
};

const ADD_OR_UPDATE_PROPERTY = (state, payload) => {
    const propertyIndex = state.properties.findIndex(property => {
        return property.id === payload.id;
    });

    if (propertyIndex !== -1) {
        state.properties[propertyIndex] = payload;
    } else {
        state.properties.push(payload);
    }

    state.properties.sort((a, b) => (a.name > b.name ? 1 : -1));
};

export default {
    LOAD_PROPERTIES,
    ADD_OR_UPDATE_PROPERTY,
};
