const routes = [
    // This will be maintained to redirect old urls to the new pattern with the reportId query
    {
        path: '/reports/:reportId',
        redirect: to => {
            return { name: 'dashboard', query: { reportId: to.params.reportId } };
        },
    },
];

export default routes;
