<template>
    <transition name="down-in">
        <AlertMessage
            v-if="alertStore.alert.message"
            :theme="alertStore.alert.type"
            @close="() => alertStore.$reset()"
        >
            <span
                v-dompurify-html="alertStore.alert.message"
                class="break-words"
            />
        </AlertMessage>
    </transition>
</template>

<script>
import { watch, computed } from 'vue';
import { ALERT_TYPE } from '@/utils/constants/common/alert';
import AlertMessage from '@/components/layout/alert/AlertMessage.vue';
import { useAlertStore } from '@/stores/alert';
import { useRouter } from 'vue-router';

const closeTimeout = {
    [ALERT_TYPE.SUCCESS]: 2500,
    [ALERT_TYPE.ERROR]: 5 * 60 * 1000,
    [ALERT_TYPE.INFO]: 5 * 60 * 1000,
};

export default {
    components: { AlertMessage },

    setup() {
        const router = useRouter();
        const alertStore = useAlertStore();

        const closeTime = computed(() => closeTimeout[alertStore.alert.type] || closeTimeout[ALERT_TYPE.SUCCESS]);

        watch(
            alertStore.alert,
            () => {
                if (alertStore.alert.message) {
                    setTimeout(() => {
                        alertStore.$reset();
                    }, closeTime);
                }

                if (alertStore.alert.back) {
                    router.go(-1);
                }
            },
            { immediate: true }
        );

        return { alertStore };
    },
};
</script>

<style scoped>
.down-in-enter-active {
    transition: all 0.35s cubic-bezier(0.71, 0.67, 0.5, 1.35);
}

.down-in-leave-active {
    transition: all 0.15s;
}

.down-in-enter-from {
    opacity: 0;
    transform: translateY(-50%);
}

.down-in-leave-to {
    opacity: 0;
}
</style>
