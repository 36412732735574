export default {
    access_devices: {
        getList: ['GET', '/devices/access-devices'],
    },
    locks: '/devices/access-devices/all-vendors',
    lock_keys: {
        getList: ['GET', '/devices/access-devices/{lockId}/keys'],
    },

    allegion_locks: {
        getOne: ['GET', '/devices/locks/{lockId}'],
        update: ['PUT', '/devices/locks/{lockId}'],
        resyncDatabase: ['POST', '/devices/locks/{lockId}/database/re-sync'],
        sendSecurityToken: ['POST', '/devices/locks/{lockId}/command/force-security-token'],
        syncTime: ['POST', '/devices/locks/{lockId}/command/sync-time'],
        returnToAvailable: ['POST', '/devices/locks/{lockId}/return-to-available'],
        getSchema: ['GET', '/devices/locks/configuration/schema'],
        updateConfig: ['PUT', '/devices/locks/{lockId}/configuration'],
        readConfig: ['POST', '/devices/locks/{lockId}/command/read-config'],
        install: ['POST', '/devices/locks/{lockId}/install'],
        retry: ['POST', '/devices/locks/{lockId}/retry'],
        getErrorStatuses: ['GET', '/devices/locks/lock-error-statuses'],
    },
    allegion_default_config: {
        getModels: ['GET', '/devices/locks/configuration/default/supported-models'],
        getDefault: ['GET', '/devices/locks/configuration/default'],
        getSchema: ['GET', '/devices/locks/configuration/default/schema'],
        update: ['PUT', '/devices/locks/configuration/default'],
    },
    allegion_lock_history: '/devices/locks/access-logs-full',
    allegion_audit_logs: {
        getList: ['GET', '/devices/locks/{id}/audit-log'],
        requestAudits: ['POST', '/devices/locks/{id}/audit-log'],
    },
    allegion_available_locks: '/devices/locks/available',

    pdk_locks: {
        getList: ['GET', '/integrations/pdk/doors'],
        getOne: ['GET', '/integrations/pdk/doors/{lockId}'],
        update: ['PATCH', '/integrations/pdk/doors/{lockId}'],
        changeLocation: ['POST', '/integrations/pdk/doors/{lockId}/change-location'],
        changeLocationBulk: ['POST', '/integrations/pdk/doors/install/bulk'],
        getHardware: ['GET', '/integrations/pdk/doors/{lockId}/hardware'],
        delete: ['DELETE', '/integrations/pdk/doors/{lockId}'],
        getCommunities: ['GET', '/apartment-communities'],
    },
    pdk_locks_all: '/integrations/pdk/doors/all',
    pdk_cloud_nodes: {
        getList: ['GET', '/integrations/pdk/cloud-nodes'],
        getOne: ['GET', '/integrations/pdk/cloud-nodes/{id}'],
        import: ['POST', '/integrations/pdk/cloud-nodes/import'],
        updateConfig: ['PUT', '/integrations/pdk/cloud-nodes/{id}/configuration'],
        delete: ['DELETE', '/integrations/pdk/cloud-nodes/{id}'],
    },
    pdk_lock_history: {
        getList: ['GET', '/integrations/pdk/audit/logs/search'],
        syncAudits: ['POST', '/integrations/pdk/audit/logs/sync'],
    },
    pdk_autoopen: '/integrations/pdk/autoopen',
    pdk_primary_reader_config: {
        get: ['GET', '/integrations/pdk/doors/{lockId}/primary-reader/configuration'],
        getSchema: ['GET', '/integrations/pdk/doors/primary-reader-schemas'],
        update: ['PUT', '/integrations/pdk/doors/{lockId}/primary-reader/configuration'],
    },
    pdk_secondary_reader_config: {
        get: ['GET', '/integrations/pdk/doors/{lockId}/additional-reader/configuration'],
        getSchema: ['GET', '/integrations/pdk/doors/additional-reader-schemas'],
        update: ['PUT', '/integrations/pdk/doors/{lockId}/additional-reader/configuration'],
    },

    yale_locks: {
        getList: ['GET', '/devices/locks/yale'],
        getOne: ['GET', '/devices/locks/yale/{lockId}'],
        update: ['PATCH', '/devices/locks/yale/{lockId}'],
        readConfig: ['GET', '/devices/locks/yale/configuration/{lockId}'],
        getSchema: ['GET', '/devices/locks/yale/configuration/schema'],
        updateConfig: ['POST', '/devices/locks/yale/configuration/{lockId}'],
        install: ['POST', '/devices/locks/yale/install'],
        installRetry: ['POST', '/devices/locks/yale/install/retry'],
        uninstallRetry: ['POST', '/devices/locks/yale/uninstall/retry'],
        delete: ['DELETE', '/devices/locks/yale/{lockId}'],
        fastDelete: ['DELETE', '/devices/locks/yale/force/{lockId}'],
    },
    yale_default_config: {
        readConfig: ['GET', '/devices/locks/yale/configuration/default'],
        getSchema: ['GET', '/devices/locks/yale/configuration/default/schema'],
        updateConfig: ['PUT', '/devices/locks/yale/configuration/default'],
    },
    yale_notification_logs: {
        getList: ['GET', '/zwave/notification-logs/search'],
        requestAudits: ['POST', '/zwave/audit-logs/receive'],
    },

    brivo_lock_history: '/integrations/brivo/access-events/search',
    brivo_locks: {
        getStatus: ['GET', '/integrations/brivo/authentication/status'],
        getList: ['GET', '/integrations/brivo/sites'],
        getExternalSites: ['GET', '/integrations/brivo/sites/external'],
        import: ['POST', '/integrations/brivo/sites/import'],
        deleteSite: ['DELETE', '/integrations/brivo/sites/{id}'],
        syncSites: ['POST', '/integrations/brivo/sites/synchronize'],
        changeLocation: ['POST', '/integrations/brivo/access-points/{lockId}/change-location'],
        changeLocationBulk: ['POST', '/integrations/brivo/access-points/bulk/install'],
        update: ['PATCH', '/integrations/brivo/access-points/{lockId}'],
        getOne: ['GET', '/integrations/brivo/access-points/{lockId}'],
        deleteDevice: ['DELETE', '/integrations/brivo/access-points/{lockId}'],
        deleteAccount: ['DELETE', '/integrations/brivo/authentication'],
        getBrivoAuthStatus: ['GET', '/integrations/brivo/authentication/status'],
        getClientId: ['GET', '/integrations/brivo/authentication/credentials'],
        updateApiKey: ['PUT', 'apartment-community/settings/brivo-api-key/'],
        confitmAuth: ['POST', '/integrations/brivo/authentication'],
    },
};
