const actions = [
    {
        routeName: 'lease.index',
        items: [
            {
                title: 'lease settings',
                routeName: 'lease.lease-terms',
            },
        ],
    },
];

export default actions;
