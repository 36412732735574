import { createPermissionChecker } from '@/router/iot/helper';

const routes = [
    {
        name: 'iot.si-dash.index',
        path: '/iot/si-dash',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/si-dash/Index'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'sensor industries',
            flowStartsWith: 'iot.si-dash.index',
            product: 'iot',
            requiresCustomer: true,
            requiresCommunity: true,
            permissionChecker: createPermissionChecker('SENSOR_INDUSTRIES_READ'),
            title: 'sensor industries',
        },
    },
];

export default routes;

// https://stackoverflow.com/questions/72339446/how-to-emit-an-event-with-vue-chartjs
