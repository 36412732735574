import { defineStore } from 'pinia';

export const useContextParams = defineStore('authContextParams', {
    state: () => ({
        contextData: {
            customerId: null,
            communityId: null,
        },
    }),
    getters: {
        context: state => state.contextData,
    },
    actions: {
        setContext(context) {
            this.contextData = context;
        },
    },
});
