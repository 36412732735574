const pushUnsavedChanges = ({ commit }, payload) => {
    commit('PUSH_UNSAVED_CHANGES', payload);
};

const reset = ({ commit }) => {
    commit('RESET');
};

export default {
    pushUnsavedChanges,
    reset,
};
