import { SET_INITIALIZED, SET_SPACE_TYPES, SET_PROPERTIES } from './mutations';

const setInitialized = ({ commit }, payload) => commit(SET_INITIALIZED, payload);

const setSpaceTypes = ({ commit }, payload) => commit(SET_SPACE_TYPES, payload);

const setRealProperties = ({ commit }, payload) => commit(SET_PROPERTIES, payload);

export default {
    setInitialized,
    setSpaceTypes,
    setRealProperties,
};
