<template>
    <TabbedRouterView :sidebar="sidebar" />
</template>

<script>
import ModalNavigation from '@/mixins/ModalNavigation';
import TabbedRouterView from '@/components/ui/TabbedRouterView.vue';

export default {
    components: { TabbedRouterView },

    mixins: [ModalNavigation],

    props: {
        sidebar: {
            type: Boolean,
            default: false,
        },
    },
};
</script>
