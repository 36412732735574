import getEnvVariable from '@/utils/getEnvVariable';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import DataProvider from '@/api/DataProvider';
import httpClient from '@/api/httpClient';
import store from '@/store';
import resources from './resources';

const baseURL = getEnvVariable('VUE_APP_SEARCH_API_URL');

const beforeRequestInterceptor = ({ requestConfig }) => {
    return {
        ...requestConfig,
        headers: {
            ...requestConfig.headers,
            'X-Quext-Customer-Id': store.getters['auth/customerId'],
            'X-Quext-Community-Id': store.getters['auth/community']?.id,
        },
    };
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);
export default new DataProvider(httpAdapter);
