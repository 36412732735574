import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('auth');

export default {
    computed: {
        ...mapGetters([
            'authenticated',
            'userRole',
            'profile',
            'profiles',
            'getAppPermissions',
            'appsPermissions',
            'isQuext',
            'isSuperAdmin',
            'isQuextUser',
            'email',
            'community',
            'communities',
            'customerAppCodes',
            'customerCommunityAppCodes',
            'profileAppCodes',
            'profilePermissions',
            'appAccesses',
            'hasAuthAccess',
        ]),

        hasAccessTo() {
            return appCode => {
                return this.appAccesses.includes(appCode);
            };
        },
    },
    methods: {
        ...mapActions([
            'signIn',
            'signOut',
            'setProfile',
            'setProfiles',
            'setAppPermissions',
            'clearAppPermissions',
            'setCommunity',
            'setCommunities',
            'setCustomerApps',
            'setCustomerCommunityAppCodes',
        ]),
    },
};
