const routes = [
    {
        name: 'responses.index',
        path: '/responses',
        component: () => {
            return import('@/views/digital_human/manage_global_responses/Index');
        },
        meta: {
            actions: true,
            loadInModal: true,
            breadcrumbName: 'global response catalog',
            title: 'global response catalog',
            size: 'large',
            flowStartsWith: 'responses.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'responses.global-responses-report',
        path: '/global-reponses-report',
        component: () => {
            return import('@/views/digital_human/manage_global_responses/Report');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'global responses report',
            title: 'global responses report',
            size: 'large',
            flowStartsWith: 'responses.index',
            allowedRoles: ['SUPER_ADMIN'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
    {
        name: 'responses.global-conversation-history',
        path: '/global-conversation-history',
        component: () => {
            return import('@/views/digital_human/manage_global_responses/ConversationHistoryReport');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'conversation history',
            title: 'conversation history',
            size: 'small',
            flowStartsWith: 'responses.index',
            allowedRoles: ['SUPER_ADMIN'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
];

export default routes;
