const addFlash = ({ commit }, payload) => {
    commit('ADD_FLASH', payload);
};

const resetFlash = ({ commit }) => {
    commit('RESET_FLASH');
};

export default {
    addFlash,
    resetFlash,
};
