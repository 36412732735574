import store from '@/store';

const permissionChecker = () => store.getters['locations/hasPermission'];

export default [
    {
        path: '/locations',
        name: 'locations.properties.index',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations/properties/PropertyList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'properties',
            title: 'properties',
            flowStartsWith: 'locations.properties.index',
            product: 'loc',
            permissionChecker,
        },
    },
    {
        path: '/locations/create',
        name: 'locations.properties.create',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations/properties/PropertyForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new property',
            flowStartsWith: 'locations.properties.create',
            product: 'loc',
            permissionChecker,
        },
    },
    {
        path: '/locations/:propertyId',
        name: 'locations.properties.details',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations/properties/PropertyDetails.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view details',
            title: 'property details',
            flowStartsWith: 'locations.properties.index',
            product: 'loc',
            permissionChecker,
        },
    },
    {
        path: '/locations/:propertyId/edit',
        name: 'locations.properties.edit',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations/properties/PropertyForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit property',
            title: 'edit property',
            flowStartsWith: 'locations.properties.index',
            product: 'loc',
            permissionChecker,
        },
    },
    {
        path: '/locations/space-types',
        name: 'locations.spacetypes.index',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations/space_types/SpaceTypeList.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'space types',
            title: 'manage space types',
            flowStartsWith: 'locations.spacetypes.index',
            product: 'loc',
            permissionChecker,
        },
    },
    {
        path: '/locations/space-types/create',
        name: 'locations.spacetypes.create',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations/space_types/SpaceTypeForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new space type',
            flowStartsWith: 'locations.spacetypes.create',
            product: 'loc',
            permissionChecker,
        },
    },
    {
        path: '/locations/space-types/:id',
        name: 'locations.spacetypes.details',
        component: () => import(/* webpackChunkName: "locations" */ '@/views/locations/space_types/SpaceTypeForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view details',
            title: 'space type details',
            flowStartsWith: 'locations.spacetypes.index',
            product: 'loc',
            permissionChecker,
        },
    },
];
