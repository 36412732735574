import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.custom_group_access.create',
        path: '/iot/groups/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/groups/Create.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'new group',
            title: 'new group',
            flowStartsWith: 'iot.custom_group_access.create',
            permissionChecker: createPermissionChecker('CUSTOM_USER_GROUP_CREATE'),
        },
    },
    {
        name: 'iot.custom_group_access.details',
        path: '/iot/groups/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/groups/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'group details',
            title: 'group details',
            flowStartsWith: 'iot.custom_group_access.index',
            permissionChecker: createPermissionChecker('CUSTOM_USER_GROUP_READ'),
        },
    },
    {
        name: 'iot.custom_group_access.edit',
        path: '/iot/groups/:id/edit',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/groups/Create.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'edit group',
            title: 'edit group',
            flowStartsWith: 'iot.custom_group_access.index',
            permissionChecker: createPermissionChecker('CUSTOM_USER_GROUP_UPDATE'),
        },
    },
]);
