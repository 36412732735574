const actions = [
    {
        routeName: 'transfer_resident.create-lease',
        items: [
            {
                title: 'edit resident details',
                routeName: 'transfer_resident.edit-resident-details',
            },
        ],
    },
];

export default actions;
