const actions = [
    {
        routeName: 'responses.index',
        items: [
            {
                title: 'global responses report',
                routeName: 'responses.global-responses-report',
            },
            {
                title: 'conversation history',
                routeName: 'responses.global-conversation-history',
            },
        ],
    },
    {
        routeName: 'responses.edit-responses',
        items: [
            {
                title: 'delete response',
                routeName: 'responses.delete-response',
            },
        ],
    },
    {
        routeName: 'responses.translation-report',
        items: [
            {
                title: 'translation-report',
                routeName: 'responses.translation-report',
            },
        ],
    },
];

export default actions;
