<template>
  <Tooltip
    v-if="hint && hint.length"
    :css="{ tooltipPopup: 'w-auto' }"
    positionX="middle"
  >
    <template v-slot:label>
      <button
        class="icon-button"
        :class="{
          'icon-button--disabled': disabled,
          ['icon-button--size-big']: size === 'big',
        }"
        type="button"
        :disabled="disabled"
        v-bind="$attrs"
        @click="$emit('click', $event)"
      >
        <icon :name="icon" class="icon-button__icon" />
      </button>
    </template>

    <template v-slot:default>
      {{ hint }}
    </template>
  </Tooltip>

  <button
    v-else
    class="icon-button"
    :class="{
      'icon-button--disabled':  disabled,
      ['icon-button--size-big']: size === 'big',
    }"
    type="button"
    :disabled="disabled"
    v-bind="$attrs"
    @click="$emit('click', $event)"
  >
    <icon :name="icon" class="icon-button__icon" />
  </button>
</template>

<script>
import Tooltip from "@/components/ui/Tooltip";
import Icon from "@/components/ui/Icon";

export default {
  components: {
    Tooltip,
    Icon,
  },

  inheritAttrs: false,

  props: {
    /**
     * Button icon name
     */
    icon: {
      type: String,
      required: true,
    },
    /**
     * Tooltip hint text
     */
    hint: {
      type: String,
      required: false,
    },
    /**
     * Disabled
     */
    disabled: {
      type: Boolean,
    },

    size: {
      type: String,
      default: 'normal',
      validator: value => ['normal', 'big'].includes(value),
    },
  },

  emits: ['click'],
};
</script>

<style scoped>
.icon-button {
  @apply w-6 h-6 p-1;

  &.icon-button--size-big {
    width: 2.25rem;
    height: 2.25rem;
    padding: 0.5rem;
  }

  &:not(.icon-button--disabled):hover {
    @apply bg-active-25;
  }
}

.icon-button__icon {
  @apply w-full;
}
</style>
