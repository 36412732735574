import vuexStore from '@/store';
import { defineStore } from 'pinia';
import { useAccountsPayableStore } from '@/stores/accounting/accounts_payable';
import { useAccountsReceivableStore } from '@/stores/accounting/accounts_receivable';
import { useGeneralLedgerStore } from '@/stores/accounting/general_ledger';

export const useAccountingStore = defineStore('accounting', {
    state: () => ({
        disableCustomCheckboxes: {},
        apStore: useAccountsPayableStore(),
        arStore: useAccountsReceivableStore(),
        glStore: useGeneralLedgerStore(),
    }),

    getters: {
        hasPermission: () => permissionAlias =>
            permissionAlias && vuexStore.getters['auth/getAppPermissions']('ACCT')?.includes(permissionAlias),
    },

    actions: {
        setDisableCustomCheckboxes(payload) {
            this.disableCustomCheckboxes = {
                ...this.disableCustomCheckboxes,
                ...payload,
            };
        },
    },
});
