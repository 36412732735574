const getIcons = state => {
    return state.icons;
};

const getCommunityAmenitiesTypes = state => {
    return state.communityAmenitiesTypes;
};

const getCustomerAmenities = state => {
    return state.customerAmenities;
};

const getCustomerAmenitiesByType = state => type => {
    return state.customerAmenities.filter(item => item.community_amenities_type_name === type);
};

const getCustomerAmenityByName = state => name => {
    return state.customerAmenities.find(item => item.community_amenities_name === name);
};

const getCustomerCommunityAmenitiesByType = state => type => {
    return state.customerCommunityAmenities[type];
};

export default {
    getIcons,
    getCommunityAmenitiesTypes,
    getCustomerAmenities,
    getCustomerAmenitiesByType,
    getCustomerAmenityByName,
    getCustomerCommunityAmenitiesByType,
};
