const clearWebsiteInformation = ({ commit }) => {
    commit('CLEAR_WEBSITE_INFO');
};

const addWebsiteInfo = ({ commit }, payload) => {
    commit('ADD_WEBSITE_INFO', payload);
};

export default {
    clearWebsiteInformation,
    addWebsiteInfo,
};
