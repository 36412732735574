const ROUTES = {
    IPS_V2: {
        name: 'ips-v2.index',
        path: '/ips-v2',
    },
    ADD_PURPOSE: {
        path: '/manage-purpose/create/:id?',
        name: 'ips-v2.purpose.create',
    },
    ADD_PARTNER: {
        path: '/manage-partner/create',
        name: 'ips-v2.partner.create',
    },
    EDIT_PARTNER: {
        path: '/manage-edit-partner/:partnerId?',
        name: 'ips-v2.partner.edit',
    },
    PARTNER_UPDATE: {
        path: '/edit-partner/:partnerId?',
        name: 'ips-v2.partner.edit-page',
    },
    ADD_CATEGORY: {
        path: '/manage-category/create',
        name: 'ips-v2.category.create',
    },
    ADD_PARAMETER: {
        path: '/manage-parameters/create',
        name: 'ips-v2.parameters.create',
    },
    MANAGE_PARTNER: {
        path: '/manage-partner',
        name: 'ips-v2.partner.manage-partner',
    },
    MANAGE_PURPOSE: {
        path: '/manage-purpose',
        name: 'ips-v2.purpose.manage-purpose',
    },
    MANAGE_PARAMETER: {
        path: '/manage-parameters',
        name: 'ips-v2.parameters.manage-parameters',
    },
    MANAGE_CATEGORY: {
        path: '/manage-category',
        name: 'ips-v2.category.manage-category',
    },
    MANAGE_CREDENTIAL: {
        path: '/manage-credential',
        name: 'ips-v2.credential.manage-credential',
    },
    MANAGE_COMMUNITY: {
        path: '/manage-community',
        name: 'ips-v2.community.manage-community',
    },
    PARTNER_PARAMETER: {
        path: '/manage-partner-purpose',
        name: 'ips-v2.partner-purpose.manage-partner-purpose',
    },
    PARTNER_PURPOSE: {
        path: '/manage-partner-parameter',
        name: 'ips-v2.partner-parameter.manage-partner-parameter',
    },
    PARTNER_EDIT: {
        path: '/manage-partner-edit',
        name: 'ips-v2.partner.manage-partner-edit',
    },
    PARTNER_ENDPOINT_EDIT: {
        path: '/manage-edit-partner/:partnerId?/edit-partner-endpoint/:partnerEndpointId?',
        name: 'ips-v2.edit-partner-endpoint.edit',
    },
    PARTNER_ENDPOINT_ADD: {
        path: '/manage-edit-partner/:partnerId?/edit-partner-endpoint',
        name: 'ips-v2.edit-partner-endpoint.create',
    },
    PARTNER_WIZARD_FORM: {
        path: '/manage-partner/partner-wizard-form',
        name: 'ips-v2.partner.partnerWizardForm',
    },
    LIST_PARAMETER: {
        path: '/manage-edit-partner/:partnerId?/list-parameter',
        name: 'ips-v2.parameters.listParameters',
    },
    ASSIGN_PARAMETER: {
        path: '/manage-edit-partner/:partnerId?/list-parameter/create-parameter',
        name: 'ips-v2.parameters.assign-parameter',
    },
    ADD_PARAMETER_SEPARATE: {
        path: '/manage-edit-partner/:partnerId?/create-parameter',
        name: 'ips-v2.parameters.create-parameters-level',
    },
    COMMUNITY_DETAILS: {
        path: '/community-details/:customerId/:communityId',
        name: 'ips-v2.communityDetails',
    },
    PARAMETER_CATEGORY: {
        path: '/manage-parameter-category/details',
        name: 'ips-v2.parameter-category.details',
    },
    ADD_PARAMETER_CATEGORY: {
        path: '/manage-parameter-category/create',
        name: 'ips-v2.parameter-category.create',
    },
    MANAGE_CUSTOMER_USER_COMMUNITY: {
        path: '/manage-customer-user-community',
        name: 'ips-v2.manage-customer-user-community',
    },
    ADD_COMMUNITY_PARAMETER: {
        path: '/community-details/:customerId/:communityId/create-parameter',
        name: 'ips-v2.communityDetails.parameters.create',
    },
    MANAGE_MODULES: {
        path: '/manage-modules',
        name: 'ips-v2.manage-modules',
    },
    MODULE_DETAILS: {
        path: '/manage-modules/:moduleId',
        name: 'ips-v2.module-details',
    },
    MODULE_ENDPOINT: {
        path: '/manage-modules/:moduleId/endpoint',
        name: '/ips-v2.module-endpoint',
    },
    CONSUMER_ENDPOINT_FORM: {
        path: '/manage-edit-partner/:partnerId/assign-endpoint',
        name: '/ips-v2.partner.assing-endpoint',
    },
    IPS_AUDIT_LOG: {
        path: '/manage-partner/audit-log',
        name: 'ips-v2.audit-log',
    },
};

export default ROUTES;

export const COMMUNITY_PARAMETERS_FIELD = [
    {
        name: 'isRequired',
        title: 'required',
        sort: false,
        switch: {
            type: 'checkbox',
            showReason: true,
        },
    },
    {
        title: 'type',
        name: 'type',
        displayField: 'type',
        dropdownOptionName: 'type',
        optionLabelField: 'value',
        optionKeyField: 'key',
        switch: {
            type: 'dropdown',
            showReason: true,
        },
    },
    {
        name: 'parameterName',
        title: 'parameter name',
        sort: false,
        switch: {
            required: true,
            type: 'textarea',
            showReason: true,
        },
    },
    {
        title: 'category',
        name: 'parameterCategoryId',
        displayField: 'parameterCategory.categoryName',
        dropdownOptionName: 'category',
        optionKeyField: 'parameterCategoryId',
        optionLabelField: 'categoryName',
        switch: {
            type: 'dropdown',
            showReason: true,
        },
    },
    {
        name: 'defaultValue',
        title: 'default value',
        sort: false,
        switch: {
            required: true,
            type: 'textarea',
            showReason: true,
        },
    },
    {
        name: 'description',
        title: 'description',
        sort: false,
        switch: {
            required: false,
            type: 'textarea',
            showReason: true,
        },
    },
    {
        name: 'partnerName',
        title: 'partner',
        sort: false,
        switch: {
            required: false,
            type: 'textarea',
            isInputDisabled: true,
        },
    },
    {
        name: 'level',
        title: 'level',
        sort: false,
        switch: {
            required: false,
            type: 'textarea',
            isInputDisabled: true,
        },
    },
];

export const PARAMETERS_LEVEL = [
    {
        key: 1,
        value: 'partner',
        targetTable: 'partnerParameter',
    },
    {
        key: 2,
        value: 'customer',
        targetTable: 'partnerCustomerParameter',
    },
    {
        key: 3,
        value: 'community',
        targetTable: 'partnerCustomerCommunityParameter',
    },
];

export const PARTNER_TYPE_OPTIONS = [
    {
        key: 'consumer',
        value: 'consumer',
    },
    {
        key: 'producer',
        value: 'producer',
    },
    {
        key: 'both',
        value: 'both',
    },
];

export const STATUSES = [
    {
        key: 1,
        status: '',
        value: 'all',
    },
    {
        key: 2,
        status: true,
        value: 'active',
    },
    {
        key: 3,
        status: false,
        value: 'inactive',
    },
];

export const METHOD_TYPES = [
    {
        name: 'get',
        value: 'GET',
    },
    {
        name: 'post',
        value: 'POST',
    },
    {
        name: 'put',
        value: 'PUT',
    },
    {
        name: 'patch',
        value: 'PATCH',
    },
    {
        name: 'delete',
        value: 'DELETE',
    },
];

export const PARAMETER_VALUE = [
    {
        key: 1,
        value: 'partner',
        targetTable: 'partnerParameter',
    },
    {
        key: 2,
        value: 'customer',
        targetTable: 'partnerCustomerParameter',
    },
    {
        key: 3,
        value: 'community',
        targetTable: 'partnerCustomerCommunityParameter',
    },
];

export const PARTNER_ENDPOINT_DETAILS_FIELDS = [
    {
        name: 'endpointName',
        title: 'endpoint name',
    },
    {
        name: 'endpointCode',
        title: 'endpoint code',
    },
    {
        name: 'hostName',
        title: 'host name',
    },
    {
        name: 'timeout',
        title: 'timeout',
    },
    {
        name: 'protocol',
        title: 'protocol',
    },
    {
        name: 'requestMethod',
        title: 'request method',
    },
    {
        name: 'urlPath',
        title: 'url path',
    },
    {
        name: 'template',
        title: 'template',
    },
    {
        name: 'headers',
        title: 'headers',
    },
    {
        name: 'description',
        title: 'description',
    },
    {
        name: 'partnerVersion.partnerVersionName',
        title: 'version name',
    },
    {
        name: 'partnerSystem.partnerSystemName',
        title: 'system name',
    },
    {
        name: 'partnerEnvironment.partnerEnvironmentName',
        title: 'environment name',
    },
    {
        name: 'validationErrorPattern',
        title: 'validation error pattern',
    },
    {
        name: 'thresholdLimitInDays',
        title: 'threshold limit days',
    },
    {
        name: 'relatedEndpointId',
        title: 'related Endpoint',
    },
    {
        name: 'relatedDataPattern',
        title: 'related data pattern',
    },
    {
        name: 'isActive',
        title: 'active',
    },
    {
        name: 'isMandatory',
        title: 'mandatory',
    },
];
