import store from '@/store';
import { AMNT } from '@/utils/ppm/permissions';

const createPermissionChecker = permissionId => () => store.getters['amnt/hasPermission'](permissionId);

export default [
    {
        routeName: 'amenities.enterprise.individual.index',
        items: [
            {
                title: 'add new amenity',
                routeName: 'amenities.enterprise.individual.create',
                params: {},
                allowed: createPermissionChecker(AMNT.EL_AMENITY_MODIFY),
            },
            {
                title: 'assign communities',
                routeName: 'amenities.communities-assignment',
                params: {},
                allowed: createPermissionChecker(AMNT.EL_COMMUNITY_ASSIGNMENT),
            },
        ],
    },
    {
        routeName: 'amenities.enterprise.individual.edit',
        items: [],
    },
    // {
    //     routeName: 'amenities.enterprise.groups.index',
    //     items: [
    //         {
    //             title: 'add new group',
    //             routeName: 'amenities.enterprise.groups.create',
    //             params: {},
    //             allowed: createPermissionChecker(AMNT.EL_AMENITY_GROUP_MODIFY),
    //         },
    //         {
    //             title: 'assign communities',
    //             routeName: 'amenities.communities-assignment',
    //             params: {},
    //             allowed: createPermissionChecker(AMNT.EL_COMMUNITY_ASSIGNMENT),
    //         },
    //     ],
    // },
    // {
    //     routeName: 'amenities.enterprise.groups.edit',
    //     items: [],
    // },
    {
        routeName: 'amenities.community.individual.index',
        items: [
            {
                title: 'units assignment',
                routeName: 'amenities.community.unitsAssignment',
                params: {},
                allowed: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
            },
            {
                title: 'new property amenity',
                routeName: 'amenities.enterprise.individual.create',
                params: {},
                allowed: createPermissionChecker(AMNT.EL_AMENITY_VIEW),
            },
        ],
    },
    {
        routeName: 'amenities.community.individual.edit',
        items: [
            {
                title: 'units assignment',
                routeName: 'amenities.community.individual.unitsAssignment',
                params: {
                    amenityId: '{amenityId}',
                },
                allowed: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
            },
        ],
    },
    // {
    //     routeName: 'amenities.community.groups.index',
    //     items: [
    //         {
    //             title: 'units assignment',
    //             routeName: 'amenities.community.unitsAssignment',
    //             params: {},
    //             allowed: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
    //         },
    //     ],
    // },
    // {
    //     routeName: 'amenities.community.groups.edit',
    //     items: [
    //         {
    //             title: 'units assignment',
    //             routeName: 'amenities.community.groups.unitsAssignment',
    //             params: {
    //                 groupId: '{groupId}',
    //             },
    //             allowed: createPermissionChecker(AMNT.CL_UNIT_ASSIGNMENT),
    //         },
    //     ],
    // },
];
