import { defineStore } from 'pinia';

export const useStaffStore = defineStore('iotStaff', {
    state: () => ({
        staff: null,
        permissionATC: [],
    }),
    getters: {
        isAllowedATC: state => {
            return state.permissionATC.some(permission => state.staff?.roleType === permission.roleType);
        },
    },
    actions: {
        setStaff(staff) {
            this.staff = staff;
        },

        setPermissionATC(permissions) {
            this.permissionATC = permissions;
        },
    },
});
