import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    categories: [],
    intents: [],
    subIntents: [],
    responses: {},
    photos: [],
    category: null,
    intent: null,
    subIntent: null,
    loading: false,
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
