import getEnvVariable from '@/utils/getEnvVariable';

export default {
    Auth: {
        Cognito: {
            allowGuestAccess: false,
            userPoolId: getEnvVariable('VUE_APP_COGNITO_USERPOOL_ID'),
            userPoolClientId: getEnvVariable('VUE_APP_COGNITO_USERPOOL_WEBCLIENT_ID'),
            mfa: {
                status: 'optional',
                totpEnabled: true,
            },
            passwordFormat: {
                minLength: 8,
                requireLowercase: true,
                requireUppercase: true,
                requireNumbers: true,
            },
        },
    },
};
