<template>
    <Form
        ref="form"
        :submit="resetPassword"
        class="w-full"
    >
        <div class="mb-4">
            <TextField
                name="username"
                label="email or phone number"
                :validate="required"
                autocomplete="off"
            />
        </div>
        <MessageBlock
            v-if="error"
            type="warning"
            class="flex mb-4"
        >
            <Icon name="warning" />
            <div class="ml-2">
                {{ error }}
            </div>
        </MessageBlock>
        <div>
            <button
                :disabled="isPending"
                type="submit"
                class="btn-primary btn-solid btn-auth"
            >
                reset my password
            </button>
        </div>
    </Form>
    <div class="mt-4 text-center">
        <button
            class="btn-link auth-btn-link"
            type="button"
            @click="backToSignIn"
        >
            back to sign in
        </button>
    </div>
</template>

<script setup>
import { computed } from 'vue';
import { useAuthenticator } from '@aws-amplify/ui-vue';
import MessageBlock from '@/components/ui/MessageBlock.vue';
import Icon from '@/components/ui/Icon.vue';
import Form from '@/components/form/Form';
import TextField from '@/components/form/TextField';
import { required } from '@/components/form/validators';

const facade = useAuthenticator();

const isPending = computed(() => facade.isPending);

const error = computed(() => facade.error);

const resetPassword = async ({ username }) => {
    try {
        await facade.submitForm({ username });
    } catch (e) {
        console.error(e);
    }
};

const backToSignIn = () => facade.toSignIn();
</script>
