import { prepareIotRoutes, createPermissionChecker } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.passes.create',
        path: '/iot/passes/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/passes/Create'),
        meta: {
            actions: true,
            breadcrumbName: 'add pass',
            title: 'new pass',
            flowStartsWith: 'iot.passes.index',
            permissionChecker:
                createPermissionChecker('VENDOR_PASS_CREATE') ||
                createPermissionChecker('RESIDENT_PASS_CREATE') ||
                createPermissionChecker('GUEST_PASS_CREATE'),
        },
    },
    {
        name: 'iot.passes.details',
        path: '/iot/passes/:id',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/passes/Details'),
        meta: {
            actions: true,
            breadcrumbName: 'pass details',
            title: 'pass details',
            flowStartsWith: 'iot.passes.index',
            permissionChecker:
                createPermissionChecker('VENDOR_PASS_READ') ||
                createPermissionChecker('RESIDENT_PASS_READ') ||
                createPermissionChecker('GUEST_PASS_READ'),
        },
    },
    {
        name: 'iot.passes.summary',
        path: '/iot/passes/:id/summary',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/passes/Summary'),
        meta: {
            actions: true,
            breadcrumbName: 'pass summary',
            title: 'pass summary',
            flowStartsWith: 'iot.passes.index',
        },
    },
]);
