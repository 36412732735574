<template>
    <Tooltip ref="tags" :position-y="positionY" :position-x="positionX">
        <template #label>
            <Tag v-bind="$attrs" >
                <slot />
            </Tag>
        </template>
        <template #default>
            <slot name="tooltipTitle" />
        </template>
    </Tooltip>
</template>

<script>
import Tooltip from '@/components/ui/Tooltip';
import Tag from '@/components/ui/Tag';

export default {
    name: 'TagWithTooltip',

    inheritAttrs: false,

    components: { Tag, Tooltip },

    props: {
        positionY: {
            type: String,
            default: 'auto'
        },
        positionX: {
            type: String,
            default: 'auto'
        },
    },
};
</script>