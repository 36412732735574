<template>
    <div class="status-list">
        <List
            :columns="statusListColumns"
            :items="statuses"
            track-by="code"
            class="mb-8"
        >
            <template #column:name="{ item }">
                <EditableField
                    :with-edit-control="editable"
                    :initial-values="item"
                    :on-submit="handleStatusChange"
                >
                    <template #field="{ editMode }">
                        <TextField
                            name="name"
                            size="small"
                            :edit-mode="editMode"
                            :validate="[required, maxLength(50)]"
                        />
                    </template>
                </EditableField>
            </template>
            <template #column:description="{ item }">
                <EditableField
                    :with-edit-control="editable"
                    :initial-values="item"
                    :on-submit="handleStatusChange"
                >
                    <template #field="{ editMode }">
                        <TextField
                            name="description"
                            size="small"
                            :edit-mode="editMode"
                            :validate="[required, maxLength(200)]"
                        />
                    </template>
                </EditableField>
            </template>
        </List>

        <loader
            :loading="loading"
            backdrop
        />
    </div>
</template>

<script>
import EditableField from '@/components/ui/inlineediting/EditableField';
import TextField from '@/components/form/TextField';
import List from '@/components/list/List';
import ValidatorMixin from '@/components/form/ValidatorMixin';
import { useAlertStore } from '@/stores/alert';
import Loader from '@/components/ui/Loader';

const statusListColumns = [
    {
        name: 'name',
        title: 'status name',
        class: 'w-5/12',
    },
    {
        name: 'description',
        title: 'description',
        class: 'w-7/12',
    },
];

export default {
    components: { Loader, List, TextField, EditableField },

    mixins: [ValidatorMixin],

    props: {
        statusListColumns: {
            type: Array,
            default: () => statusListColumns,
        },

        statuses: {
            type: Array,
            required: true,
        },

        editable: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['update:statuses'],

    setup() {
        const { notifyError } = useAlertStore();
        return { notifyError };
    },

    data() {
        return {
            loading: false,
        };
    },

    methods: {
        handleStatusChange(status) {
            this.loading = true;

            this.$cbrDataProvider
                .updateStatus('unitStatuses', {
                    statusCode: status.code,
                    data: {
                        name: status.name,
                        description: status.description,
                    },
                })
                .then(updatedStatus => {
                    const toEmit = [...this.statuses];
                    toEmit[this.statuses.findIndex(({ code }) => code === updatedStatus.code)] = updatedStatus;
                    this.$emit('update:statuses', toEmit);
                })
                .catch(error => {
                    this.notifyError(error);
                })
                .finally(() => {
                    this.loading = false;
                });
        },
    },
};
</script>

<style scoped>
.status-list:deep(.body) > .row {
    @apply items-start;
}
</style>
