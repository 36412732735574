export default {
    contact: {
        getOne: ['GET', 'contact/{contact_id}'],
        create: ['POST', 'contact'],
        update: ['PUT', 'contact/{contact_id}'],
        delete: ['DELETE', 'contact/{contact_id}'],
    },

    emergencyContact: {
        getList: ['GET', 'contact/emergency/list/{contact_id}'],
        create: ['POST', 'contact/emergency'],
        delete: ['DELETE', 'contact/emergency/{contact_id}'],
    },

    associated: {
        getList: ['GET', 'contact/associated/list/{contact_id}'],
        create: ['POST', 'contact/associated'],
        delete: ['DELETE', 'contact/associated/{contact_id}'],
    },

    note: {
        delete: ['DELETE', 'contact/{contact_id}/note/{note_id}'],
    },
};
