import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';

const baseURL = getEnvVariable('VUE_APP_API_DIGITAL_HUMAN_AI_URL');

const actions = ['get', 'getOne', 'getList', 'create', 'delete', 'update'];
const digitalHumanResources = [
    'conversationReport',
    'conversationReportPdf',
    'getHoursOfOperations',
    'previewTrainingPhrasesReport',
    'trainingPhrasesReportPdf',
    'createCustomerCategory',
    'updateCustomerCategory',
    'updateTopicCheckbox',
];

const beforeRequestInterceptor = ({ requestConfig, action, resource }) => {
    if (actions.includes(action) && digitalHumanResources.includes(resource)) {
        return {
            ...requestConfig,
            responseType: requestConfig.params.responseType,
            headers: { ...requestConfig.params.headers },
        };
    }

    return requestConfig;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider(httpAdapter);
