import { defineStore } from 'pinia';

export const useAccountsPayableStore = defineStore('accountsPayable', {
    state: () => ({
        invoiceId: '',
        paymentData: {},
    }),

    actions: {
        setInvoiceId(payload) {
            this.invoiceId = payload;
        },
        setPaymentData(payload) {
            this.paymentData = payload;
        },
    },
});
