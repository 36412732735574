import notifications from './_notifications';
import sreq from './_sreq';
import unitStatuses from './_unit_statuses';
import pricingMatrix from './_pricing_matrix';

const entries = [unitStatuses, sreq, pricingMatrix, notifications];

export const getAllowedSettingsEntries = () => entries.filter(({ permissionChecker }) => permissionChecker());

export const hasAnyCommunitySettingsAccess = () => getAllowedSettingsEntries().length > 0;
