import { computed } from 'vue';
import store from '@/store';
import { AUTH_PERMISSION_CATEGORY, QUEXT_PERMISSIONS, PORTFOLIO_PERMISSIONS } from '@/components/auth/permissions/constants';

export const useAuthPermissions = () => {
    const hasAuthPermission = computed(() => permissionName => {
        const hasAuthAccess = store.getters['auth/hasAuthAccess'];

        if (!hasAuthAccess) {
            return false;
        }

        const getPermission = store.getters['auth/getAuthPermissions'];

        if (Array.isArray(permissionName)) {
            return permissionName.some(name => getPermission(name)?.value === 'true');
        }

        return getPermission(permissionName)?.value === 'true';
    });

    const hasContextPermission = computed(() => permissionName => {
        const customerId = store.getters['auth/customerId'];
        const permission = store.getters['auth/getAuthPermissions'](permissionName);

        return customerId && permission?.category === AUTH_PERMISSION_CATEGORY.PORTFOLIO && permission.value === 'true';
    });

    return {
        QUEXT_PERMISSIONS,
        PORTFOLIO_PERMISSIONS,
        hasAuthPermission,
        hasContextPermission,
    };
};
