import { createPermissionChecker } from '@/router/iot/helper';
import { hasTemporaryStaffAccessCreatePermission } from '@/router/iot/access/_staff_access';

export default [
    {
        routeName: 'iot.group-access.index',
        items: [
            {
                title: 'add location',
                routeName: 'iot.group-access.add-location',
                allowed: createPermissionChecker('GROUP_USER_ACCESS_READ_LIST'),
            },
        ],
    },
    {
        routeName: 'iot.staff-access.index',
        items: [
            {
                title: 'expired/revoked access',
                routeName: 'iot.staff-access.expired-revoked-access',
                allowed: createPermissionChecker('TEMPORARY_USER_ACCESS_READ_LIST'),
            },
            {
                title: 'event log',
                routeName: 'iot.staff-access.event-log',
                allowed: createPermissionChecker('LOCK_READ_VIRTUAL_HISTORY_LOG'),
            },
            {
                title: 'grant access',
                routeName: 'iot.staff-access.grant-access',
                allowed: hasTemporaryStaffAccessCreatePermission,
            },
        ],
    },
    {
        routeName: 'iot.staff-access.access-details',
        items: [
            {
                id: 'revoke-staff-access',
                title: 'revoke access',
                showConfirmation: true,
                confirmationMessage: 'are you sure you want to revoke access?',
                confirmBtnText: 'yes, revoke',
                cancelBtnText: 'no, go back',
                confirmationType: 'warning',
                allowed: createPermissionChecker(['TEMPORARY_USER_ACCESS_REVOKE_MOBILE', 'TEMPORARY_USER_ACCESS_REVOKE_VIRTUAL']),
            },
            {
                id: 'edit-staff-access',
                title: 'edit access',
                routeName: 'iot.staff-access.access-details.edit',
                params: {
                    id: '{id}',
                },
                allowed: createPermissionChecker('TEMPORARY_USER_ACCESS_UPDATE_VIRTUAL'),
            },
        ],
    },
];
