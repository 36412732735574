import { defineStore } from 'pinia';
import vuexStore from '@/store';
import EventBus from '@/utils/EventBus';
import { LOAD_PERMISSIONS_EVENT } from '@/components/permission_service/constants';

export const usePermissionsStore = defineStore('permissionService', {
    state: () => ({
        config: {},
        data: {},
        loading: false,
    }),

    getters: {
        identityId: () => vuexStore.getters['auth/cognitoUserId'],
        context: () => ({
            customerId: vuexStore.getters['auth/customerId'],
            communityId: vuexStore.getters['auth/community']?.id,
        }),
        supportedAppCodes: () => vuexStore.getters['auth/appAccesses'],
        servicePermissions: () => appCode => {
            if (!appCode) {
                return [];
            }

            return this.data.find(item => item.appCode === appCode) || {};
        },

        noContextPermissions: () => appCode => {
            return this.servicePermissions(appCode).noContextPermissions || [];
        },

        customerPermissions: () => (appCode, customerId) => {
            return this.servicePermissions(appCode).customers?.find(item => item.customerId === customerId) || {};
        },

        communityPermissions: () => (appCode, customerId, communityId) => {
            return (
                this.customerPermissions(appCode, customerId)?.communities.find(item => item.communityId === communityId)
                    ?.communityContextPermissions || []
            );
        },
    },

    actions: {
        setConfig(payload) {
            this.config = payload;
        },

        setData(payload) {
            this.data = payload;
        },

        loadPermissions(filters) {
            EventBus.$emit(LOAD_PERMISSIONS_EVENT, filters);
        },
    },
});
