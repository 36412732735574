const DATA_CONSUMING_SYSTEM_ROUTES = {
    DASHBOARD: {
        name: 'sync-service.index',
        path: '/sync-service',
        breadcrumbName: 'Integration Dashboard',
        title: 'Dashboard',
    },
    ADD_SCHEDULE: {
        name: 'sync-service.schedule.add',
        path: '/sync-service/schedule/create',
        breadcrumbName: 'Add new',
        title: 'Add new schedule',
    },
    EDIT_SCHEDULE: {
        name: 'sync-service.edit',
        path: '/sync-service/schedule/:scheduleId',
        breadcrumbName: 'Edit schedule',
        title: 'Edit schedule',
    },
    VIEW_TASK: {
        name: 'sync-service.details',
        path: '/sync-service/task/:jobTransactionId',
        breadcrumbName: 'Task Details',
        title: 'Task Details',
    },
    VIEW_JOB: {
        name: 'sync-service.job',
        path: '/sync-service/job/:requestId',
        breadcrumbName: 'Jobs',
        title: 'Jobs',
        props: true,
    },
    VIEW_JOB_BY_STATUS: {
        name: 'sync-service.status.job',
        path: '/sync-service/job/status/:status',
        breadcrumbName: 'Jobs',
        title: 'Jobs',
        props: true,
    },
    VIEW_EVENT: {
        name: 'sync-service.event',
        path: '/sync-service/event/:jobTransactionId?',
        breadcrumbName: 'Events',
        title: 'Events',
    },
    VIEW_MESSAGE: {
        name: 'sync-service.message',
        path: '/sync-service/message/:jobTransactionId?/:messageId?',
        breadcrumbName: 'Messages',
        title: 'Messages',
    },
    VIEW_SCHEDULES: {
        name: 'sync-service.schedules',
        path: '/sync-service/schedules',
        breadcrumbName: 'Schedules',
        title: 'Schedules',
    },
    VIEW_AUDIT: {
        name: 'sync-service.audit',
        path: '/sync-service/audit',
        breadcrumbName: 'Audit',
        title: 'Audit',
    },
};

export default DATA_CONSUMING_SYSTEM_ROUTES;
