<template>
  <div v-if="loading">
    <div class="circle" />
    <div class="circle circle-2" />
    <div class="circle" />
  </div>
</template>

<script>
export default {
  props: {
    loading: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<style scoped>
@keyframes beat {
  50% {
    transform: scale(0.75);
    opacity: 0.2;
  }

  100% {
    transform: scale(1);
    opacity: 1;
  }
}

.circle {
  @apply bg-active-700;
  display: inline-block;
  width: 15px;
  height: 15px;
  margin: 2px;
  border-radius: 100%;
  animation: beat 0.7s 0s infinite linear;
  animation-fill-mode: both;
}

.circle-2 {
  animation: beat 0.7s 0.35s infinite linear;
}
</style>
