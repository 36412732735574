const routes = [
    {
        name: 'guest-cards.index',
        path: '/guest-cards',
        component: () => import(/* webpackChunkName: "guestCards" */ '@/views/guest_cards/Index.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'guest cards',
            title: 'guest cards',
            flowStartsWith: 'guest-cards.index',
            product: 'guest-cards',
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
    {
        name: 'guest-cards.create',
        path: '/guest-cards/create',
        component: () => import(/* webpackChunkName: "guestCards" */ '@/views/guest_cards/Create.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'new guest card',
            title: 'manage guest card',
            flowStartsWith: 'guest-cards.index',
            product: 'guest-cards',
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
    {
        name: 'guest-cards.details',
        path: '/guest-cards/:guestCardId',
        component: () => import(/* webpackChunkName: "guestCards" */ '@/views/guest_cards/Details.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'details',
            title: 'view guest card',
            flowStartsWith: 'guest-cards.index',
            product: 'guest-cards',
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
    {
        name: 'guest-cards.details.convert-to-applicant',
        path: '/guest-cards/:guestCardId/convert-to-applicant',
        component: () => import(/* webpackChunkName: "guestCards" */ '@/views/guest_cards/ConvertToApplicantForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'convert to applicant',
            title: 'convert guest card to applicant',
            flowStartsWith: 'guest-cards.index',
            product: 'guest-cards',
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
    {
        name: 'guest-cards.settings',
        path: '/guest-cards/settings',
        component: () => import(/* webpackChunkName: "guestCards" */ '@/views/guest_cards/Settings.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'guest Cards',
            title: 'guest card settings',
            flowStartsWith: 'guest-cards.index',
            product: 'guest-cards',
            requiresCustomer: true,
            requiresCommunity: true,
        },
    },
];

export default routes;
