import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    /**
     * All customer files (images, pdf, etc...)
     */
    library: [],

    /*
     An array of manager object
     {
        managerId: uuid,
        communityId: uuid,
        maxNbOfFiles: int,
        library: [],
        libraryDisabled: bool,
        selectedFiles [],
        uploader: {
            mainDirectory: string,
            copyToDirectory: []
            accepts: string,
            maxUploadSize: int,
            cropperOptions: {},
            uploadedFiles: [],
     },
    */
    managers: [],

    /*
     * Available image mime types
     * source from: https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types
     */
    imageMimeTypes: ['image/png', 'image/jpeg', 'image/gif', 'image/webp', 'image/bmp'],

    /*
     * Image cropper default options
     */
    cropperOptions: {},

    /*
     * UI media gallery options
     */
    uiMediaGalleryOptions: {
        communityId: null,
        base_path: 'marketing',
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
