export default {
    light_switches: {
        getList: ['GET', '/devices/light-switches'],
        create: ['POST', '/devices/light-switches/install'],
        getDefaultConfiguration: ['GET', '/devices/light-switches/configuration/default'],
        updateDefaultConfiguration: ['PUT', '/devices/light-switches/configuration/default/update'],
        getConfiguration: ['GET', '/devices/light-switches/configuration/{lightSwitchId}'],
        updateConfiguration: ['POST', '/devices/light-switches/configuration/{lightSwitchId}'],
        getSchema: ['GET', '/devices/light-switches/configuration/schema'],
        getOne: ['GET', '/devices/light-switches/{id}'],
        update: ['PUT', '/devices/light-switches/{id}'],
        delete: ['DELETE', '/devices/light-switches/{id}'],
        forceDelete: ['DELETE', '/devices/light-switches/force/{id}'],
        installRetry: ['POST', '/devices/light-switches/install/retry'],
        uninstallRetry: ['POST', '/devices/light-switches/uninstall/retry'],
    },

    device_connection_logs: {
        getList: ['GET', '/devices/{id}/connection-logs'],
        sendScan: ['POST', '/devices/{id}/connection-logs/send-scan'],
        checkStatus: ['POST', '/devices/{id}/connection-logs/check-status'],
    },
};
