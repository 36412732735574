import { createPermissionChecker, prepareIotRoutes } from '../helper';

export default prepareIotRoutes([
    {
        name: 'iot.keys.create',
        path: '/iot/keys/create',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/keys/CreateKeys.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'add keys',
            title: 'add keys',
            flowStartsWith: 'iot.keys.create',
            permissionChecker: createPermissionChecker('LOCK_CREATE_KEY_CREDENTIALS'),
        },
    },
    {
        name: 'iot.keys.details',
        path: '/iot/keys/:keyId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/keys/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'key details',
            title: 'key details',
            flowStartsWith: 'iot.keys.index',
            permissionChecker: createPermissionChecker('LOCK_READ_KEY_CREDENTIALS'),
        },
    },
    {
        name: 'iot.keys.import',
        path: '/iot/keys/import',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/keys/ImportKeysFromCSV.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'import keys from csv',
            title: 'import keys from csv',
            flowStartsWith: 'iot.keys.import',
            permissionChecker: createPermissionChecker('LOCK_IMPORT_KEY_CREDENTIALS'),
        },
    },
    {
        name: 'iot.keys.assign',
        path: '/iot/keys/assign/:keyId?',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/keys/AssignKeys.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'assign keys',
            title: 'assign keys',
            flowStartsWith: 'iot.keys.assign',
            permissionChecker: createPermissionChecker('LOCK_ISSUE_KEY_CREDENTIALS'),
        },
    },
    {
        name: 'iot.keys.delete',
        path: '/iot/keys/delete/:keyId?',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/keys/DeleteKeys.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'delete keys',
            title: 'delete keys',
            flowStartsWith: 'iot.keys.delete',
            permissionChecker: createPermissionChecker('LOCK_DELETE_KEY_CREDENTIALS'),
        },
    },
    {
        name: 'iot.keys.change-locations',
        path: '/iot/keys/change-locations/:keyId?',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/access/keys/ChangeKeyLocations.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'change locations',
            title: 'change locations',
            flowStartsWith: 'iot.keys.change-locations',
            permissionChecker: createPermissionChecker('LOCK_CREATE_KEY_CREDENTIALS'),
        },
    },
]);
