const ignoredProdVariables = ['VUE_APP_ENV_NAME', 'VUE_APP_PROXY_PRMSN_API_URL', 'VUE_APP_PROXY_TARGET_APP_URL'];

const getEnvVariable = ev => {
    if (!process.env[ev] && !ignoredProdVariables.includes(ev)) {
        console.error(`Please add ${ev} to .env file`);
    }

    return process.env[ev];
};

export default getEnvVariable;
