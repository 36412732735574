<template>
    <div class="modal-wrapper">
        <div class="fixed w-screen left-0 bg-modal-50 h-overlay top-overlay" />
        <transition name="move-in">
            <div
                v-show="animatingTransition && direction === 'in'"
                class="top-modal fake-modal-back fixed z-50 h-modal margin-auto"
                :class="modalClassList"
            >
                <div class="fake-bread" />
                <div class="fake-card" />
            </div>
        </transition>

        <back-modal
            v-show="showBack && !$route.meta.nonStacked"
            :size="size"
        />

        <!-- Fake Modal for transitioning to new card -->
        <transition name="move-out">
            <div
                v-show="animatingTransition && direction === 'out'"
                class="top-modal fake-modal fixed z-50 h-modal margin-auto"
                :class="modalClassList"
            >
                <div class="fake-bread" />
                <div class="fake-card" />
            </div>
        </transition>

        <transition :name="'up-in-' + direction">
            <div
                v-show="!animatingTransition && showMain"
                id="modal-container"
                class="fixed z-20 margin-auto overflow-visible h-modal top-modal flex flex-col"
                :class="modalClassList"
            >
                <modal-nav @close="closeModal" />

                <div
                    id="modal-body"
                    class="p-8 relative overflow-hidden w-full flex-1 relative bg-white"
                >
                    <div
                        id="modal-body-container"
                        class="modal-body-container w-full h-full relative overflow-auto"
                    >
                        <slot />
                    </div>
                    <modal-confirmation />
                    <modal-download />
                    <modal-file-manager />
                    <modal-colors-selector />
                    <modal-drawer />
                </div>
                <modal-actions
                    v-if="hasActions"
                    direction="right"
                    :route-name="currentRouteName"
                />
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { useFullPageStore } from '@/stores/navigation';
import EventBus from '@/utils/EventBus';
import BackModal from '@/components/ui/modals/BackModal';
import ModalNav from '@/components/ui/modals/ModalNav';
import ModalActions from '@/components/ui/modals/ModalActions';
import ModalDownload from '@/components/ui/modals/ModalDownload';
import ModalConfirmation from '@/components/ui/modals/ModalConfirmation';
import ModalFileManager from '@/components/ui/filemanager/ModalFileManager';
import ModalColorsSelector from '@/components/ui/modals/ModalColorsSelector';
import ModalDrawer from '@/components/ui/modals/ModalDrawer.vue';

export default {
    components: {
        ModalDrawer,
        ModalDownload,
        ModalConfirmation,
        BackModal,
        ModalNav,
        ModalActions,
        ModalFileManager,
        ModalColorsSelector,
    },

    props: {
        size: {
            type: String,
            required: false,
            default: 'large',
        },

        hasActions: {
            type: Boolean,
            required: false,
            default: false,
        },

        currentRouteName: {
            type: String,
            required: false,
            default: '',
        },
    },

    setup() {
        const fullPageStore = useFullPageStore();
        return { fullPageStore };
    },

    data() {
        return {
            direction: 'out',
            animatingTransition: false,
            showMain: true,
            showBack: true,
        };
    },

    computed: {
        ...mapGetters({
            backModal: 'modals/getLastModal',
            modals: 'modals/all',
        }),

        modalClassList() {
            const classes = ['w-modal-' + this.size, 'left-modal-' + this.size];

            return classes.join(' ');
        },
    },

    mounted() {
        EventBus.on('navigateForward', ({ from, to }) => {
            if (from.meta?.nonStacked && to.meta?.nonStacked) {
                return;
            }

            this.direction = 'out';
            this.animatingTransition = true;
            this.showMain = false;
            this.showBack = false;

            window.setTimeout(() => {
                this.animatingTransition = false;
            }, 20);
            window.setTimeout(() => {
                this.showMain = true;
            }, 500);
            window.setTimeout(() => {
                this.showBack = true;
            }, 615);
        });

        EventBus.on('navigateBack', ({ from, to }) => {
            if (from.meta?.nonStacked && to.meta?.nonStacked) {
                return;
            }

            this.direction = 'in';
            this.animatingTransition = true;
            this.showMain = false;

            window.setTimeout(() => {
                this.animatingTransition = false;
            }, 100);

            window.setTimeout(() => {
                this.showMain = true;
            }, 600);
        });
    },

    methods: {
        closeModal() {
            EventBus.emit('close-modal-file-manager');
            EventBus.emit('close-modal-colors-selector');

            this.$router.push(
                this.backModal
                    ? { name: this.backModal.routeName, params: this.backModal.params }
                    : { name: this.fullPageStore.lastFullPageRouteName }
            );
        },
    },
};
</script>

<style scoped>
.modal-wrapper {
    --menuHeight: 4.7rem;
    @apply fixed left-0 w-screen;
    top: var(--menuHeight);
    height: calc(100vh - var(--menuHeight));
}

.has-footer {
    padding-bottom: 88px;
}

.top-modal {
    top: 50%;
    transform: translateY(calc(-50% + calc(var(--menuHeight) / 2) - 0.5rem));
}

.top-back-modal {
    top: 50%;
    transform: translateY(calc(-50% + 2.625rem));
}

.fake-modal-back {
    transform: scale(0.9) translate(-113.5%, -49.75%);
    will-change: transform;
}

.fake-modal,
.fake-modal-back {
    transition: 0.4s ease-in;
}

.fake-bread {
    border: 1px solid transparent;
    padding-bottom: 1.35rem;
}

.fake-card {
    background: #fff;
    height: calc(100% - 2.9rem);
}

/*=============================================
    =            ANIMATIONS            =
    =============================================*/

.move-out-leave-active {
    transition: 0.65s cubic-bezier(0.785, 0.135, 0.15, 0.86);
}

.move-out-leave-to {
    transform: scale(0.9) translate(-113.5%, -49.75%);
}

.move-in-leave-active {
    transition: all 0.6s cubic-bezier(0.075, 0.82, 0.165, 1);
}

.move-in-leave-to {
    transform: translate(0%, -45%) scale(1);
}

/*----------  NEW CARD ENTER ANIMATION  ----------*/

.up-in-out-enter-active {
    transition: all 0.75s cubic-bezier(0.34, 1.2, 0.64, 1);
}

.up-in-out-enter-from {
    opacity: 0;
    transform: translateY(2px);
}

/*=====  End of ANIMATIONS  ======*/
</style>
