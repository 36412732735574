import DataProvider from '@/api/DataProvider.js';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '@/store';

const baseURL = getEnvVariable('VUE_APP_GUEST_CARDS_API_URL');

const beforeRequestInterceptor = ({ requestConfig }) => {
    return {
        ...requestConfig,
        headers: {
            ...requestConfig.headers,
            'X-Quext-User-Id': store.getters['auth/cognitoUserId'],
            'X-Quext-Community-Id': store.getters['auth/community'] ? store.getters['auth/community'].id : null,
            'X-Quext-Customer-Id': store.getters['auth/customerId'],
        },
    };
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider(httpAdapter);
