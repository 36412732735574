import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting/routes_names';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';

const actions = [
    {
        routeName: ROUTES.ACCT_SETTINGS_INVESTORS.name,
        items: [
            {
                title: 'add investor',
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_MANAGE_INVESTORS);
                },
                routeName: ROUTES.ACCT_SETTINGS_INVESTORS_ADD_NEW.name,
            },
        ],
    },
];

export default actions;
