const all = state => {
    return state.pages_widgets;
};

const getPagesWidgetsByPageNameAndWebsiteId = state => (pageName, websiteId) => {
    return state.pages_widgets
        .filter(page_widget => page_widget.website_id === websiteId && page_widget.page_name === pageName)
        .sort((a, b) => {
            return a.order_on_page - b.order_on_page;
        });
};

export default {
    all,
    getPagesWidgetsByPageNameAndWebsiteId,
};
