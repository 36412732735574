export default {
    leases: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            type: 'future',
            startDate: '2020-09-18T13:44:13.409266Z',
            endDate: '2020-10-18T13:44:13.409266Z',
            locationId: 'c46752b0-00f8-4d21-b904-4e236be43138',
            source: 'auth',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2021-09-18T13:44:13.409266Z',
        },
    ],
    locations: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            name: 'Test location',
            communityId: '0fc26780-eb88-4275-830d-20fa823ed5da',
            communityName: 'Oak creek',
            customerId: '3f9f5bad-e1ad-4a1e-9f39-20c0015870d4',
            customerName: 'NewCustomer1',
        },
    ],
    occupancies: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            type: 'future',
            customerId: '87d8e330-2878-4742-a86f-dbbb3bf522ac',
            customerName: 'Fake Customer',
            locationId: '1a5515a3-ba81-4a42-aee7-ad9ffc090a54',
            locationName: 'Unit 586',
            communityId: 'b3a7bb5e-2860-4aca-803b-cc380a8c5ca0',
            communityName: 'Fake Community',
            startDate: '2020-09-18T13:44:13.409266Z',
            endDate: '2020-10-18T13:44:13.409266Z',
            moveInDate: '2020-09-18T13:44:13.409266Z',
            moveOutDate: '2020-10-18T13:44:13.409266Z',
            source: 'auth',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2021-09-18T13:44:13.409266Z',
        },
    ],
    persons: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            firstName: 'Test',
            lastName: 'Person',
            source: 'auth',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: null,
        },
    ],
    parties: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            description: 'Party description #1',
            source: 'auth',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: null,
        },
    ],
    profileTemplates: [
        {
            id: 'bf094ee6-7aac-4578-97a2-7616e16b7e20',
            name: 'Leasing Data Block',
            description: 'Leasing related data block',
            keys: [
                {
                    id: 'UUID',
                    key: 'allow_smth',
                    description: 'descr..',
                    specificationId: 'UUID',
                    createdAt: '2020-09-18T22:38:17.506243Z',
                    updatedAt: '2020-09-18T13:44:13.409266Z',
                    deletedAt: '2020-09-19T10:23:49.205118Z',
                },
            ],
            createdAt: '2020-09-18T22:38:17.506243Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: null,
        },
    ],
    profileDataBlocks: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            name: 'location_information',
            description: 'Location information',
            version: 2,
            records: [
                {
                    id: 'UUID',
                    key: 'building_id',
                    value: '48a98fc0-be3f-423f-90f7-814785e7844b',
                    specificationId: 'df380d59-2906-4417-bec3-4ac4567b1960',
                    description: 'Building id',
                    createdAt: '2020-09-18T22:38:17.506243Z',
                    updatedAt: '2020-09-18T13:44:13.409266Z',
                    deletedAt: '2020-09-19T10:23:49.205118Z',
                },
                {
                    id: 'UUID2',
                    key: 'smth_id',
                    value: 'oxoxoxo',
                    specificationId: 'df380d59-2906-4417-bec3-4ac4567b1960',
                    description: 'Something else',
                    createdAt: '2020-09-18T22:38:17.506243Z',
                    updatedAt: '2020-09-18T13:44:13.409266Z',
                    deletedAt: '2020-09-19T10:23:49.205118Z',
                },
            ],
            createdAt: '2020-09-18T22:38:17.506243Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: null,
        },
    ],
    specifications: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            name: 'boolean',
            isNullable: true,
            allowedValues: 'string',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2021-09-18T13:44:13.409266Z',
        },
        {
            id: 'df380d59-2906-4417-bec3-4ac4567b1960',
            name: 'string',
            isNullable: false,
            allowedValues: 'string',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2021-09-18T13:44:13.409266Z',
        },
    ],
    dataKeys: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f07',
            key: 'lease_status',
            description: 'lease status',
            specificationId: 'df380d59-2906-4417-bec3-4ac4567b1960',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2021-09-18T13:44:13.409266Z',
        },
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f09',
            key: 'access_to_portal',
            description: 'access to resident portal',
            specificationId: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2021-09-18T13:44:13.409266Z',
        },
    ],
    sharedDataBlocks: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            name: 'location_information',
            description: 'Location information',
            version: 2,
            records: [
                {
                    id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
                    key: 'building_id',
                    value: '48a98fc0-be3f-423f-90f7-814785e7844b',
                    description: 'Building id',
                    specificationId: 'df380d59-2906-4417-bec3-4ac4567b1960',
                    createdAt: '2020-09-18T13:44:13.409266Z',
                    updatedAt: '2020-09-18T13:44:13.409266Z',
                    deletedAt: '2020-09-18T13:44:13.409266Z',
                },
            ],
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2020-09-18T13:44:13.409266Z',
        },
    ],
    sharedDataBlockVersioned: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            name: 'location_information',
            description: 'Location information',
            version: 2,
            records: [
                {
                    id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
                    key: 'building_id',
                    value: '48a98fc0-be3f-423f-90f7-814785e7844b',
                    description: 'Building id',
                    specificationId: 'df380d59-2906-4417-bec3-4ac4567b1960',
                    createdAt: '2020-09-18T13:44:13.409266Z',
                    updatedAt: '2020-09-18T13:44:13.409266Z',
                    deletedAt: '2020-09-18T13:44:13.409266Z',
                },
            ],
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2020-09-18T13:44:13.409266Z',
        },
    ],
    appDataBlocks: [
        {
            id: '497f6eca-6276-4993-bfeb-53cbbbba6f08',
            appCode: 'IOT',
            relationshipType: 'resident',
            value: '{"someKey": "someValue"}',
            version: 1,
            createdAt: '2020-09-18T13:44:13.409266Z',
            updatedAt: '2020-09-18T13:44:13.409266Z',
            deletedAt: '2021-09-18T13:44:13.409266Z',
        },
    ],
};
