const actions = [
    {
        routeName: 'pages.show',
        items: [
            {
                title: 'Add Module',
                routeName: 'modules.index',
                params: { websiteId: '{websiteId}', pageSlug: '{pageSlug}' },
            },
            {
                title: 'Deploy Changes',
                routeName: 'deploys.create',
                params: { id: '{websiteId}', pageSlug: '{pageSlug}' },
            },
            {
                title: 'Edit Page',
                routeName: 'pages.edit',
                params: { websiteId: '{websiteId}', pageSlug: '{pageSlug}' },
            },
            {
                id: 'cms-delete-page',
                title: 'Delete Page',
                showConfirmation: true,
                confirmationMessage: 'Are you sure you want to delete this page?',
                confirmBtnText: 'yes, delete',
                confirmationType: 'warning',
            },
        ],
    },
];

export default actions;
