<template>
    <div class="relative" :class="{'flex items-center': flexHeader }">
        <span :class="disabled ? 'cursor-default' : 'cursor-pointer'" @click="toggleMenu">
            <slot name="header">Menu</slot>
        </span>
        <PopoverTeleport
            v-if="isOpen"
            :position-x="direction"
            :placement-x="placement"
            @close="handleClickOutside"
        >
            <nav
                class="drop-menu"
                :class="{'p-4': !removePadding, 'rounded': rounded}"
            >
                <slot name="default" :close="close" />
            </nav>
        </PopoverTeleport>
    </div>
</template>

<script>
import PopoverTeleport from '@/components/ui/PopoverTeleport.vue';

export default {
    components: { PopoverTeleport },

    props: {
        direction: {
            type: String,
            required: false,
            default: 'left'
        },
        placement: {
            type: String,
            default: 'outside'
        },
        overflow: {
            type: String,
            default: 'auto',
            required: false
        },
        minWidth: {
            type: String,
            required: false,
            default: '13.85rem'
        },
        closeOnOutsideClick: {
            type: Boolean,
            required: false,
            default: true
        },
        disabled: {
            type: Boolean,
            default: false,
        },
        flexHeader: {
            type: Boolean,
            required: false,
            default: false,
        },
        removePadding: {
            type: Boolean,
            required: false,
            default: false,
        },
        rounded: {
            type: Boolean,
            default: true
        }
    },

    data: () => {
        return {
            isOpen: false,
        }
    },

    methods: {
        toggleMenu(e) {
            this.isOpen = !this.disabled && !this.isOpen;
            e.stopPropagation();
        },

        close() {
            this.isOpen = false;
        },

        handleClickOutside() {
            if (this.closeOnOutsideClick) {
                this.close();
            }
        },
    },
};
</script>

<style scoped>
.drop-menu {
    box-shadow: 0 0.5rem 1rem rgba(222, 224, 228, 0.5);
    max-height: 60vh;
    overflow-y: auto;
    @apply bg-white border;
}
</style>
