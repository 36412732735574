import actions from './actions';
import getters from './getters';
import mutations from './mutations';

const state = {
    attachments: [],
    params: {
        maxItems: 0,
        ownerRoute: null,
        ownerService: null,
        urlFetcher: null,
    },
};

export default {
    namespaced: true,
    state,
    actions,
    getters,
    mutations,
};
