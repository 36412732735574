const gallery = state => state.gallery;

const attachments = state => state.attachments;

const attachmentParams = state => state.params;

const attachingPossible = state => !state.params.maxItems || state.attachments.length < state.params.maxItems;

const tooManyAttachments = state => !!state.params.maxItems && state.attachments.length > state.params.maxItems;

export default {
    gallery,
    attachments,
    attachmentParams,
    attachingPossible,
    tooManyAttachments,
};
