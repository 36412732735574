export default {
    computed: {
        hasNonDefaultValues() {
            for (const category of this.categories) {
                if (!this.isCategoryDefault(category)) {
                    return true;
                }
            }

            return false;
        },
    },

    methods: {
        getDefaultCategory(code) {
            return this.defaultCategories.find(category => code === category.code);
        },

        getDefaultStatus(statusCode, categoryCode) {
            return this.getDefaultCategory(categoryCode).statuses.find(({ code }) => code === statusCode);
        },

        isCategoryDefault(category) {
            for (const status of category.statuses) {
                if (!this.isStatusDefault(status, category.code)) return false;
            }

            return true;
        },

        isStatusDefault(status, categoryCode) {
            const statusDefaults = this.getDefaultStatus(status.code, categoryCode);

            if (status.name !== statusDefaults.name || status.description !== statusDefaults.description) {
                return false;
            }

            if (status.labels.length !== statusDefaults.labels.length) {
                return false;
            }

            for (const label of status.labels) {
                const labelDefaults = statusDefaults.labels.find(({ id }) => id === label.id);

                if (!labelDefaults || label.name !== labelDefaults.name || label.description !== labelDefaults.description) {
                    return false;
                }
            }

            return true;
        },
    },
};
