import ROUTES from '@/utils/constants/integrations_partners_v2/index.js';
import IPSV2_PERMISSIONS from '@/utils/constants/integrations_partners_v2/permissions.js';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners.js';

const createPermissionChecker = permissionId => () => {
    const hasPermissions = useIntegrationPartnersStore().hasPermission;
    return hasPermissions(permissionId);
};

const routes = [
    {
        name: ROUTES.MANAGE_CUSTOMER_USER_COMMUNITY.name,
        path: ROUTES.MANAGE_CUSTOMER_USER_COMMUNITY.path,
        component: () => {
            return import('@/views/integration_partner/home/CustomerUserCommunity.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'manage customer community',
            title: 'communities',
            size: 'large',
            product: 'integrations_partners',
            requiresCustomer: true,
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.VIEW_COMMUNITY),
        },
    },
    {
        name: ROUTES.ADD_PURPOSE.name,
        path: ROUTES.ADD_PURPOSE.path,
        component: () => {
            return import('@/views/integration_partner/purpose/PurposeForm.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'Create purpose',
            size: 'large',
            flowStartsWith: ROUTES.MANAGE_PURPOSE.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PURPOSE),
        },
    },
    {
        path: ROUTES.ADD_PARTNER.path,
        name: ROUTES.ADD_PARTNER.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/PartnerDetails.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'add new',
            flowStartsWith: ROUTES.MANAGE_PARTNER.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARTNER),
        },
    },
    {
        path: ROUTES.EDIT_PARTNER.path,
        name: ROUTES.EDIT_PARTNER.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/PartnerDetails.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'manage partner',
            size: 'large',
            product: 'integrations_partners',
            hideFromSearch: true,
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARTNER),
        },
    },
    {
        path: ROUTES.PARTNER_UPDATE.path,
        name: ROUTES.PARTNER_UPDATE.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/PartnerFormData.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'edit partner',
            flowStartsWith: ROUTES.MANAGE_PARTNER.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.UPDATE_PARTNER),
        },
    },
    {
        path: ROUTES.ADD_CATEGORY.path,
        name: ROUTES.ADD_CATEGORY.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/purpose_category/CategoryForm.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            flowStartsWith: ROUTES.MANAGE_CATEGORY.name,
            breadcrumbName: 'add new',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PURPOSE),
        },
    },
    {
        path: ROUTES.ADD_PARAMETER.path,
        name: ROUTES.ADD_PARAMETER.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/parameters/ParameterForm.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            flowStartsWith: ROUTES.MANAGE_PARAMETER.name,
            breadcrumbName: 'add new',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARAMETER),
        },
    },
    {
        path: ROUTES.MANAGE_PARTNER.path,
        name: ROUTES.MANAGE_PARTNER.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/ManagePartners.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            flowStartsWith: ROUTES.MANAGE_PARTNER.name,
            breadcrumbName: 'partner',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARTNER),
        },
    },
    {
        path: ROUTES.MANAGE_PURPOSE.path,
        name: ROUTES.MANAGE_PURPOSE.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/purpose/ManagePurpose.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            flowStartsWith: ROUTES.MANAGE_PURPOSE.name,
            breadcrumbName: 'purpose',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PURPOSE),
        },
    },
    {
        path: ROUTES.MANAGE_PARAMETER.path,
        name: ROUTES.MANAGE_PARAMETER.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/parameters/ManageParameters.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'parameter',
            flowStartsWith: ROUTES.MANAGE_PARAMETER.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARAMETER),
        },
    },
    {
        path: ROUTES.MANAGE_CATEGORY.path,
        name: ROUTES.MANAGE_CATEGORY.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/purpose_category/ManageCategory.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'purpose category',
            flowStartsWith: ROUTES.MANAGE_CATEGORY.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PURPOSE),
        },
    },
    {
        path: ROUTES.MANAGE_CREDENTIAL.path,
        name: ROUTES.MANAGE_CREDENTIAL.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/credential/ManageCredentials.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'credential',
            flowStartsWith: ROUTES.MANAGE_CREDENTIAL.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARTNER),
        },
    },
    {
        path: ROUTES.PARTNER_EDIT.path,
        name: ROUTES.PARTNER_EDIT.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/PartnerEditDetails.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'manage partner',
            flowStartsWith: ROUTES.PARTNER_EDIT.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.UPDATE_PARTNER),
        },
    },
    {
        path: ROUTES.PARTNER_ENDPOINT_ADD.path,
        name: ROUTES.PARTNER_ENDPOINT_ADD.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/endpoints/PartnerEndpointForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'add endpoint',
            flowStartsWith: ROUTES.PARTNER_EDIT.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARTNER),
        },
    },
    {
        path: ROUTES.PARTNER_ENDPOINT_EDIT.path,
        name: ROUTES.PARTNER_ENDPOINT_EDIT.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/endpoints/PartnerEndpointForm.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'edit endpoint',
            flowStartsWith: ROUTES.PARTNER_EDIT.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.UPDATE_PARTNER),
        },
    },
    {
        path: ROUTES.PARTNER_WIZARD_FORM.path,
        name: ROUTES.PARTNER_WIZARD_FORM.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/partners/PartnerWizardForm.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'partner wizard form',
            flowStartsWith: ROUTES.MANAGE_PARTNER.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARTNER),
        },
    },
    {
        path: ROUTES.LIST_PARAMETER.path,
        name: ROUTES.LIST_PARAMETER.name,
        component: () =>
            import(/* webpackChunkName: "ips" */ '@/views/integration_partner/manage_partner_community_parameter/Parameter.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'list parameter',
            flowStartsWith: ROUTES.PARTNER_EDIT.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.VIEW_PARAMETER_VALUES),
        },
    },
    {
        path: ROUTES.ASSIGN_PARAMETER.path,
        name: ROUTES.ASSIGN_PARAMETER.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/parameters/ParameterForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'add parameter',
            flowStartsWith: ROUTES.PARTNER_EDIT.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARAMETER_VALUES),
        },
    },
    {
        path: ROUTES.ADD_PARAMETER_SEPARATE.path,
        name: ROUTES.ADD_PARAMETER_SEPARATE.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/parameters/ParameterForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'add parameter',
            flowStartsWith: ROUTES.PARTNER_EDIT.name,
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARAMETER_VALUES),
        },
    },
    {
        path: ROUTES.COMMUNITY_DETAILS.path,
        name: ROUTES.COMMUNITY_DETAILS.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/community/CommunityDetails.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'community details',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.VIEW_COMMUNITY),
        },
    },
    {
        path: ROUTES.PARAMETER_CATEGORY.path,
        name: ROUTES.PARAMETER_CATEGORY.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/parameter_category/ParameterCategory.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'parameter category',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARAMETER),
        },
    },
    {
        path: ROUTES.ADD_PARAMETER_CATEGORY.path,
        name: ROUTES.ADD_PARAMETER_CATEGORY.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/parameter_category/AddParameterCategory.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'add parameter category',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARAMETER),
        },
    },
    {
        path: ROUTES.ADD_COMMUNITY_PARAMETER.path,
        name: ROUTES.ADD_COMMUNITY_PARAMETER.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/parameters/ParameterForm.vue'),
        meta: {
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'add parameter',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.CREATE_PARAMETER_VALUES),
        },
    },
    {
        path: ROUTES.MANAGE_MODULES.path,
        name: ROUTES.MANAGE_MODULES.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/modules/ManageModules.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'manage modules',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.ACCESS_CONTROL_LIST),
        },
    },
    {
        path: ROUTES.MODULE_ENDPOINT.path,
        name: ROUTES.MODULE_ENDPOINT.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/modules/ModuleEndpoint.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'module endpoint',
            product: 'integration_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.ACCESS_CONTROL_LIST),
        },
    },
    {
        path: ROUTES.MODULE_DETAILS.path,
        name: ROUTES.MODULE_DETAILS.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/modules/ModuleDetails.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'module details',
            product: 'integration_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.ACCESS_CONTROL_LIST),
        },
    },
    {
        path: ROUTES.CONSUMER_ENDPOINT_FORM.path,
        name: ROUTES.CONSUMER_ENDPOINT_FORM.name,
        component: () => import('@/views/integration_partner/modules/ConsumerEndpointForm.vue'),
        meta: {
            title: 'add consumer endpoint',
            loadInModal: true,
            actions: true,
            size: 'large',
            breadcrumbName: 'consumer module',
            product: 'integration_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.ACCESS_CONTROL_LIST),
        },
    },
    {
        path: ROUTES.IPS_AUDIT_LOG.path,
        name: ROUTES.IPS_AUDIT_LOG.name,
        component: () => import(/* webpackChunkName: "ips" */ '@/views/integration_partner/audit/Audit.vue'),
        meta: {
            title: 'integration partners',
            loadInModal: true,
            size: 'large',
            breadcrumbName: 'audit log',
            product: 'integrations_partners',
            permissionChecker: () => createPermissionChecker(IPSV2_PERMISSIONS.AUDIT_LOG),
        },
    },
];

export default routes;
