const routes = [
    {
        name: 'iot.residents.reset-password',
        path: '/app/resetUserPassword/:token',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/auth/ResetPassword'),
        props: {
            actionType: 'user_reset_password',
        },
        meta: {
            bareUnauthorized: true,
            hideFromSearch: true,
        },
    },
    {
        name: 'iot.residents.set-password',
        path: '/app/setPassword/:token',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/auth/ResetPassword'),
        props: {
            actionType: 'user_set_password',
        },
        meta: {
            bareUnauthorized: true,
            hideFromSearch: true,
        },
    },
    {
        name: 'iot.residents.forgot-password',
        path: '/app/forgotUserPassword/:token',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/auth/ResetPassword'),
        props: {
            actionType: 'user_forgot_password',
        },
        meta: {
            bareUnauthorized: true,
            hideFromSearch: true,
        },
    },
    {
        name: 'iot.residents.change-email',
        path: '/app/changeEmail/:token',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/auth/ChangeEmail'),
        meta: {
            bareUnauthorized: true,
            hideFromSearch: true,
        },
    },
];

export default routes;
