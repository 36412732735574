export default vm => {
    if (!vm.profile.customerId) {
        return Promise.resolve([]);
    }

    return Promise.allSettled([
        vm.$iotDataProvider.get('permissions'),
        vm.$iotDataProvider.getSettings('firekey'),
        vm.$iotDataProvider.readSettings('si_integration'),
    ]).then(([permissionsResult, firekeyResult, siIntegrationResult]) => {
        let permissions = permissionsResult?.value || [];
        const firekey = firekeyResult?.value;
        const siIntegration = siIntegrationResult?.value;

        if (!firekey?.enabled) {
            permissions = permissions.filter(p => `${p.domainObjectName}_${p.domainObjectAction}` !== 'FIRE_KEY_VIEW_FIRE_KEY_TILE');
        }

        if (!siIntegration?.enabled) {
            permissions = permissions.filter(p => `${p.domainObjectName}_${p.domainObjectAction}` !== 'SENSOR_INDUSTRIES_READ');
        }

        return permissions;
    });
};
