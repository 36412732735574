import { getDefaultActions } from './factories';

const ADD_ACTION = (state, payload) => {
    let routeIndex = state.actions.findIndex(action => action.routeName === payload.routeName);

    if (routeIndex === -1) {
        routeIndex = state.actions.push({ routeName: payload.routeName }) - 1;
    }

    const route = state.actions[routeIndex];

    if (!route.items) {
        route.items = [];
    }

    const index = route.items.findIndex(item => {
        if (payload.item.id) {
            return item.id === payload.item.id;
        } else {
            return item.title === payload.item.title;
        }
    });

    if (index !== -1) {
        route.items[index] = payload.item;
    } else {
        route.items.push(payload.item);
    }
};

const REMOVE_ACTION = (state, payload) => {
    const route = state.actions.find(action => action.routeName === payload.routeName);

    if (!route) {
        return;
    }

    route.items = (route.items || []).filter(item => {
        if (payload?.item?.id) {
            return item.id !== payload.item.id;
        } else {
            return item.title !== payload.title;
        }
    });
};

const RESET_ACTIONS = state => {
    state.actions = getDefaultActions();
};

export default {
    ADD_ACTION,
    RESET_ACTIONS,
    REMOVE_ACTION,
};
