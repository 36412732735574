<template>
    <FinalField
        ref="field"
        :name="name"
        :validate="validate"
    >
        <template #default="props">
            <checkbox
                :label="label"
                :value="Boolean(props.value)"
                :disabled="disabled"
                @blur="props.events.blur()"
                @focus="props.events.focus()"
                @change="handleChange"
            />
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import Checkbox from '@/components/ui/Checkbox';

export default {
    name: 'CheckboxInput',
    components: {
        FinalField,
        Checkbox,
    },

    props: {
        name: {
            type: String,
            required: true,
        },

        validate: {
            type: [Function, Array],
            default: () => {},
        },

        value: {
            type: Boolean,
        },

        label: {
            type: String,
            required: false,
            default: '',
        },

        disabled: {
            type: Boolean,
            default: false,
        },
    },

    emits: ['change'],
    methods: {
        handleChange(value) {
            this.$refs.field.fieldState.change(value);
            this.$emit('change', value);
        },
    },
};
</script>
