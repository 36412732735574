export const SET_APPLICATION = 'SET_APPLICATION';
export const SET_APPLICATIONS_LIST = 'SET_APPLICATIONS_LIST';
export const SET_CURRENT_APPLICANT = 'SET_CURRENT_APPLICANT';
export const SET_FUTURE_GUARANTOR = 'SET_FUTURE_GUARANTOR';
export const SET_FUTURE_APPLICANT = 'SET_FUTURE_APPLICANT';
export const SET_APPLICATION_TO_LEASE = 'SET_APPLICATION_TO_LEASE';
export const SET_CLEAR_APPLICATION = 'SET_CLEAR_APPLICATION';

export default {
    [SET_APPLICATION]: (state, payload) => {
        state.application = payload;
    },
    [SET_CURRENT_APPLICANT]: (state, payload) => {
        state.currentApplicant = payload;
    },
    [SET_APPLICATIONS_LIST]: (state, payload) => {
        state.applications = payload;
    },
    [SET_FUTURE_GUARANTOR]: (state, payload) => {
        state.future_guarantor = payload;
    },
    [SET_FUTURE_APPLICANT]: (state, payload) => {
        state.future_applicant = payload;
    },
    [SET_APPLICATION_TO_LEASE]: (state, payload) => {
        state.application_to_lease = payload;
    },
    [SET_CLEAR_APPLICATION]: state => {
        state.application = {};
        state.currentApplicant = {};
        state.future_guarantor = {};
        state.future_applicant = {};
        state.application_to_lease = {};
        state.applications = [];
    },
};
