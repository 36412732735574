import { isProxy } from 'vue';
import { useStore } from 'vuex';

export default function useAccountingQueryManager() {
    const store = useStore();
    const customerId = store?.getters?.['auth/customerId'];
    const communityId = store?.getters?.['auth/community']?.id;

    async function queryHandler(method, endpoint, data, provider, customHeaders) {
        const queryProvider = isProxy(provider) ? provider : this[provider] || this.$accountsDataProvider;
        const customHeadersNotEmpty = customHeaders ? Object.values(customHeaders).every(value => !!value) : false;

        return await queryProvider[method](endpoint, {
            headers: {
                customer_id: customerId,
                customerId,
                community_id: communityId,
                communityId,
                ...(customHeadersNotEmpty && customHeaders),
                ...(provider === '$leasingDataProvider' && {
                    'X-Quext-Customer-Id': customerId,
                    'X-Quext-Community-Id': communityId,
                }),
            },
            ...data,
        });
    }

    return {
        queryHandler,
    };
}
