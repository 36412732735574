import {
    SET_LEASES,
    SET_LEASE,
    SET_DOCUMENT_INSTANCE,
    SET_E_SIGNATURE,
    SET_BLUE_MOON_CREDENTIALS,
    SET_CLEAR_LEASE_STATE,
    SET_IS_KEY_UPDATED,
    SET_LEASE_CREATION_EXECUTED,
    SET_LEASE_SIGNATURE_EXECUTED,
    SET_LEASE_DOWNLOAD_EXECUTED,
    SET_CREDITS_TO_EDIT,
} from './mutations';

const setDocumentInstance = ({ commit }, payload) => commit(SET_DOCUMENT_INSTANCE, payload);
const setLeases = ({ commit }, payload) => commit(SET_LEASES, payload);
const setLease = ({ commit }, payload) => commit(SET_LEASE, payload);
const setESignature = ({ commit }, payload) => commit(SET_E_SIGNATURE, payload);
const setBluemoonCredentials = ({ commit }, payload) => commit(SET_BLUE_MOON_CREDENTIALS, payload);
const clearLeaseState = ({ commit }, payload) => commit(SET_CLEAR_LEASE_STATE, payload);
const setIsKeyUpdated = ({ commit }, payload) => commit(SET_IS_KEY_UPDATED, payload);
const setLeaseCreationExecuted = ({ commit }, payload) => commit(SET_LEASE_CREATION_EXECUTED, payload);
const setLeaseSignatureExecuted = ({ commit }, payload) => commit(SET_LEASE_SIGNATURE_EXECUTED, payload);
const setLeaseDownloadExecuted = ({ commit }, payload) => commit(SET_LEASE_DOWNLOAD_EXECUTED, payload);
const setTransactionsToEdit = ({ commit }, payload) => commit(SET_CREDITS_TO_EDIT, payload);

export default {
    setDocumentInstance,
    setLeases,
    setLease,
    setESignature,
    setBluemoonCredentials,
    clearLeaseState,
    setIsKeyUpdated,
    setLeaseCreationExecuted,
    setLeaseSignatureExecuted,
    setLeaseDownloadExecuted,
    setTransactionsToEdit,
};
