import mitt from 'mitt';

const emitter = mitt();

emitter.once = (type, handler) => {
    const fn = (...args) => {
        emitter.off(type, fn);
        handler(...args);
    };

    emitter.on(type, fn);
};

export default emitter;
