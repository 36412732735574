export const SET_PARTNER_FIELD = 'SET_PARTNER_FIELD';
export const SET_PARTNER = 'SET_PARTNER';

export default {
    [SET_PARTNER_FIELD]: (state, payload) => {
        state.partner_field = payload;
    },
    [SET_PARTNER]: (state, payload) => {
        state.partner = payload;
    },
};
