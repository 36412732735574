const actions = [
    {
        routeName: 'manage_intents.index',
        items: [
            {
                title: 'create intent',
                routeName: 'manage_intents.create-intent',
            },
            {
                title: 'edit intent',
                routeName: 'manage_intents.edit-intent',
            },
            {
                title: 'delete intent',
                routeName: 'manage_intents.delete-intent',
            },
            {
                title: 'training phrases report',
                routeName: 'manage_intents.training-phrases-report',
            },
        ],
    },
];

export default actions;
