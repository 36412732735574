export default {
    apiResult: {
        getSearchApiResult: ['POST', '/search'],
    },
    searchSettingsConfig: {
        update: ['PATCH', '/customer-settings'],
        get: ['GET', '/customer-settings'],
        updateRefreshIndex: ['POST', '/batch-process'],
        updateAll: ['POST', '/batch-process/all'],
    },
    taskList: {
        getList: ['GET', '/search'],
        create: ['POST', '/taskList'],
        update: ['PATCH', '/taskList/{id}'],
        bulk: ['POST', '/upload-task'],
    },
    recentSearch: {
        get: ['GET', '/recent-search'],
        create: ['POST', '/recent-search'],
        delete: ['DELETE', '/recent-search/{id}'],
    },
    serviceList: {
        getAll: ['GET', '/task-service-names'],
    },
};
