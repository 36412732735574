<template>
    <div>
        <ContextSelect
            ref="customerSelector"
            :value="customer"
            :options="options"
            track-by="customerId"
            label="customerName"
            placeholder="select customer"
            :disabled="disabled"
            :no-clear="!profile.customerId"
            @input="updateCustomer"
        />
    </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { useAlertStore } from '@/stores/alert';
import EventBus from '@/utils/EventBus';
import { INIT_CUSTOMERS_FETCH, SIGNED_OUT } from '@/utils/authEvents';
import ContextSelect from '@/components/auth/context_select/ContextSelect';
import { REFRESH_PERMISSIONS_EVENT } from '@/components/permission_service/constants';

const OPTIONS_REFRESH_INTERVAL = 60000;

export default {
    name: 'CustomerContextSelect',
    components: {
        ContextSelect,
    },

    props: {
        disabled: {
            type: Boolean,
            default: false,
        },
    },

    setup() {
        const { notifyError } = useAlertStore();
        return { notifyError };
    },

    data() {
        return {
            customer: null,
            loadedOptions: [],
            optionsPollingIntervalId: undefined,
        };
    },

    computed: {
        ...mapGetters({
            profile: 'auth/profile',
            profiles: 'auth/profiles',
        }),

        quextProfile: function () {
            return this.profiles[0];
        },

        options() {
            return [
                {
                    ...this.profile,
                    customerId: null,
                    customerName: 'Unselect customer',
                    $isDisabled: !this.customer,
                },
                { $isSeparator: true },
            ].concat(this.loadedOptions);
        },
    },

    watch: {
        customer(value) {
            const newProfile = value || this.quextProfile;
            this.setProfile(newProfile);
            if (!value) {
                this.setCustomerApps([]);
            }
            this.$refs.customerSelector.search = null;

            EventBus.emit(REFRESH_PERMISSIONS_EVENT);
        },
    },

    mounted() {
        this.customer = this.profile?.customerId ? this.profile : null;

        this.getOptions().then(() => {
            this.optionsPollingIntervalId = setInterval(this.getOptions, OPTIONS_REFRESH_INTERVAL);
        });

        EventBus.on(INIT_CUSTOMERS_FETCH, () => {
            this.getOptions();
        });

        EventBus.on(SIGNED_OUT, () => {
            if (this.optionsPollingIntervalId) {
                clearInterval(this.optionsPollingIntervalId);
            }
        });
    },

    beforeUnmount() {
        clearInterval(this.optionsPollingIntervalId);
    },

    methods: {
        ...mapActions({
            setProfile: 'auth/setProfile',
            setCustomerApps: 'auth/setCustomerApps',
        }),

        getOptions() {
            return this.$authDataProvider
                .get('customers', { size: 999, nonDeleted: true, sort: 'name,asc' })
                .then(({ content = [] }) => {
                    this.loadedOptions = content.map(context => ({
                        ...this.profile,
                        customerId: context.customerId,
                        customerName: context.name,
                    }));
                })
                .catch(e => {
                    console.error(e);
                });
        },

        updateCustomer(customer) {
            this.customer = customer?.customerId ? customer : null;
        },
    },
};
</script>
