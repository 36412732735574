import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        name: 'superAdmins.index',
        path: '/super-admins',
        component: () => import(/* webpackChunkName: "supAdmin" */ '@/views/auth/superadmins/SuperAdminsList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'super admins',
            title: 'super admins',
            flowStartsWith: 'superAdmins.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
        },
    },
    {
        name: 'superAdmins.create',
        path: '/super-admins/create',
        component: () => import(/* webpackChunkName: "supAdmin" */ '@/views/auth/superadmins/SuperAdminCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new super admin',
            flowStartsWith: 'superAdmins.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_SUPERADMIN),
        },
    },
    {
        name: 'superAdmins.invite.index',
        path: '/super-admins/:profileId/reinvite',
        component: () => import(/* webpackChunkName: "supAdmin" */ '@/views/auth/superadmins/SuperAdminReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
        },
    },
    {
        name: 'superAdmins.edit',
        path: '/super-admins/:id',
        component: () => import(/* webpackChunkName: "supAdmin" */ '@/views/auth/superadmins/SuperAdminEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit super admin',
            flowStartsWith: 'superAdmins.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_SUPERADMIN),
        },
    },
    {
        name: 'superAdmins.deletedAdmins.index',
        path: '/deleted-admins',
        component: () => import(/* webpackChunkName: "supAdmin" */ '@/views/auth/superadmins/DeletedSuperAdminsList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'deleted super admins',
            title: 'deleted super admins',
            flowStartsWith: 'superAdmins.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
        },
    },
    {
        name: 'superAdmins.deletedAdmins.details',
        path: '/deleted-admins/:id/details',
        component: () => import(/* webpackChunkName: "supAdmin" */ '@/views/auth/superadmins/SuperAdminEdit'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'view',
            title: 'view super admin',
            flowStartsWith: 'superAdmins.deletedAdmins.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_SUPERADMIN),
        },
    },
];
