import { SET_RENTAL_INSURANCE_POLICY_ID, SET_START_DATE, SET_END_DATE, CLEAN_STATE, SET_CHARGES, SET_CURRENT_CHARGE } from './mutations';

const setStartDate = ({ commit }, payload) => commit(SET_START_DATE, payload);

const setEndDate = ({ commit }, payload) => commit(SET_END_DATE, payload);

const setRentalInsurancePolicyId = ({ commit }, payload) => commit(SET_RENTAL_INSURANCE_POLICY_ID, payload);

const setCharges = ({ commit }, payload) => commit(SET_CHARGES, payload);

const setCurrentCharge = ({ commit }, payload) => commit(SET_CURRENT_CHARGE, payload);

const cleanState = ({ commit }, payload) => commit(CLEAN_STATE, payload);

export default {
    setStartDate,
    setEndDate,
    setRentalInsurancePolicyId,
    setCharges,
    setCurrentCharge,
    cleanState,
};
