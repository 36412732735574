const loadProperties = ({ commit }, payload) => {
    commit('LOAD_PROPERTIES', payload);
};

const addOrUpdateProperty = ({ commit }, payload) => {
    commit('ADD_OR_UPDATE_PROPERTY', payload);
};

export default {
    loadProperties,
    addOrUpdateProperty,
};
