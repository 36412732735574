import { createPermissionChecker, prepareIotRoutes } from '../helper';
import TabbedRouterModal from '@/components/ui/modals/TabbedRouterModal.vue';

export default prepareIotRoutes([
    {
        name: 'iot.devices',
        path: '/iot/devices',
        component: TabbedRouterModal,
        meta: {
            breadcrumbName: 'devices',
            title: 'devices',
            nonStacked: true,
            permissionChecker: createPermissionChecker(['THERMOSTAT_READ_LIST', 'LOCK_READ_LIST']),
        },
        children: [
            {
                name: 'iot.thermostats.index',
                path: '/iot/thermostats',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/thermostats/Index.vue'),
                meta: {
                    actions: true,
                    flowStartsWith: 'iot.thermostats.index',
                    title: 'thermostats',
                    permissionChecker: createPermissionChecker('THERMOSTAT_READ_LIST'),
                },
            },
            {
                name: 'iot.locks.index',
                path: '/iot/locks',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/Index.vue'),
                meta: {
                    actions: true,
                    title: 'locks',
                    flowStartsWith: 'iot.locks.index',
                    permissionChecker: createPermissionChecker('LOCK_READ_LIST'),
                },
            },
            {
                name: 'iot.locks.lock-management',
                path: '/iot/locks/management',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/LockManagement.vue'),
                meta: {
                    actions: true,
                    title: 'locks management',
                    flowStartsWith: 'iot.locks.lock-management',
                    permissionChecker: createPermissionChecker([
                        'LOCK_INSTALL',
                        'YALE_LOCK_INSTALL',
                        'PDK_DOOR_UPDATE',
                        'BRIVO_DEVICES_CONNECT',
                    ]),
                },
            },
            {
                name: 'iot.leak-sensors.index',
                path: '/iot/leak-sensors',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/leak_sensors/Index.vue'),
                meta: {
                    actions: true,
                    title: 'leak sensors',
                    flowStartsWith: 'iot.leak-sensors.index',
                    permissionChecker: createPermissionChecker('LEAK_SENSOR_READ_LIST'),
                },
            },
            {
                name: 'iot.light-switches.index',
                path: '/iot/light-switches',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/light_switches/Index.vue'),
                meta: {
                    actions: true,
                    title: 'lights',
                    flowStartsWith: 'iot.light-switches.index',
                    permissionChecker: createPermissionChecker('LIGHT_SWITCH_READ_LIST'),
                },
            },
            {
                name: 'iot.pacs-queue-log.index',
                path: '/iot/pacs-queue-log',
                component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/pacs/Index.vue'),
                meta: {
                    title: 'pacs',
                    flowStartsWith: 'iot.pacs-queue-log.index',
                    permissionChecker: createPermissionChecker('LOCK_READ_PACS_QUEUE_LOG_LIST'),
                },
            },
        ],
    },
]);
