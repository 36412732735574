const routes = [
    {
        name: 'conversations_responses.index',
        path: '/conversations-responses',
        component: () => {
            return import('@/views/digital_human/conversations_responses/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            title: 'customize your conversation',
            breadcrumbName: 'manage conversation responses',
            size: 'large',
            flowStartsWith: 'conversations_responses.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'conversations_responses.create-response',
        path: '/create-response',
        component: () => {
            return import('@/views/digital_human/conversations_responses/CreateResponse');
        },
        meta: {
            loadInModal: true,
            actions: true,
            title: 'add custom response',
            breadcrumbName: 'add custom response',
            flowStartsWith: 'conversations_responses.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
    {
        name: 'conversations_responses.create-card',
        path: '/create-card',
        component: () => {
            return import('@/views/digital_human/conversations_responses/CreatePhoto');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'upload photo',
            title: 'upload photo',
            size: 'large',
            flowStartsWith: 'conversations_responses.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
    {
        name: 'conversations_responses.edit-photo',
        path: '/edit-photo/:id',
        component: () => {
            return import('@/views/digital_human/conversations_responses/EditPhoto');
        },
        meta: {
            loadInModal: true,
            breadcrumbName: 'edit photo',
            title: 'edit photo',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            size: 'large',
        },
    },
    {
        name: 'conversations_responses.edit-response',
        path: '/edit-response/:id',
        component: () => {
            return import('@/views/digital_human/conversations_responses/EditResponse');
        },
        meta: {
            loadInModal: true,
            title: 'edit custom response',
            breadcrumbName: 'edit custom response',
            flowStartsWith: 'conversations_responses.index',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
    {
        name: 'conversations_responses.report',
        path: '/conversation-report',
        component: () => {
            return import('@/views/digital_human/conversations_responses/Report');
        },
        meta: {
            loadInModal: true,
            title: 'my conversation report',
            breadcrumbName: 'my conversation report',
            // allowedRoles: ['CUSTOMER_ADMIN', 'SUPER_ADMIN', 'USER'],
            requiredAppAccess: ['MIA'],
            size: 'large',
        },
    },
];

export default routes;
