import { defineStore } from 'pinia';

export const useFiltersStore = defineStore('iotFilters', {
    state: () => ({
        filters: {},
    }),

    getters: {
        getFilters: state => {
            return state.filters;
        },
    },

    actions: {
        setFilters({ key, data }) {
            this.filters[key] = data;
        },

        clearFilters() {
            this.filters = {};
        },
    },
});
