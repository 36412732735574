import { createPermissionChecker, PORTFOLIO_PERMISSIONS, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'customers.create',
        items: [],
    },
    {
        routeName: 'customers.details',
        items: [
            {
                title: 'manage communities',
                routeName: 'customer.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
            {
                title: 'manage users',
                routeName: 'customer.users.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            },
            {
                title: 'manage admins',
                routeName: 'customer.admins.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            },
            {
                title: 'manage roles',
                routeName: 'customer.roles.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            },
            {
                title: 'manage permissions',
                routeName: 'customer.permissions.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_PERMISSIONS),
            },
        ],
    },
    //Deactivated customer
    {
        routeName: 'deletedCustomers.details',
        items: [
            {
                title: 'view communities',
                routeName: 'deletedCustomer.communities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_COMMUNITIES),
            },
            {
                title: 'view users',
                routeName: 'deletedCustomer.users.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_USERS),
            },
            {
                title: 'view admins',
                routeName: 'deletedCustomer.admins.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            },
        ],
    },
    // Communities
    {
        routeName: 'customer.communities.index',
        items: [
            {
                title: 'add new community',
                routeName: 'customer.communities.create',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_COMMUNITY),
            },
            {
                title: 'archived communities',
                routeName: 'customer.deletedCommunities.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_COMMUNITIES),
            },
            {
                title: 'import communities',
                routeName: 'customer.communities.import',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.IMPORT_COMMUNITIES),
            },
        ],
    },
    {
        routeName: 'customer.communities.details',
        items: [],
    },
    // Users
    {
        routeName: 'customer.users.index',
        items: [
            {
                title: 'add user',
                routeName: 'customer.users.create',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_CUSTOMER_USER),
            },
            {
                title: 'deleted users',
                routeName: 'customer.deletedUsers.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_DELETED_CUSTOMER_USERS),
            },
        ],
    },
    {
        routeName: 'customer.users.details',
        items: [],
    },
    {
        routeName: 'customer.users.create',
        items: [],
    },
    {
        routeName: 'customer.deletedUsers.details',
        items: [],
    },
    // Admins
    {
        routeName: 'customer.admins.index',
        items: [
            {
                title: 'add new portfolio admin',
                routeName: 'customer.admins.create',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.ADD_PORTFOLIO_ADMIN),
            },
            {
                title: 'deleted portfolio admins',
                routeName: 'customer.deletedAdmins.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_PORTFOLIO_ADMINS),
            },
        ],
    },
    {
        routeName: 'customer.admins.details',
        items: [],
    },
    {
        routeName: 'customer.admins.create',
        items: [],
    },
    // Roles
    {
        routeName: 'customer.roles.index',
        items: [
            {
                title: 'deleted roles',
                routeName: 'customer.deletedRoles.index',
                params: {
                    customerId: '{customerId}',
                },
                allowed: createPermissionChecker(PORTFOLIO_PERMISSIONS.VIEW_CUSTOMER_ROLES),
            },
        ],
    },
    // Permissions
    {
        routeName: 'customer.permissions.index',
        items: [],
    },
];
