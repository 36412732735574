const getCategories = state => state.categories;

const getIntents = state => state.intents;

const getSubIntents = state => state.subIntents;

const getResponses = state => state.responses;

const photosLibrary = state => state.photos;

const getCategory = state => state.category;

const getIntent = state => state.intent;

const getSubIntent = state => state.subIntent;

const getLoading = state => state.loading;

export default {
    getCategories,
    getIntents,
    getSubIntents,
    getCategory,
    getIntent,
    getSubIntent,
    getLoading,
    getResponses,
    photosLibrary,
};
