import { useAccountingStore } from '@/stores/accounting';
import ROUTES from '@/utils/constants/accounting/routes_names';
import USER_PERMISSIONS from '@/utils/constants/accounting/permissions_aliases';

const actions = [
    {
        routeName: ROUTES.AR_CREDITS.name,
        items: [
            {
                title: 'add credit',
                routeName: ROUTES.AR_CREDITS_ADD_NEW_CREDIT.name,
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
                },
            },
            {
                title: 'view credits by month',
                routeName: ROUTES.AR_CREDITS_STATISTIC.name,
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_VIEW_CHARGE_LIST);
                },
            },
            {
                title: 'add concession',
                routeName: ROUTES.AR_CREDITS_ADD_NEW_CONCESSION.name,
                allowed: () => {
                    const acctStore = useAccountingStore();
                    return acctStore.hasPermission(USER_PERMISSIONS.ACCT_ADD_CHARGES);
                },
            },
        ],
    },
];

export default actions;
