const getContacts = state => {
    return state.contacts;
};

const getContactById = state => {
    return contactId => state.contacts.find(contact => contact.id_website_leads === contactId);
};

export default {
    getContacts,
    getContactById,
};
