export default {
    passes: {
        getList: ['GET', '/temporary/passes'],
        getOne: ['GET', '/temporary/passes/{id}'],
        getSummary: ['GET', '/temporary/passes/{id}/summary'],
        approve: ['POST', '/temporary/passes/{id}/phone/{phoneNumber}/approve'],
        block: ['POST', 'blocked/phones/add'],
        revoke: ['POST', '/temporary/passes/{id}/revoke'],
        vendor_create: ['POST', 'vendor/passes'],
        resident_create: ['POST', 'resident/passes'],
        guest_create: ['POST', 'guest/passes'],
    },

    passes_settings: {
        get: ['GET', '/temporary-pass/settings'],
        getSchema: ['GET', '/temporary-pass/settings/default/schema'],
        updateSettings: ['PUT', '/temporary-pass/settings/update'],
        getBlockedPhones: ['GET', '/blocked/phones'],
        addBlockedPhone: ['POST', '/blocked/phones/add'],
        removeBlockPhone: ['DELETE', '/blocked/phones/remove'],
    },

    pass_logs: {
        getList: ['GET', '/temporary/passes/{id}/logs'],
    },
};
