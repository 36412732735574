import ServiceRequest from '@/components/community_settings/ServiceRequests';
import store from '@/store';
import { SREQ } from '@/utils/ppm/permissions';

export default {
    key: 'sreq',
    title: 'service requests',
    component: ServiceRequest,
    permissionChecker: () => store.getters['sreq/hasPermission'](SREQ.COMMUNITY_SETTINGS_VIEW),
};
