class DataProvider {
    static install;

    constructor(dataAdapter) {
        if (!dataAdapter || typeof dataAdapter !== 'object') {
            throw new Error('You probably forgot to pass dataAdapter into DataProvider constructor');
        }

        if (!('handle' in dataAdapter)) {
            throw new Error('dataAdapter must implement handle method');
        }

        if (typeof dataAdapter.handle !== 'function') {
            throw new Error('dataAdapter must be a function');
        }

        this.adapter = new Proxy(
            {},
            {
                get: (_, action) => (resource, params) => dataAdapter.handle(action, resource, params),
            }
        );
    }

    install(app, { name }) {
        app.config.globalProperties[`$${name}`] = this.adapter;
    }
}

export default DataProvider;
