<template>
    <div class="qr-code-wrapper">
        <div
            class="qr-code"
            :class="{ 'qr-code--visible': qrCodeVisible }"
        >
            <qrcode-vue
                :value="props.qrValue"
                :size="150"
                level="Q"
            />
        </div>
        <div class="qr-toggler">
            <button
                class="btn-primary bg-white"
                :style="{ backgroundColor: '#ffffff' }"
                type="button"
                @click="toggleQrCode"
            >
                {{ qrTogglerText }}
            </button>
        </div>
    </div>
</template>

<script setup>
import { computed, ref } from 'vue';
import QrcodeVue from 'qrcode.vue';

const props = defineProps({
    qrValue: {
        type: String,
        required: true,
    },
});

const qrCodeVisible = ref(false);

const qrTogglerText = computed(() => `${qrCodeVisible.value ? 'hide' : 'show'} qr code`);

const toggleQrCode = () => {
    qrCodeVisible.value = !qrCodeVisible.value;
};
</script>

<style scoped>
.qr-code {
    opacity: 0.2;
}

.qr-code--visible {
    opacity: 1;
}

.qr-toggler {
    @apply absolute top-0 bottom-0 left-0 right-0 flex items-center justify-center;
}

.qr-code--visible + .qr-toggler {
    opacity: 0;
}

.qr-code-wrapper {
    @apply relative;
    margin: 1.5rem auto 0.5rem;
    width: 150px;

    &:hover > .qr-toggler {
        opacity: 1;
    }

    &:hover > .qr-code {
        opacity: 0.2;
    }
}
</style>
