import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        routeName: 'quextUsers.index',
        items: [
            {
                title: 'add new user',
                routeName: 'quextUsers.create',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.ADD_QUEXT_USER),
            },
            {
                title: 'deleted users',
                routeName: 'quextUsers.deletedQuextUsers.index',
                params: {},
                allowed: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_QUEXT_USERS),
            },
        ],
    },
    {
        routeName: 'quextUsers.edit',
        items: [],
    },
];
