const routes = [
    {
        name: 'manage-partner-documents.index',
        path: '/partner-documents',
        component: () => {
            return import('@/views/lease/PartnerDocuments/ManagePartnerDocuments.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'manage partner documents',
            title: 'manage partner documents',
            size: 'large',
            flowStartsWith: 'manage-partner-documents.index',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'partner-field.edit',
        path: '/partner-field',
        component: () => {
            return import('@/views/lease/PartnerDocuments/EditPartnerField.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit partner field',
            title: 'edit partner field',
            size: 'normal',
            flowStartsWith: 'manage-partner-documents.index',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'add-partner-document.create',
        path: '/create-partner-document',
        component: () => {
            return import('@/views/lease/PartnerDocuments/AddNewPartnerDocument.vue');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add partner document',
            title: 'add partner document',
            size: 'normal',
            flowStartsWith: 'manage-partner-documents.index',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'add-partner-field.create',
        path: '/create-partner-field',
        component: () => {
            return import('@/views/lease/PartnerDocuments/AddNewPartnerField.vue');
        },
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'add partner field',
            title: 'add partner field',
            size: 'normal',
            flowStartsWith: 'manage-partner-documents.index',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'add-quext-field.manage',
        path: '/manage-quext-field',
        component: () => {
            return import('@/views/lease/PartnerDocuments/AddNewQuextField.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'manage quext fields',
            title: 'manage quext fields',
            size: 'normal',
            flowStartsWith: 'manage-partner-documents.index',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
    {
        name: 'manage-quext-concept.manage',
        path: '/manage-quext-concept',
        component: () => {
            return import('@/views/lease/PartnerDocuments/ManageQuextConcept.vue');
        },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'manage quext fields',
            title: 'manage quext concepts',
            size: 'normal',
            flowStartsWith: 'manage-partner-documents.index',
            allowedRoles: ['SUPER_ADMIN'],
        },
    },
];

export default routes;
