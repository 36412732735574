import { defineStore } from 'pinia';

export const useCommunitySettingsStore = defineStore('communitySettings', {
    state: () => ({
        notificationsState: {},
    }),

    getters: {},

    actions: {
        setNotificationsState(payload) {
            this.notificationsState = payload;
        },
    },
});
