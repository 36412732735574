import { kebabCase } from 'lodash-es';
import { parsePhoneNumberFromString } from 'libphonenumber-js';

export function slugify(str) {
    if (!str) return null;

    return kebabCase(str);
}

export function resolveUrl(str) {
    if (!str) return null;

    return str
        .toLowerCase()
        .trim()
        .replace(/^(?!(?:\w+:)?\/\/)/, 'http://');
}

// Search for anything that is surrounded by the brackets, and replaces it with the values from data object
// @example: renderTemplate('{name} details', {name: 'madera'}) will return 'madera details'
export function renderTemplate(str, data) {
    return str.replace(/{(.*?)}/g, match => data[match.split(/[{}]/).filter(Boolean)[0]]);
}

export function formatPhone(phone) {
    const number = parsePhoneNumberFromString(phone, 'US') ?? '';
    return number.number;
}

export function formatCurrency(number, withDollarSign = true) {
    if (withDollarSign && number === 0) {
        return '-';
    }

    const result = number.toLocaleString('en-US', {
        style: 'currency',
        currency: 'USD',
    });

    return withDollarSign ? result : result.substring(1);
}

export function formatDeviceId(val) {
    if (!val) return val;

    const r = /([a-f0-9]{2})([a-f0-9]{2})/i;
    let str = val.replace(/[^a-f0-9]/gi, '');

    while (r.test(str)) {
        str = str.replace(r, '$1:$2');
    }

    return str.slice(0);
}

export function replaceAllIgnoreCase(fullStr, replaceStr, newStr) {
    if (!replaceStr || !newStr) {
        return fullStr;
    }

    var esc = replaceStr.replace(/[-/\\^$*+?.()|[\]{}]/g, '\\$&');
    var reg = new RegExp(esc, 'ig');

    return fullStr.replace(reg, newStr);
}

export function getSortQuery(sort = {}) {
    if (!sort?.name) {
        return '';
    }

    return `${sort.name},${sort.dir.toUpperCase()}`;
}

/**
 * Joins an array of strings into a single string with a specified separator.
 *
 * @param {Array} array - The array of strings to be joined.
 * @param {string} separator - The separator to be used between each string element.
 * @returns {string} The joined string or null. If the array is empty or contains no string elements, null will be returned
 * @throws {Error} Will throw an error if the first argument is not an array or the second argument is not a string.
 *
 * @example
 * // returns "apple,banana,orange"
 * joinStringElements(["apple", "banana", "orange"], ",");
 *
 * @example
 * // returns "apple banana orange"
 * joinStringElements(["apple", "banana", "orange"], " ");
 */
export const joinStringElements = (array, separator) => {
    // Validate input types
    if (!Array.isArray(array) || typeof separator !== 'string') {
        throw new Error('Invalid input: First argument must be an array and second argument must be a string.');
    }

    // Filter the array to include only non-null, non-undefined string elements
    const filteredArray = array.filter(item => typeof item === 'string');

    // Join the filtered elements with the provided separator
    return filteredArray.join(separator) || null;
};
