import ROUTES from '@/utils/constants/integrations_partners_v2/index.js';
import { useIntegrationPartnersStore } from '@/stores/integration_partners/integrationPartners.js';
import IPSV2_PERMISSIONS from '@/utils/constants/integrations_partners_v2/permissions.js';

export default [
    {
        routeName: ROUTES.EDIT_PARTNER.name,
        items: [
            {
                title: 'edit partner',
                routeName: ROUTES.PARTNER_UPDATE.name,
                params: {
                    partnerId: '{partnerId}',
                },
                allowed: () => {
                    const ipsStore = useIntegrationPartnersStore();
                    return ipsStore.hasPermission(IPSV2_PERMISSIONS.UPDATE_PARTNER);
                },
            },
        ],
    },
];
