const LOAD_POINTS_OF_INTEREST = (state, payload) => {
    state.pointsOfInterest = payload;
};

const LOAD_POINTS_OF_INTEREST_CATEGORIES = (state, payload) => {
    state.pointsOfInterest = payload;
};

export default {
    LOAD_POINTS_OF_INTEREST,
    LOAD_POINTS_OF_INTEREST_CATEGORIES,
};
