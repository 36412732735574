<template>
  <div
    class="text-input"
    :class="{
      [$attrs.class]: $attrs.class,
      [`text-input--size-${size}`]: size,
      [`text-input--with-leading-icon`]: $slots['leading-icon'],
      [`text-input--with-trailing-icon`]: $slots['trailing-icon'],
      [`text-input--clearable`]: clearable && !readOnly,
      ['text-input--password']: passwordType,
    }"
    :style="$attrs.style"
  >
    <div
      v-if="readOnly"
      :class="textClass"
      v-bind="$attrs"
    >
      {{textValue(value)}}
    </div>

    <textarea
      ref="field"
      v-bind="attrs"
      v-if="!readOnly && multiline"
      v-on="fieldEvents"
      :value="value"
      class="form-input"
      :class="inputClass"
      :data-test="name"
      :disabled="disabled"
      :placeholder="placeholder"
      :maxlength="maxLength"
    />

    <div v-if="!readOnly && !multiline" class="relative">
      <span v-if="!!$slots['leading-icon']" class="text-input__icon">
        <!-- @slot Leading icon block -->
        <slot name="leading-icon"></slot>
      </span>

      <input
        ref="field"
        v-bind="attrs"
        v-on="fieldEvents"
        :value="value"
        :type="inputType"
        class="form-input"
        :class="inputClass"
        :data-test="name"
        :placeholder="placeholder"
        :maxlength="maxLength"
        :disabled="disabled"
      />

      <button
        v-if="clearable && !readOnly && value && !disabled"
        class="absolute flex items-center right-0 top-0 bottom-0 p-2 text-graphite-800 outline-none focus:outline-none"
        type="button"
        @click.stop="onClear"
      >
        <icon class="w-4 h-4" name="closeCircle2"/>
      </button>

      <button
        v-if="passwordType"
        type="button"
        class="absolute right-0 top-0 bottom-0 p-3 outline-none"
        @click="togglePassword"
      >
        <icon v-show="showPassword" name="eyeOff" class="stroke-current eye-icon"/>
        <icon v-show="!showPassword" name="eye" class="fill-current eye-icon"/>
      </button>

      <span v-if="!!$slots['trailing-icon']" class="text-input__icon">
        <!-- @slot Trailing icon block -->
        <slot name="trailing-icon"></slot>
      </span>
    </div>
  </div>
</template>

<script>
  import Icon from "@/components/ui/Icon";
import { omit } from 'lodash-es';

  export default {
    name: 'TextInput',

    inheritAttrs: false,

    components: { Icon },

    props: {
      /**
       *  Input's 'name' attribute
       */
      name: {
        type: String,
      },

      /**
       * Input's 'value' attribute
       */
      value: {
        type: [String, Number],
      },

      /**
       * Defines whether field should be multiline (as `<textarea />`)
       */
      multiline: {
        type: Boolean,
        required: false,
        default: false,
      },

      /**
       * Whether field should be non-editable
       */
      readOnly: {
        type: Boolean,
      },

      /**
       * Additional custom class for `<input />` / `<textarea />` in read mode
       */
      textClass: {
        type: String
      },

      /**
       * Additional custom class for `<input />` / `<textarea />` in write mode
       */
      inputClass: {
        type: [String, Array],
      },

      /**
       * Field's `type` attribute value
       */
      type: {
        type: String,
        default: 'text'
      },

      /**
       * Display clean icon button
       */
      clearable: {
        type: Boolean,
        required: false,
        default: false
      },

      /**
       * Size for the field
       */
      size: {
        type: String,
        default: '',
        validator: function (value) {
          return ['', 'small', 'xsmall'].indexOf(value) !== -1;
        },
      },

        /**
         * Defines the maximum string length that the user can enter into an input
         */
        maxLength: {
            type: Number,
            default: undefined,
        },

        /**
         * Field placeholder
         */
        placeholder: {
            type: String,
            default: '',
        },

        /**
         * Whether field should be disabled
         */
        disabled: {
            type: Boolean,
            default: false,
        },

        /**
         * Allows you to perform additional logic on input change
         */
        onInputEventCb: {
          type: Function,
          default: (value) => value,
        },

        /**
         * Whether value should be auto-trimmed
         */
        trim: {
            type: Boolean,
            default: true,
        },
    },

    emits: ['input', 'blur', 'focus', 'clear', 'click'],

    data() {
      return {
        showPassword: false
      }
    },

    computed: {
      fieldEvents() {
        return {
          input: e => {
            /**
             * Emitted on input
             */
            const value = e.target.value;
            this.$emit("input", this.trim ? value.trim() : value);
            this.onInputEventCb(value, this);
          },
          blur: () => {
            /**
             * Emitted on losing focus
             */
            this.$emit("blur");
          },
          focus: () => {
            /**
             * Emitted on focus
             */
            this.$emit("focus");
          },
          click: (e) => {
            /**
            * Prevent event propagation when clicking inside input
            */
            e.stopPropagation();
            this.$emit("click");
          },
        };
      },

      passwordType() {
        return this.type === 'password';
      },

      inputType() {
        return this.type === 'password' && this.showPassword ? 'text' : this.type;
      },

      attrs(){
        return omit(this.$attrs, ['class', 'style']);
      },
    },

    methods: {
      togglePassword() {
        this.showPassword = !this.showPassword;
      },

      textValue(value) {
        return value ?? '-';
      },

      focus() {
        this.$refs.field.focus();
      },

      onClear() {
        /**
         * Emitted when resetting value
         */
        this.$emit('clear');
      },
    },
  };
</script>

<style scoped>
.text-input {
  @apply w-full;
}

.text-input--size-small .form-input {
    height: 2.25rem;
    @apply px-2;
}

.text-input--size-xsmall .form-input {
    height: 1.625rem;
    @apply px-2;
}

.text-input--clearable .form-input {
  @apply pr-8;
}

.text-input__icon {
  @apply absolute;
  top: 50%;
  transform: translateY(-50%);
}

.text-input--with-leading-icon {
  .form-input {
    @apply pl-10;
  }

  .text-input__icon {
    left: 13px;
  }
}

.text-input--with-trailing-icon {
  .form-input {
    @apply pr-10;
  }

  .text-input__icon {
    right: 13px;
  }
}

.text-input--password {
    .form-input {
        @apply pr-10;
    }
}

.eye-icon {
  @apply w-5 h-5 text-gray-500;
}
</style>
