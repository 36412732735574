const routes = [
    {
        name: 'knowledge_library.index',
        path: '/knowledge-library',
        component: () => {
            return import('@/views/digital_human_ai/knowledge_library/Index');
        },
        redirect: { name: 'knowledge_library.home' },
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'select community settings',
            title: 'document ingestion',
            size: 'normal',
            flowStartsWith: 'digital_human_settings.index',
            // allowedRoles: ['DIGITAL_HUMAN_ADMIN', 'DIGITAL_HUMAN_MANAGER', 'SUPER_ADMIN', 'ADMIN', 'SUPER_READONLY_ADMIN', 'USER'],
            requiredAppAccess: ['DHAI'],
            product: 'dh',
        },
        children: [
            {
                name: 'knowledge_library.home',
                path: '',
                component: () => {
                    return import('@/views/digital_human_ai/knowledge_library/Home');
                },
                meta: {
                    loadInModal: true,
                    actions: false,
                    breadcrumbName: 'document ingestion',
                    title: 'document ingestion',
                    flowStartsWith: 'knowledge_library.home',
                    // allowedRoles: ['DIGITAL_HUMAN_ADMIN', 'DIGITAL_HUMAN_MANAGER', 'SUPER_ADMIN', 'ADMIN', 'SUPER_READONLY_ADMIN', 'USER'],
                    requiredAppAccess: ['DHAI'],
                    product: 'dh',
                },
            },
            {
                name: 'knowledge_library.list',
                path: 'list',
                component: () => {
                    return import('@/views/digital_human_ai/knowledge_library/UploadDocumentsList');
                },
                meta: {
                    loadInModal: true,
                    actions: false,
                    modalCustomClass: 'modal-no-padding',
                    breadcrumbName: 'document ingestion > upload documents',
                    title: 'upload documents',
                    flowStartsWith: 'knowledge_library.list',
                    // allowedRoles: ['DIGITAL_HUMAN_ADMIN', 'DIGITAL_HUMAN_MANAGER', 'SUPER_ADMIN', 'ADMIN', 'SUPER_READONLY_ADMIN', 'USER'],
                    requiredAppAccess: ['DHAI'],
                    product: 'dh',
                },
            },
            {
                name: 'knowledge_library.progress',
                path: 'progress',
                component: () => {
                    return import('@/views/digital_human_ai/knowledge_library/UploadDocumentsProgress');
                },
                meta: {
                    loadInModal: true,
                    modalCustomClass: 'modal-no-padding',
                    actions: false,
                    breadcrumbName: 'document ingestion > upload documents > process documents',
                    title: 'process documents',
                    flowStartsWith: 'knowledge_library.progress',
                    // allowedRoles: ['DIGITAL_HUMAN_ADMIN', 'DIGITAL_HUMAN_MANAGER', 'SUPER_ADMIN', 'ADMIN', 'SUPER_READONLY_ADMIN', 'USER'],
                    requiredAppAccess: ['DHAI'],
                    product: 'dh',
                },
            },
            {
                name: 'knowledge_library.analyze',
                path: 'analyze',
                component: () => {
                    return import('@/views/digital_human_ai/knowledge_library/UploadDocumentsAnalyze');
                },
                meta: {
                    loadInModal: true,
                    modalCustomClass: 'modal-no-padding',
                    actions: false,
                    breadcrumbName: 'document ingestion > upload documents > process documents > commuinity document association',
                    title: 'commuinity document association',
                    flowStartsWith: 'knowledge_library.complete',
                    // allowedRoles: ['DIGITAL_HUMAN_ADMIN', 'DIGITAL_HUMAN_MANAGER', 'SUPER_ADMIN', 'ADMIN', 'SUPER_READONLY_ADMIN', 'USER'],
                    requiredAppAccess: ['DHAI'],
                    product: 'dh',
                },
            },
            {
                name: 'knowledge_library.approval',
                path: 'approval',
                component: () => {
                    return import('@/views/digital_human_ai/knowledge_library/DocumentsApproval');
                },
                meta: {
                    loadInModal: true,
                    actions: false,
                    breadcrumbName: 'document ingestion',
                    title: 'document ingestion',
                    flowStartsWith: 'knowledge_library.approval',
                    // allowedRoles: ['DIGITAL_HUMAN_ADMIN', 'DIGITAL_HUMAN_MANAGER', 'SUPER_ADMIN', 'ADMIN', 'SUPER_READONLY_ADMIN', 'USER'],
                    requiredAppAccess: ['DHAI'],
                    product: 'dh',
                },
            },
        ],
    },
];

export default routes;
