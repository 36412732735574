import DataProvider from '@/api/DataProvider';
import HttpAdapter from '@/api/adapters/HttpAdapter';
import resources from './resources';
import httpClient from '@/api/httpClient';
import getEnvVariable from '@/utils/getEnvVariable';
import store from '@/store';

const baseURL = getEnvVariable('VUE_APP_SREQ_API_URL');
const beforeRequestInterceptor = ({ requestConfig }) => {
    const resultingConfig = {
        ...requestConfig,
        headers: {
            ...requestConfig.headers,
            'X-Quext-Customer-Id': store.getters['auth/customerId'],
        },
    };
    if (requestConfig.url !== '/my/profile') {
        resultingConfig.headers['X-Quext-Community-Id'] = store.getters['auth/community']?.id;
    }
    if (requestConfig.url === '/print/sreq') {
        resultingConfig.responseType = 'arraybuffer';
    }
    return resultingConfig;
};

const httpAdapter = new HttpAdapter(httpClient(baseURL), resources, beforeRequestInterceptor);

export default new DataProvider(httpAdapter);
