<template>
    <div class="flex flex-col">
        <div class="tabs-wrapper">
            <div
                v-for="(tab, index) in tabs"
                :key="index"
                class="tab-head"
                :class="{
                    active: tab.isActive,
                    disabled: tab.disabled,
                }"
                @click="handleClick(index)"
            >
                {{ tab.title }}
            </div>
        </div>

        <div class="flex-grow overflow-auto">
            <slot />
        </div>
    </div>
</template>

<script>
export default {
    name: 'Tabs',
    props: {
        onChangeTab: {
            type: Function,
            required: false,
            default: null,
        },

        initialTab: {
            type: Number,
            default: 0,
        },
    },

    emits: ['activeIndexChange'],
    data() {
        return {
            tabs: [],
        };
    },

    mounted() {
        this.tabs = this.$children;
        this.openTab(this.initialTab);
    },

    methods: {
        handleClick(index) {
            this.onChangeTab ? this.onChangeTab(index) : this.openTab(index);
        },

        openTab(index) {
            if (!this.tabs[index] || this.tabs[index]?.disabled) return;

            this.$emit('activeIndexChange', index);

            this.tabs.forEach(tab => (tab.isActive = false));
            this.tabs[index].isActive = true;
        },
    },
};
</script>

<style scoped>
.tabs-wrapper {
    @apply relative inline-flex w-full;
    &:after {
        content: '';
        @apply absolute bottom-0 left-0 w-full bg-graphite-400;
        height: 2px;
        border-radius: 1px;
    }
}

.tab-head {
    @apply relative z-10 mr-8 pb-1 font-frank font-medium text-sm leading-5 text-active-400 cursor-pointer;
    &:last-child {
        @apply mr-0;
    }
    &:after {
        content: '';
        @apply absolute bottom-0 left-0 w-full bg-graphite-400;
        height: 2px;
        border-radius: 1px;
    }
    &:hover:not(&.disabled) {
        @apply text-active-600;
        &:after {
            @apply bg-active-600;
        }
    }
    &.active {
        @apply text-active-500;
        &:after {
            @apply bg-active-500;
        }
    }
    &.disabled {
        @apply text-graphite-700 cursor-not-allowed;
    }
}
</style>
