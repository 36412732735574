<template>
    <div v-show="authorizedRoute">
        <AmplifyAuthenticator
            :login-mechanisms="['username']"
            hide-sign-up
        >
            <template #header>
                <div class="mb-10">
                    <Icon
                        name="logo"
                        class="h-12"
                    />
                </div>
            </template>

            <template #sign-in-header>
                <div class="auth-heading">
                    sign in to your account
                </div>
            </template>
            <template #sign-in-form>
                <SignIn />
            </template>

            <template #confirm-sign-in>
                <div class="auth-heading">
                    confirm TOTP code
                </div>
                <ConfirmSignIn />
            </template>

            <template #forgot-password>
                <div class="auth-heading">
                    forgot your password?
                </div>
                <div class="mb-6 text-center">
                    Enter your email or phone number below and we will send a message to reset your password
                </div>
                <ForgotPassword />
            </template>

            <template #confirm-reset-password>
                <div class="auth-heading">
                    reset your password
                </div>
                <ConfirmResetPassword />
            </template>

            <template #force-new-password>
                <div class="auth-heading">
                    new password required
                </div>
                <ForceNewPassword />
            </template>
        </AmplifyAuthenticator>
    </div>
    <template v-if="auth.route === 'authenticated'">
        <SetupMFA
            v-if="mfaSetupRequired"
            :on-submit="submitMfaSetup"
        />
        <template v-else-if="isUserSignedIn">
            <template v-if="authorizedRoute">
                <slot :logout="logout" />
            </template>
            <template v-else>
                <slot name="unauthenticated" />
            </template>
        </template>
        <Loader :loading="checkingNextStep" />
    </template>
    <template v-if="auth.authStatus === 'unauthenticated' && !authorizedRoute">
        <slot name="unauthenticated" />
    </template>
    <template v-if="auth.authStatus === 'authenticated' && auth.route !== 'authenticated'">
        <Loader loading />
    </template>
</template>

<script setup>
import { Authenticator as AmplifyAuthenticator, useAuthenticator } from '@aws-amplify/ui-vue';
import '@aws-amplify/ui-vue/styles.css';
import { signOut as AuthSignOut, getCurrentUser, fetchAuthSession, fetchUserAttributes, fetchMFAPreference } from 'aws-amplify/auth';
import { Hub } from 'aws-amplify/utils';
import { computed, onBeforeUnmount, onMounted, ref } from 'vue';
import EventBus from '@/utils/EventBus';
import { INIT_PROFILE_UPDATE, SIGNED_IN, SIGNED_OUT } from '@/utils/authEvents';
import Icon from '@/components/ui/Icon.vue';
import Loader from '@/components/ui/Loader.vue';
import {
    ConfirmResetPassword,
    ConfirmSignIn,
    ForceNewPassword,
    ForgotPassword,
    SetupMFA,
    SignIn,
} from '@/components/auth/custom_auth_flow';
import { useAuthDevices } from '@/components/auth/useAuthDevices';
import { useProfilePolling } from '@/components/auth/useProfilePolling';
import { useCustomerAccessPolling } from '@/components/auth/useCustomerAccessPolling';
import { usePendo } from '@/components/auth/usePendo';
import store from '@/store';
import { useAlertStore } from '@/stores/alert';
import { useRoute } from 'vue-router';

const route = useRoute();
const authorizedRoute = computed(() => !route.meta.bareUnauthorized);

const { notifyError } = useAlertStore();

const auth = useAuthenticator();

const profileData = useProfilePolling();
const customerAccess = useCustomerAccessPolling();
const pendo = usePendo();
const { processUserDevice } = useAuthDevices();

let hubListenerCancelToken = null;

const mfaSetupRequired = ref(false);
const checkingNextStep = ref(true);

const isUserSignedIn = computed(() => store.getters['auth/authenticated']);

const logout = async () => {
    try {
        checkingNextStep.value = true;

        await AuthSignOut();
    } catch (error) {
        notifyError('error signing out: ', error);
    }
};

const handleUserSignedIn = () => {
    checkingNextStep.value = false;

    if (profileData.pollingRequired.value) {
        profileData.startPolling();
    }

    if (customerAccess.pollingRequired.value) {
        customerAccess.startPolling();
    }

    // Initialize Pendo session
    pendo.initializeVisitor();
};

const handleUserSignedOut = () => {
    customerAccess.stopPolling();
    profileData.stopPolling();
};

const completeSignIn = async () => {
    try {
        const user = await getCurrentUser();
        const userAttributes = await fetchUserAttributes();
        const data = {
            ...user,
            ...userAttributes,
        };

        await store.dispatch('auth/signIn', data);

        handleUserSignedIn();

        await processUserDevice();

        EventBus.emit(SIGNED_IN);
    } catch (error) {
        console.error(error);
        await logout();
    }
};

const submitMfaSetup = async () => {
    checkingNextStep.value = true;
    mfaSetupRequired.value = false;

    await completeSignIn();
};

onMounted(async () => {
    hubListenerCancelToken = Hub.listen('auth', async ({ payload: { event } }) => {
        switch (event) {
            case 'signedIn':
                // eslint-disable-next-line no-case-declarations
                const profile = await profileData.pullProfile();
                // eslint-disable-next-line no-case-declarations
                const preferredMfa = await fetchMFAPreference();

                if (profile.mfaSetupRequired && !preferredMfa.preferred) {
                    mfaSetupRequired.value = true;
                    checkingNextStep.value = false;
                } else {
                    await completeSignIn();
                }
                break;
            case 'signedOut':
                // after AwsSignOut this event fired twice with
                // authState='authenticated' route='authenticated' and authState='unauthenticated' route='signOut'
                if (auth.authStatus === 'authenticated') {
                    await store.dispatch('auth/signOut');

                    handleUserSignedOut();

                    EventBus.emit(SIGNED_OUT);
                }
                break;
        }
    });

    EventBus.on(INIT_PROFILE_UPDATE, () => {
        profileData.pullProfile();
    });

    if (isUserSignedIn.value) {
        await profileData.pullProfile();
        handleUserSignedIn();
    } else {
        try {
            // the sign-in process failed or was interrupted.
            // Sign out the user automatically if aws authentication was successfull.
            const { userSub } = await fetchAuthSession();

            if (userSub) {
                await logout();
            }
        } catch (e) {
            console.error(e);
        }
    }
});

onBeforeUnmount(() => {
    hubListenerCancelToken();
    handleUserSignedOut();
    EventBus.off(INIT_PROFILE_UPDATE);
});
</script>

<style>
[data-amplify-authenticator] {
    --amplify-colors-primary-100: theme(colors.active.500);
    --amplify-primary-color: theme(colors.active.500);
    --amplify-secondary-tint: theme(colors.active.500);
    --amplify-primary-tint: theme(colors.active.700);
    --amplify-primary-shade: theme(colors.active.800);
    --amplify-components-button-link-focus-box-shadow: none;

    min-height: 100vh;
}

[data-amplify-authenticator] [data-amplify-container] {
    @apply flex items-center flex-col px-8 py-16 bg-white;
    min-height: 30vh;
}

[data-amplify-authenticator] [data-amplify-router] {
    background: transparent;
    border: 0;
    box-shadow: none;
    width: 100%;
}

@media (min-width: 768px) {
    [data-amplify-authenticator] {
        @apply bg-gray-100;
    }

    [data-amplify-authenticator] [data-amplify-container] {
        @apply w-1/2 px-16;
    }
}

@media (min-width: 1024px) {
    [data-amplify-authenticator] [data-amplify-container] {
        @apply w-1/3;
        min-width: 400px;
    }
}

.amplify-button {
    @apply font-frank font-normal text-2sm lowercase cursor-pointer select-none;

    &:focus {
        box-shadow: none;
        background: inherit;
    }
}

.amplify-button[data-variation='primary'] {
    @apply border rounded-sm whitespace-no-wrap h-10 px-6;
    @apply font-medium tracking-wide;
    @apply bg-active-500 border-active-500 text-white;

    &:hover {
        @apply bg-active-600 border-active-600 text-white;
    }
}

.amplify-button[data-variation='link'] {
    @apply text-active-500;
    border: 0;
    box-shadow: none;
    outline: 0 !important;

    &:hover {
        background: none;
        @apply text-active-600 underline;
    }
}
</style>
