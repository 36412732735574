export const SET_LIST_FILTER_VALUES = 'SET_LIST_FILTER_VALUES';
export const SET_TMP_BUFFER = 'SET_TMP_BUFFER';

export default {
    [SET_LIST_FILTER_VALUES]: (state, payload) => {
        state.list.filterValues = payload;
    },
    [SET_TMP_BUFFER]: (state, payload) => {
        state.tmpBuffer = payload;
    },
};
