const loadLibrary = ({ commit }, payload) => {
    commit('LOAD_LIBRARY', payload);
};

const removeFileFromLibrary = ({ commit }, payload) => {
    commit('REMOVE_FILE_FROM_LIBRARY', payload);
};

const clearLibrary = ({ commit }) => {
    commit('CLEAR_LIBRARY');
};

const addFilesToLibrary = ({ commit }, payload) => {
    commit('ADD_FILES_TO_LIBRARY', payload);
};

const createManager = ({ commit }, payload) => {
    commit('CREATE_MANAGER', payload);
};

const removeManagerById = ({ commit }, managerId) => {
    commit('REMOVE_MANAGER_BY_ID', managerId);
};

const updateManagerCommunityId = ({ commit }, payload) => {
    commit('UPDATE_MANAGER_COMMUNITY_ID', payload);
};

const clearAllManagers = ({ commit }) => {
    commit('CLEAR_ALL_MANAGERS');
};

const addFilesToManagerUploadedFiles = ({ commit }, payload) => {
    commit('ADD_FILES_TO_MANAGER_UPLOADED_FILES', payload);
};

const removeFileFromManagerUploadedFiles = ({ commit }, payload) => {
    commit('REMOVE_FILE_FROM_MANAGER_UPLOADED_FILES', payload);
};

const clearManagerUploadedFiles = ({ commit }, managerId) => {
    commit('CLEAR_MANAGER_UPLOADED_FILES', managerId);
};

const updateManagerUploaderMainDirectory = ({ commit }, payload) => {
    commit('UPDATE_MANAGER_UPLOADER_MAIN_DIRECTORY', payload);
};

const addFilesToManagerSelectedFiles = ({ commit }, payload) => {
    commit('ADD_FILES_TO_MANAGER_SELECTED_FILES', payload);
};

const removeFileFromManagerSelectedFiles = ({ commit }, payload) => {
    commit('REMOVE_FILE_FROM_MANAGER_SELECTED_FILES', payload);
};

const setManagerSelectedFiles = ({ commit }, payload) => {
    commit('SET_MANAGER_SELECTED_FILES', payload);
};

const addFilesToManagerLibrary = ({ commit }, payload) => {
    commit('ADD_FILES_TO_MANAGER_LIBRARY', payload);
};

const updateUiMediaGalleryOption = ({ commit }, payload) => {
    commit('UPDATE_UI_MEDIA_GALLERY_OPTION', payload);
};

const updateFileTags = ({ commit }, payload) => {
    commit('UPDATE_FILE_TAGS', payload);
};

export default {
    // user global library
    loadLibrary,
    addFilesToLibrary,
    removeFileFromLibrary,
    clearLibrary,

    // manager
    createManager,
    removeManagerById,
    clearAllManagers,
    updateManagerCommunityId,

    // manager selected files
    addFilesToManagerSelectedFiles,
    setManagerSelectedFiles,
    removeFileFromManagerSelectedFiles,

    // manager uploader
    addFilesToManagerUploadedFiles,
    removeFileFromManagerUploadedFiles,
    clearManagerUploadedFiles,
    updateManagerUploaderMainDirectory,

    // manager library
    addFilesToManagerLibrary,

    // Ui media gallery option
    updateUiMediaGalleryOption,

    // single file
    updateFileTags,
};
