export default {
    topics: '/topics',
    attachments: '/attachments',
    filterTags: {
        search: ['GET', '/filter-tags/search'],
        calculateStat: ['POST', '/filter-tags/calculate-stat'],
        convertToDetails: ['POST', '/filter-tags/convert-to-details'],
    },
    quextUsers: '/quext-users',
    permissions: '/my/profile',
};
