<script>
import { computed, ref, watch } from 'vue';
import {
    onClickOutside,
    templateRef,
    unrefElement,
    useElementBounding,
    useElementSize,
    useElementVisibility,
    useParentElement,
    useWindowSize,
} from '@vueuse/core';

export const PopoverUnifiedPosition = {
    AUTO: 'auto',
    START: 'start',
    END: 'end',
    MIDDLE: 'middle',
};

export const PopoverHorizontalPosition = {
    AUTO: 'auto',
    LEFT: 'left',
    RIGHT: 'right',
    MIDDLE: 'middle',
};

export const PopoverVerticalPosition = {
    AUTO: 'auto',
    BOTTOM: 'bottom',
    TOP: 'top',
    MIDDLE: 'middle',
};

export const PopoverPlacement = {
    INSIDE: 'inside',
    OUTSIDE: 'outside',
};

export default {
    props: {
        /**
         * The element to which the popover should be anchored.
         *
         * If not defined, the target will be the parent element of the PopoverTeleport's initial mounting point.
         */
        target: {
            type: Element,
            required: false,
            default: null,
        },

        /**
         * Position of the popover along the X-axis.
         *
         * `left` - the popover should be positioned to the left border of the target element.
         * `right` - the popover should be positioned to the right border of the target element.
         * `auto` - the position (`left` or `right`) is chosed by the popover size and target position.
         * `middle` - the popover is positioned at the middle of the target along the X-axis.
         */
        positionX: {
            type: String,
            default: PopoverHorizontalPosition.AUTO,
            validator(value) {
                return Object.values(PopoverHorizontalPosition).includes(value);
            },
        },

        /**
         * Position of the popover along the Y-axis.
         *
         * `top` - the popover should be positioned to the top border of the target element.
         * `bottom` - the popover should be positioned to the bottom border of the target element.
         * `auto` - the position (`top` or `bottom`) is chosed by the popover size and target position.
         * `middle` - the popover is positioned at the middle of the target along the Y-axis.
         */
        positionY: {
            type: String,
            default: PopoverVerticalPosition.AUTO,
            validator(value) {
                return Object.values(PopoverVerticalPosition).includes(value);
            },
        },

        /**
         * Whether the popover should be placed to the `inside` or `outside` direction of the target element
         * relative to its left and right borders. Not used if `position-x="middle"`.
         *
         * `inside` - the popover should be placed inside the borders.
         * `outside` - the popover should be placed outside the borders.
         */
        placementX: {
            type: String,
            default: PopoverPlacement.INSIDE,
            validator(value) {
                return Object.values(PopoverPlacement).includes(value);
            },
        },

        /**
         * Whether the popover should be placed to the `inside` or `outside` direction of the target element
         * relative to its top and bottom borders. Not used if `position-x="middle"`.
         *
         * `inside` - the popover should be placed inside the borders.
         * `outside` - the popover should be placed outside the borders.
         */
        placementY: {
            type: String,
            default: PopoverPlacement.OUTSIDE,
            validator(value) {
                return Object.values(PopoverPlacement).includes(value);
            },
        },
    },

    emits: [
        /**
         * Emitted when the popover becomes non-visible due to scrolling out of view or clicking outside of it.
         */
        'close',

        /**
         * Emitted when the pointer is moved onto the popover.
         */
        'mouseenter',

        /**
         * Emitted when the pointer has moved out of the popover.
         */
        'mouseleave',
    ],

    setup(props, { emit }) {
        const handleMouseEnter = event => emit('mouseenter', event);

        const handleMouseLeave = event => emit('mouseleave', event);

        const getUnifiedPosition = position => {
            switch (position) {
                case PopoverHorizontalPosition.LEFT:
                case PopoverVerticalPosition.TOP:
                    return PopoverUnifiedPosition.START;

                case PopoverHorizontalPosition.RIGHT:
                case PopoverVerticalPosition.BOTTOM:
                    return PopoverUnifiedPosition.END;

                default:
                    return position;
            }
        };

        const getOffset = ({ position, placement, windowSize, popoverSize, targetStartValue, targetEndValue }) => {
            let unifiedPosition = getUnifiedPosition(position);

            if (unifiedPosition === PopoverUnifiedPosition.MIDDLE) {
                return (targetStartValue + targetEndValue - popoverSize) / 2;
            }

            const startPosition = placement === PopoverPlacement.INSIDE ? PopoverUnifiedPosition.START : PopoverUnifiedPosition.END;
            const endPosition = placement === PopoverPlacement.INSIDE ? PopoverUnifiedPosition.END : PopoverUnifiedPosition.START;

            const targetStart = placement === PopoverPlacement.INSIDE ? targetStartValue : targetEndValue;
            const targetEnd = placement === PopoverPlacement.INSIDE ? targetEndValue : targetStartValue;

            if (unifiedPosition === PopoverUnifiedPosition.AUTO) {
                unifiedPosition = windowSize >= targetStart + popoverSize ? startPosition : endPosition;
            }

            switch (unifiedPosition) {
                case startPosition:
                    return targetStart;

                case endPosition:
                    return targetEnd - popoverSize;
            }
        };

        const parentElement = useParentElement();

        const target = computed(() => unrefElement(props.target || parentElement));

        watch(useElementVisibility(target), value => {
            if (!value) {
                emit('close');
            }
        });

        const popover = templateRef('popover');

        onClickOutside(popover, () => {
            emit('close');
        });

        const { width: popoverWidth, height: popoverHeight } = useElementSize(popover);

        const { top: targetTop, bottom: targetBottom, left: targetLeft, right: targetRight } = useElementBounding(target);

        const { width: windowWidth, height: windowHeight } = useWindowSize();

        const rawStyle = computed(() => {
            const offsetX = getOffset({
                position: props.positionX,
                placement: props.placementX,
                windowSize: windowWidth.value,
                popoverSize: popoverWidth.value,
                targetStartValue: targetLeft.value,
                targetEndValue: targetRight.value,
            });

            const offsetY = getOffset({
                position: props.positionY,
                placement: props.placementY,
                windowSize: windowHeight.value,
                popoverSize: popoverHeight.value,
                targetStartValue: targetTop.value,
                targetEndValue: targetBottom.value,
            });

            return {
                left: `${Math.ceil(offsetX)}px`,
                top: `${Math.ceil(offsetY)}px`,
            };
        });

        const style = ref({ opacity: 0 });

        watch(rawStyle, value => {
            setTimeout(() => {
                style.value = value;
            });
        });

        return {
            target,
            style,
            handleMouseEnter,
            handleMouseLeave,
        };
    },
};

</script>

<template>
    <teleport v-if="target" to="body">
        <div
            ref="popover"
            class="popover"
            :style="style"
            @mouseenter="handleMouseEnter"
            @mouseleave="handleMouseLeave"
        >
            <slot />
        </div>
    </teleport>
</template>

<style scoped>
.popover {
    position: fixed;
    z-index: 1000;
}
</style>
