export const EMAILBOT_PERMISSIONS = {
    VIEW_EMAILBOT: 'VEB',
    EDIT_EMAILBOT: 'EEB',
    CREATE_EMAILBOT: 'CEB',
    VIEW_COMMUNITY_TEMPLATE: 'VCT',
    EDIT_COMMUNITY_TEMPLATE: 'ECT',
    CREATE_COMMUNITY_TEMPLATE: 'CCT',
    VIEW_CATEGORY: 'VC',
    CREATE_CATEGORY: 'CC',
    EDIT_CATEGORY: 'EC',
    PUBLISH_TEMPLATE: 'PT',
};
