const PUSH_MODAL = (state, modal) => {
    state.modals.push(modal);
};

const POP_ALL_MODALS = state => {
    state.modals = [];
};

const REMOVE_MODAL_BY_ROUTE_NAME = (state, routeName) => {
    state.modals = state.modals.filter(modal => modal.routeName !== routeName);
};

const SET_ACTIVE_MODAL_TITLE = (state, title) => {
    state.activeModalTitle = title;
};

const SET_ACTIVE_MODAL_BREADCRUMB_NAME = (state, name) => {
    state.activeBreadcrumbName = name;
};

const REFRESH_MODALS = (state, routeName) => {
    const routeIndex = state.modals.findIndex(modal => modal.routeName === routeName);
    if (routeIndex > -1) {
        state.modals = state.modals.slice(0, routeIndex);
    }
};

const SET_SAVED_POSITION = (state, payload) => {
    state.savedPosition = payload;
};

export default {
    PUSH_MODAL,
    POP_ALL_MODALS,
    REMOVE_MODAL_BY_ROUTE_NAME,
    SET_ACTIVE_MODAL_TITLE,
    SET_ACTIVE_MODAL_BREADCRUMB_NAME,
    REFRESH_MODALS,
    SET_SAVED_POSITION,
};
