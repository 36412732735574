const LOAD_WEBSITES = (state, payload) => {
    state.websites = payload;
};

const ADD_WEBSITE = (state, payload) => {
    state.websites.push(payload);
    state.websites.sort((a, b) => (a.production_url > b.production_url ? 1 : -1));
};

const UPDATE_WEBSITE = (state, payload) => {
    const website = state.websites.find(website => website.id === payload.id);
    Object.assign(website, payload);
};

const UPDATE_WEBSITE_TEMPLATE = (state, payload) => {
    const website = state.websites.find(website => website.id === payload.websites_id);
    website.template = {
        is_custom: false,
        name: payload.templates_name,
        website_id: payload.websites_id,
    };
};

export default {
    LOAD_WEBSITES,
    ADD_WEBSITE,
    UPDATE_WEBSITE,
    UPDATE_WEBSITE_TEMPLATE,
};
