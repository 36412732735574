export const APP_SYNC_STARTED = 'APP_SYNC_STARTED';
export const APP_SYNC_STOPPED = 'APP_SYNC_STOPPED';

export default {
    [APP_SYNC_STARTED]: (state, payload) => {
        state.appSyncTimeoutIntervalIds[payload.appId] = payload.timeoutId;
    },
    [APP_SYNC_STOPPED]: (state, payload) => {
        delete state[payload];
    },
};
