import { orderBy } from 'lodash-es';

const LOAD_LIBRARY = (state, payload) => {
    state.library = payload;
};

const REMOVE_FILE_FROM_LIBRARY = (state, payload) => {
    state.library = state.library.filter(file => {
        return file.file_uuid !== payload.file.file_uuid;
    });
};

const CLEAR_LIBRARY = state => {
    state.library = [];
};

const ADD_FILES_TO_LIBRARY = (state, payload) => {
    payload.forEach(file => {
        state.library.push(file);
    });
    state.library = orderBy(state.library, ['created_at'], ['desc']);
};

const CREATE_MANAGER = (state, payload) => {
    state.managers.push(payload);
};

const REMOVE_MANAGER_BY_ID = (state, managerId) => {
    state.managers = state.managers.filter(manager => {
        return manager.managerId !== managerId;
    });
};

const CLEAR_ALL_MANAGERS = state => {
    state.managers = [];
};

const UPDATE_MANAGER_COMMUNITY_ID = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    if (manager) manager.communityId = payload.communityId;
};

const ADD_FILES_TO_MANAGER_UPLOADED_FILES = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    payload.files.forEach(file => {
        manager.uploader.uploadedFiles.push(file);
    });
};

const REMOVE_FILE_FROM_MANAGER_UPLOADED_FILES = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    manager.uploader.uploadedFiles = manager.uploader.uploadedFiles.filter(file => {
        return file.file_uuid !== payload.file.file_uuid;
    });
};

const ADD_FILES_TO_MANAGER_SELECTED_FILES = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    payload.files.forEach(file => {
        manager.selectedFiles.push(file);
    });
};

const SET_MANAGER_SELECTED_FILES = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    manager.selectedFiles = payload.files;
};

const CLEAR_MANAGER_UPLOADED_FILES = (state, managerId) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === managerId;
    });

    manager.uploader.uploadedFiles = [];
};

const ADD_FILES_TO_MANAGER_LIBRARY = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    payload.files.forEach(file => {
        manager.library.push(file);
    });
};

const REMOVE_FILE_FROM_MANAGER_SELECTED_FILES = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    manager.selectedFiles = manager.selectedFiles.filter(file => {
        return file.file_uuid !== payload.file.file_uuid;
    });
};

const UPDATE_MANAGER_UPLOADER_MAIN_DIRECTORY = (state, payload) => {
    const manager = state.managers.find(manager => {
        return manager.managerId === payload.managerId;
    });

    if (manager) manager.uploader.mainDirectory = payload.mainDirectory;
};

const UPDATE_UI_MEDIA_GALLERY_OPTION = (state, payload) => {
    state.uiMediaGalleryOptions[payload.key] = payload.value;
};

const UPDATE_FILE_TAGS = (state, payload) => {
    const file = state.library.find(file => {
        return payload.file_uuid === file.file_uuid;
    });

    if (file) file.tags = payload.tags;
};

export default {
    // user global library
    LOAD_LIBRARY,
    ADD_FILES_TO_LIBRARY,
    REMOVE_FILE_FROM_LIBRARY,
    CLEAR_LIBRARY,

    // manager
    CREATE_MANAGER,
    REMOVE_MANAGER_BY_ID,
    CLEAR_ALL_MANAGERS,
    UPDATE_MANAGER_COMMUNITY_ID,

    // manager selected files
    ADD_FILES_TO_MANAGER_SELECTED_FILES,
    SET_MANAGER_SELECTED_FILES,
    REMOVE_FILE_FROM_MANAGER_SELECTED_FILES,

    // manager uploader
    ADD_FILES_TO_MANAGER_UPLOADED_FILES,
    REMOVE_FILE_FROM_MANAGER_UPLOADED_FILES,
    CLEAR_MANAGER_UPLOADED_FILES,
    UPDATE_MANAGER_UPLOADER_MAIN_DIRECTORY,

    // manager library
    ADD_FILES_TO_MANAGER_LIBRARY,

    // Ui media gallery option
    UPDATE_UI_MEDIA_GALLERY_OPTION,

    // single file
    UPDATE_FILE_TAGS,
};
