import { createPermissionChecker, QUEXT_PERMISSIONS } from '@/components/auth/permissions';

export default [
    {
        name: 'quextUsers.index',
        path: '/quext-users',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUsersList'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'quext users',
            title: 'quext users',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_QUEXT_USERS),
        },
    },
    {
        name: 'quextUsers.details',
        path: '/quext-users/:id/details',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUserDetails'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'view',
            title: 'view quext user',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_QUEXT_USER_DETAILS),
        },
    },
    {
        name: 'quextUsers.edit.permissions',
        path: '/quext-users/:id/permissions',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUserCustomPermissions'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'customize permissions',
            title: 'custom permissions',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_QUEXT_USER),
        },
    },
    {
        name: 'quextUsers.create',
        path: '/quext-users/create',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUsersCreate'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'add new',
            title: 'add new quext user',
            flowStartsWith: 'quextUsers.create',
            product: 'qxt-admin',
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_QUEXT_USER),
        },
    },
    {
        name: 'quextUsers.create.permissions',
        path: '/quext-users/permissions',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUserCustomPermissions'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'customize permissions',
            title: 'custom permissions',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.ADD_QUEXT_USER),
        },
    },
    {
        path: '/quext-users/:profileId/reinvite',
        name: 'quextUsers.invite.index',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUserReinviteView'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 're-invite',
            title: 'resend invitation',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.REINVITE_QUEXT_USER),
        },
    },
    {
        name: 'quextUsers.edit',
        path: '/quext-users/:id',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUsersEdit'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'edit',
            title: 'edit quext user',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.EDIT_QUEXT_USER),
        },
    },
    {
        path: '/deleted-quext-users',
        name: 'quextUsers.deletedQuextUsers.index',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/DeletedQuextUsersList'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'deleted quext users',
            title: 'deleted quext users',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_QUEXT_USERS),
        },
    },
    {
        path: '/deleted-quext-users/:id/details',
        name: 'quextUsers.deletedQuextUsers.details',
        component: () => import(/* webpackChunkName: "qxtuser" */ '@/views/auth/quextUsers/QuextUserDetails'),
        meta: {
            loadInModal: true,
            actions: false,
            breadcrumbName: 'view',
            title: 'view quext user',
            flowStartsWith: 'quextUsers.index',
            product: 'qxt-admin',
            hideFromSearch: true,
            permissionChecker: createPermissionChecker(QUEXT_PERMISSIONS.VIEW_DELETED_QUEXT_USERS),
        },
    },
];
