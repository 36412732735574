const routes = [
    {
        name: 'manage_intents.index',
        path: '/manage-intents',
        component: () => {
            return import('@/views/digital_human/manage_intents/Index');
        },
        meta: {
            loadInModal: true,
            actions: true,
            title: 'manage the intents',
            breadcrumbName: 'manage intents',
            size: 'normal',
            flowStartsWith: 'manage_intents.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
        },
    },
    {
        name: 'manage_intents.create-intent',
        path: '/create-intent',
        component: () => {
            return import('@/views/digital_human/manage_intents/CreateIntent');
        },
        meta: {
            loadInModal: true,
            actions: true,
            title: 'create intent',
            breadcrumbName: 'create intent',
            flowStartsWith: 'manage_intents.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
    {
        name: 'manage_intents.delete-intent',
        path: '/delete-intent',
        component: () => {
            return import('@/views/digital_human/manage_intents/DeleteIntent');
        },
        meta: {
            loadInModal: true,
            actions: true,
            title: 'delete intent',
            breadcrumbName: 'delete intent',
            flowStartsWith: 'manage_intents.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
    {
        name: 'manage_intents.edit-intent',
        path: '/edit-intent',
        component: () => {
            return import('@/views/digital_human/manage_intents/EditIntent');
        },
        meta: {
            loadInModal: true,
            title: 'edit intent',
            breadcrumbName: 'edit intent',
            flowStartsWith: 'manage_intents.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
    {
        name: 'manage_intents.training-phrases-report',
        path: '/training-phrases-report',
        component: () => {
            return import('@/views/digital_human/manage_intents/TrainingPhrasesReport');
        },
        meta: {
            loadInModal: true,
            title: 'training phrases report',
            breadcrumbName: 'training phrases report',
            flowStartsWith: 'manage_intents.index',
            allowedRoles: ['SUPER_ADMIN', 'DIGITAL_HUMAN_WRITER'],
            requiredAppAccess: ['MIA'],
            product: 'dh',
            hideFromSearch: true,
        },
    },
];

export default routes;
