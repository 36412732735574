<template>
    <FinalForm
        ref="form"
        class="h-full"
        :submit="submit"
        :initial-values="initialValues"
        @change.self="$emit('change', $event)"
    >
        <template #default="props">
            <form
                class="w-full h-full"
                :class="formClass"
                @submit.prevent="e => handleSubmit(e, props.handleSubmit)"
            >
                <slot
                    name="default"
                    v-bind="props"
                />
            </form>
        </template>
    </FinalForm>
</template>

<script>
import { FinalForm } from 'vue-final-form';
import InitializeFormMixin from '@/components/form/InitializeFormMixin';
import EventBus from '@/utils/EventBus';

export default {
    name: 'Form',

    components: {
        FinalForm,
    },

    mixins: [InitializeFormMixin],

    props: {
        formClass: {
            type: String,
            required: false,
            default: undefined,
        },

        submit: {
            type: Function,
            required: true,
        },

        initialValues: {
            type: Object,
            default: () => ({}),
        },
    },

    emits: ['change'],

    data() {
        return {
            submitting: false,
        };
    },

    computed: {
        formState() {
            return this.$refs.form.formState;
        },

        formApi() {
            return this.$refs.form.finalForm;
        },
    },

    mounted() {
        EventBus.on('locale-changed', this.restart);
    },

    beforeUnmount() {
        EventBus.off('locale-changed', this.restart);
    },

    methods: {
        async handleSubmit(e, submit) {
            if (this.submitting) {
                return;
            }

            this.submitting = true;

            try {
                await submit();

                const el = document.querySelector('.form-error');

                if (el) {
                    el.scrollIntoView({ block: 'center', behavior: 'smooth' });
                }
            } finally {
                this.submitting = false;
            }
        },

        restart() {
            this.$refs.form.finalForm.restart();
        },
    },
};
</script>
