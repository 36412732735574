export default [
    {
        path: '/attachments/gallery',
        name: 'atts.gallery',
        component: () => import(/* webpackChunkName: "attachments" */ '@/views/atts/Gallery'),
        meta: {
            loadInModal: true,
            actions: true,
            breadcrumbName: 'attachments',
            title: 'attachments',
            flowStartsWith: 'atts.gallery',
            product: 'atts',
        },
    },
];
