import { computed, ref, watch } from 'vue';
import { difference } from 'lodash-es';
import store from '@/store';
import { useAlertStore } from '@/stores/alert';
import AuthDataProvider from '@/api/auth';

// If the logged-in user is Quext admin and has customer context selected
// then we need to fetch customer's apps access and refresh it from time to time
export const useCustomerAccessPolling = () => {
    const REFRESH_INTERVAL = 60000;

    const pollingIntervalId = ref(null);

    const activeCustomerId = computed(() => store.getters['auth/customerId']);
    const activeCustomerAppCodes = computed(() => store.getters['auth/customerAppCodes']);

    const pollingRequired = computed(() => store.getters['auth/isQuext'] && Boolean(activeCustomerId.value));

    const { notifyError } = useAlertStore();

    const pullCustomerAppAccess = async () => {
        if (!activeCustomerId.value) {
            return;
        }

        try {
            const { content } = await AuthDataProvider.adapter.getList('customerAppAccesses', { customerId: activeCustomerId.value });

            if (
                content.length !== activeCustomerAppCodes.value.length ||
                difference(
                    content.map(app => app.appCode),
                    activeCustomerAppCodes.value
                ).length > 0
            ) {
                await store.dispatch('auth/setCustomerApps', content);
            }
        } catch (error) {
            notifyError(error);
        }
    };

    const startPolling = async () => {
        clearInterval(pollingIntervalId.value);
        await pullCustomerAppAccess();
        pollingIntervalId.value = setInterval(pullCustomerAppAccess, REFRESH_INTERVAL);
    };

    const stopPolling = () => {
        clearInterval(pollingIntervalId.value);
    };

    watch(
        () => activeCustomerId.value,
        async () => {
            stopPolling();
            if (pollingRequired.value) {
                await startPolling();
            }
        }
    );

    return {
        pollingRequired,
        startPolling,
        stopPolling,
    };
};
