import { useIotPermissionsStore } from '@/stores/iot/permissions';
import { merge } from 'lodash-es';

/**
 *
 * @param {string | Array<string>} permissionIds Permission code(s)
 * @param {boolean} exist Whether a permission(s) should exist
 */
export const createPermissionChecker =
    (permissionIds, exist = true, value) =>
        () => {
            const hasPermissions = useIotPermissionsStore().hasPermissions(permissionIds, value);
            return exist ? hasPermissions : !hasPermissions;
        };

export const prepareIotRoutes = routes =>
    routes.map(route =>
        merge(
            {
                meta: {
                    loadInModal: true,
                    size: 'large',
                    product: 'iot',
                    requiredAppAccess: ['IOT'],
                    requiresCustomer: true,
                    requiresCommunity: true,
                },
            },
            route
        )
    );
export default []; //needs for routes collector
