<script>
import { mapActions } from 'vuex';

export default {
    props: {
        logout: {
            type: Function,
            required: true,
        },
    },

    computed: {
        isInactive() {
            return this.$store.state.idleVue.isIdle;
        },

        nbSeconds() {
            return process.env.VUE_APP_IDLE_TIME / 1000;
        },
    },

    watch: {
        isInactive: {
            handler: function () {
                if (this.isInactive) {
                    this.addFlash('your session has timed out, please login to continue');
                    this.logout();

                    // update the url to query string to '/' (homepage)
                    // that means the user will be signing to the homepage if he logged out because of IDLE activity.
                    history.pushState({}, null, '/');
                } else {
                    this.resetFlash();
                }
            },

            immediate: true,
        },
    },

    methods: {
        ...mapActions({
            addFlash: 'flashes/addFlash',
            resetFlash: 'flashes/resetFlash',
        }),
    },

    render() {
        return null;
    },
};
</script>
