<template>
  <div :class="['counter', $attrs['class']]">
    <template v-if="!readOnly">
      <amount-input
        :precision="0"
        class="counter__input"
        :amountClass="['text-center rounded-none outline-none w-full px-2', amountClass]"
        @input="$emit('change', $event)"
        v-bind="{
          ...inputAttrs,
          value,
          disabled,
          min,
          max,
        }"
        v-on="focusEvents"
      >
        <template v-slot:before>
          <div class="counter__wrapper counter__wrapper--left">
            <button
              type="button"
              class="counter__button"
              :class="{
                'counter__button--disabled': disabled || minDisabled,
              }"
              :disabled="minDisabled || disabled"
              @click="$emit('change', value - 1)"
              v-on="focusEvents"
              >
                <icon name="minus" class="counter__icon" />
            </button>
          </div>
        </template>

        <template #icon>
          <slot name="icon" />
        </template>

        <template v-slot:after>
          <div class="counter__wrapper counter__wrapper--right">
            <button
              type="button"
              class="counter__button"
              :class="{
                'counter__button--disabled': disabled || maxDisabled,
              }"
              :disabled="maxDisabled || disabled"
              @click="$emit('change', value + 1)"
              v-on="focusEvents"
              >
                <icon name="add" class="counter__icon" />
            </button>
          </div>
        </template>
      </amount-input>

      <span v-if="!hideHint"  class="form-hint">{{hint}}</span>
    </template>

    <template v-else>
        {{value}}
    </template>
  </div>
</template>

<script>
import { omit } from "lodash-es";
import { i18n } from '@/i18n';
import Icon from "@/components/ui/Icon";
import AmountInput from "@/components/ui/AmountInput";

export default {
  components: { AmountInput, Icon },

  inheritAttrs: false,

  props: {
    /**
     * Counter value
     */
    value: {
      type: [String, Number],
    },
    /**
     * Max available value
     */
    max: {
      type: Number,
      required: false,
    },
    /**
     * Min availbale value
     */
    min: {
      type: Number,
      default: 1,
    },
    /**
     * If the control should be disabled
     */
    disabled: {
      type: Boolean,
    },
    /**
     * Input element class
     */
    amountClass: {
      type: [String, Array],
    },
    /**
     * Whether input is readonly
     */
    readOnly: {
      type: Boolean,
    },
     /**
     * If the hint should be hide
     */
     hideHint: {
      type: Boolean,
      default: false,
    },
  },

  emits: [
    /**
    * 	Emitted on value change
    */
    'change',
    /**
     * Emitted on focus
     */
    'focus',
    /**
     * Emitted on blur
     */
    'blur',
  ],

  computed: {
    inputAttrs(){
      return omit(this.$attrs, 'class');
    },

    minDisabled() {
      return this.value <= this.min;
    },

    maxDisabled() {
      return this.value >= this.max;
    },

    hint(){
      const min = this.min ?? i18n.global.t('hints.notLimited');
      const max = this.max ?? i18n.global.t('hints.notLimited');

      return i18n.global.t('hints.minMax', { min, max });
    },

    focusEvents() {
        return {
          blur: () => {
            this.$emit("blur");
          },
          focus: () => {
            this.$emit("focus");
          },
        };
      },
  },
};
</script>

<style scoped>
.counter__wrapper {
  @apply border-gray-300 p-1 h-10 w-10;
  min-width: 2.5rem;

  &--left {
    @apply border-r;
  }

  &--right {
    @apply border-l;
  }
}

.counter__input {
  @apply inline-flex items-center border rounded-xs;
}

.counter__button {
  @apply w-full h-full flex justify-center items-center;

  &:not(.counter__button--disabled):hover {
    @apply bg-active-25;
  }
}

.counter__icon {
  @apply text-active-500 h-4 w-4 min-w-4;
}

.counter__button--disabled .counter__icon {
  @apply text-graphite-500;
}
</style>
