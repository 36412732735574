import { cloneDeep } from 'lodash-es';

export const getDefaultActions = () => {
    let actions = [];
    const files = require.context('./', true, /\.js$/i);

    files.keys().map(key => {
        const filename = key.split('/').pop().split('.')[0];
        if (filename !== 'index') {
            const fileActions = require(`${key}`);
            actions = actions.concat(cloneDeep(fileActions.default));
        }
    });

    return actions;
};
