import { defineStore } from 'pinia';

export const useLocksStore = defineStore('iotLocks', {
    state: () => ({
        lock: null,
        locksForInstallation: {},
    }),
    getters: {
        isAvailableAction: state => permission => {
            return !!state.lock?.metaInfo?.state?.availableActions.includes(permission);
        },

        isSecondaryReaderAvailable: state => {
            return !!state.lock?.additionalReaderType;
        },
    },
    actions: {
        setLock(lock) {
            this.lock = lock;
        },

        updateLocksForInstallation(lockId, data) {
            if (!this.locksForInstallation[lockId]) {
                this.locksForInstallation[lockId] = {};
            }
            Object.keys(data).forEach(key => {
                this.locksForInstallation[lockId][key] = data[key];
            });
        },

        clearLocksForInstallation(lockId) {
            if (lockId) {
                delete this.locksForInstallation[lockId];
            } else {
                this.locksForInstallation = {};
            }
        },
    },
});
