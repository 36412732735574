import {
    SET_AMENITY_TYPE,
    SET_AMENITIES,
    SET_AMENITIES_DETAILS,
    SET_CHANGED_STATE,
    SET_AMENITY,
    SET_SETUP_COMPLETED,
    SET_LOADING,
    CLEAN_STATE,
} from './mutations';

const setAmenityType = ({ commit }, payload) => commit(SET_AMENITY_TYPE, payload);

const setAmenities = ({ commit }, payload) => commit(SET_AMENITIES, payload);

const setAmenitiesDetails = ({ commit }, payload) => commit(SET_AMENITIES_DETAILS, payload);

const setSetupCompleted = ({ commit }, payload) => commit(SET_SETUP_COMPLETED, payload);

const setChangedState = ({ commit }, payload) => commit(SET_CHANGED_STATE, payload);

const setAmenity = ({ commit }, payload) => commit(SET_AMENITY, payload);

const setLoading = ({ commit }, payload) => commit(SET_LOADING, payload);

const cleanState = ({ commit }, payload) => commit(CLEAN_STATE, payload);

export default {
    setAmenityType,
    setAmenities,
    setAmenitiesDetails,
    setChangedState,
    setAmenity,
    setSetupCompleted,
    setLoading,
    cleanState,
};
