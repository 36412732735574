import { createPermissionChecker, prepareIotRoutes } from '../../helper';

const lockRoutes = [
    {
        name: 'iot.locks.pdk',
        path: '/iot/locks/pdk/:lockId',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/Details.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock details',
            title: 'lock details',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('LOCK_READ'),
        },
    },
    {
        name: 'iot.locks.pdk.lock-history',
        path: '/iot/locks/pdk/:lockId/lock-history',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/LockHistory.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'lock history',
            title: 'lock history',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('PDK_AUDIT_LOGS_READ_LIST'),
        },
    },
    {
        name: 'iot.locks.pdk.change-location',
        path: '/iot/locks/pdk/:lockId/change-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/ChangeLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'change location',
            title: 'change location',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('PDK_DOOR_CHANGE_LOCATION'),
        },
    },
    {
        name: 'iot.locks.pdk.hardware-controller',
        path: '/iot/locks/pdk/:lockId/hardware-controller',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/HardwareController.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'hardware controller',
            title: 'hardware controller',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('PDK_DOOR_READ_HARDWARE'),
        },
    },
    {
        name: 'iot.locks.pdk.primary-reader-configuration',
        path: '/iot/locks/pdk/:lockId/primary-reader-configuration',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/ReaderConfiguration.vue'),
        props: {
            readerResource: 'pdk_primary_reader_config',
        },
        meta: {
            actions: true,
            breadcrumbName: 'primary reader configuration',
            title: 'primary reader configuration',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('PDK_DOOR_READ_CONFIG'),
        },
    },
    {
        name: 'iot.locks.pdk.secondary-reader-configuration',
        path: '/iot/locks/pdk/:lockId/secondary-reader-configuration',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/ReaderConfiguration.vue'),
        props: {
            readerResource: 'pdk_secondary_reader_config',
        },
        meta: {
            actions: true,
            breadcrumbName: 'secondary reader configuration',
            title: 'secondary reader configuration',
            flowStartsWith: 'iot.locks.index',
            permissionChecker: createPermissionChecker('PDK_DOOR_READ_CONFIG'),
        },
    },
];

const managementRoutes = [
    {
        name: 'iot.locks.lock-management.pdk',
        path: '/iot/locks/management/pdk',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/management/Index.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'pdk',
            title: 'lock installation',
            flowStartsWith: 'iot.locks.lock-management.pdk',
            permissionChecker: createPermissionChecker(['PDK_DOOR_UPDATE', 'PDK_CLOUD_NODE_READ_ALL']),
        },
    },
    {
        name: 'iot.locks.lock-management.pdk.select-location',
        path: '/iot/locks/management/pdk/:lockId/select-location',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/management/SelectLocation.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'select location',
            title: 'select location',
            flowStartsWith: 'iot.locks.lock-management.pdk',
            permissionChecker: createPermissionChecker('PDK_DOOR_CHANGE_LOCATION'),
        },
    },
    {
        name: 'iot.locks.lock-management.pdk.import-nodes',
        path: '/iot/locks/management/pdk/import-nodes',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/devices/locks/pdk/management/ImportNodes.vue'),
        meta: {
            actions: true,
            breadcrumbName: 'import nodes',
            title: 'import nodes',
            flowStartsWith: 'iot.locks.lock-management.pdk.import-nodes',
            permissionChecker: createPermissionChecker('PDK_CLOUD_NODE_READ_ALL'),
        },
    },
];

export default prepareIotRoutes([...lockRoutes, ...managementRoutes]);
