export default {
    community: {
        get: ['GET', 'community/community-by-customer'],
    },
    unit: 'unit',
    unitAvailability: {
        upload: ['POST', 'unit/availability'],
        validate: ['POST', 'unit/availability/validate'],
        getTemplate: ['GET', 'unit/availability/template'],
        sync: ['POST', 'unit/availability/sync'],
    },
    unitEntity: 'unit/{idUnit}',
    categories: 'category',
    categoriesCustom: 'category/custom',
    intents: 'intent/category/{category}',
    intent: 'intent',
    customIntents: 'intent/community/{communityId}/category/{category}',
    syncCustomIntents: 'intent/community/{communityId}/category/{category}/sync',
    subIntents: 'intent/community/{communityId}/detail/{intent}',
    subIntentsLevel: 'intent/community/{communityId}/level/{intent}',
    subIntentsDetail: 'intent/level/{intent}',
    cards: 'card/community/{community_id}/intent/{intent}',
    card: 'card',
    photosLibrary: 'file/community/{communityId}',
    allGlobalIntentResponses: 'intent/response/global/{intent}',
    allCustomIntentResponses: 'intent/response/custom/{intent}',
    allCommunityCustomIntentResponses: 'response/custom/all',
    allCustomResponsesByCommunity: 'community/{communityId}/intent/{intent}/response',
    allCustomResponses: 'intent/response/custom',
    response: 'response',
    allGlobalResponses: 'response/global',
    allGlobalResponsesByResponseId: 'response/global/{responseId}',
    communitySettings: 'setting/community/{communityId}',
    allVariablesSettings: 'setting/community/{communityId}/variable',
    dashboardEmbedUrl: 'aws/community/{communityId}/embed-url',
    allFeesSettings: 'setting/community/{communityId}/fee',
    communityIntents: 'intent/community',
    communityIntentsHasSubIntents: 'communities/intent-has-active-sub-intents',
    editResponse: 'intent/response',
    customResponse: 'response/custom',
    blockCustomResponse: 'response/custom/block',
    communitySetting: 'setting/community',
    photo: 'file',
    photoD: 'file/{url}',
    globalResponse: 'response/global',
    toggleGlobalResponse: 'response/global/enable',
    toggleCustomResponse: 'response/custom/enable',
    promoteResponse: 'response/custom/promote/{communityId}/{intentId}/{isPositive}',
    conversationReport: 'report/conversation',
    conversationReportPdf: 'report/conversation/pdf',
    customResponseReportByTopic: 'report/custom/topic/{topic}',
    customResponseReportByTopicPdf: 'report/custom/topic/pdf/{topic}',
    transcriptReport: 'conversation/transcript/{communityId}/{startDate}/{endDate}',
    mismatchReport: 'conversation/mismatched/{communityId}/{startDate}/{endDate}',
    customResponseCustomer: 'report/custom/customer',
    customResponseCustomerPdf: 'report/custom/customer/pdf',
    previewGlobalResponsesReport: 'report/global/response/preview',
    previewCustomResponsesTopicReport: 'report/custom/topic/preview/{topic}',
    previewCustomResponsesCustomerReport: 'report/custom/customer/preview/responses',
    previewConversationGlobalReport: 'report/conversation/preview/global/{communityId}',
    previewConversationCustomReport: 'report/conversation/preview/custom/{communityId}',
    globalResponseReport: 'report/global/response',
    globalResponseReportPdf: 'report/global/response/pdf',
    communityHasConversation: 'conversation/community',
    communityConversationById: 'conversation/community/{communityId}',
    translateReport: 'report/translate/response',
    allGuest: 'conversation/guest/community/{communityId}?sort={direction}&search={search}',
    guest: 'conversation/guest/{id} ',
    guestConversations: 'conversation/guest/{id}/transcript?communityId={communityId}',
    getIntent: 'intent/{intent}?language={language}',
    addIntent: 'intent',
    editIntent: 'intent',
    deleteIntent: 'intent',
    getHoursOfOperations: 'setting/community/hours-of-operation',
    previewTrainingPhrasesReport: 'report/trainingPhrases/preview',
    trainingPhrasesReportPdf: 'report/trainingPhrases/{language}',
};
