<template>
<!--    ":is" is a property of component that can accept ComponentDefinition.-->
<!--    https://vuejs.org/v2/api/#component-->
    <component :is="iconComponent"></component>
</template>

<script>
    import { defineAsyncComponent } from 'vue';
    import {upperFirst} from "lodash-es";

    export default {
        props: {
            name: {
                required: true
            }
        },
        computed: {
            iconComponent() {
                let componentName =  upperFirst(this.name) + 'Icon';
                return defineAsyncComponent(() => import(/* webpackChunkName: "icons", webpackMode: "lazy-once" */`@/components/ui/icons/${componentName}`));
            }
        },
    }
</script>
