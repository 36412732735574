<template>
    <FinalField
        ref="field"
        :name="name"
        :validate="validate"
    >
        <template #default="props">
            <div :class="containerClass">
                <FieldLabel
                    v-if="label || !!$slots['label']"
                    :name="name"
                    :tooltip="tooltip"
                >
                    <slot name="label">
                        {{ label }}
                    </slot>
                </FieldLabel>

                <toggle-input
                    :model-value="props.value"
                    :name="name"
                    v-bind="$attrs"
                    @update:modelValue="props.change"
                />

                <span
                    v-if="props.meta.error && props.meta.touched"
                    class="form-hint form-error"
                >
                    {{ props.meta.error }}
                </span>
            </div>
        </template>
    </FinalField>
</template>

<script>
import { FinalField } from 'vue-final-form';
import ToggleInput from '@/components/ui/ToggleInput';
import FieldLabel from '@/components/form/FieldLabel';

export default {
    name: 'ToggleField',
    components: {
        FinalField,
        ToggleInput,
        FieldLabel,
    },

    props: {
        name: {
            type: String,
            default: undefined,
        },

        label: {
            type: String,
            default: undefined,
        },

        validate: {
            type: [Function, Array],
            required: false,
            default: undefined,
        },

        containerClass: {
            type: String,
            default: 'form-col',
        },

        tooltip: {
            type: String,
            default: '',
        },
    },
};
</script>
