export const resources = {
    permissions: {
        get: ['POST', '/identities/{identityId}/permissions'],
    },
};

export const proxyResources = {
    permissions: {
        identityPermissions: {
            get: ['GET', '/identities/{cognitoUserId}'],
            update: ['PUT', '/identities/{cognitoUserId}'],
        },
    },

    targetApp: {
        quextUser: {
            notify: ['PUT', '/identities/{identityId}'],
        },
        customerUser: {
            notify: ['PUT', '/customers/{customerId}/customer-users/{profileId}'],
        },
    },
};
