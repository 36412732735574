export const SET_INITIALIZED = 'SET_INITIALIZED';

export const SET_SPACE_TYPES = 'SET_SPACE_TYPES';

export const SET_PROPERTIES = 'SET_PROPERTIES';

export default {
    [SET_INITIALIZED]: (state, payload) => {
        state.initialized = payload;
    },

    [SET_SPACE_TYPES]: (state, payload) => {
        state.spaceTypes = payload.reduce((acc, item) => {
            acc[item.space_type_id] = item;
            return acc;
        }, {});
    },

    [SET_PROPERTIES]: (state, payload) => {
        state.properties = payload.reduce((acc, item) => {
            acc[item.property_id] = item;
            return acc;
        }, {});
    },
};
