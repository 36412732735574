import { createPermissionChecker, prepareIotRoutes } from './helper';

export default prepareIotRoutes([
    {
        name: 'iot.residents-queue.index',
        path: '/iot/residents-queue',
        component: () => import(/* webpackChunkName: "iot" */ '@/views/iot/residents_queue/Index'),
        meta: {
            actions: true,
            breadcrumbName: 'resident queue',
            title: 'resident queue',
            flowStartsWith: 'iot.residents-queue.index',
            permissionChecker: createPermissionChecker('RESIDENT_READ_RESIDENT_EVENT_QUEUE'),
        },
    },
]);
