<template>
    <div
        class="message-block"
        :class="{
            'message-block--default': type === 'default',
            'message-block--warning': type === 'warning',
        }"
    >
        <!-- @slot Content inside the block -->
        <slot />
    </div>
</template>

<script>
export default {
    name: 'MessageBlock',

    props: {
        type: {
            type: String,
            default: 'default',
            validator: value => ['default', 'warning'].includes(value),
        },
    },
}
</script>

<style scoped>
.message-block {
    @apply p-6 border font-frank text-2sm font-400 leading-5;
    &--default {
        @apply text-active-800 bg-active-50 border-active-150;
    }
    &--warning {
        @apply text-red-900 bg-red-100 border-red-300;
    }
}
</style>