const hasPermission = (state, getters, rootState, rootGetters) => permissionId => {
    return (
        rootGetters['auth/getAppPermissions']('MSGS')?.find(({ communityId, permissions }) => {
            return communityId === rootGetters['auth/community']?.id && permissions.find(({ id }) => id === permissionId);
        }) !== undefined
    );
};

const tmpBuffer = state => state.tmpBuffer;

const listFilterValues = state => state.list.filterValues;

export default {
    hasPermission,
    tmpBuffer,
    listFilterValues,
};
